import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from '../contexts/locale.js'
import { Container, Row, Col, Button, Modal, Tooltip, Spinner, OverlayTrigger, Form, Nav, Tab, InputGroup, ButtonGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format, setDay, differenceInMinutes, addDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';

import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';
import SearchContext, { SearchProvider } from '../contexts/searchContext';
import InterventionContext from '../contexts/interventionContext.js';
import AlertSection from "../AlertSection.js"
import JoditEditor from 'jodit-react';

import { getSelectedElement, checkComponentPresence, myTimestampToDate, setMyDate, makeid, getTimeStr, myDateToTimestamp, getTimeValue } from "../../util/ui_utils.js"
import { ModalInitButton, WeekDays, ServiceBadge, renderActivityPeriodListPopover } from '../StructureGraphicElements.js'

import firebase from 'firebase/compat/app'

import { addWorkflow as addWorkflow2, editWorkflow as editWorkflow2 } from '../../Firebase2/Storage2/dbWorkflow';
import { editFileTracker as editFileTracker2 } from '../../Firebase2/Storage2/dbFileStorage.js';


import FrequencySelector from '../FrequencySelector'
import { getInterventionById } from '../../Firebase2/Storage2/dbIntervention'


const section = 'workflow'
const defaultcolors = [
	'#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
]

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalHelpmanager({ item, setItem, t, is_new, small, item_list, canWrite, workarea_list }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid
	const quillRef = useRef();

	const childRefAlert = useRef()
	const new_elem = {
		help_text: ''
	}
	const initValues = async () => {
	}
	const config = useMemo(
		() => ({
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			toolbarButtonSize: 'small',
			placeholder: '',
			showWordsCounter: false,
			showCharsCounter: false,
			enter: "BR",
			buttons: "bold,italic,underline,font,superscript,subscript"
		}),
		[]
	);
	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(new_elem)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		new_elem.media_list = []
		setCurrentElement(new_elem)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const updateElement = (el) => {
		setCurrentElement(el)
		setEditedElement(true)

	}
	useEffect(() => {
		initValues()
	}, [])


	const setLastChange = (tt) => {
		console.log(tt)

	}

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		/* console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addWorkflow2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editWorkflow2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		} */
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={'Nuovo help'} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} backdrop="static" className="bg-secondary" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">Crea help</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">Modifica help</span>
							</>
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">testo per help </Form.Label>
						</Form.Group>
						<JoditEditor
							ref={quillRef}
							value={curElement.help_text}
							config={config}
							tabIndex={1} // tabIndex of textarea
							onBlur={(newContent) => onChangeHandler({ name: 'help_text', value: newContent })} // preferred to use only this option to update the content for performance reasons
							onChange={(newContent) => { }}
						/>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}






export default translate()(ModalHelpmanager)