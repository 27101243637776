import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Card, ButtonGroup } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'
import QRCode from 'qrcode.react'
import { useParams } from "react-router-dom"
import { useReactToPrint } from 'react-to-print';
import ReactDOM from 'react-dom';



import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import { getElementPreview, getBaseObjectValue, getValue } from "../util/ui_utils.js"











function ModalDepartmentPreview({ element }) {
    const t = useTranslate();
    const { domain } = useContext(DomainContext)
    const { locale } = useContext(LocaleContext)
	const { contract, departmenttype_dict, structureElements  } = useContext(GlobalDataContext)

    let { cid } = useParams();
    let contractId = cid
    const componentRef = useRef();

    const [showDepartmentPreview, setShowDepartmentEdit] = useState(false)

    const handleClosePreview = () => setShowDepartmentEdit(false);

    const handleShowPreview = () => {
        setShowDepartmentEdit(true)
    }
    const handlePrint = useReactToPrint({
        pageStyle: pageStyle,
        content: () => componentRef.current,
    });


/*     const savePdf = () =>{
        ReactDOM.render(<Viewer  element={element}/>, document.getElementById('root'));


    } */

    return (
        <>
            <Button className="me-1" variant="outline-info" onClick={() => handleShowPreview()}><FontAwesomeIcon icon="print" /> Stampa QrCode</Button>
            <Modal show={showDepartmentPreview} onHide={handleClosePreview} backdrop="static" className="bg-secondary">
                <Modal.Header closeButton >
                    <Modal.Title>
                        {element.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Card className="qrcard" ref={componentRef}>
                            <Card.Header className="p-2">
                                {element.name} 
                            </Card.Header>
                            <Card.Body>
                                <Row className="">
                                    <Col>
					                    <QRCode value={JSON.stringify({ id: element.id, nome: element.name, dimensione: element.size, parent_list: element.parent_list })} />
                                    </Col>
                                    <Col className="p-0">
                                        <Row className="my-auto h-100">
                                            <Col className="text-center my-auto">
                                                {getElementPreview(contract, '90px')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreview}>
                        {t('modal.close')}
                    </Button>
                    <Button variant="info" onClick={handlePrint}>
                    <FontAwesomeIcon icon="print" />  {t('modal.print')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
/* 
const Viewer = ({element}) =>{
    const t = useTranslate();
    const { domain } = useContext(DomainContext)
    const { locale } = useContext(LocaleContext)
	const { contract, departmenttype_dict, structureElements  } = useContext(GlobalDataContext)

    return (
                    <PDFViewer>
                        
        <MyDocument structureElements={structureElements} element={element} departmenttype_dict={departmenttype_dict} locale={locale} contract={contract} />
                    </PDFViewer>

    )
}
const styles = StyleSheet.create({
  page: { backgroundColor: 'tomato' },
  section: { color: 'white', textAlign: 'center', margin: 30 }
});

// Create Document Component
const MyDocument = ({structureElements, element, departmenttype_dict, locale, contract }) => (
    <Document>
      <Page size="A4" style={styles.page}>
                        <Card className="qrcard" >
                            <Card.Header >
                                {structureElements.all_department_dict[element.parent_list[3]].name}
                                <br/>
                                {element.name} <i>{getValue(departmenttype_dict[element.departmenttype.id], 'name')}</i> 
                            </Card.Header>
                            <Card.Body>
                                <Row className="">
                                    <Col className="p-1">
                                        <QRCode value={getDepartmentData(element, locale, structureElements)} size={110}/>
                                    </Col>
                                    <Col className="p-0">
                                        <Row className="my-auto h-100">
                                            <Col className="text-center my-auto">
                                                {getElementPreview(contract, '90px')}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                            </Card.Footer>
                        </Card>
      </Page>
    </Document>
  );
 */
const pageStyle = `
html:{
    size: 90mm 60mm;
    backgroundColor: '#ff0000';

}
    .qrcard{
        width: 90mm;
        height: 60mm;
    }
  @page {
    size: 90mm 60mm;
    backgroundColor: '#ff0000';
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {

  }
`;



export default translate()(ModalDepartmentPreview)