import React, { useState, useContext, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, FormControl, ButtonGroup, Nav, Tab, ProgressBar, Image } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { format, addMonths, endOfMonth } from 'date-fns'
import QRCode from 'qrcode.react'
import { useParams, useLocation, useHistory } from "react-router-dom"

import { getSelectedElement, getValue, myTimestampToDate, checkComponentPresence, makeid, propComparator, myDateToTimestamp } from "../../util/ui_utils.js"

import LocaleContext from '../contexts/locale.js';
import DomainContext from '../../components/contexts/domainContext'

import AlertSection from "../../components/AlertSection.js"
import PhoneList from "../../components/PhoneList.js"
import { ModalInitButton } from '../../components/StructureGraphicElements.js'

import firebase from 'firebase/compat/app'

import { addDomainEmployer, addEmployer as addEmployer2, editDomainEmployer, editEmployer as editEmployer2 } from '../../Firebase2/Storage2/dbEmployers';
import { uploadFileComplete as uploadFileComplete2 } from '../../Firebase2/Storage2/dbFileStorage';
import { getStructureList } from '../../Firebase2/Storage2/dbBaseCompany.js';



const section = 'employee'
export const newemployee = {
	firstname: '',
	lastname: '',
	cleaningcompany: {},
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	residence: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	phone_list: [],
	register: '',
	photo: '',
	timing: 'permanentjob',
	birthdate: firebase.firestore.Timestamp.now(),
	id_expiration: firebase.firestore.Timestamp.now(),
	work_expiration: firebase.firestore.Timestamp.now(),
	assumption_date: firebase.firestore.Timestamp.now(),
	qualification_id: -1,
	training_list: [],
	clothing_list: [],
	structure_list: [],
	study_title: {}
}

const ModalDomainEmployee = forwardRef(({ item, setItem, t, is_new, small, index, item_list, setParentList, ui_list, canWrite, functions, contract_list }, ref) => {
	const { domain } = useContext(DomainContext)
	const [fromExt, setFromExt] = useState(false)

	let history = useHistory();
	let location = useLocation()

	const childRefAlert = useRef()


	const [showEmployeeEdit, setShowEmployeeEdit] = useState(false)
	const [curElement, setCurElement] = useState(newemployee)
	const [editedElement, setEditedElement] = useState(false)

	const [isInvalid, setIsInvalid] = useState(false)
	const [uploadingState, setUploadingState] = useState(-1)
	const [structure_source_data, setStructureData] = useState({})

	useImperativeHandle(ref, () => ({
		onEdit(_el) {
			handleShowEditEmployee(_el)
			setFromExt(true)
		}
	}))

	const clearQuery = () => {
		const queryParams = new URLSearchParams(location.search)
		if (queryParams.has('elemId')) {
			queryParams.delete('elemId')
			history.replace({
				search: queryParams.toString(),
			});
		}

	}

	const handleCloseEmployee = () => {
		clearQuery()

		setShowEmployeeEdit(false);
	}

	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			console.log(byte / total)
			if (byte < total) {
				setUploadingState(100 * byte / total)
			} else {
				setUploadingState(-1)
			}

		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}
	const handleSaveEmployee = async () => {

		setShowEmployeeEdit(false);

		let error = false;
		if (curElement.firstname.length < 2) {
			error = true;
			childRefAlert.current.addAlert('danger', 'nome non valido')
		}
		if (curElement.lastname.length < 2) {
			error = true;
			childRefAlert.current.addAlert('danger', 'cognome non valido')
		}
		console.log("SAVE EMPLOYER", curElement)
		if (is_new) {
			console.log(curElement)
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.photo = url
					curElement.photo_tracker = tracker
				}
				delete curElement.file
				const return_data = await addDomainEmployer(domain, curElement)
				let newemployee_list = [...item_list]
				newemployee_list.push(return_data)
				console.log(newemployee_list)
				setParentList(newemployee_list)
				setEditedElement(false)
			}

			if (curElement.file) {
				const mypath = section
				const mediainfo = { description: 'photo', source_name: curElement.file[0].name, section: section, item_reference: {} }
				let file_data = uploadFileComplete2(curElement.domain, undefined, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}

		} else {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.photo = url
					curElement.photo_tracker = tracker
				}
				delete curElement.file
				console.log(item_list)
				let newemployee_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				newemployee_list.splice(c, 1, curElement)
				setParentList(newemployee_list)
				console.log("SAVE EMPLOYER", curElement, domain)
				const return_data = await editDomainEmployer(domain, curElement.id, curElement)
				setEditedElement(false)
			}
			if (curElement.file) {
				const mypath = section
				const mediainfo = { description: 'image', source_name: curElement.file[0].name, section: section, item_reference: {} }
				console.log(mediainfo, curElement.file[0])
				let file_data = uploadFileComplete2(domain, undefined, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
		clearQuery()
		childRefAlert.current.addAlert('info', 'se il dipendente non ha un contratto di lavoro attivo e il filtro utenti è attivo, non verrà visualizzato')
	}



	const handleShowNewEmployee = (_w) => {
		console.log('show new')
		setIsInvalid(false)
		setCurElement(newemployee)
		setShowEmployeeEdit(true)
		setEditedElement(false)
	}


	const handleShowEditEmployee = (el) => {
		let _el = item
		if (el) {
			_el = el
		}
		if (!_el.color) {
			_el.color = { ...newemployee.color }
		}
		console.log(ui_list)
		setIsInvalid(false)
		setCurElement(_el)
		setShowEmployeeEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}


	const onChangeHandler = (el, is_multi) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		if (is_multi) {
			setCurElement(e => ({ ...e, ...el }))

		} else {

			if (type === 'radio') {
				if (value === 'true') {
					value = true
				} else if (value === 'false') {
					value = false
				}
			} else if (type === 'checkbox') {
				let oldcheck = []
				try {
					oldcheck = [...curElement[name]]
				} catch (err) { }
				if (el.checked) {
					if (oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					}
				} else {
					if (oldcheck.indexOf(value) >= 0) {
						oldcheck.splice(oldcheck.indexOf(value), 1)
					}
				}
				value = oldcheck
			} else if (type === 'file') {
				console.log(el.files)
				value = el.files
				name = 'file'

			}
			if (sub) {
				value = { ...curElement[sub], [name]: value }
				name = sub;
			}
			console.log(name, value)
			setCurElement(e => ({ ...e, [name]: value }))
		}
		setEditedElement(true)
	}



	useEffect(() => {

		const fetchData = async (c_id, cb) => {
		}
		const reloadStructure = async () => {
			let _cs = { ...structure_source_data }
			const _key_list = Object.keys(structure_source_data)
			for (const k of _key_list) {
				if (curElement.contract_list.indexOf(k) < 0) {
					delete _cs[k]
				}
			}
			if (curElement.contract_list) {
				for (const c of curElement.contract_list) {
					if (_key_list.indexOf(c) < 0) {
						const return_data = await getStructureList(domain, c) || []
						if (return_data && return_data.error) {
							console.log(return_data)
						} else {
							_cs[c] = return_data
						}
					}
				}
			}

			setStructureData(_cs)
		}

		reloadStructure()

	}, [curElement.contract_list])

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('structure.labels.employee_add')} action_new={handleShowNewEmployee} action_edit={handleShowEditEmployee} action_info={handleShowEditEmployee} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEmployeeEdit} onHide={handleCloseEmployee} scrollable={true} fullscreen backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('employes.labels.new_employee')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('employes.labels.edit')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('employes.labels.info_employee')}</span>
								</>
							]
						}
						: {curElement.firstname} {curElement.lastname}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabGeneral" >
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabGeneral">{t('employes.labels.general')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabOtherInfo">{t('employes.labels.other_info')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabWorkjob">Contratto di lavoro</Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabContract">Contratti/strutture</Nav.Link>
										</Nav.Item>

									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabGeneral">
											<ElementFormGeneral element={curElement} t={t} functions={{ edit: onChangeHandler, updateData: functions.updateData }} cleaningcompany_list={ui_list.cleaningcompany_list} ui_list={ui_list} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabOtherInfo">
											<ElementFormOtherInfo element={curElement} t={t} functions={{ edit: onChangeHandler, updateData: functions.updateData }} ui_list={ui_list} canWrite={canWrite} uploadingState={uploadingState} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabWorkjob">
											<ElementFormWorkjob element={curElement} t={t} functions={{ edit: onChangeHandler, setElement: setCurElement, updateData: functions.updateData }} ui_list={ui_list} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabContract">
											<ElementFormContract element={curElement} t={t} functions={{ edit: onChangeHandler, setElement: setCurElement, updateData: functions.updateData }} ui_list={ui_list} canWrite={canWrite} contract_list={contract_list} structure_source_data={structure_source_data} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseEmployee}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button disabled={isInvalid} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveEmployee}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
})





function ElementFormGeneral({ element, functions, cleaningcompany_list, t, ui_list, canWrite }) {
	const { domain } = useContext(DomainContext)

	return (
		<>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.register')}</Form.Label>
				<Col sm="8">
					<Form.Control size="sm" type="text" name="register" value={element.register} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control size="sm" type="text" name="firstname" value={element.firstname} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeLastName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
				<Col sm="8">
					<Form.Control size="sm" type="text" name="lastname" value={element.lastname} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeAddress" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.address')} </Form.Label>
				<Col >
					<Row>
						<Col>
							<Form.Control type="text" name="street" sub="address" value={element.address.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col sm="2">
							<Form.Control type="text" name="zip" sub="address" value={element.address.zip} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							</Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" name="city" sub="address" value={element.address.city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="state" sub="address" value={element.address.state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeAddressResidence" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.residence')}</Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" name="street" sub="residence" value={element.residence.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col sm="2">
							<Form.Control type="text" name="zip" sub="residence" value={element.residence.zip} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							</Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" name="city" sub="residence" value={element.residence.city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="state" sub="residence" value={element.residence.state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<PhoneList element={element} functions={functions} t={t} canWrite={canWrite} />
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">Email</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="email" value={element.email} onChange={(event) => functions.edit(event.currentTarget)} />

				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">CF</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="cf" value={element.cf} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('employes.labels.birthdate')}</Form.Label>
				<Col sm="8">
					<DateTime initialValue={myTimestampToDate(element.birthdate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="birthdate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'birthdate', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeAddressResidence" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.birthplace')}</Form.Label>
				<Col>
					<Row>
						<Col >
							<Form.Control type="text" name="birth_city" value={element.birth_city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="birth_state" value={element.birth_state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="birth_nation" value={element.birth_nation} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								Stato
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>

			{element.id ?
				<QRCode value={JSON.stringify({ domain: domain, employee_id: element.id })} />
				:
				<QRCode value={"test"} fgColor={"#eeeeee"} />

			}
		</>
	)
}




function ElementFormOtherInfo({ element, functions, t, ui_list, uploadingState }) {

	const { locale } = useContext(LocaleContext)

	const updateStudy = (b) => {
		functions.updateData('study_titles', b)
	}
	return (
		<>
			<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.photo')}</Form.Label>
				<Col>
					<Form.Control type="file" name="photo" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.photo
						?
						<>

							<Image style={{ height: '50px' }} src={element.photo} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'photo', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			{uploadingState > -1 ?
				<Row>
					<Col>
						<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
					</Col>
				</Row>
				: <></>}
			<Form.Group as={Row} controlId="formEmployeeNote" className="border-bottom mb-1">
				<Form.Label column sm="2">Note</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>

		</>
	)
}


function ElementFormContract({ element, functions, t, ui_list, canWrite, contract_list, structure_source_data }) {
	const updateContractStructure = (el) => {
		console.log(el.checked, el.name, el.value)
		if (!el.checked) {
			console.log('check old structure')
			let index_list = []
			let _old_s_list = []
			if (element.structure_list && element.structure_list.length>0) {
				_old_s_list = [...element.structure_list]
				for (const s_id of structure_source_data[el.value].map(s => s.id)) {
					const i = element.structure_list.indexOf(s_id)
					if (i >= 0) {
						index_list.push(i)
					}
				}
			}
			if (index_list.length > 0) {
				index_list.sort().reverse()
				const value = el.value
				let _c_list = [...element.contract_list]
					if (_c_list.indexOf(value) >= 0) {
						_c_list.splice(_c_list.indexOf(value), 1)
					}
				for (const i of index_list) {
					_old_s_list.splice(i, 1)
				}
				functions.edit({ contract_list: _c_list, structure_list: _old_s_list }, true)
			} else {
				functions.edit(el)
			}
		}else{
			let _c_list = element.contract_list? [...element.contract_list]: []
			_c_list.push(el.value)
			functions.edit({ contract_list: _c_list}, true)


		}

	}
	return (
		<>
			<Row>
				<Col>
					Contratti/struture associate
				</Col>
			</Row>
			<Form.Group as={Row} className="border-bottom">
				{contract_list?.map((a, k) => (
					<Col key={k} sm="3" className={element.contract_list && element.contract_list.indexOf(a.id) >= 0 ? "m-1 bg-info text-light" : ' m-1 bg-light'}>
						<Form.Check key={k} type="checkbox" inline name="contract_list" checked={element.contract_list && element.contract_list.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => updateContractStructure(event.currentTarget)} />
						{element.contract_list && element.contract_list.indexOf(a.id) >= 0 && structure_source_data[a.id] &&
							<Row className="bg-light text-secondary">
								{
									structure_source_data[a.id].length > 0 ?
									<>
									<Col sm="12">
									<i>strutture nel contratto</i>
									</Col>
										{structure_source_data[a.id].map((a, k) => (
											<Col key={k} sm="10" className={element.structure_list && element.structure_list.indexOf(a.id) >= 0 ? "m-1 bg-secondary text-light" : ' m-1 bg-light'}>
												<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										))}
									</>
										:
										<>Nessuna struttura presente nel contratto</>
								}
							</Row>
						}
					</Col>
				))}
			</Form.Group>

			{/* 			<Form.Group as={Row} controlId="formEmployeeLevel" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.structures')}</Form.Label>
				<Col>
					{Object.entries(structure_source_data).map((s, k) => (
						<Row>
						</Row>
					))}
					{element.structure_list.filter(s_id => !structureElements.all_structure_dict[s_id]).map((s, k) => (
						<Col key={k} sm="3" className={element.structure_list && element.structure_list.indexOf(s) >= 0 ? "m-1 bg-warning text-light" : ' m-1 bg-light'}>
							<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(s) >= 0} value={s} label={'---struttura non presente---'} alt={"---"} onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>

					))}

				</Col>
			</Form.Group>  */}

		</>

	)
}

/* 
const ModalEmployerContractAssociation = ({ is_new, element, t, functions, ui_list, index, domain, contract_list, element_list, not_contract }) => {

	const newcontractgroup = {
		contract_id: -1,
		structure: {},
		department: {},
		workarea: {},
		mode: 'structure',
		permissiongroup: [],
		dashboardsection: []
	}

	const [structure_list, setStructureList] = useState([])
	const [department_list, setDepartmentList] = useState([])
	const [workarea_list, setWorkareaList] = useState([])

	const [showContractgroupEdit, setShowContractgroupEdit] = useState(false)
	const [curContractgroup, setCurContractgroup] = useState(newcontractgroup)
	const [editedElement, setEditedElement] = useState(false)
	const [loading, setLoading] = useState(false)


	const handleCloseContractgroup = () => setShowContractgroupEdit(false);

	const handleSaveContractgroup = async () => {
		console.log(domain, curContractgroup)
		if (is_new) {
			let newcontractgroups = element.contractgroups || []
			newcontractgroups.push(curContractgroup)
			console.log(newcontractgroups)
			functions.edit({ name: 'contractgroups', value: newcontractgroups })
		} else {
			let editlist = element_list
			//            const c = checkComponentPresence(curContractgroup, element.contractgroups)
			editlist.splice(index, 1, curContractgroup)
			functions.edit({ name: 'contractgroups', value: editlist })
		}
		setShowContractgroupEdit(false);
	}

	const handleShowNew = () => {
		preload = false
		console.log("new contractGroup (preload)", preload)
		setCurContractgroup(newcontractgroup)
		setShowContractgroupEdit(true)
		setEditedElement(false)
	}


	const handleShowEdit = async () => {
		preload = true
		console.log('edit contractGroup (element, index, preload)', element, index, preload)
		setShowContractgroupEdit(true)
		setCurContractgroup(element)
		setEditedElement(false)
	}


	useEffect(() => {
		console.log("UPDATE contract contract group: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeContract({ value: element.contract_id })
			if (!element.structure.id) {
				console.log("END LOAD INITIAL DATA contract")
				preload = false
			}
		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [curContractgroup.contract_id])


	useEffect(() => {
		async function preloadNext() {
			const ret_data = await onChangeContract({ value: curContractgroup.contract_id })
		}
		preloadNext()

	}, [curContractgroup.mode])


	useEffect(() => {
		console.log("UPDATE contract group structure: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeStructure({ value: getValue(element.structure, 'id') })
			if (!element.department.id) {
				console.log("END LOAD INITIAL DATA structrue")
				preload = false
			}
		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [structure_list])




	useEffect(() => {
		console.log("UPDATE contract group, department: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeDepartment({ value: getValue(element.department, 'id') })
			console.log("END LOAD INITIAL DATA department")
			preload = false
		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [department_list])


	useEffect(() => {
		console.log("UPDATE contract group workarea: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeWorkarea({ value: getValue(element.workarea, 'id') })
			if (!element.department.id) {
				console.log("END LOAD INITIAL DATA workarea")
				preload = false
			}

		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [workarea_list])



	const onChangeContract = async (el) => {
		console.log("Change contractid", domain, el)
		if (preload) {
			setCurContractgroup({ ...curContractgroup, contract_id: el.value })
		} else {
			setCurContractgroup({ ...curContractgroup, contract_id: el.value, structure: {}, department: {}, workarea: {} })
			setEditedElement(true)

		}

		if (!not_contract) {

			if (curContractgroup.mode === 'structure') {

				const return_data = await getStructureList2(domain, el.value) || []
				if (return_data && return_data.error) {
					console.log(return_data)
					return false
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					setStructureList(return_data)
					console.log('structure_list', return_data)
					return return_data
				}
			} else {
				const return_data = await getWorkareaList(domain, el.value) || []
				if (return_data && return_data.error) {
					console.log(return_data)
					return false
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					setWorkareaList(return_data)
					console.log('workarea_list', return_data)
					return return_data
				}
			}
		}

	}

	const onChangeWorkarea = async (el) => {
		if (el) {
			const workarea_id = el.value

			console.log(" workareaid", el, el.value, workarea_list)
			const workarea = getSelectedElement(workarea_id, workarea_list, 'id')
			console.log("SEL workarea", workarea_id, el.value, workarea.id, workarea)
			if (preload) {
				setCurContractgroup({ ...curContractgroup, workarea: { id: workarea.id, name: workarea.name } })
			} else {
				setCurContractgroup({ ...curContractgroup, workarea: { id: workarea.id, name: workarea.name }, department: {}, structure: {} })
				setEditedElement(true)

			}
		}

	}

	const onChangeStructure = async (el) => {
		//	setLoading(true)
		console.log("structure", el)
		if (el) {
			setLoading(true)
			const structure_id = el.value

			console.log(" structureid", el, el.value, structure_list)
			const structure = getSelectedElement(structure_id, structure_list, 'id')
			console.log("SEL STRUCTURE", structure_id, el.value, structure.id, structure)
			if (preload) {
				setCurContractgroup({ ...curContractgroup, structure: { id: structure.id, name: structure.name } })

			} else {
				setCurContractgroup({ ...curContractgroup, structure: { id: structure.id, name: structure.name }, department: {}, workarea: {} })
				setEditedElement(true)

			}
			//		functions.edit({ name: el.name, value: structure })
			let return_data = await getAllDepartmentInStructure2(structure_id)
			if (return_data && return_data.error) {
				console.error(return_data)
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let f_dict = {}
				let p_dict = {}
				for (let d of return_data) {
					if (f_dict[d.parent_list[2]]) {
						d.floor_name = f_dict[d.parent_list[2]].name
					} else {
						const f = await getFloorById(domain, curContractgroup.contract_id, structure_id, d.parent_list[1], d.parent_list[2])
						if (!f.error) {
							f_dict[d.parent_list[2]] = f
							d.floor_name = f.name
						}
					}
					if (p_dict[d.parent_list[1]]) {
						d.pavillon_name = p_dict[d.parent_list[1]].name
					} else {
						const _p = await getPavillonById(domain, curContractgroup.contract_id, structure_id, d.parent_list[1])
						console.log("PAV", _p)
						if (!_p.error) {
							p_dict[d.parent_list[1]] = _p
							d.pavillon_name = _p.name
						}
					}

				}
				setDepartmentList(return_data)
				//			setRoomList([])
				setLoading(false)
				console.log('department_list', el.value, return_data)
			}
		}
	}


	const onChangeDepartment = async (el) => {
		console.log("deparmtn id", el, department_list)
		if (!preload) {

			setEditedElement(true)
		}
		const department = getSelectedElement(el.value, department_list, 'id')
		setCurContractgroup({ ...curContractgroup, department: { id: department.id, name: department.name } })
	}





	const onChangeHandlerContractgroup = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curContractgroup[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		setCurContractgroup({ ...curContractgroup, [name]: value })
	}





	return (
		<>

			<ModalInitButton t={t} is_new={is_new} canWrite={true} title_new={not_contract ? t('user.labels.add_catalog_permission') : t('user.labels.add_contract_permission')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} is_secondary={true} />
			<Modal show={showContractgroupEdit} onHide={handleCloseContractgroup} scrollable={true} fullscreen backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{not_contract ? t('users.modal.title_newcataloggroupassing') : t('users.modal.title_newcontractgroupassing')}</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{not_contract ? t('users.modal.editcataloggrouppassing') : t('users.modal.editcontractgrouppassing')} </span>
							</>
						}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
							<Form.Label column sm="2">{not_contract ? t('global.labels.catalog') : t('global.labels.contract')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" value={curContractgroup.contract_id} name="contract_id" className="mb-2" onChange={(event) => onChangeContract({ name: 'contract_id', type: 'select', value: event.target.value })}  >
									<option value="-1" >{not_contract ? t('catalog.labels.select_catalog') : t('contract.labels.select_contract')}</option>
									{contract_list.map((f, k) => (
										<option key={k} value={f.id} >{f.name}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curContractgroup.contract_id !== -1 ?
							<>
								<Form.Group as={Row} controlId="userGroups" className="pb-1 border-bottom mb-1">
									<Form.Label column sm="2">{t('global.labels.roles')}</Form.Label>
									<Col>
										<Row>
											{Object.keys(ui_list.group_list).map((g, k) => (
												<Col key={k} sm="3">
													<Form.Check type="checkbox" name="permissiongroup" checked={curContractgroup.permissiongroup.indexOf(ui_list.group_list[g].id) >= 0} value={ui_list.group_list[g].id} label={ui_list.group_list[g].name} onChange={(event) => onChangeHandlerContractgroup(event.currentTarget)} />
												</Col>
											))}

										</Row>
									</Col>
								</Form.Group>
								{!not_contract ?
									<>
										<Form.Group as={Row} controlId="userGroups" className="pb-1 border-bottom mb-1">
											<Form.Label column sm="2">{t('users.labels.access_mode')}</Form.Label>
											<Col sm="3">
												<Form.Check type="radio" name="mode" value={'structure'} checked={curContractgroup.mode === 'structure'} label={t('global.labels.structure')} onChange={(event) => onChangeHandlerContractgroup(event.currentTarget)} />
												<Form.Check type="radio" name="mode" value={'workarea'} checked={curContractgroup.mode === 'workarea'} label={t('global.labels.workarea')} onChange={(event) => onChangeHandlerContractgroup(event.currentTarget)} />
											</Col>
										</Form.Group>
										{curContractgroup.mode === 'structure' || curContractgroup.mode === undefined ?
											<Form.Group as={Row} controlId="userGroups" className="pb-1">
												<Form.Label column sm="2">{t('users.labels.filter_structure')}
												</Form.Label>
												<Col sm="3">
													<Form.Control as="select" className="mb-2" name="structure" value={getValue(curContractgroup.structure, 'id')} onChange={(event) => onChangeStructure(event.currentTarget)} >
														<option value="-1" >{t('structure.labels.all_structure')}</option>
														{structure_list
															? [Object(structure_list).map((f, k) => (
																<option key={k} value={f.id} >{f.name}</option>
															))]
															: ''
														}
													</Form.Control>
												</Col>
												<Col sm="5">
													{loading ? <Spinner animation="border" role="status">
														<span className="sr-only">Loading...</span>
													</Spinner> : <>
														<Form.Control as="select" className="mb-2" name="department" value={getValue(curContractgroup.department, 'id')} onChange={(event) => onChangeDepartment(event.currentTarget)} >
															<option value="-1" >{t('structure.labels.all_department')}</option>
															{department_list
																? [Object(department_list).map((f, k) => (
																	<option key={k} value={f.id} >{f.pavillon_name} | {f.floor_name} | {f.name}</option>
																))]
																: ''}
														</Form.Control>
													</>}
												</Col>
											</Form.Group>
											:
											<Form.Group as={Row} controlId="userGroups" className="pb-1">
												<Form.Label column sm="2">{t('users.labels.filter_workarea')}
													{loading ? <Spinner animation="border" role="status">
														<span className="sr-only">Loading...</span>
													</Spinner> : ''}</Form.Label>
												<Col sm="3">
													<Form.Control as="select" className="mb-2" name="workarea" value={getValue(curContractgroup.workarea, 'id')} onChange={(event) => onChangeWorkarea(event.currentTarget)} >
														<option value="-1" >{t('workarea.labels.all_workarea')}</option>
														{workarea_list
															? [Object(workarea_list).map((f, k) => (
																<option key={k} value={f.id} >{f.name}</option>
															))]
															: ''
														}
													</Form.Control>
												</Col>
											</Form.Group>
										}
									</>
									: ''}
							</> : ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseContractgroup}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveContractgroup}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
 */
function ElementFormWorkjob({ element, functions, t, ui_list, canWrite }) {

	const onChangeHandlerQualification = (q_id) => {

		const q = getSelectedElement(q_id, ui_list.workjob_list, 'id')
		console.log(q)
		if (q.id) {

			//			functions.edit({ name: 'qualification_id', value: q_id })

			if (q.training_list) {
				const _need_training_list = q.training_list.map(t => ({ ...t, execution_date: firebase.firestore.Timestamp.now(), expiration_date: firebase.firestore.Timestamp.now() }))
				//FIXME cerca tra eventuali titoli validi _> inserisci data di scadenza su lista nuova
				for (let nt of _need_training_list) {
					const training_list = element.training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
					if (training_list.length > 0) {
						const training = training_list[0]
						nt.done = training.done
						nt.execution_id = training.id
						nt.execution_date = training.execution_date
						nt.expiration_date = training.expiration_date || ''
					} else {
						nt.done = false
						delete nt.execution_id
					}
				}
				functions.setElement({ ...element, clothing_list: q.clothing_list ? q.clothing_list : [], qualification_id: q_id, need_training_list: _need_training_list ? _need_training_list : [] })
			} else {
				functions.setElement({ ...element, clothing_list: q.clothing_list ? q.clothing_list : [], qualification_id: q_id, need_training_list: [] })
			}
		} else {

			functions.setElement({ ...element, clothing_list: [], qualification_id: -1, need_training_list: [] })
		}

	}



	const checkTrainingValidity = () => {
		let _need_training_list = element.need_training_list
		if (_need_training_list) {
			console.log(_need_training_list)
			for (let nt of _need_training_list) {
				const training_list = element.training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
				if (training_list.length > 0) {
					const training = training_list[0]
					nt.done = training.done
					nt.execution_id = training.id
					nt.execution_date = training.execution_date
					nt.expiration_date = training.expiration_date || ''
				} else {
					nt.done = false
					delete nt.execution_id
				}
			}
		}
		functions.edit({ name: 'need_training_list', value: _need_training_list || [] })
	}


	const onChangeHandlerWear = (value, index) => {
		let _clothing_list = [...element.clothing_list]
		_clothing_list[index]['clothing_size'] = value
		functions.edit({ name: 'clothing_list', value: _clothing_list })
	}
	const setTrainingList = (_training_list) => {
		console.log("SET TRAININT LIST")
		functions.edit({ name: 'training_list', value: _training_list })
	}
	useEffect(() => {
		checkTrainingValidity()

	}, [element.training_list])
	const updateTrainingList = (_training_list) => {
		let _need_training_list = element.need_training_list
		for (let nt of _need_training_list) {
			const training_list = _training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
			if (training_list.length > 0) {
				const training = training_list[0]
				nt.done = training.done
				nt.execution_id = training.id
				nt.execution_date = training.execution_date
				nt.expiration_date = training.expiration_date || ''
			} else {
				nt.done = false
				delete nt.execution_id
			}
		}
		functions.setElement({ ...element, 'need_training_list': _need_training_list ? _need_training_list : [], 'training_list': _training_list })
	}


	return (
		<>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">Stato contratto</Form.Label>
				<Col>
					<Form.Control as="select" value={element.contract_status} name="contract_status" onChange={(event) => functions.edit(event.target)} >
						<option value={-2} >...seleziona stato</option>
						<option value={0} >Neo assunto</option>
						<option value={1} >In organico</option>
						<option value={-1}>Cessato</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.assumption_date')}</Form.Label>
				<Col>
					<DateTime initialValue={myTimestampToDate(element.assumption_date)} dateFormat='DD/MM/YYYY' timeFormat={false} name="assumption_date" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'assumption_date', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			{element.contract_status < 0 &&
				<Form.Group as={Row} className="border-bottom mb-1 pb-1">
					<Form.Label column sm="2">{t('employes.labels.contractexpiration_date')}</Form.Label>
					<Col>
						<DateTime initialValue={myTimestampToDate(element.expiration_date)} dateFormat='DD/MM/YYYY' timeFormat={false} name="expiration_date" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'expiration_date', value: myDateToTimestamp(e) })} />
					</Col>
				</Form.Group>
			}
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.work_contract')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="timing" value="permanentjob" checked={(element && element.timing === 'permanentjob')} label={t('employes.labels.permanent_job')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="timing" value="temporarywork" checked={(element && element.timing === 'temporarywork')} label={t('employes.labels.temporarywork')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col>
					{element.timing === 'temporarywork'
						?
						<>
							<DateTime initialValue={myTimestampToDate(element.work_expiration)} dateFormat='DD/MM/YYYY' timeFormat={false} name="work_expiration" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'work_expiration', value: myDateToTimestamp(e) })} />
							<Form.Text className="text-muted">{t('employes.labels.workexpiration')}</Form.Text>
						</>
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEmployeeCF" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.work_qualification')} </Form.Label>
				<Col>
					<Form.Control as="select" value={element.qualification_id} name="qualification" onChange={(event) => onChangeHandlerQualification(event.target.value)} >
						<option value="-1" >{t('employee.labels.select_qualification')}</option>
						{console.log("WORKJOB", ui_list)}
						{ui_list.workjob_list?.map((i, k) => (
							<option value={i.id} key={k}>{i.name} - {t('employes.labels.qualification_level')} {getValue(i.level, 'name')}</option>
						))}
					</Form.Control>
				</Col>
			</Form.Group>
			{/* {element.clothing_list && element.clothing_list.length > 0 ?
						<Row className="border-bottom mb-1 pb-1">
							<Col sm="2">{t('workcontract.labels.clothing_list')}</Col>
							<Col>
								<Row>
									<Col className="bg-secondary text-light ms-1" sm="8">
										{t('global.labels.name')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.clothing_size')}
									</Col>
								</Row>
								{element.clothing_list.map((w, k) => (
									<Row key={k} className="border-bottom mb-1 pb-1">
										<Col sm="8">
											{w.name}
										</Col>
										<Col>
											<FormControl aria-describedby="basic-addon1" value={w.clothing_size || ''} onChange={(event) => onChangeHandlerWear(event.target.value, k)} />
										</Col>
									</Row>
								))}
							</Col>
						</Row>
						: <i>Nessun abbigliamento richiesto.</i>} */}
			{element.need_training_list && element.need_training_list.length > 0 ?
				<Row className="border-bottom mb-1 pb-1">
					<Col sm="2">{t('workcontract.labels.need_training_list')}</Col>
					<Col>
						<Row>
							<Col className="bg-secondary text-light ms-1" sm="5">
								{t('global.labels.name')}
							</Col>
							<Col className="bg-secondary text-light ms-1">
								{t('employes.labels.done')}
							</Col>
							<Col className="bg-secondary text-light ms-1">
								{t('employes.labels.execution_date')}
							</Col>
							<Col className="bg-secondary text-light ms-1">
								{t('employes.labels.expiration_date')}
							</Col>
							<Col sm="2" className="bg-secondary text-light ms-1 text-center">{t('global.labels.actions')}</Col>
						</Row>
						{element.need_training_list.map((w, k) => (
							<OneNeedTraining training={w} t={t} index={k} key={k} functions={{ setTrainingList: setTrainingList, updateTrainingList: updateTrainingList }} element={element} training_list={element.need_training_list} ui_list={ui_list} canWrite={canWrite} />
						))}
					</Col>
				</Row>
				: <i> Nessun titolo richiesto.</i>}

			<Row className="border-bottom mb-1 pb-1">
				<Col sm="2">{t('workcontract.labels.title_list')}</Col>
				{/* 						<Col>
							<Button onClick={checkTrainingValidity}>check</Button>
						</Col> */}
			</Row>
			{console.log('training_list', element.training_list)}
			{element.training_list && element.training_list.length > 0 ?
				<Row className="border-bottom mb-1 pb-1">
					<Col sm="2"></Col>
					<Col>
						<Row>
							<Col className="bg-secondary text-light ms-1" sm="5">
								{t('global.labels.name')}
							</Col>
							<Col className="bg-secondary text-light ms-1">
								{t('employes.labels.done')}
							</Col>
							<Col className="bg-secondary text-light ms-1">
								{t('employes.labels.execution_date')}
							</Col>
							<Col className="bg-secondary text-light ms-1">
								{t('employes.labels.expiration_date')}
							</Col>
							<Col sm="2" className="bg-secondary text-light ms-1 text-center">{t('global.labels.actions')}</Col>
						</Row>
						{element.training_list.map((w, k) => (
							<OneTraining training={w} t={t} index={k} key={k} functions={{ setTrainingList: setTrainingList, updateTrainingList: updateTrainingList }} element={element} training_list={element.training_list} ui_list={ui_list} canWrite={canWrite} />
						))}
					</Col>
				</Row>
				: <i>Nessun titolo conseguito</i>}

			{/* <Form.Group as={Row} controlId="formEmployeeLevel" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.structures')}</Form.Label>
				<Col>
					<Form.Group as={Row} className="pb-1">
						{structureElements.structure_list.map((a, k) => (
							<Col key={k} sm="3" className={element.structure_list && element.structure_list.indexOf(a.id) >= 0 ? "m-1 bg-info text-light" : ' m-1 bg-light'}>
								<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
						))}
						{element.structure_list.filter(s_id => !structureElements.all_structure_dict[s_id]).map((s, k) => (
							<Col key={k} sm="3" className={element.structure_list && element.structure_list.indexOf(s) >= 0 ? "m-1 bg-warning text-light" : ' m-1 bg-light'}>
								<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(s) >= 0} value={s} label={'---struttura non presente---'} alt={"---"} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>

						))}

					</Form.Group>
				</Col>
			</Form.Group> */}
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneNeedTraining = ({ t, training: need_training, index, training_list, functions, element, ui_list, canWrite }) => {


	const setItem = (el) => {
		let _titem_list = [...element.training_list]
		console.log("TRAINING LIST", _titem_list)
		if (need_training.done) {
			const c = checkComponentPresence(el, element.training_list, 'id', 'training_id')
			console.log("do", c)
			_titem_list.splice(c, 1, el)
		} else {
			el.training_id = el.id
			el.id = makeid(6)
			console.log("renew")
			_titem_list.push(el)
		}
		functions.setTrainingList(_titem_list)
	}

	const updateItem = (el) => {
		console.log("update")
		let _item_list = [...element.training_list]
		_item_list.splice(index, 1, el)
		functions(_item_list)

	}

	const getTrainingClass = (text) => {
		if (!need_training.done || (need_training.expiration && myTimestampToDate(need_training.expiration_date) < new Date())) {
			return text ? " text-danger" : " border-danger"
		} else {
			return ""
		}
	}

	return (
		<Row className={`border-bottom mb-1 pb-1` + getTrainingClass()}>
			<Col sm="5" className={getTrainingClass(true)}>
				{need_training.name}
			</Col>
			<Col>
				{need_training.done ?
					<>{t('employes.labels.done')}
					</> :
					<>{t('employes.labels.undone')}
					</>}
			</Col>
			<Col>

				{need_training.done ?
					<>
						{format(myTimestampToDate(need_training.execution_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col className={getTrainingClass(true)}>
				{need_training.expiration && need_training.done ?
					<>
						{format(myTimestampToDate(need_training.expiration_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col sm="2" className="text-center">
				{need_training.done ?
					<>
						{console.log("TR id", need_training.execution_id, need_training, element.training_list, getSelectedElement(need_training.id, element.training_list, 'training_id'))}
						<ModalOneTraining item={getSelectedElement(need_training.id, element.training_list, 'training_id')} is_new={false} setItem={setItem} t={t} ui_list={ui_list} mode={'edit'} canWrite={canWrite} />
						{need_training.expiration ?
							<>
								<ModalOneTraining item={need_training} is_new={true} setItem={setItem} t={t} small={true} ui_list={ui_list} mode={"renew"} canWrite={canWrite} />
							</>
							: <></>}
					</>
					:
					<>
						<ModalOneTraining item={need_training} is_new={true} small={true} setItem={setItem} t={t} ui_list={ui_list} mode={"do"} canWrite={canWrite} />
					</>
				}
			</Col>
		</Row>

	)

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneTraining = ({ t, training, index, functions, element, ui_list, canWrite }) => {



	const getTrainingClass = (text) => {
		if (!training.done || (training.expiration && myTimestampToDate(training.expiration_date) < new Date())) {
			return text ? " text-danger" : " border-danger"
		} else {
			return ""
		}
	}
	const handleDeleteTraining = () => {
		let _titem_list = [...element.training_list]
		console.log(_titem_list)
		const c = checkComponentPresence(training, element.training_list, 'id')
		_titem_list.splice(c, 1)
		functions.setTrainingList(_titem_list)

	}

	const setItem = (el) => {
		let _titem_list = [...element.training_list]
		console.log(_titem_list)
		console.log("do")
		const c = checkComponentPresence(el, element.training_list, 'id')
		_titem_list.splice(c, 1, el)
		functions.setTrainingList(_titem_list)
	}

	return (
		<Row className={`border-bottom mb-1 pb-1` + getTrainingClass()}>
			{console.log(training)}
			<Col sm="5" className={getTrainingClass(true)}>
				{training.name}
			</Col>
			<Col>
				{training.done ?
					<>{t('employes.labels.done')}
					</> :
					<>{t('employes.labels.undone')}
					</>}
			</Col>
			<Col>

				{training.done ?
					<>
						{format(myTimestampToDate(training.execution_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col className={getTrainingClass(true)}>
				{training.expiration && training.done ?
					<>
						{format(myTimestampToDate(training.expiration_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col sm="2" className="text-center">
				<ButtonGroup>
					<ModalOneTraining item={training} is_new={false} setItem={setItem} t={t} ui_list={ui_list} mode={'edit'} canWrite={canWrite} />
					<Button size="sm" variant="danger" onClick={handleDeleteTraining}><FontAwesomeIcon icon="trash" /></Button>
				</ButtonGroup>

			</Col>
		</Row>

	)

}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalOneTraining({ item, setItem, t, is_new, small, item_list, index, canWrite, all_training_list, ui_list, mode }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()

	const newtraining = {
		name: '',
		code: '',
		objectives: '',
		teaching_methodology: '',
		description: '',
		hour_length: 4,
		expiration: true,
		expiration_months: 24,
		execution_date: firebase.firestore.Timestamp.now(),
		expiration_date: firebase.firestore.Timestamp.now()
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newtraining)
	const [editedElement, setEditedElement] = useState(false)



	const handleShowEdit = () => {
		console.log("SHOW EDIT", is_new)
		if (is_new) {
			let new_t = { ...newtraining, ...item }
			console.log(new_t)
			setCurrentElement(new_t)

		} else {
			console.log(item)
			setCurrentElement(item)
		}
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 200)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (name === 'done' && value === true && curElement.expiration) {
			setCurrentElement({ ...curElement, [name]: value, expiration_date: addMonths(new Date(), item.expiration_months) })
		} else {
			setCurrentElement({ ...curElement, [name]: value })

		}
		console.log(name, value)
		setEditedElement(true)
	}




	const onChangeTrainingDate = (value) => {
		let _date = new Date()
		try {
			_date = value
		} catch (err) {
			console.log("ERROR DATE", err)
		}
		if (curElement.expiration) {
			const _expiration_date = addMonths(_date, curElement.expiration_months)
			setCurrentElement({ ...curElement, execution_date: firebase.firestore.Timestamp.fromDate(_date), expiration_date: firebase.firestore.Timestamp.fromDate(_expiration_date) })

		} else {
			setCurrentElement({ ...curElement, 'execution_date': firebase.firestore.Timestamp.fromDate(_date) })

		}
	}

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		setItem(curElement)
		setShow(false)
		setEditedElement(false)
	}

	const ModeTitle = () => {
		if (mode === 'renew') {
			return ("rinnova")
		} else if (mode === 'do') {
			return ("esegui")
		} else if (mode === 'edit') {
			return ("modifica")
		} else {
			return ("error")
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={true} small={small} title_new={t('workcontract.labels.renew_training')} action_new={handleShowEdit} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						<ModeTitle />

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('employer.labels.new_training')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('employer.labels.edit_training')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('employer.labels.view_training')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						{is_new ?
							<Form.Group as={Row} controlId="eventSubject">
								<Form.Label column sm="2"> {t('global.labels.training')}</Form.Label>
								<Col>
									<Form.Control as="select" value={item.id} className="mb-2" name="training"
										onChange={(event) => onChangeHandler(event.currentTarget.value)} >
										<option value={"-1"} >{t('training.labels.select_training')}</option>
										{ui_list.training_list?.map((f, k) => (
											<option key={k} value={f.id} >{console.log(f)}{f.name}</option>
										))}
									</Form.Control>
								</Col>
							</Form.Group>
							:
							<>
							</>
						}
						<Form.Group as={Row} className="border-bottom mb-1 pb-1" >
							<Form.Label column sm="2">{t('employes.labels.done')}</Form.Label>
							<Col sm="4">
								<Form.Check type="radio" name="done" value={true} checked={curElement.done} label={t('global.labels.yes')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Check type="radio" name="done" value={false} checked={!curElement.done} label={t('global.labels.no')} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						{curElement.done ?
							<Form.Group as={Row} className="border-bottom mb-1 pb-1" >
								<Form.Label column sm="2">{t('employes.labels.execution_date')}</Form.Label>
								<Col sm="4">
									<DateTime initialValue={myTimestampToDate(curElement.execution_date)} dateFormat='DD/MM/YYYY' timeFormat={false} name={"execution_date"} locale="it" closeOnSelect={true} onChange={(e) => onChangeTrainingDate(e.toDate())} />
								</Col>
							</Form.Group>
							: ''
						}
						{(curElement.done && curElement.expiration) || !curElement.done ?
							<Form.Group as={Row} className="border-bottom mb-1 pb-1" >
								<Form.Label column sm="2">{t('employes.labels.expiration_date')}</Form.Label>
								<Col sm="4" className={myTimestampToDate(curElement.expiration_date) < new Date() ? 'text-danger' : ''}>
									{format(myTimestampToDate(curElement.expiration_date), 'dd/MM/yyyy')}
								</Col>
								<Col>
									{curElement.expiration_months} {t('global.labels.months')}
								</Col>
							</Form.Group>
							: <></>}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}







export default ModalDomainEmployee

//export default translate()(ModalEmployee)