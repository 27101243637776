import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';
import { setDate } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import DateTime from 'react-datetime';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { ticketType_dict } from '../constants/global_definitions.js';
import { getBaseObjectValue, myTimestampToDate } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"
import { OneTicket } from '../../pages/TicketView.js';
import PrintFooter from '../generic/PrintFooter.js';
import { addMonths, endOfMonth, set } from 'date-fns';
import { OneFailure } from '../../pages/FailureView.js';



////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalPrintTicket = ({ uData, viewSection, element_list, t, is_failure }) => {
	const { contract, structureElements } = useContext(GlobalDataContext)

	const edit = useContext(EditContext)

	const componentRef = useRef();
	const childRefAlert = useRef()

	const [showPrePrint, setShowPrePrint] = useState(false)
	const [printParams, setPrintParam] = useState([])
	const [date_range, setDateRange] = useState({ start: new Date(), end: addMonths(new Date(), 1) })
	const [filterAppliedList, setFilterAppliedList] = useState({ type: [] })

	const ticket_type_dict = ticketType_dict({ t })


	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	const handleShowPrePrint = async () => {
		setShowPrePrint(true)

	}



	const handleClosePrePrint = () => {
		setShowPrePrint(false)
	}


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle
	});

	const updateDate = (el, p) => {
		console.log(el)
		if (p === 'start') {

			setDateRange(d => ({
				start: el.toDate(),
				end: d.end
			}))
		} else {
			setDateRange(d => ({
				start: d.start,
				end: el.toDate()
			}))

		}
	}



	useEffect(() => {


	}, [printParams])

	useEffect(() => {
		setDateRange({
			start: setDate(new Date(), 1),
			end: endOfMonth(set(new Date(), { hours: 23, minutes: 59 }))
		})

	}, [])




	return (
		<>
			<Button variant={"outline-primary"} onClick={() => handleShowPrePrint()}>
				<FontAwesomeIcon icon="print" />Stampa
			</Button>
			<Modal show={showPrePrint} onHide={handleClosePrePrint} fullscreen bsclass="full-modal" backdrop="static" scrollable={true}>
				<Modal.Header closeButton>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Row className="border-bottom mb-1">
							<Col>
								<Modal.Title>
									Stampa messaggi
								</Modal.Title>
							</Col>
						</Row>
						<Row>
							<Col sm="1"><FontAwesomeIcon icon="cogs" /></Col>
							<Col>
								<Row>
									<Col>
										Data creazione
									</Col>
									<Col sm="3">
										<DateTime initialValue={date_range.start} dateFormat={"DD/MM/yyyy"} timeFormat={false} name="start" locale="it" closeOnSelect={true} onChange={(v) => updateDate(v, 'start')} />
									</Col>
									<Col sm="3">
										<DateTime initialValue={date_range.end} dateFormat={"DD/MM/yyyy"} timeFormat={false} name="end" locale="it" closeOnSelect={true} onChange={(v) => updateDate(v, 'end')} />
									</Col>
								</Row>
							</Col>
						</Row>
						{!is_failure &&
						<Row>
							<Col>
								{Object.values(ticket_type_dict).filter(t => is_failure? t.id === 'failure' :  t.id !== 'failure').map((g, k) => (
									<Button key={k} size="sm" variant={filterAppliedList['type'].indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('type', g.id)}>
										<FontAwesomeIcon icon={g.icon} /> {g.label}
									</Button>

								))}
								{filterAppliedList.type.length > 0 ?
									<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'type': [] })}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						}
					</Container>
				</Modal.Header>
				<Modal.Body>
					<Container fluid ref={componentRef} className="preprint_page">
						<style type="text/css" media="print">{"\
  @page {\ size: A4;\ }\
"}</style>
						<Row>
							Messaggi {viewSection === 'sent' ?  'inviati': 'ricevuti'}
						</Row>
						{viewSection === 'trace' ?
						<>
						{element_list.filter(m => myTimestampToDate(m.createdAt) < date_range.end && myTimestampToDate(m.createdAt) > date_range.start)
							.map((m, k) => (
								<OneFailure key={k} t={t} i={m} element_list={element_list} uData={uData} viewMode={viewSection} onlyView={true} />
							))}
						</>
						:
						<>
						{element_list.filter(m => myTimestampToDate(m.createdAt) < date_range.end && myTimestampToDate(m.createdAt) > date_range.start)
							.filter(m =>
								filterAppliedList.type.length === 0 ||
								filterAppliedList.type.indexOf(m.type) >= 0)
							.map((m, k) => (
								<OneTicket key={k} t={t} ticket={m} element_list={element_list} uData={uData} viewMode={viewSection} onlyView={true} />
							))}
						</>}
						<PrintFooter />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-end">
								<Button variant="secondary" onClick={handleClosePrePrint} className="me-1">
									{t('modal.close')}
								</Button>
								<Button variant="info" onClick={handlePrint} className="ms-1">
									<FontAwesomeIcon icon="print" />  {t('modal.print')}
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}



const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


const pageStyleOld = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
	.preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.print_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	  .single_label {
		width: 50mm;
		height:50mm;
	  }
  div.page-footer{
	display:none;
  }
  @media print {
	html, body, .preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  margin-top:2mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
		display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
	  .single_label {
		width: 50mm !important;
		height: 50mm !important;
	  }
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


