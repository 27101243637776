import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import { format, add, differenceInMonths } from 'date-fns'
import CurrencyInput from 'react-currency-input';

import DomainContext from '../components/contexts/domainContext'

import Footer from "../components/Footer"
import ElementFormUsers from '../components/FormUsers'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import { renderPhoneList, RiskareaBadge } from '../components/StructureGraphicElements.js'
import ServiceamountList, { ServiceamountHourList } from "../components/ServiceAmount"
import { ModalLoadOneContract } from "../components/page_sections/contract_common"
import ElementFormMedia from "../components/FormMedia.js"

import { getSelectedElement, getValue, getFloatNumber, myTimestampToDate, compareOrder, compareName, checkComponentPresence, getElementPreview, getAmountValue } from "../util/ui_utils.js"

import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getServiceList as getServiceList2 } from '../Firebase2/Storage2/dbService';
import { getContractListExpanded as getCatalogListExpanded2, addContract as addcontract2, editContract as editcontract2 } from '../Firebase2/Storage2/dbContract';
import { getStructureList as getStructureList2 } from '../Firebase2/Storage2/dbBaseCompany';
import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';






const newcatalog = {
    code: '',
    name: '',
    company: {},
    logo: '',
    website: '',
    is_archived: false,
}


const newcompany = {
    name: '',
    code: '',
    vat: '',
    cf: '',
    address: {
        street: '',
        number: '',
        zip: '',
        city: '',
        state: ''
    },
    phone_list: [],
    email: '',
    pec: '',
    website: '',
    logo: '',
    referent_list: [],
    bank: '',
    payment_mode: '',
    sdi: '',
    note: '',
    supply_list: [],
    structure_list: []
}
const status_list = [
    'draft',
    'testing',
    'operational',
    'extension',
    'expired',
]


const catalog_scope_icons = {
    "hospital": "./icone_contratto/ospedale.png",
    "retirement": "./icone_contratto/casariposo.png",
    "clinic": "./icone_contratto/poliambulatorio.png",
    "community": "./icone_contratto/comunita.png",
    "school": "./icone_contratto/scuola.png",
    "office": "./icone_contratto/ufficio.png",
    "shopping_center": "./icone_contratto/centrocommerciale.png",
    "bank": "./icone_contratto/banca.png",
    "railroad": "./icone_contratto/ferrovia.png",
    "highway": "./icone_contratto/autostrada.png",
    "airport": "./icone_contratto/aeroporto.png",
    "hotel": "./icone_contratto/hotel.png",
    "catering": "./icone_contratto/ristorante.png",
    "prison": "./icone_contratto/carcere.png",
}



const getcatalogDescription = (i, t) => {
    if (catalog_scope_icons[i.scope]) {
        return (
            <>
                <img height="20" src={catalog_scope_icons[i.scope]} />
                                                                &nbsp;
                {t('catalog.labels.type_' + i.type)}
                                                               &nbsp;
                {t('catalog.labels.scope_' + i.sector)}
                                                               &nbsp;
                <i>({t('catalog.labels.sector_' + i.scope)})</i>
            </>
        )
    }

}

const checkDate = (endDate, extension, extension_active) => {
    let final_date = endDate.toDate()
    if (extension && extension_active) {
        final_date = add(endDate.toDate(), { months: extension })
    }

    if (new Date() >= final_date) {
        return 1
    } else if (new Date() >= endDate.toDate()) {
        return 0
    } else {
        return -1
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const CatalogDashboard = ({ t, uData, fromMenu }) => {
    const { domain, domain_data } = useContext(DomainContext)
    //console.log("DOMAIN catalogdasboard", domain, domain_data)
    let userData = uData

    const childRefAlert = useRef()
    const childRefDelete = useRef()

    //	 const { locale } = useContext(LocaleContext)
    const [element_list, setElementList] = useState([])
    const [loading, setLoading] = useState(false)

    const [showcatalogWizard, setShowcatalogWizard] = useState(false)
    const [showcatalogTemplate, setShowcatalogTemplate] = useState(false)
    const [canWrite, setWritePage] = useState(false)
    //    const [template_list, setTemplateList] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState({ geographic_area: [], type: [] })
    const [filterAppliedList, setFilterAppliedList] = useState({ geographic_area: [], type: [] })
    const [viewArchived, setViewArchived] = useState(false)




    //filter
    const handleCloseFilter = () => {
        setShowFilter(false)
    }

    const resetAppliedFilter = (section) => {
        setFilterAppliedList({ ...filterAppliedList, [section]: [] })
        setShowFilter(false)
    }


    const updateAppliedFilter = (section, elem) => {
        const c = filterAppliedList[section].indexOf(elem)
        console.log('index', c)
        let f_data = [...filterAppliedList[section]]
        if (c === -1) {
            f_data.push(elem)
        } else {
            f_data.splice(c, 1)
        }
        setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
    }


    const [isGlobal, setIsGlobal] = useState(false)




    const [ui_list, setUiList] = useState({})
    const initValues = async () => {

        console.log("cotractdashboard, USERDATA", userData)
        const company_list = []
        const structure_list = []
        const message_group_list = []
        setLoading(true)
        if (domain_data.licence_status && uData && (userData.isGlobalAdmin || uData.write_page.indexOf('catalogdashboard') !== -1)) {
            setWritePage(true)
        }
        if (domain) {
            let risk_area_list = await getBaseObjectTypeList2(domain, 'risk_areas')
            if (risk_area_list) {
                risk_area_list.sort(compareOrder)
            }
            const referent_role_list = await getBaseObjectTypeList2(domain, 'referent_role')
            let glist = await getSystemGroupList2()
            let group_dict = {}
            glist.map(g => (group_dict[g.id] = g))
            let risk_area_dict = Object.assign({}, ...risk_area_list.map((x) => ({ [x.Id]: x })))
            let filter_data = { geographic_area: [], type: [] }
            let newelement_list = []
            setUiList({ ...ui_list, company_list, structure_list, risk_area_list, risk_area_dict, referent_role_list, group_list: glist, group_dict: group_dict, message_group_list })
            if (userData) {
                if (userData.isGlobalAdmin || ((!userData.catalog_dict || Object.keys(userData.catalog_dict).length === 0) && (userData.write_page.length || userData.write_page.length > 0))) {
                    newelement_list = await getCatalogListExpanded2(domain)
                    console.log('ALL catalog list', newelement_list)
                    setIsGlobal(true)
                } else {

                    console.log("catalogDATA not globaladmin userData", userData)
                    if (userData.catalog_dict) {
                        newelement_list = await getCatalogListExpanded2(domain)
                        newelement_list = newelement_list.filter(m => (Object.keys(userData.contract_dict).indexOf(m.id) !== -1))
                    }
                }
                if (userData.isGlobalAdmin) {
                    setIsGlobal(true)

                }
                //} else {
                //                newelement_list =  await getcatalogListExpanded2(domain)

            }
            newelement_list.map(c => {
                if (filter_data.geographic_area.indexOf(c.geographic_area) === -1) {
                    filter_data.geographic_area.push(c.geographic_area)
                }
                if (filter_data.type.indexOf(c.type) === -1) {
                    filter_data.type.push(c.type)
                }
            })
            setFilterData(filter_data)
            newelement_list = newelement_list.sort(compareName)
            setElementList(newelement_list)

        }
        setLoading(false)
    }



    useLayoutEffect(() => {
        console.log("catalog userData", userData)
    }, [userData])



    useEffect(() => {

        initValues()
        //}, [domain])
    }, [])






    return (
        <>
            <DeleteConfirmationSection ref={childRefDelete} />
            <AlertSection ref={childRefAlert} />
            <Container fluid className="bg-white titleStickyTop">
                {isGlobal ?
                    <Row>
                        {element_list && element_list.filter(c => !c.is_archived).length > 0 ?
                            <Col>
                                {viewArchived ?
                                    <Button size="sm" variant={viewArchived ? 'danger' : "outline-danger"} onClick={() => setViewArchived(!viewArchived)}>{t('catalog.labels.hide_archived')}</Button>
                                    :
                                    <Button size="sm" variant={viewArchived ? 'danger' : "outline-danger"} onClick={() => setViewArchived(!viewArchived)}>{t('catalog.labels.view_archived')}</Button>
                                }
                            </Col>
                            : ''}
                        <Col className="text-end">
                            <ModalCatalog is_new={true} element_list={element_list} setElementList={setElementList} ui_list={ui_list} t={t} canWrite={canWrite || isGlobal} isGlobal={isGlobal} setUiList={setUiList} />
                            <ModalDomainUser t={t} ui_list={ui_list} catalog_list={element_list} />
                        </Col>
                    </Row>
                    : ''}
                <Row>

                    <Col className="text-center">
                        {element_list && element_list.length > 1 ?
                            <ButtonGroup>
                                <Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
                                {filterAppliedList.geographic_area.length > 0 ?
                                    <>
                                        {filterAppliedList.geographic_area.map((g, k) => (
                                            <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('geographic_area', g)}>
                                                {g.length > 0 ? g : <i>non impostata</i>}
                                            </Button>
                                        ))}
                                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('geographic_area')}><FontAwesomeIcon icon="times" /></Button>
                                    </>
                                    : ''}
                                {filterAppliedList.type.length > 0 ?
                                    <>
                                        {filterAppliedList.type.map((g, k) => (
                                            <Button key={k} size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('type', g)}>
                                                {g}
                                            </Button>
                                        ))}

                                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}> <FontAwesomeIcon icon="times" /></Button>
                                    </>
                                    : ''}
                            </ButtonGroup>
                            : ''}

                    </Col>
                </Row>
            </Container>
            <Container fluid>
                {loading ?
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :
                    <>
                        {element_list && element_list.length > 0 ?
                            <>
                                <Row>
                                    {element_list.filter(c => (
                                        (viewArchived || !c.is_archived) && (
                                            ((filterAppliedList.geographic_area.length === 0 || filterAppliedList.geographic_area.indexOf(c.geographic_area) !== -1)
                                                &&
                                                (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(c.type) !== -1))
                                        )
                                    )
                                    ).map((i, key) => (
                                        <Col key={key} sm="12" md="6" xl="3" className="mt-2">
                                            {console.log("----isglobal", isGlobal)}
                                            <Onecatalog catalog={i} t={t} canWrite={canWrite} isGlobal={isGlobal} setUiList={setUiList} ui_list={ui_list} setElementList={setElementList} element_list={element_list} fromMenu={fromMenu} />
                                        </Col>
                                    ))}
                                </Row>
                            </>
                            : <> <Row><Col className="font-italic">
                                {t('catalogs.messages.no_catalog')}
                            </Col>
                            </Row>
                            </>
                        }
                    </>
                }




            </Container>

            <Footer />
        </ >
    );
}








///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const Onecatalog = ({ catalog, t, ui_list, setUiList, element_list, setElementList, canWrite, isGlobal, fromMenu }) => {

    return (
        <>
            <Card className={catalog.is_archived ? "border-danger h-100" : "h-100"}>
                <Card.Body>
                    <Container fluid >
                    {catalog.is_archived ?
                        <FontAwesomeIcon icon="trash" /> : ''}
                    <b>{catalog.name}</b>

                    </Container>
                </Card.Body>
                <Card.Footer className={catalog.is_archived ? "bg-danger" : ''}>
                    <Row >
                        <Col className="">
                            <ButtonGroup>
                                <ModalLoadOneContract element={catalog} t={t} preload_catalog={fromMenu} />
                                <ModalCatalog element={catalog} element_list={element_list} setElementList={setElementList} ui_list={ui_list} t={t} canWrite={canWrite || isGlobal} isGlobal={isGlobal} setUiList={setUiList} />
                            </ButtonGroup>
                        </Col>
                    </Row>

                </Card.Footer>
            </Card>
        </>
    )

}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalDomainUser = ({ t, catalog_list, ui_list }) => {
    const { domain } = useContext(DomainContext)
    const [showEditUsers, setShowUsers] = useState(false);
    const handleCloseUsers = () => setShowUsers(false);
    const [isInvalid, setIsInvalid] = useState(false)
    const [editedElement, setEditedElement] = useState(false)

    return (
        <>
            <Button variant="outline-info" onClick={() => setShowUsers(true)}>

                <img src="/menu_icons/Gestione_utente.png" height="42"
                    className="d-inline-block align-top me-1"
                    alt="utenti" />

                {t('navs.pages.users')}</Button>

            <Modal show={showEditUsers} onHide={handleCloseUsers} fullscreen backdrop="static" className="bg-secondary">
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {t('navs.pages.users')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ElementFormUsers t={t} catalog_list={catalog_list} functions={{ setIsInvalid: setIsInvalid, isInvalid: isInvalid }} ui_list={ui_list} domain={domain} not_contract={true}/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUsers}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalCatalog = ({ is_new, element, setElementList, setElement, element_list, ui_list, t, canWrite, setUiList, isGlobal }) => {
    const { domain, domain_data } = useContext(DomainContext)
    const childRefAlert = useRef()

    const [isPreparing, setIsPreparing] = useState(false)
    const [showEdit, setShow] = useState(false);
    const [newEdit, setNew] = useState(true);
    const [curElement, setCurrentElement] = useState(element)
    const [editedElement, setEditedElement] = useState(false)
    const [loading, setLoading] = useState(false)
    const [structure_list, setStructureList] = useState([])
    const [service_list, setServiceList] = useState([])
    const [new_catalog, setNewcatalog] = useState({ mode: 'new', source: -1 })
    const [isSaving, setIsSaving] = useState(false)
    const [showcatalogWizard, setShowcatalogWizard] = useState(false)
    const [showcatalogTemplate, setShowcatalogTemplate] = useState(false)

    const handleClose = () => setShow(false);
    const handleSave = async () => {
        if (newEdit) {
            let return_data = await addcontract2(domain, curElement)
            console.log(return_data)
            if (return_data && return_data.error) {
                //childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
            } else {
                const newlist = [...element_list]
                newlist.push(return_data)
                setEditedElement(false)
                setElementList(newlist)
                childRefAlert.current.addAlert('success', 'salvataggio eseguito correttamente')
                //        setShow(false);
            }
        } else {
            console.log(curElement)
            let return_data = await editcontract2(domain, curElement.id, curElement)
            console.log(return_data)
            if (return_data && return_data.error) {
                //				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
            } else {
                const newlist = [...element_list]
                const c = checkComponentPresence(curElement, element_list, 'id')
                if (c >= 0) {
                    newlist.splice(c, 1, curElement)
                }
                childRefAlert.current.addAlert('success', 'salvataggio eseguito correttamente')
                setEditedElement(false)
                setElementList(newlist)
                //      setShow(false);
            }
        }
    }


    const handleShowEdit = async (wizard) => {
        setCurrentElement(element)
        setNew(false)
        setShow(true)
    }

    const onChangeHandler = (el) => {
        let { name, value, type } = el
        let sub = undefined;
        try {
            sub = el.getAttribute('sub') || el.sub
        } catch (err) { }
        console.log(name, value, type, sub)
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curElement[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck && oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                } else {
                    oldcheck = [value]
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        if (sub) {
            value = { ...curElement[sub], [name]: value }
            name = sub;
        }
        console.log(name, value)
        setCurrentElement({ ...curElement, [name]: value })
        setEditedElement(true)
    }






    const handleShowNew = () => {
        setCurrentElement(newcatalog)
        setIsPreparing(false)
        setNew(true)
        setShow(true)
        setEditedElement(false)
    }







    return (
        <>
            {is_new ?
                <Button key={0} variant="primary" onClick={() => handleShowNew()}><FontAwesomeIcon icon="plus-square" /><br />{t('catalog.buttons.add_catalog')}</Button>
                :
                [canWrite ?
                    <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /></Button>
                    :
                    <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="eye" /></Button>
                ]
            }
            {showEdit ?
                <Modal key={"dlg_edit"} show={showEdit} onHide={handleClose} size="xl" backdrop="static" className="bg-secondary" >
                    <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                        <Modal.Title>
                            {newEdit ?
                                <>
                                    <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('catalog.labels.new_catalog')}</span>
                                </>
                                :
                                [canWrite ?
                                    <>
                                        <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('catalog.labels.edit_catalog')}</span>
                                    </>
                                    :
                                    <>
                                        <FontAwesomeIcon icon="eye" /> <span className="text-info">{t('catalog.labels.info_catalog')}</span>
                                    </>
                                ]
                            }
                        : {curElement.name}&nbsp;
                        {curElement.is_archived ? <>({t('catalog.labels.archived')})</> : ''}
                            {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AlertSection ref={childRefAlert} />
                        <Container fluid>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
                                <Row>
                                    <Col sm={3} >
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item variant="secondary" className="mb-1">
                                                <Nav.Link eventKey="tabDetails">{t('company.labels.details')} </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className="mb-1">
                                                <Nav.Link variant="secondary" eventKey="tabMedia" disabled={newEdit}>{t('global.labels.imagesdocs')} </Nav.Link>
                                            </Nav.Item>
                                            {isGlobal ?
                                                <Nav.Item className="mb-1">
                                                    <Nav.Link variant="secondary" eventKey="tabArchive" disabled={newEdit}>{t('global.labels.archive')} </Nav.Link>
                                                </Nav.Item>
                                                : ''}
                                        </Nav>
                                    </Col>
                                    <Col>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tabDetails">
                                                <ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} canWrite={canWrite} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tabMedia">
                                                <ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} section='catalog' canWrite={canWrite} />
                                            </Tab.Pane>
                                            {isGlobal ?
                                                <Tab.Pane eventKey="tabArchive">
                                                    <ElementFormArchive element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} />
                                                </Tab.Pane>
                                                : ''}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('modal.close')}
                        </Button>
                        {canWrite ?
                            <Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
                                <FontAwesomeIcon icon="save" /> {t('modal.save')}
                            </Button>
                            : ''}
                    </Modal.Footer>
                </Modal>


                : ''}




        </>
    )

}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
    const { locale } = useContext(LocaleContext)
    return (
        <>
            <Form.Group as={Row} controlId="catalogName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="catalogName" className="border-bottom mb-1">
                <Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.target)} className="mb-2" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Col>
            </Form.Group>

        </>
    )
}




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormArchive({ element, functions, ui_list, t }) {
    const childRefDelete = useRef()
    const { locale } = useContext(LocaleContext)
    const onArchive = () => {
        const cb = async () => {
            functions.edit({ name: 'is_archived', value: true })
        }
        childRefDelete.current.confirmDelete(t('catalog.messages.archive_catalog_title'), t('catalogs.messages.archive_catalog', { catalog: element.name }), cb)
    }
    const onUnArchive = () => {
        functions.edit({ name: 'is_archived', value: false })

    }
    return (
        <>
            <DeleteConfirmationSection ref={childRefDelete} />
            {element.is_archived ?
                <Button variant="success" onClick={onUnArchive}>{t('global.labels.undo_archive')}</Button>
                :
                <Button variant="danger" onClick={onArchive}>{t('global.labels.do_archive')}</Button>
            }

        </>
    )
}






export default translate()(CatalogDashboard)