import axios from 'axios'; 
import {GFUNC_DOMAIN} from '../constants/cloudfunctionparams'

///////////////////////////////////////////////////////////////////////////////
export const getTemplateList = async(isArchived:Boolean) => {
	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	return axios
		.get(`${GFUNC_DOMAIN}/templateList/${isArchived}`)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				}else{
					console.log("unknown error", error)
				}
			}
			return {error: error};
		});
}



///////////////////////////////////////////////////////////////////////////////
export const editTemplate = async(templateId:any, templateData:any) => {
	const data = {
		templateData : templateData,
	}
	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	return axios
		.post(`${GFUNC_DOMAIN}/editTemplate/${templateId}`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				}else{
					console.log("unknown error", error)
				}
			}
			return {error: error};
		});
}

///////////////////////////////////////////////////////////////////////////////
export const deleteTemplate = async(templateId:any) => {
	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	return axios
		.delete(`${GFUNC_DOMAIN}/deleteTemplate/${templateId}`)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				}else{
					console.log("unknown error", error)
				}
			}
			return {error: error};
		});
}





///////////////////////////////////////////////////////////////////////////////
export const templateFromContract = async(sourceContractId:any, sourceDomain:any, templateName:any) => {
	const data = {
		source : sourceContractId,
		domain: sourceDomain,
		templateName:templateName
	}
	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	return axios
		.post(`${GFUNC_DOMAIN}/toTemplate/`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				}else{
					console.log("unknown error", error)
				}
			}
			return {error: error};
		});
}

///////////////////////////////////////////////////////////////////////////////
export const contractFromTemplate = async(destContractName:any, destDomain:any, templateId:any) => {
	const data = {
		dest : destContractName,
		domain: destDomain,
		templateId:templateId
	}
	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	return axios
		.post(`${GFUNC_DOMAIN}/FromTemplate/`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				}else{
					console.log("unknown error", error)
				}
			}
			return {error: error};
		});
}


///////////////////////////////////////////////////////////////////////////////
export const contractFromContract = async(destContractName:any, domain:any, sourceContract:any) => {
	const data = {
		dest : destContractName,
		domain: domain,
		source:sourceContract
	}
	const authToken = localStorage.getItem('AuthToken');
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	return axios
		.post(`${GFUNC_DOMAIN}/FromContract/`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				}else{
					console.log("unknown error", error)
				}
			}
			return {error: error};
		});
}
