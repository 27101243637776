import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'


export const SearchSection = ({ searchTerm, setSearchTerm }) => {
    const [searchInput, setSearchInput] = useState('')
    const handleChangeSearch = (s) => {
        setSearchInput(s.value)
        console.log(s)
        if (s.value.length > 1) {
            setSearchTerm(s.value)
        } else {
            setSearchTerm('')
        }
    }

    const handleResetSearch = () => {
        setSearchTerm('')
        setSearchInput('')

    }

    return (
        <InputGroup className="mb-3">
            
                <Button disabled variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
            
            <Form.Control type="text" value={searchInput} placeholder={"Cerca"} onChange={(event) => handleChangeSearch(event.target)} />
            {searchTerm.length > 0 ?
                
                    <Button variant="outline-secondary" onClick={() => handleResetSearch()}><FontAwesomeIcon icon="times" /></Button>
                
                : ''}
        </InputGroup>
    )
}

export const SearchSectionTest = () => {
    return (
        <>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                <Form.Control
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                />
            </InputGroup>
        </>
    )
}