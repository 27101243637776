import React, { useState, useContext, useEffect,  useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { format, setDate, addMonths, endOfMonth } from 'date-fns'
import DateTime from 'react-datetime';

import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { controlOption_list, checkStrengthType_dict,  checkMode_dict } from "../constants/global_definitions"

import {  getValue,  checkComponentPresence, myTimestampToDate, getPlanCode, getPlanEndDate, getPlanStartDate, getSelectedElement, getSelectedElementB, getFloatNumber, getFixedNumber, compareDateCustomParam, propComparatorDate, myDateToTimestamp } from '../../util/ui_utils.js';
import { getMatrix } from "../StructureGraphicElements"

import AlertSection from "../AlertSection.js"
import CheckMatrix from "../CheckMatrix"
import { addSamplingParam, editSamplingParam } from "../../Firebase2/Storage2/dbSamplingPlan"

import firebase from 'firebase/compat/app'


const level_list = {
	'level1': { label: 'Liv.1' },
	'level2': { label: 'Liv.2' },
	'level3': { label: 'Liv.3' },
}

const lqa_list = {
	'lqa4': { label: '4%' },
	'lqa6': { label: '6.5%' },
	'lqa10': { label: '10%' }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalSamplingProcessPlan = ({ is_new, item, structure, controlgroup, control_mode, canWrite, ui_list, t, employer_list, item_list, sampling_data, setItem }) => {



	const control_option_list = controlOption_list({t})
	const check_mode_dict = checkMode_dict({t})
	const strengthType = checkStrengthType_dict({ t })




	const newsampling_plan = {
		code: "P" + check_mode_dict[control_mode].code  + getPlanCode(item_list.filter(plan => plan.controlgroup_id === controlgroup.id && plan.control_mode === control_mode && plan.check_type === 'process').length) + "_" + controlgroup.code,
		controlgroup_id: controlgroup.id,
		check_period: 1,
		startDate: firebase.firestore.Timestamp.fromDate(setDate(new Date(), 1)),
		endDate: getPlanEndDate(firebase.firestore.Timestamp.fromDate(new Date()), control_option_list[1].month_count),
		ac_check_count: 0,
		sampling_count:0,
		level: 'level2',
		lqa: 'lqa10',
		operator_count_mode: 'assigned',
		check_type: 'process',
		control_mode: control_mode,
		control_strength_mode: 'ordinary',
		operator_count: 0,
		user_id: -1
	}
	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)


	const childRefAlert = useRef()

	const [showPlanEdit, setShowPlanEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [partialData, setPartialData] = useState({ ac_check_count0: 0, ac_check_count1: 0, ac_check_count2: 0, ac_check_count:0 })
	const [curControlGroup, setCurControlGroup] = useState()
	const [curPlan, setCurPlan] = useState(newsampling_plan)

	const handleShowEditPlan = async () => {
		setCurControlGroup(controlgroup)
		setShowPlanEdit(true)
		console.log(is_new)
		if (is_new) {
			newsampling_plan.operator_count = getOperatorCount()
			setCurPlan(newsampling_plan)
		} else {
			console.log("OLD PLAN")
			setCurPlan(item)
		}
		setEditedElement(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
//		initData(controlgroup, employer_list)

	}


	const handleClosePlan = () => {
		setShowPlanEdit(false)
	}



	const handleSavePlan = async () => {
		console.log("____________________________________SAVE_____________________________", !sampling_data)
		let t_element_list = [...item_list]
		const tmpcurPlan = { ...curPlan, total_data: partialData }
		console.log(tmpcurPlan)
		curPlan.endDate = getPlanEndDate(curPlan.start, control_option_list[curPlan.check_period].month_count)
		console.log(domain, contractId, tmpcurPlan.id, curPlan.endDate, curPlan.start, curPlan.check_period)
		if (is_new) {
			let return_data = await addSamplingParam(domain, contractId, tmpcurPlan)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				t_element_list.push(tmpcurPlan)
				setItem(t_element_list)
				console.log("SET LIST", t_element_list)
				setShowPlanEdit(false);
			}
		} else {
			let return_data = await editSamplingParam(domain, contractId, tmpcurPlan.id, tmpcurPlan)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const c = checkComponentPresence(curPlan, t_element_list, 'id')
				if (c >= 0) {
					t_element_list.splice(c, 1, curPlan)
					setItem(t_element_list)
				}
				setShowPlanEdit(false);
			}
		}
	}

	const onChangeHandlerPlan = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curPlan[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		if (name === 'startDate') {
			setCurPlan({ ...curPlan, [name]: getPlanStartDate(value), endDate: getPlanEndDate(value, control_option_list[curPlan.check_period].month_count) })
		} else if (name === 'check_period') {
			setCurPlan({ ...curPlan, [name]: value, endDate: getPlanEndDate(curPlan.startDate, control_option_list[value].month_count) })
		} else if (name === 'operator_count_mode') {
			setCurPlan({ ...curPlan, [name]: value, operator_count: getOperatorCount() })
		} else {
			setCurPlan({ ...curPlan, [name]: value })
		}
		setEditedElement(true)
	}

	const getOperatorCount = () => {
		try {
			console.log(controlgroup)
			const e_count = employer_list.filter(e => e.structure_list && e.structure_list.filter(s => controlgroup.structure_list.indexOf(s) >= 0)).length
			console.log(e_count)
			return e_count
		} catch (err) {
			console.error(err)
			return 0
		}

	}


	useEffect(() => {
		updateData()

	}, [curPlan])

	const updateData = () => {
		let temp_data = { ...partialData }
/* 		for (let r = 0; r < matrix_list.length; r++) {
			if (matrix_list[r].max_index >= curPlan.operator_count && matrix_list[r].min_index <= curPlan.operator_count) {
				temp_data.data = matrix_list[r]
			}
		} */
		temp_data.data = getMatrix({count: curPlan.operator_count, level: curPlan.level, strength_mode: curPlan.control_strength_mode})
		try {
			temp_data.ac_check_count = temp_data.data[curPlan.lqa].ac
			temp_data.sampling_count = temp_data.data.sampling_count
			temp_data.sampling_count0 = Math.ceil(temp_data.data.sampling_count * control_option_list[curPlan.check_period].multiplier[0])
			temp_data.sampling_count1 = Math.ceil(temp_data.data.sampling_count * control_option_list[curPlan.check_period].multiplier[1])
			temp_data.sampling_count2 = Math.ceil(temp_data.data.sampling_count * control_option_list[curPlan.check_period].multiplier[2])
			temp_data.ac_check_count0 = Math.ceil(temp_data.data[curPlan.lqa].ac * control_option_list[curPlan.check_period].multiplier[0])
			temp_data.ac_check_count1 = Math.ceil(temp_data.data[curPlan.lqa].ac * control_option_list[curPlan.check_period].multiplier[1])
			temp_data.ac_check_count2 = Math.ceil(temp_data.data[curPlan.lqa].ac * control_option_list[curPlan.check_period].multiplier[2])

			setPartialData(temp_data)

		} catch (err) {

		}
	}




	return (
		<>
			{canWrite ?
				(is_new ?
					<Button className="me-1" variant="primary" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="plus-square" /> {t('samplingplan.labels.new_process_plan')} </Button>
					:
					<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
				)
				:
				<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="eye" /> </Button>
			}
			{curControlGroup ?
				<Modal show={showPlanEdit} onHide={handleClosePlan} fullscreen bsclass="full-modal" backdrop="static" scrollable={true} >
					<AlertSection ref={childRefAlert} />
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<Modal.Title>
							{canWrite ?
								(is_new ?
									<>
										<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('samplingplan.labels.new_plan')}</span>
									</>
									:
									<>
										<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('samplingplan.labels.edit_plan')}</span>
									</>
								)
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('roomtypes.labels.view_plan')}</span>
								</>
							}&nbsp;
							({t('samplingplan.labels.process_check')}
							&nbsp;{format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')}
							)&nbsp;
							{controlgroup.name}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
							<CheckMatrix />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							<Form.Group as={Row} controlId="dpiName" className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
								<Col sm="8">
									<Form.Control type="text" name="code" value={curPlan.code} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formProductBrand" className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.start_plan')}</Form.Label>
								<Col sm="3">
									<DateTime initialValue={myTimestampToDate(curPlan.startDate)} dateFormat='MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandlerPlan({ name: 'startDate', value: myDateToTimestamp(e.toDate()) })} />
								</Col>
								<Col>
									{format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')}
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.period')} </Form.Label>
								<Col sm="3">
									<Form.Control as="select" className="mb-2" value={curPlan.check_period} name="check_period" onChange={(event) => onChangeHandlerPlan(event.target)} >
										{control_option_list.map((l, k) => (
											<option key={k} value={l.id}>{l.label}</option>
										))}
									</Form.Control>
								</Col>
								<Col>
									{format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')}
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.level')} </Form.Label>
								<Col sm="3">
									<Form.Control as="select" className="mb-2" value={curPlan.level} name="level" onChange={(event) => onChangeHandlerPlan(event.target)} >
										{Object.keys(level_list).map((l, k) => (
											<option key={k} value={l}>{level_list[l].label}</option>
										))}
									</Form.Control>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.operator_count_mode')}</Form.Label>
								<Col>
									<Form.Check type="radio" name="operator_count_mode" value="manual" checked={curPlan.operator_count_mode === 'manual'} label={t('samplingplan.labels.operator_count_mode_manual')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
									<Form.Check type="radio" name="operator_count_mode" value="assigned" checked={curPlan.operator_count_mode === 'assigned'} label={t('samplingplan.labels.operator_count_mode_assigned')} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.operator_count')} </Form.Label>
								<Col sm="3">
									<Form.Control type="number" disabled={curPlan.operator_count_mode === 'assigned'} className="mb-2" value={curPlan.operator_count} min="0" name="operator_count" onChange={(event) => onChangeHandlerPlan(event.target)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('samplingplan.labels.lqa')} </Form.Label>
								<Col sm="3">
									<Form.Control as="select" className="mb-2" value={curPlan.lqa} name="lqa" onChange={(event) => onChangeHandlerPlan(event.target)} >
										{Object.keys(lqa_list).map((l, k) => (
											<option key={k} value={l}>{lqa_list[l].label}</option>

										))}
									</Form.Control>

								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom mb-1 pb-1">
								<Form.Label column sm="2">{t('check.labels.strengthtype')}</Form.Label>
								<Col sm="8">
									<Form.Control as="select" className="mb-2" value={curPlan.control_strength_mode} name="control_strength_mode" onChange={(event) => onChangeHandlerPlan(event.currentTarget)} >
										{Object.keys(strengthType).map((r, rk) => (
											<option key={r} value={r} >{strengthType[r].code} - {strengthType[r].label}</option>
										))}
									</Form.Control>
								</Col>
							</Form.Group>
							<Row className="text-light text-center">
								<Col className="bg-secondary ms-1 pt-2">
									Controlli
								</Col>
								<Col className="bg-secondary ms-1 pt-2">
									Ac
								</Col>
								<Col className="bg-secondary ms-1 pt-2">
									PQA
								</Col>
								<Col className="bg-secondary ms-1 pt-2">
									PQL
								</Col>
								<Col className="bg-secondary ms-1 pt-2">
									Numero controlli
									<Row className="border-top border-light">
										<Col className="p-1">Sett.</Col>
										<Col className="p-1">Mese</Col>
										<Col className="p-1">Anno</Col>
									</Row>
								</Col>
							</Row>
							<Row className="text-center">
                                {console.log(partialData.data)}
								<Col >{partialData.sampling_count} </Col>
								<Col >{partialData.data[curPlan.lqa].ac} </Col>
								<Col >{partialData.data[curPlan.lqa].pqa} %</Col>
								<Col >{partialData.data[curPlan.lqa].pql} %</Col>
								<Col>
									<Row>
										<Col>{partialData.sampling_count0}</Col>
										<Col>{partialData.sampling_count1}</Col>
										<Col>{partialData.sampling_count2}</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClosePlan}>
							{t('modal.close')}
						</Button>
						{canWrite ?
							<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSavePlan}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							: ''}
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}