import React, { useState, useContext, useEffect, useRef,  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import {  checkComponentPresence,} from "../util/ui_utils.js"
import AlertSection from "../components/AlertSection.js"


import { addResultQuestion as addResultQuestion2, editResultQuestion as editResultQuestion2 } from '../Firebase2/Storage2/dbChecks';
import { addProcessQuestion as addProcessQuestion2, editProcessQuestion as editProcessQuestion2 } from '../Firebase2/Storage2/dbChecks';

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


const companyId = '0'

const element_list = []; 

function ModalCheck({ item, setItem, t, is_new, small, item_list, index, check_type }) {
	const { domain } = useContext(DomainContext)
	const { contract} = useContext(GlobalDataContext)
	const contractId = contract.id

	const childRefAlert = useRef()
	const newcheck = {
		name:'',
		text:'',
		type:'text',
		coefficient: 1,
		note: ''
	}
	const initValues = async () => {
/* 		let newelement_list = await getProcessQuestionList(contractId, companyId, 'check', null)
		newelement_list.sort(compareName)
		setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newcheck)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newcheck.media_list = []
		setCurrentElement(newcheck)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
        setTimeout(function () {
            setEditedElement(false)
        }, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } =el 
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
 		 if (is_new) {
			let return_data = (check_type==='results') ? await addResultQuestion2(domain, contractId, curElement): await addProcessQuestion2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = (check_type==='results') ? await editResultQuestion2(domain, contractId,  curElement.id, curElement): await editProcessQuestion2(domain, contractId,  curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		} 
	}

	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('check.buttons.checkquestion_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					{is_new && <Modal.Title variant="primary">{t('checks.modal.titlenew')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title variant="info">{t('checks.modal.titleedit')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} />
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t }) {
	const { locale } = useContext(LocaleContext)

	return (
		<>
			<Form.Group as={Row} controlId="checkName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.title')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="checkCode" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.text')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="text" value={element.text} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('workarea.labels.weight_coefficient')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="coefficient" value={element.coefficient} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="checkNote" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
export default translate()(ModalCheck)