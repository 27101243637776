import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, ButtonGroup, Row, Col, Form, Button,  DropdownButton, Dropdown } from 'react-bootstrap';




import { checkComponentPresence, getSelectedElement, getValue } from "../util/ui_utils.js"









const newanswer = {
	answer: '',
	weight: 1,
	icon_list: [],
}


export function CustomerAnswerList({ element, functions, t }) {


	const [showAnswerEdit, setShowAnswerEdit] = useState(false)
	const [is_new, setNewAnswerEdit] = useState(true)
	const [curAnswer, setCurAnswer] = useState(newanswer)
	const [editedElement, setEditedElement] = useState(false)

	//----answer
	const handleCloseAnswer = () => setShowAnswerEdit(false);
	const handleSaveAnswer = () => {
		if (is_new) {
			let newanswer_list = element.answer_list || []
			newanswer_list.push(curAnswer)
			console.log(newanswer_list)
			functions.edit({ name: 'answer_list', value: newanswer_list })
		} else {
			let editlist = element.answer_list
			//            const c = checkComponentPresence(curAnswer, element.answer_list)
			editlist.splice(curAnswer.index, 1, curAnswer)
			functions.edit({ name: 'answer_list', value: editlist })
		}
		setShowAnswerEdit(false);
	}

	const handleDeleteAnswer = (el, index) => {
		console.log(el)
		let editlist = element.answer_list
//		const c = checkComponentPresence(el, element.answer_list)
		editlist.splice(index, 1)
		functions.edit({ name: 'answer_list', value: editlist })
	}

	const handleShowNewAnswer = () => {
		setCurAnswer(newanswer)
		setNewAnswerEdit(true)
		setShowAnswerEdit(true)
		setEditedElement(false)
	}

	const handleShowEditAnswer = (i, index) => {
		console.log('edit', i, index)
		i.index = index
		setCurAnswer(i)
		setNewAnswerEdit(false)
		setShowAnswerEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerAnswer = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curAnswer[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurAnswer({ ...curAnswer, [name]: value })
		setEditedElement(true)
	}


	const handleDeleteIcon = (el, index) => {
		let editlist = [...curAnswer.icon_list]
		editlist.splice(index, 1)
		onChangeHandlerAnswer({ name: 'icon_list', value: editlist })
	}


	const handleAddIcon = (icon) => {
		let editlist = [...curAnswer.icon_list]
		editlist.push(icon)
		onChangeHandlerAnswer({ name: 'icon_list', value: editlist })
	}

	return (
		<>
			<Row>
				<Col>
					{t('customersatisfaction.labels.answer_list')}
				</Col>
				<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewAnswer()}><FontAwesomeIcon icon="plus-square"></FontAwesomeIcon>{t('suppliers.labels.answer_add')}</Button></Col>
			</Row>
			<Row className="border-bottom  border-end mb-1">
				{element.answer_list && element.answer_list.length > 0 ?
					<Col>
						<Row >
							<Col className="bg-secondary text-light ms-1">{t('customersatisfaction.labels.answer_text')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('customersatisfaction.labels.icon_list')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('customersatisfaction.labels.weight')} </Col>
							<Col className="bg-secondary text-light ms-1" sm="2"> </Col>
						</Row>
						{element.answer_list.map((a, key) => (
							<Row key={key} className="border-bottom mb-1 pt-1">
								<Col className="font-weight-bold">{a.answer}</Col>
								<Col>
									<Row>
										{a.icon_list.map((i, key) => (
											<Col key={key} sm="1">
												<FontAwesomeIcon icon={i} />
											</Col>
										))}
									</Row>
								</Col>
								<Col>{a.weight}</Col>
								<Col className="text-center" sm="2">
									<ButtonGroup>
										<Button size="sm" variant="outline-info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditAnswer(a, key)} /> </Button>
										<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteAnswer(a, key)} /> </Button>
									</ButtonGroup>
								</Col>
							</Row>))
						}
					</Col>
					: <Col><i> {t('workarea.messages.no_answer')} </i></Col>
				}
			</Row>


			<Modal show={showAnswerEdit} onHide={handleCloseAnswer} size="xl" className="bg-secondary" backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('customersatisfaction.labels.new_answer')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('customersatisfaction.labels.edit_answer')}</span>
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('customersatisfaction.labels.answer_text')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="answer" value={curAnswer.answer} onChange={(event) => onChangeHandlerAnswer(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('customersatisfaction.labels.weight')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="weight" value={curAnswer.weight} onChange={(event) => onChangeHandlerAnswer(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Row>
							<Col>
								{t('customersatisfaction.labels.icon_list')}
							</Col>
							<Col className="text-end">
								<DropdownButton as={ButtonGroup} variant="info" size="sm" title={t('customersatisfaction.buttons.add_icon')}>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('star')}><FontAwesomeIcon icon="star" /></Dropdown.Item>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('smile')}><FontAwesomeIcon icon="smile" /></Dropdown.Item>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('grin-stars')}><FontAwesomeIcon icon="grin-stars" /></Dropdown.Item>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('meh')}><FontAwesomeIcon icon="meh" /></Dropdown.Item>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('frown')}><FontAwesomeIcon icon="frown" /></Dropdown.Item>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('thumbs-up')}><FontAwesomeIcon icon="thumbs-up" /></Dropdown.Item>
									<Dropdown.Item variant="outline-primary" size="sm" onClick={() => handleAddIcon('thumbs-down')}><FontAwesomeIcon icon="thumbs-down" /></Dropdown.Item>
								</DropdownButton>
							</Col>
						</Row>
						<Row className="border-bottom  border-end mb-1">
							{curAnswer.icon_list.map((i, key) => (
								<Col key={key} sm="1">
									<FontAwesomeIcon icon={i} />
									<Badge>
										<FontAwesomeIcon icon="trash" onClick={() => handleDeleteIcon(key)} />
									</Badge>
								</Col>
							))}
						</Row>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAnswer}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveAnswer}>
						<FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}





