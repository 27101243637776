import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Form, Button, InputGroup} from 'react-bootstrap';

export const DilutionList = ({ element, functions, t, canWrite }) => {

	const newid = {
		min_quantity: 0,
		max_quantity: 0,
		description: ''
	}

	const handleAddNewDilution = () => {
		let newdilution_list = []
		if (element.dilution_list) {
			newdilution_list = [...element.dilution_list]
		}
		newdilution_list.push(newid)
		functions.edit({ name: 'dilution_list', value: newdilution_list })
	}
	const handleEditDilution = (el, index) => {
		let editlist = [...element.dilution_list]
		let { name, value } = el
		let editdilution = { ...editlist[index], [name]: value }
		editlist.splice(index, 1, editdilution)
		functions.edit({ name: 'dilution_list', value: editlist })
	}
	const handleDeleteDilution = (index) => {
		let editlist = element.dilution_list
		console.log('index old', index, editlist)
		editlist.splice(index, 1)
		functions.edit({ name: 'dilution_list', value: editlist })
	}

	return (

		<Form.Group as={Row} className="border-bottom">
			<Form.Label column sm="2">{t('global.labels.dilution_list')}</Form.Label>
			{element.dilution_list && element.dilution_list.length > 0
				?
				<Col>
					<Row>
						<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleAddNewDilution()}><FontAwesomeIcon icon="plus-square" /> {t('product.labels.dilution_add')}</Button></Col>
					</Row>
					{element.dilution_list.map((i, key) => (
						<Row key={key} className="border-bottom mb-1 pb-1">
							<Col sm="3">
								<InputGroup>
									<Form.Control type="number" name="min_quantity" min="0" max="100" value={i.min_quantity} onChange={(event) => handleEditDilution(event.currentTarget, key)} />
									
										<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
									
								</InputGroup>
								<Form.Text className="text-muted">
									{t('global.labels.min_quantity')}
								</Form.Text>
							</Col>
							<Col sm="3">
								<InputGroup>
									<Form.Control type="number" name="max_quantity" min="0" max="100" value={i.max_quantity} onChange={(event) => handleEditDilution(event.currentTarget, key)} />
									
										<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
									
								</InputGroup>
								<Form.Text className="text-muted">
									{t('global.labels.max_quantity')}
								</Form.Text>
							</Col>
							<Col sm="4">
								<Form.Control type="text" name="description" value={i.description} onChange={(event) => handleEditDilution(event.currentTarget, key)} />
								<Form.Text className="text-muted">
									{t('global.labels.description')}
								</Form.Text>
							</Col>
							<Col className="text-end">
								<Button variant="outline-danger" size="sm" onClick={() => handleDeleteDilution(key)}><FontAwesomeIcon icon="trash" /></Button>
							</Col>
						</Row>
			))}
				</Col>
				: <> <Col className="font-italic"> {t('product.messages.no_dilution')}</Col>
					<Col className="text-end">
						{canWrite ?
							<Button variant="outline-primary" size="sm" onClick={() => handleAddNewDilution()}><FontAwesomeIcon icon="plus-square" /> {t('product.labels.dilution_add')}</Button>
							: ''}
					</Col>
				</>
			}
		</Form.Group>
	)
}

export default DilutionList