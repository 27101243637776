import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addDomainTraining = async (domainId,   data ) => {
    console.log(domainId,  data)
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainings')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addTraining error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getDomainTrainingById = async (domainId,   trainingId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainings')
			.doc(trainingId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getTraining error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getDomainTrainingList = async (domainId, ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainings')
            .get();

        const trainingList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return trainingList;  
    } catch (e) {
        console.log("getTraining error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editDomainTraining = async (domainId,  trainingId, data) => {
    try {
        const training = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainings')
            .doc(trainingId);

        const res = await training.set(
            data
        ).then( () => {
			console.log("editTraining - edit successfully")
			return data
		}).catch( error => {
			console.log("editTraining - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editTraining error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const deleteDomainTraining = async (domainId,   trainingId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainings')
            .doc(trainingId).delete().then(() => {
                console.log("Document successfully deleted!");
                return true;
            }).catch((error) => {
                console.error("Error removing document: ", error);
                return false;
            });
        return res
    }  catch (e) {
        console.log("deleteTraining error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
export const addDomainTrainingEvent = async (domainId,  data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainingagenda')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addTrainingEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getDomainTrainingEventById = async (domainId,  checkeventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainingagenda')
            .get(checkeventId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getTrainingEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getDomainTrainingEventList = async (domainId,  structure=null) => {
    try {
        if (structure === null || structure === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('trainingagenda')
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('trainingagenda')
                .where('structure', '==', structure)
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        }
    } catch (e) {
        console.log("getTrainingEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getDomainTrainingEventRange = async (domainId,  startDate, endDate) => {
    try {
       const res = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('trainingagenda')
                .where('start', ">=", startDate)
                .where('start', "<=", endDate)
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        
    } catch (e) {
        console.log("getTrainingEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editDomainTrainingEvent = async (domainId,  checkeventId, data) => {
    try {
        const checkevent =  firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainingagenda')
            .doc(checkeventId);

        const res = await checkevent.set(data)
            .then( () => {
                console.log("editTrainingEvent - edit successfully")
                return data
            }).catch( error => {
                console.log("editTrainingEvent - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editTrainingEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteDomainTrainingEvent = async (domainId,  checkeventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('trainingagenda')
            .doc(checkeventId).delete();
        return res
    }  catch (e) {
        console.log("deleteTrainingEvent error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
export const addTraining = async (domainId, contractId,  data ) => {
    console.log(domainId, contractId, data)
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainings')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addTraining error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTrainingById = async (domainId,  contractId, trainingId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainings')
			.doc(trainingId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getTraining error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getTrainingList = async (domainId, contractId ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainings')
            .get();

        const trainingList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return trainingList;  
    } catch (e) {
        console.log("getTraining error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editTraining = async (domainId, contractId, trainingId, data) => {
    try {
        const training = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainings')
            .doc(trainingId);

        const res = await training.set(
            data
        ).then( () => {
			console.log("editTraining - edit successfully")
			return data
		}).catch( error => {
			console.log("editTraining - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editTraining error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const deleteTraining = async (domainId, contractId,  trainingId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainings')
            .doc(trainingId).delete().then(() => {
                console.log("Document successfully deleted!");
                return true;
            }).catch((error) => {
                console.error("Error removing document: ", error);
                return false;
            });
        return res
    }  catch (e) {
        console.log("deleteTraining error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
export const addTrainingEvent = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('trainingagenda')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addTrainingEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTrainingEventById = async (domainId, contractId, checkeventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('trainingagenda')
            .get(checkeventId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getTrainingEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTrainingEventList = async (domainId, contractId, structure=null) => {
    try {
        if (structure === null || structure === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('trainingagenda')
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('trainingagenda')
                .where('structure', '==', structure)
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        }
    } catch (e) {
        console.log("getTrainingEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getTrainingEventRange = async (domainId, contractId, startDate, endDate) => {
    try {
       const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('trainingagenda')
                .where('start', ">=", startDate)
                .where('start', "<=", endDate)
                .get();

            const checkeventList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return checkeventList;
        
    } catch (e) {
        console.log("getTrainingEventList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editTrainingEvent = async (domainId, contractId, checkeventId, data) => {
    try {
        const checkevent =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('trainingagenda')
            .doc(checkeventId);

        const res = await checkevent.set(data)
            .then( () => {
                console.log("editTrainingEvent - edit successfully")
                return data
            }).catch( error => {
                console.log("editTrainingEvent - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editTrainingEvent error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteTrainingEvent = async (domainId, contractId, checkeventId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('trainingagenda')
            .doc(checkeventId).delete();
        return res
    }  catch (e) {
        console.log("deleteTrainingEvent error: ", e)
        return { error: e }
    }
}






