import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, InputGroup, FormControl, Row, Col, Button, Spinner, ButtonGroup } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
//import { format, setDay } from 'date-fns'
//import { it } from 'date-fns/locale'
//import { SketchPicker } from 'react-color';
//import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
//import CurrencyInput from 'react-currency-input-field';
import { checkComponentPresence, compareName, getMediaIcon, getElementPreview, getBaseObjectValue } from "../util/ui_utils.js"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalMediaType from "../components/ModalMediaType"
import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"

import { getMediaTypeList, deleteMediaType } from '../Firebase2/Storage2/dbGlobalSettings';


import DomainContext from '../components/contexts/domainContext'
import { getSystemMenu_list } from '../components/constants/systemsections.js';
import ModalHelpmanager from '../components/modal/ModalHelpmanager.js';


const contractId = sessionStorage.getItem('contractId')

const MediaTypesView = ({ t, uData }) => {

	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const icon_list = ['file', 'image', 'map', 'book', 'certificate', 'archive']


	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [newelement_list, setNewElementList] = useState([])
	const [filterAppliedList, setFilterAppliedList] = useState({ section: [] })

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		console.log('index', c)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}

	const initValues = async () => {
		console.log(uData)
		setLoading(true)
		let telement_list = await getMediaTypeList()
		telement_list = telement_list.sort(compareName)
		setElementList(telement_list)
		setNewElementList([...telement_list])
		setLoading(false)
	}


	const permission_dict = getSystemMenu_list({ config: true, t: t })

	useEffect(() => {
		initValues()
	}, [])


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteMediaType(el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('mediatype.messages.delete_mediatype_title'), t('mediatype.messages.delete_mediatype'), cb)
	}



	return (
		<div>

			{/* <SubNavBarRegistry curpage={'mediatypes'} t={t} /> */}
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={'Help Manager'} admin={true} />
					</Col>
					<Col className="text-center">
					</Col>
					<Col sm="2">
					</Col>
					<Col style={{ textAlign: 'right' }}>
					</Col>
				</Row>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{permission_dict.map((i, kk) => (
					<Row key={kk} className="border-start border-end mb-1 mt-1 border-bottom border-secondary">
						<Col>
							<Row className="bg-secondary text-light">
								<Col>
									{i.label}
								</Col>
							</Row>
							{i.menulist.filter(m => m.type === 'link').map((m, km) => (
								<>
									<OnePageHelp m={m} key={km} />
								</>
							))}
						</Col>
					</Row>
				))}


			</Container>
		</div >
	);
}


const OnePageHelp = ({ m }) => {
	return (
		<Row>
			<Col>{m.label}</Col>
			<Col>
				<ModalHelpmanager item={m} is_new={false} small={true} canWrite={true} />
			</Col>
		</Row>
	)
}

export default translate()(MediaTypesView)



