import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Modal,  Container, FormControl, Row, Col, Form, Button, Spinner, ButtonGroup } from 'react-bootstrap';
//import DateTime from '@nateradebaugh/react-datetime';
import { translate } from 'react-polyglot'


//import "@nateradebaugh/react-datetime/scss/styles.scss";

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"

import { checkComponentPresence, } from "../util/ui_utils.js"


import { addProduct as addProduct2, 
	getProductList as getProductList2, 
	editProduct as editProduct2, 
	deleteProduct as deleteProduct2 } from '../Firebase2/Storage2/dbWarehouse';



import DomainContext from '../components/contexts/domainContext'



const newcostcenter = {
	code: '',
	name: '',
	note: ''
}
/*
const element_list = [
	{
		name: '',
		brand: {},
		material: {},
		size_w: 0,
		size_h: 0,
		size_d: 0,
		net_weight: 0,
		capacity: 0,
		capacity_quantity: 0,
		color: { r: 10, g: 10, b: 90, a: 1 }
	}
]
*/

const contractId = sessionStorage.getItem('contractId')
const companyId = '0'

const CostcentersView = ({ mylocation, t }) => {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const [ui_list] = useState({})
	const [element_list, setElementList] = useState([])
	const [loading, setLoading] = useState(false)



	useEffect(() => {
	const initValues = async () => {
		setLoading(true)
		const newelement_list = await getProductList2(domain, contractId, companyId, 'costcenter', null)
		setElementList(newelement_list)
	}
		initValues()
	}, [])

	useEffect(() => {
		setLoading(false)
	}, [element_list])

	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curElement, setCurrentElement] = useState(newcostcenter)

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		if (newEdit) {
			let return_data = await addProduct2(domain, contractId, companyId, 'costcenter', curElement)
			console.log(return_data)
			if (return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const newlist = [...element_list]
				newlist.push(return_data)
				setElementList(newlist)
				setShow(false);
			}
		} else {
			let return_data =  await editProduct2(domain, contractId, companyId, 'costcenter', curElement.id, curElement)
			console.log(return_data)
			if (return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const newlist = [...element_list]
				const c = checkComponentPresence(return_data, element_list, 'id')
				newlist.splice(c, 1, return_data)
				setElementList(newlist)
				setShow(false);
			}
		}
	}

	const handleShowNew = () => {
		setCurrentElement(newcostcenter)
		setNew(true)
		setShow(true)
	}

	const handleShowEdit = (el) => {
		setCurrentElement(el)
		setNew(false)
		setShow(true)
	}

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data =  await deleteProduct2(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('product.messages.delete_costcenter_title'), t('product.messages.delete_costcenter'), cb)
	}

	const onChangeHandler = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (event.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}


	return (
		<>

			{/* <SubNavBarRegistry curpage={'costcenters'} t={t} /> */}
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col className="h3">
						{t('navs.registries.costcenters')}
					</Col>
					<Col sm={8}>
						<Form inline>
							<FormControl type="text" placeholder={t('search')} className="" />
							<Button variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
						</Form>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('costcenters.buttons.add_new')}</Button>
					</Col>
				</Row>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ms-1" sm="2">{t('costcenters.labels.code')}</Col>
							<Col className="bg-secondary ms-1">{t('global.labels.name')}</Col>
							<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list.map((i, key) => (
							<Row className="oneCompany p-1 border-bottom">
								<Col sm="2">{i.code}</Col>
								<Col>
									<b>{i.name}</b>
								</Col>
								<Col className="text-center" sm="1" >
									<ButtonGroup>
										<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit(i)}><FontAwesomeIcon icon="pencil-alt" /></Button>
										<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
									</ButtonGroup>
								</Col>
							</Row>
						))}
					</>
					: <> {t('costcenters.messages.no_costcenter')}
					</>
				}
				<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
					<Modal.Header closeButton>
						{newEdit && <Modal.Title variant="primary">{t('costcenters.modal.titlenew')}</Modal.Title>}
						{!newEdit && <Modal.Title variant="info">{t('costcenters.modal.titleedit')}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Container>
							<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} />
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className="btn-myprimary" form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>

		</>
	)
}

export default translate()(CostcentersView)


function ElementFormDetails({ element, functions, ui_list, t }) {

	return (
		<>
			<Form.Group as={Row} controlId="costcenterCode" className="border-bottom">
				<Form.Label column sm="2">{t('costcenters.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" className="mb-2" name="code" defaultValue={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="costcenterDescription" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" className="mb-2" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="costcenterNote" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" className="mb-2" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}