import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Image, ProgressBar, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
import { ModalInitButton, MyBadge } from '../../StructureGraphicElements';
import AlertSection from '../../AlertSection';
import { getValue } from '../../../util/ui_utils';

const icon_list = [
    'hospital-alt',
    'clinic-medical',
    'list-ol',
    'stethoscope',
    'bed',
    'building',
    'home',
    'warehouse',
]




///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ElementStructureSkel({ element, functions, ui_list, t, service_list, canWrite }) {

    const handleDeleteStructureSkel = (index) => {
        let editlist = [...element.structure_skel_list]
        editlist.splice(index, 1)
        functions.edit({ name: 'structure_skel_list', value: editlist })
    }


    const setstructure_skelList = (editlist) => {
        functions.edit({ name: 'structure_skel_list', value: editlist })
    }
    return (
        <>


            <Container fluid>
                {element.structure_skel_list && element.structure_skel_list.length > 0 ?
                    <>
                        <Row className="mb-1">
                            <Col className="bg-secondary text-light ms-1" sm="4">{t('global.labels.name')} </Col>
                            <Col className="bg-secondary text-light ms-1">Raggrupamento</Col>
                            <Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
                        </Row>
                        {element.structure_skel_list?.map((i, key) => (
                            <Row key={key}>
                                <Col sm="4">
                                    {i.name}
                                </Col>
                                <Col className="text-end">
                                </Col>
                                <Col className="text-center" sm="1">
                                    <ModalStructureSkel t={t} item={i} service_list={service_list} index={key} canWrite={canWrite} item_list={element.structure_skel_list} setItem={setstructure_skelList} />
                                    {canWrite ?
                                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteStructureSkel(key)}><FontAwesomeIcon icon="trash" /></Button>
                                        : ''}
                                </Col>
                            </Row>
                        ))}
                    </>
                    :
                    <Row>
                        <ModalStructureSkel t={t} is_new service_list={service_list} canWrite={canWrite} item_list={element.structure_skel_list} setItem={setstructure_skelList} />
                        <Col className="font-italic">
                            Nessuna definizione struttura inserita
                        </Col>
                    </Row>
                }

            </Container>
        </>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalStructureSkel({ item, setItem, t, is_new, small, item_list, index, canWrite, }) {

    const childRefAlert = useRef()

    const newskel = {
        name: '',
        level_list: []
    }


    const [showEdit, setShow] = useState(false)
    const [curElement, setCurrentElement] = useState(newskel)
    const [editedElement, setEditedElement] = useState(false)

    const handleShowNew = () => {
        setCurrentElement(newskel)
        setShow(true)
        setEditedElement(false)
    }

    const handleShowEdit = () => {
        setCurrentElement(item)
        setShow(true)
        setTimeout(function () {
            setEditedElement(false)
        }, 500)
    }


    const onChangeHandler = (el) => {
        console.log(el)
        let { name, value, type } = el
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = curElement[name]
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        console.log(name, value)
        setCurrentElement({ ...curElement, [name]: value })
        setEditedElement(true)
    }



    const handleClose = () => setShow(false);

    const handleSave = async () => {
        console.log("____________________________________SAVE_____________________________", is_new)
        let edit_list = [...item_list]
        if (is_new) {
            edit_list.push(curElement)
        } else {
            edit_list.splice(index, 1, curElement)
            console.log(index, item_list)
        }
        setItem(edit_list)
        setShow(false);
    }


    const setLevelList = (l_list) => {
        setCurrentElement({ ...curElement, level_list: l_list })
    }
    const handleDeleteStructureSkelElem = (index) => {
        let _editlist = [...curElement.level_list]
        _editlist.splice(index, 1)
        setCurrentElement({ ...curElement, level_list: _editlist })
    }

    return (
        <>
            <ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={"Aggiungi definizione struttura"} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
            <Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
                <AlertSection ref={childRefAlert} />
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {is_new ?
                            <>
                                <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">Nuovo definizione struttura</span>
                            </>
                            :
                            [canWrite ?
                                <>
                                    <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">Modifica definizione struttura</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon="info" /> <span className="text-info">Info deinizione struttura</span>
                                </>
                            ]
                        }
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
                            <Form.Label column sm="2">Nome</Form.Label>
                            <Col sm="6">
                                <Form.Control className="mb-2" type="text" name="name" value={getValue(curElement, 'name')} onChange={(event) => onChangeHandler(event.currentTarget)} />
                            </Col>
                        </Form.Group>
				<Row className="mt-1">
					<Col>
						Livelli
					</Col>
					<Col sm="3" className="font-italic">
                    Aggiungi almeno 2 livelli 
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite &&
                        <ModalStructureSkelLevel is_new={true} t={t} canWrite={canWrite} setItem={setLevelList} item_list={curElement.level_list} />
						}
					</Col>
				</Row>
                        {curElement.level_list && curElement.level_list.length > 0 ?
                            <Row className="p-1 text-light">
                                <Col className="bg-secondary ms-2" sm="2">Livello</Col>
                                <Col className="bg-secondary ms-1">Nome</Col>
                                <Col className="bg-secondary ms-1">Colore</Col>
                                <Col className="bg-secondary ms-1">Icona</Col>
                                <Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
                            </Row>
                            : ''}
                        {curElement.level_list.map((l, k) => (
                            <Row key={k} className="p-1 border-bottom"  >
                                <Col sm={2}>
                                    {k} {k === curElement.level_list.length - 1 && curElement.level_list.length > 1 && '(nodo finale)'}                               </Col>
                                <Col>
                                    {l.name}
                                </Col>
                                <Col>
                                    <MyBadge color={l.color} className="border-white">
                                    &nbsp;&nbsp;</MyBadge>
                                </Col>
                                <Col>
                                    <FontAwesomeIcon icon={l.icon} />
                                </Col>
                                <Col sm={1}>
                                    <ModalStructureSkelLevel item={l} t={t} index={k} canWrite={canWrite} setItem={setLevelList} item_list={curElement.level_list} />
                                    {canWrite &&
                                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteStructureSkelElem(k)}><FontAwesomeIcon icon="trash" /></Button>
                                    }
                                </Col>
                            </Row>
                        ))}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('modal.close')}
                    </Button>
                    {canWrite &&
                        <Button className={editedElement ? "btn-warning" : "btn-primary"} disabled={curElement.level_list.length < 2} form="companyForm" onClick={handleSave}>
                            <FontAwesomeIcon icon="save" /> {t('modal.save')}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalStructureSkelLevel({ item, setItem, t, is_new, small, item_list, index, canWrite, }) {

    const childRefAlert = useRef()

    const newskel_level = {
        name: '',
        icon: 'list-ol',
        color: '#111',
        is_group: false
    }
    const [displayColorPicker, setShowColorPicker] = useState(false)
    const [color, setCurColor] = useState({ r: 100, g: 0, b: 0, a: 1 })
    const [showEdit, setShow] = useState(false)
    const [curElement, setCurrentElement] = useState(newskel_level)
    const [editedElement, setEditedElement] = useState(false)


    const defaultcolors = [
        '#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
    ]

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: curElement.color
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });


    const handleShowNew = () => {
        setCurrentElement(newskel_level)
        setShow(true)
        setEditedElement(false)
    }

    const handleShowEdit = () => {
        setCurrentElement(item)
        setShow(true)
        setTimeout(function () {
            setEditedElement(false)
        }, 500)
    }


    const onChangeHandler = (el) => {
        console.log(el)
        let { name, value, type } = el
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = curElement[name]
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        console.log(name, value)
        setCurrentElement({ ...curElement, [name]: value })
        setEditedElement(true)
    }



    const handleClose = () => setShow(false);

    const handleSave = async () => {
        console.log("____________________________________SAVE_____________________________", is_new)
        let edit_list = [...item_list]
        if (is_new) {
            edit_list.push(curElement)
        } else {
            edit_list.splice(index, 1, curElement)
            console.log(index, item_list)
        }
        setItem(edit_list)
        setShow(false);
    }

    const onChangeHandlerColor = (el) => {
        setCurrentElement({ ...curElement, color: el.hex })
    }
    const handleChangeColor = (c) => {
        console.log('set color', c)
        setCurColor(c.hex)
    }
    const handleShowColorPicker = () => {
        setShowColorPicker(!displayColorPicker)
    }
    const handleCloseColorPicker = () => {
        setShowColorPicker(false)
    }

    const isDisabled = () => {
        if (curElement.name.length === 0) {
            return true
        }
        return false
    }

    return (
        <>
            <ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={"Aggiungi livello struttura"} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
            <Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
                <AlertSection ref={childRefAlert} />
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
                    <Modal.Title>

                        {is_new ?
                            <>
                                <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">Nuovo livello struttura</span>
                            </>
                            :
                            [canWrite ?
                                <>
                                    <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">Modifica livello struttura</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon="info" /> <span className="text-info">Info livello struttura</span>
                                </>
                            ]
                        }
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
                            <Form.Label column sm="2">Nome</Form.Label>
                            <Col sm="6">
                                <Form.Control className="mb-2" type="text" name="name" value={getValue(curElement, 'name')} onChange={(event) => onChangeHandler(event.currentTarget)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
                            <Form.Label column sm="2">Icona</Form.Label>
                            <Col sm="6">
                                {icon_list.map((s, k) => (
                                    <Form.Check key={k} type="radio" name="icon" value={s} checked={curElement.icon === s} label={<FontAwesomeIcon icon={s} />} onChange={(event) => onChangeHandler(event.currentTarget)} />
                                ))}
                            </Col>
                        </Form.Group>
                        <Row className="border-bottom">
                            <Col sm="2">{t('objecttype.labels.color')}</Col>
                            <Col>
                                <Button variant="outline-info" onClick={handleShowColorPicker}>
                                    <div style={styles.color} />
                                </Button>
                                {displayColorPicker ? <div style={styles.popover}>
                                    <div style={styles.cover} onClick={handleCloseColorPicker} />
                                    <BlockPicker color={color} colors={defaultcolors} triangle="hide" onChange={handleChangeColor} onChangeComplete={onChangeHandlerColor} />
                                </div> : null}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('modal.close')}
                    </Button>
                    {canWrite ?
                        <Button className={editedElement ? "btn-warning" : "btn-primary"} disabled={isDisabled()} form="companyForm" onClick={handleSave}>
                            <FontAwesomeIcon icon="save" /> {t('modal.save')}
                        </Button>
                        : ''}
                </Modal.Footer>
            </Modal>
        </>
    )
}




