import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import systemparams from "../components/constants/systemparams"
import { Row, Col, Badge, Button } from 'react-bootstrap';
import { getValue } from "../util/ui_utils"
import { NavLink, useLocation } from "react-router-dom"
import GlobalDataContext from './contexts/globaldataContext.js';

const PageTitle = ({ title, button_list, admin, counter }) => {

    //const contract = JSON.parse(sessionStorage.getItem('contract'))
    const { contract } = useContext(GlobalDataContext)

    const [contractname, setcontractname] = useState('')
    const [rowHover, toggleHover] = useState(false)
    const location = useLocation();


    const initValues = () => {
        if (location.pathname === '/usersettings') {
            document.title = systemparams.sw_name + " | " + title + " | User"
            setcontractname('')

        } else {

            if (admin) {
                document.title = systemparams.sw_name + " | " + title + " | ADMIN"
                setcontractname('ADMIN')
            } else {
                document.title = systemparams.sw_name + " | " + title + " | " + getValue(contract, 'name')
                setcontractname(getValue(contract, 'name'))
            }
        }

    }

    useEffect(() => {
        initValues()
    }, [contract])



    return (
        <>
            <Row>
                <Col>
                    <Row className="h5" >
                        <Col style={{ borderTopRightRadius: '10px' }} >
                            <span className="bg-secondary text-light pt-1 pb-1 ps-3 border-light border-end pe-3 border-bottom ">
                                {admin ?
                                    <FontAwesomeIcon icon="cogs" className="text-danger" />
                                    :
                                    <FontAwesomeIcon icon="tachometer-alt" />
                                }
                            </span>
                            <span className="bg-secondary border-light border-bottom text-light pt-1 pb-1 pe-3 ps-3" style={{ borderTopRightRadius: '10px' }}>
                                {contract && contractname}

                            </span>
                        </Col>
                    </Row>
                    <Row className="h4 mt-2" >
                        <Col>
                            <span className={`pe-4 ps-4 pb-1 text-light page_title ${admin ? "activeadmin" : "active"}`}style={{ borderBottomRightRadius: counter? "": '10px' }}>
                                {title}
                            </span>

                            {counter &&
                                <span className={`pe-4 ps-4 pb-1 text-light page_title ${admin ? "activeadmin" : "active"}`} style={{ borderBottomRightRadius: '10px' }}>
                                    {counter}
                                </span>
                            }
                        </Col>
                        {button_list &&
                            <Col>
                                <Button className="button" onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}><span><FontAwesomeIcon icon="plus-square" />{rowHover ? 'aggiungi un dato' : ''}</span></Button>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            {/* <ol className="breadcrumb breadcrumb-arrow h5 mb-1 overflow-hidden">
                <li><NavLink to="./home">
                    {admin ?
                        <FontAwesomeIcon icon="cogs" className="text-danger" />
                        :
                        <FontAwesomeIcon icon="tachometer-alt" />
                    }
                </NavLink> </li>
                {contract ?
                    <li className=""><NavLink to="#">{contractname}</NavLink></li>
                    : ''}
                <li className={admin ? "activeadmin" : "active"}>
                    <span>

                        {title}
                    </span>
                </li>
                {counter ?
                    <li className={admin ? "activeadmin" : "active"}>
                        <span>
                            {counter}
                        </span>
                    </li>
                    : ''}
                {button_list ?
                    <li className="button" onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}><span><FontAwesomeIcon icon="plus-square" />{rowHover ? 'aggiungi un dato' : ''}</span></li>
                    : ''
                }
            </ol> */}
        </>
    )
}


export default PageTitle;
