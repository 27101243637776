import React, { useState, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-day-picker/lib/style.css';
import { format, getDay, differenceInMinutes, set, addHours, isThisMonth, getMonth, add, getDate, differenceInHours } from 'date-fns'
import { it } from 'date-fns/locale'

import firebase from 'firebase/compat/app'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { myTimestampToDate, myDateToTimestamp, getValue, getSelectedElementB, getFormattedDate } from "../../util/ui_utils.js"
import { ServiceBadge, RiskareaBadge, RoomInfoBadge, Frequency, IsModified, SecondarySection } from '../StructureGraphicElements.js'
import { payment_mode_dict, interventionType_dict } from '../constants/global_definitions.js';


import { filterRoom } from '../RoomSelector'




///////////////////////////////////////////
const ModalInterventionEvent = forwardRef((props, ref) => {
    const def_dialog = {
        message: '',
        title: 'conferma',
    }
    const intervention_type_dict = interventionType_dict({ t: props.t })
    const { service_dict, contract } = useContext(GlobalDataContext)

    const [curEvent, setCurEvent] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [editedElement, setEditedElement] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(def_dialog)
    const [editDateReference, setEditDateReference] = useState({ start: new Date(), end: new Date() })
    const [isEditable, setIsEditable] = useState(false)
    const [isError, setIsError] = useState(false)
    const [payment_mode, setPaymentMode] = useState()


    useImperativeHandle(ref, () => ({
        setEvent(_ev, saveCallback, cref, info) {
            console.log(cref, info, _ev)
            let end = _ev.end || addHours(_ev.start, 1)
            console.log(end, _ev.end)
            setConfirmDialog({
                event: { ..._ev, end: end },
                saveCallback: saveCallback,
                cref: cref,
                info: info,
            })
            try {
                setEditDateReference({ start: Object.assign(_ev.start), end: Object.assign(end) })
            } catch (err) {
                console.log(err)
            }
            let force_edit = false
            if (_ev.extendedProps.source_message_id) {
                const pm = contract && contract.serviceamounthour_list ? contract.serviceamounthour_list.filter(s => s.service && s.service.id === _ev.extendedProps.service_id) : []
                console.log("CHECK PAYMENT MODE", contract, pm)
                if (pm.length > 0) {
                    const _pm = pm[0]
                    let _def_quantity = 1
                    if (_pm.payment_mode === 'hour') {
                        _def_quantity = differenceInHours(_ev.end, _ev.start)
                    } else if (_pm.quantity_done === 'size') {
                        _def_quantity = _ev.intervention_data.room_list.reduce((a, v) => a + parseFloat(v.size), 0)
                    }
                    setPaymentMode({ ..._pm, default_quantity: _def_quantity })
                    if (!_ev.extendedProps.quantity_done) {
                        force_edit = true
                        _ev.extendedProps.quantity_done = _def_quantity
                    }
                } else {
                    setPaymentMode()
                }
            } else {
                setPaymentMode()
            }
            setCurEvent({ ..._ev, end: end })
            setShowModal(true)
            setEditedElement(force_edit)
            const MAX_DAY = contract.lock_intervention_nextdays
            if (isThisMonth(_ev.start) || (isThisMonth(add(_ev.start, { month: 1 })) && getDate(new Date()) < MAX_DAY)) {
                console.log("---editable")
                setIsEditable(true)
            } else {
                setIsEditable(false)
            }
            console.log(_ev)

            setIsError(!_ev.end)
        }
    }));


    const handleClose = () => {
        if (confirmDialog.undoCallback) {
            try {
                confirmDialog.undoCallback()
            } catch (err) {
                console.log("(DeleteconfirmationSection) ", err)
            }
        }
        setShowModal(false)
    }



    const handleSave = async () => {
        let _event = { ...curEvent, start: firebase.firestore.Timestamp.fromDate(curEvent.start), end: firebase.firestore.Timestamp.fromDate(curEvent.end) }
        delete _event.extendedProps.summary
        delete _event.extendedProps.intervention_data
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback(_event, confirmDialog.cref, confirmDialog.info)
            } catch (err) {
                console.log("|||||error|||||", err)
            }

        }
        setShowModal(false)
    }

    const handleRecover = () => {
        let _event = { ...curEvent, start: firebase.firestore.Timestamp.fromDate(curEvent.start), end: firebase.firestore.Timestamp.fromDate(curEvent.end), extendedProps: { ...curEvent.extendedProps, is_deleted: false } }
        delete _event.extendedProps.summary
        delete _event.extendedProps.intervention_data
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback(_event, confirmDialog.cref, confirmDialog.info)
            } catch (err) {
                console.log("|||||error|||||", err)
            }
        }
        setShowModal(false)
    }


    const handleDelete = () => {
        console.log(curEvent)
        let _event = {
            ...curEvent,
            start: firebase.firestore.Timestamp.fromDate(curEvent.start),
            end: curEvent.end !== null ? firebase.firestore.Timestamp.fromDate(curEvent.end) : firebase.firestore.Timestamp.fromDate(addHours(curEvent.start, 1)),
            extendedProps: { ...curEvent.extendedProps, is_deleted: true }
        }
        delete _event.extendedProps.summary
        delete _event.extendedProps.intervention_data
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback(_event, confirmDialog.cref, confirmDialog.info)
            } catch (err) {
                console.log("|||||error|||||", err)
            }
        }
        setShowModal(false)

    }

    const onChangeHandler = (event) => {
        let { name, value, type } = event;
        console.log(name, value)
        if (name === 'start' || name === 'end') {
            try {
                value = value.toDate()
                const ds = (name === 'start') ? myTimestampToDate(editDateReference.start) : myTimestampToDate(editDateReference.end)
                console.log(ds)
                value = set(value, { date: ds.getDate(), month: ds.getMonth(), year: ds.getFullYear() })
                //value.setDate(curEvent.extendedProps.originalEnd.getDate())
                //value.setMonth(curEvent.extendedProps.originalEnd.getMonth())
            } catch (err) {
                console.error(err)
            }
            if (!curEvent.extendedProps.is_modified) {
                setCurEvent({ ...curEvent, [name]: value, extendedProps: { ...curEvent.extendedProps, is_modified: true, originalStart: myDateToTimestamp(curEvent.start), originalEnd: myDateToTimestamp(curEvent.end) } })
            } else {
                setCurEvent({ ...curEvent, [name]: value })
            }
        } else if (name === 'date') {
            let _start = value.toDate()
            let _end = value.toDate()
            _start = set(_start, { hours: curEvent.start.getHours(), minutes: curEvent.start.getMinutes(), seconds: 0 })
            _end = set(_end, { hours: curEvent.end.getHours(), minutes: curEvent.end.getMinutes(), seconds: 0 })
            //console.log(_start, _end)
            setEditDateReference({ start: Object.assign(_start), end: Object.assign(_end) })
            if (!curEvent.extendedProps.is_modified) {
                setCurEvent({ ...curEvent, start: _start, end: _end, extendedProps: { ...curEvent.extendedProps, is_modified: true, originalStart: myDateToTimestamp(curEvent.start), originalEnd: myDateToTimestamp(curEvent.end) } })
            } else {
                setCurEvent({ ...curEvent, start: _start, end: _end })
            }

        } else {
            if (type === 'radio') {
                if (value === 'true') {
                    value = true
                } else if (value === 'false') {
                    value = false
                }
            }
            let newprops = { ...curEvent.extendedProps, [name]: value }
            setCurEvent({ ...curEvent, extendedProps: newprops })
        }
        setEditedElement(true)
    }




    return <div className="deleteconfirmation_container">
        {curEvent.start ?
            <Modal show={showModal} onHide={handleClose} centered size="xl" scrollable={true} >
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
                    <Modal.Title>
                        {curEvent.extendedProps.ticket ?
                            <FontAwesomeIcon icon="plus-square" className="me-1" />
                            : <></>}
                        {getIntervetionTitle(curEvent.start, curEvent.end)}	| {getValue(curEvent.extendedProps.intervention_data, 'name')}
                        {isError ?
                            <span style={{ fontSize: '0.8em', marginLeft: '5px' }}><i>verificare i dati orari</i></span>
                            : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                {curEvent.extendedProps.source_message_id ?
                                    <></>
                                    :
                                    <IsModified is_modified={curEvent.extendedProps.is_modified} legend={true} />
                                }
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-1 mb-1">
                            <Col>
                                {intervention_type_dict[curEvent.extendedProps.type].title}
                            </Col>
                            {curEvent.extendedProps.intervention_data ?
                                <Col>
                                    <ServiceBadge service={service_dict[curEvent.extendedProps.intervention_data.service.id]} freq={true} />
                                    {curEvent.extendedProps.intervention_data.enable_refill ?
                                        <>&nbsp;(
                                            <FontAwesomeIcon icon="pump-soap" /> con Ricarica dei dispenser)</>
                                        : <></>}
                                </Col>
                                : <></>}
                        </Row>
                        {curEvent.extendedProps.intervention_data && curEvent.extendedProps.intervention_data.secondary_service_list && curEvent.extendedProps.intervention_data.secondary_service_list.length > 0 ?
                            <Row>
                                <Col>
                                    <SecondarySection ev={curEvent} intervention_data={curEvent.extendedProps.intervention_data} long={true} />
                                </Col>
                            </Row>
                            : ''}
                        {props.canWrite ?
                            <>
                                {curEvent.extendedProps.type !== 'ordinary_intervention' ?
                                    <Form.Group as={Row} className="border-bottom pb-1">
                                        <Form.Label column sm="2">{props.t('workareas.labels.date')}</Form.Label>
                                        <Col sm="6">
                                            <DateTime initialValue={myTimestampToDate(curEvent.start)} dateFormat={'DD/MM/yyyy'} timeFormat={false} name="date" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'date', value: e })} />
                                        </Col>
                                    </Form.Group>
                                    : ''
                                }
                                <Form.Group as={Row} className="border-bottom pb-1">
                                    <Form.Label column sm="2">{props.t('workareas.labels.start_time')}</Form.Label>
                                    <Col sm="6">
                                        <DateTime initialValue={myTimestampToDate(curEvent.start)} dateFormat={false} timeFormat='HH:mm' name="start" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'start', value: e })} />
                                    </Col>

                                </Form.Group>
                                <Form.Group as={Row} className="border-bottom pb-1">
                                    <Form.Label column sm="2">{props.t('workareas.labels.end_time')}</Form.Label>
                                    <Col sm="6">
                                        <DateTime initialValue={myTimestampToDate(curEvent.end)} dateFormat={false} timeFormat='HH:mm' name="end" locale="it" closeOnSelect={true} onChange={(e) => onChangeHandler({ name: 'end', value: e })} />
                                    </Col>
                                    {/*                                     <Col>
                                        {getIntervetionLength(curEvent.start, curEvent.end)}
                                        <Form.Text>
                                            durata
                                        </Form.Text>
                                    </Col> */}
                                </Form.Group>
                                {contract.execution_mode === 'manual' ?
                                    <Form.Group as={Row} className="border-bottom pb-1">
                                        <Form.Label column sm="2" className="text-warning">{props.t('intervention.labels.execution_done')}</Form.Label>
                                        <Col sm="2">
                                            <Form.Check type="radio" name="execution_done" disabled={curEvent.start >= new Date()} value={true} checked={curEvent.extendedProps.execution_done} label={props.t('global.labels.yes')} onChange={(event) => onChangeHandler(event.currentTarget)} />
                                            <Form.Check type="radio" name="execution_done" disabled={curEvent.start >= new Date()} value={false} checked={!curEvent.extendedProps.execution_done} label={props.t('global.labels.no')} onChange={(event) => onChangeHandler(event.currentTarget)} />
                                        </Col>
                                        <Col>
                                            {curEvent.start >= new Date ?
                                                <i>Intervento non ancora inziato </i> : <></>}
                                        </Col>
                                    </Form.Group>
                                    :
                                    ''}
                                {payment_mode &&
                                    <>
                                        <Form.Group as={Row} className="border-bottom mb-1">
                                            <Form.Label column sm="2">{payment_mode_dict[payment_mode.payment_mode].label_done}</Form.Label>
                                            <Col sm="4">
                                                <Form.Control type="number" min={0} step={0.5} name="quantity_executed" defaultValue={curEvent.quantity_done || payment_mode.default_quantity} onChange={(event) => onChangeHandler(event.currentTarget)} className="mb-2" />
                                            </Col>
                                            <Col>
                                                predefinito: {payment_mode.default_quantity} {payment_mode_dict[payment_mode.payment_mode].label_unit}
                                            </Col>
                                        </Form.Group>
                                    </>
                                }

                            </>
                            : ''}
                        {curEvent.extendedProps.note || curEvent.extendedProps.ticket ?
                            <Row className="border-bottom pb-1">
                                <Form.Label column sm="2">{props.t('global.labels.note')}</Form.Label>
                                <Col>
                                    {curEvent.extendedProps.ticket ?
                                        <Form.Control as="textarea" rows="3" name="note" defaultValue={curEvent.extendedProps.note} onChange={(event) => onChangeHandler(event.currentTarget)} className="mb-2" />
                                        :
                                        <>{curEvent.extendedProps.note}</>
                                    }
                                </Col>
                            </Row>
                            : <></>}
                        {curEvent.createdBy ?
                            <Row>
                                <Col>
                                    Creato da
                                </Col>
                                <Col>
                                    {curEvent.createdBy}
                                </Col>
                            </Row>
                            :
                            <></>}


                        <Row>
                            <Col>
                                {curEvent.extendedProps.intervention_data && curEvent.extendedProps.intervention_data.room_list && curEvent.extendedProps.intervention_data.room_list.length > 0 ?
                                    <>
                                        <Row className="p-1 text-light mt-4">
                                            <Col className="ms-1 text-info" >{props.t('workarea.labels.roomlist')}</Col>
                                        </Row>
                                        <Row className="p-1 mb-1">
                                            <Col className="ms-1 bg-secondary text-light" >{props.t('global.labels.name')}</Col>
                                            <Col className="ms-1 bg-secondary text-light" >{props.t('structures.labels.room_type')}</Col>
                                            {curEvent.extendedProps.type !== 'requested_intervention' ?
                                                <Col className="ms-1 bg-secondary text-light" sm="2" > Frequenza</Col>
                                                : <></>}
                                            <Col className="ms-1 bg-secondary text-light text-center" sm="1" >m<sup>2</sup></Col>
                                            <Col className="ms-1 bg-secondary text-light text-center" sm="2" >
                                                {contract && contract.type === 'civil' ?
                                                    props.t('global.labels.standard_cleaning_short')
                                                    :
                                                    props.t('global.labels.risk_area_short')
                                                }
                                            </Col>
                                            <Col className="ms-1 bg-secondary text-light" sm="1" >Cdc</Col>
                                        </Row>
                                        {curEvent.extendedProps.intervention_data.room_list.map((room, index) => (
                                            <OneRoomRow key={index} room={room} intervention_type={curEvent.extendedProps.type} curEvent={curEvent} t={props.t} ui_list={props.ui_list} />
                                        ))}
                                    </>
                                    : ''
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {props.t('modal.close')}
                    </Button>
                    {props.canWrite && (!contract.lock_intervention_active || isEditable) ?
                        <>
                            {curEvent.extendedProps.is_deleted ?
                                <Button className="btn-success" onClick={handleRecover}>
                                    <FontAwesomeIcon icon="play" /> {props.t('modal.recover')}
                                </Button>
                                :
                                <Button className="btn-danger" onClick={handleDelete}>
                                    <FontAwesomeIcon icon="trash" /> {props.t('modal.delete')}
                                </Button>
                            }
                            <Button disabled={!isValid(curEvent)} className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
                                <FontAwesomeIcon icon="save" /> {props.t('modal.save')}
                            </Button>
                        </>
                        : ''}
                </Modal.Footer>
            </Modal>
            : ''}
    </div>
})



const getIntervetionTitle = (start, end) => {
    try {
        return (<>
            {format(start, 'cccc dd/MM (HH:mm', { locale: it })} - {format(end, 'HH:mm)', { locale: it })}
        </>)
    } catch (err) {
        return <>Data non valida</>

    }
}


const getIntervetionLength = (start, end) => {
    try {
        return (<>{differenceInMinutes(end, start)} minuti </>)

    } catch (err) {
        return <></>

    }
}


const isValid = (ev) => {
    try {

        const d = differenceInMinutes(ev.end, ev.start)
        console.log(d)
        if (!isNaN(d) && d > 0) {
            console.log("isValid")
            return true
        } else {
            console.log("invalid")
            return false
        }
    } catch (err) {
        return false

    }
}



// --------------------------------------------------------
const OneRoomRow = ({ room, index, intervention_type, curEvent, t, ui_list, ticket, functions }) => {
    const { structureElements, risk_area_dict, roomtype_dict, service_dict } = useContext(GlobalDataContext)
    const _room = structureElements.all_room_dict[room.id]
    if (_room){

    const rt = roomtype_dict[_room.roomtype.id]
    let wd = undefined
    try {
        const i = getSelectedElementB(curEvent.extendedProps.intervention_data.service.id, roomtype_dict[_room.roomtype.id].service_list, 'service', 'id')
        wd = curEvent.extendedProps.intervention_data.custom_weekdays && curEvent.extendedProps.intervention_data.custom_weekdays[room.id] ?
            curEvent.extendedProps.intervention_data.custom_weekdays[room.id] :
            i.weekdays
    } catch (err) {

    }

    const onChangeHandlerRoom = (e, index) => {
        let editlist = [...ticket.room_list]
        editlist[index].workarea_id = e.value
        functions.edit({ name: 'room_list', value: editlist })
    }
    const intervention_data = curEvent.extendedProps.intervention_data


    if (!wd) {
        console.log("PROBLEMA WD", wd, intervention_type)
    }
    return (
        <Row key={room.id + index} className={intervention_type === 'periodic_intervention' || intervention_type === 'requested_intervention' || (wd && wd.indexOf(getDay(curEvent.start).toString()) !== -1) ? 'border-bottom border-start pb-1 mt-1 bg-light border-success' : 'border-bottom pb-1 mt-1 text-secondary'} >
            <Col>
                {wd && wd.indexOf(getDay(curEvent.start).toString()) !== -1 ?
                    <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={<Tooltip>locale presente da programmazione nel giorno selezionato</Tooltip>}>
                        <FontAwesomeIcon icon="check" />
                    </OverlayTrigger>
                    : ''}
                <RoomInfoBadge parent_list={_room.parent_list} />
                {_room.name}
            </Col>
            <Col className="text-muted">
                {rt ? rt.name : ''}
            </Col>
            {intervention_type !== 'requested_intervention' ?
                <Col sm="2" className="ps-1 pe-1">
                    <Row>
                        <Col>
                            <Frequency roomtype_id={_room.roomtype.id} service_id={intervention_data.service.id} curDay={getDay(curEvent.start).toString()} roomtype_dict={roomtype_dict} customFrequency={intervention_data.custom_weekdays && intervention_data.custom_weekdays[room.id] ? intervention_data.custom_weekdays[room.id] : undefined} />
                        </Col>
                    </Row>
                    {intervention_data && intervention_data.secondary_service_list && intervention_data.secondary_service_list.length > 0 && intervention_data.secondary_custom_weekdays && intervention_data.secondary_custom_weekdays[_room.id] ?
                        intervention_data.secondary_service_list.filter(s => rt.service_list && rt.service_list.filter(sr => sr.service && sr.service.id === s).length > 0).map((s) => (
                            <Row key={s} className="bg-light border-top border-info mt-1 text-secondary">
                                <Col className="">
                                    {intervention_data.secondary_custom_weekdays && intervention_data.secondary_custom_weekdays[room.id] && intervention_data.secondary_custom_weekdays[room.id][s] && intervention_data.secondary_custom_weekdays[room.id][s].indexOf(getDay(curEvent.start).toString()) !== -1 ?
                                        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" className="text-sucess" overlay={<Tooltip>locale presente da programmazione nel giorno selezionato</Tooltip>}>
                                            <><FontAwesomeIcon icon="check" /> {service_dict[s].name}</>
                                        </OverlayTrigger>
                                        : service_dict[s].name}<br />
                                    <Frequency roomtype_id={rt.id} service_id={s} roomtype_dict={roomtype_dict}
                                        curDay={getDay(curEvent.start).toString()}
                                        customFrequency={intervention_data.secondary_custom_weekdays && intervention_data.secondary_custom_weekdays[room.id] && intervention_data.secondary_custom_weekdays[room.id][s]
                                            ? intervention_data.secondary_custom_weekdays[room.id][s] : []} />
                                </Col>
                            </Row>
                        ))
                        : <></>}

                </Col>
                : <>
                </>}
            <Col sm="1">{getRoomSize(_room.size)}</Col>
            <Col sm="2" className="text-center">
                <RiskareaBadge riskarea={risk_area_dict[_room.risk_area.Id]} />
            </Col>
            <Col sm="1">
                {getValue(_room, 'cdc')}
            </Col>
        </Row>
    )
    }else{
        return <Row>
            Locale eliminato {room.name}
        </Row>

    }

}


const getRoomSize = (size) => {
    try{
        return parseFloat(size).toFixed(2)
    }catch(err){
        return 'size'
    }

}

export default ModalInterventionEvent