import { useContext } from 'react';

import GlobalDataContext from '../components/contexts/globaldataContext.js';
import DomainContext from '../components/contexts/domainContext'

import { compareOrder, compareName } from "./ui_utils"

import { getRoomTypeList, getRoomTypeList_page } from '../Firebase2/Storage2/dbBaseStruct';
import { getBaseObjectTypeList } from "../Firebase2/Storage2/dbBaseObject"
import { getServiceDict, getOperationDict } from '../Firebase2/Storage2/dbService';
import { getContractById } from '../Firebase2/Storage2/dbContract';
import {
    getStructureList,
    getPavillonList,
    getAllDepartmentInStructure,
    getAllFloorInStructure
} from "../Firebase2/Storage2/dbBaseCompany"
import { getMessageGroupList } from '../Firebase2/Storage2/dbMessageGroup';


import {
	//getAllRoomList as getAllRoomList2
	getAllLightRoomList as getAllRoomList2
} from '../Firebase2/Storage2/dbRoom';
import { getWorkareaList } from '../Firebase2/Storage2/dbWorkarea.js';



////////////////////////////////////////////////////////////////////////////////////////
export const InitContractHelper = () => {
    const { updateContract, setStructureElements } = useContext(GlobalDataContext)
    const { domain } = useContext(DomainContext)

    const initContract = async (contract_id) => {
        console.log("-----########################", contract_id)
        const contract = await getContractById(domain, contract_id)
        console.log("GET CONTRACY")
        const contractId = contract.id
        let risk_area_list = await getBaseObjectTypeList(domain, 'risk_areas')
        console.log("get baseobj")
        risk_area_list = risk_area_list.sort(compareOrder)
        const risk_area_dict = Object.assign({}, ...risk_area_list.map((x) => ({ [x.Id]: x })));
       // let roomtype_list = await getRoomTypeList(domain, contract_id)
        let rt_list = []
        let do_query = true
        let cursor = undefined
        while (do_query){
            const r_data = await getRoomTypeList_page(domain, contract_id, cursor, 50)
            if (r_data.query_cursor!== null){  
                rt_list = [...rt_list, ...r_data.roomTypeList]
                cursor = r_data.query_cursor
            }else{
                do_query = false
            }
        }
        let  roomtype_list = rt_list
        roomtype_list = roomtype_list.sort(compareName)
        const roomtype_dict = Object.assign({}, ...roomtype_list.map((x) => ({ [x.id]: x })));
        const service_dict = await getServiceDict(domain, contract_id)
        const operation_dict = await getOperationDict(domain, contract_id) || {}
		let receiver_group_list = await getMessageGroupList(domain, contractId)
		receiver_group_list = receiver_group_list.sort(compareName)
        const structure_list = await getStructureList(domain, contract_id) || []
        console.log("GET STRUCTURE_LIST", contract_id, domain, structure_list)
        const workarea_list = await getWorkareaList(domain, contractId)
        let pavillon_list = []
        let floor_list = []
        let department_list = []
        let all_floor_dict = {}
        let all_pavillon_dict = {}
        let all_department_dict = {}
        let all_structure_dict = {}
        let all_room_dict = {}
        let room_list = []
        for (const structure of structure_list) {
            const return_value_pavillon = await getPavillonList(domain, contractId, structure.id)
            if (return_value_pavillon.error) {

            } else {
                pavillon_list = [...pavillon_list, ...return_value_pavillon]
            }
            const return_value_floor = await getAllFloorInStructure(structure.id)
            if (return_value_floor.error) {

            } else {
                floor_list = [...floor_list, ...return_value_floor]
            }
            const return_value_department = await getAllDepartmentInStructure(structure.id)
            if (return_value_department.error) {

            } else {
                department_list = [...department_list, ...return_value_department]
            }
			const return_room_list = await getAllRoomList2({ structureId: structure.id })
            room_list = [...room_list, ...return_room_list]
        }
        all_pavillon_dict = Object.assign({}, ...pavillon_list.map((x) => ({ [x.id]: x })));
        all_floor_dict = Object.assign({}, ...floor_list.map((x) => ({ [x.id]: x })));
        all_department_dict = Object.assign({}, ...department_list.map((x) => ({ [x.id]: x })));
        all_structure_dict = Object.assign({}, ...structure_list.map((x) => ({ [x.id]: x })));
        all_room_dict = Object.assign({}, ...room_list.map((x) => ({ [x.id]: x })));
        const all_workarea_dict = Object.assign({}, ...workarea_list.map((x) => ({ [x.id]: x })));
        setStructureElements({ all_structure_dict, structure_list, pavillon_list, all_pavillon_dict, floor_list, all_floor_dict, department_list, all_department_dict, all_room_dict, room_list })
        sessionStorage.setItem('contractId', contract_id)
        sessionStorage.setItem('risk_area_list', JSON.stringify(risk_area_list))
        sessionStorage.setItem('risk_area_dict', JSON.stringify(risk_area_dict))
        console.log("INIT CONTRACT", contract, risk_area_dict, roomtype_dict, service_dict)
        updateContract(contract, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict, service_dict, operation_dict, receiver_group_list, workarea_list, all_workarea_dict)
        return contract
    }

    return { initContract }

}


export const ReloadStructureElements = async (domain, contractId) => {

    const structure_list = await getStructureList(domain, contractId) || []
    let pavillon_list = []
    let floor_list = []
    let department_list = []
    let all_floor_dict = {}
    let all_pavillon_dict = {}
    let all_department_dict = {}
    let all_structure_dict = {}
    for (const structure of structure_list) {
        const return_value_pavillon = await getPavillonList(domain, contractId, structure.id)
        if (return_value_pavillon.error) {

        } else {
            pavillon_list = [...pavillon_list, ...return_value_pavillon]
        }
        const return_value_floor = await getAllFloorInStructure(structure.id)
        if (return_value_floor.error) {

        } else {
            floor_list = [...floor_list, ...return_value_floor]
        }
        const return_value_department = await getAllDepartmentInStructure(structure.id)
        if (return_value_department.error) {

        } else {
            department_list = [...department_list, ...return_value_department]
        }
    }
    all_pavillon_dict = Object.assign({}, ...pavillon_list.map((x) => ({ [x.id]: x })));
    all_floor_dict = Object.assign({}, ...floor_list.map((x) => ({ [x.id]: x })));
    all_department_dict = Object.assign({}, ...department_list.map((x) => ({ [x.id]: x })));
    all_structure_dict = Object.assign({}, ...structure_list.map((x) => ({ [x.id]: x })));
//    setStructureElements({ all_structure_dict, structure_list, pavillon_list, all_pavillon_dict, floor_list, all_floor_dict, department_list, all_department_dict })
    return { all_structure_dict, structure_list, pavillon_list, all_pavillon_dict, floor_list, all_floor_dict, department_list, all_department_dict }
}