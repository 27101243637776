import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage"
import "firebase/compat/functions"

const firebaseConfig = {
    apiKey: "AIzaSyDGmfbYMMVkvMOQyOZQuUN6Ofb4ZuO0FRc",
    authDomain: "claudittest.firebaseapp.com",
    projectId: "claudittest",
    storageBucket: "claudittest.appspot.com",
    messagingSenderId: "872463416330",
    appId: "1:872463416330:web:beae0629ee53823d7133f3",
    measurementId: "G-JV7TKWGDVZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

export { firebase }
export const auth = firebase.auth();
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier
export const PhoneAuthProvider = firebase.auth.PhoneAuthProvider
export const getAuth = firebase.auth.getAuth

export const firestore = firebase.firestore();
export const storageRef = firebase.storage().ref();
export const functions = firebase.app().functions('europe-west1');
//DEBUG on LOCAL EMULATOR: functions.useFunctionsEmulator('http://localhost:5000')
export default { firebaseConfig, }