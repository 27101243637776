import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { it } from 'date-fns/locale'
import DateTime from 'react-datetime';

import { roomStatus_dict } from "../components/constants/global_definitions"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import EditContext, { EditProvider } from "../components/contexts/edit.js"
import { InitContractHelper } from "../util/contract"

import { propComparator, getStructureFromWorkareas, myTimestampToDate, myDateToTimestamp } from '../util/ui_utils.js';

import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import Footer from "../components/Footer"
import ListStructureGroupItem from "../components/ListStructureGroupItem.js"

import { getAllRoomList } from '../Firebase2/Storage2/dbRoom.js';
import firebase from 'firebase/compat/app'
import { format } from 'date-fns';



let load_page = false


////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const VariationRegisterView = ({ t, uData }) => {
	const { domain_data } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, all_workarea_dict, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)

	let { cid } = useParams();
	let contractId = cid
	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [structure_list, setStructureList] = useState([])
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [permission_department_list, setPermissionDepartment] = useState([])

	const initValues = async () => {
		const userData = uData
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('variationregister') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		load_page = true
		let _structure_list = structureElements.structure_list
		let edit_permission_department_list = [...permission_department_list]
		let permission_structure_list = []
		if (userData && userData.contract_dict && userData.contract_dict[contractId]) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId][0].mode === 'workarea') {
				const permission_workarea_list = userData.contract_dict[contractId].map(cl => cl.workarea.id)
				permission_structure_list = getStructureFromWorkareas(permission_workarea_list, all_workarea_dict)
				console.log("permission_structure_list", permission_structure_list)
				_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
			} else {
				if (userData.contract_dict[contractId][0].structure) {
					if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
						edit_permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
						console.log("permission department_list", edit_permission_department_list, userData.contract_dict[contractId][0].department)
					}
					if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
						permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
						_structure_list = _structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

					}
					console.log("permission structure_list", permission_structure_list)
				}
			}
		}
		setPermissionDepartment(edit_permission_department_list)

		setStructureList(_structure_list)
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, roomtype_list, roomtype_dict })
		setLoading(false)

	}


	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("VARIATION REGISTRY reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("VARIATION REGISTRY init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])



	useEffect(() => {
		console.log('VARIATION REGISTRY change contract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.variation_register')} />
					</Col>
				</Row>
				{structure_list && structure_list.length > 0 ?
					<Row className="mb-1">
						<Col className="bg-secondary ms-1 text-light">
							{t('global.labels.name')}
						</Col>
						<Col className="bg-secondary ms-1 text-light text-center" sm="3">
							{t('global.labels.actions')}
						</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					</Row>
					:
					<Row className="mt-1">
						{structure_list && structure_list.length > 0 ?
							<Col>
								{structure_list.map((s, key) => (
									<Row className="mb-1 pb-1 border-bottom" key={key}>
										<Col className="font-weight-bold">
											{s.name}
										</Col>
										<Col className="text-center" sm="3">
											<ButtonGroup>
												<EditProvider>
													<ModalStructureComposition structure={s} canWrite={canWrite} ui_list={ui_list} t={t} item_list={element_list} setItem={setElementList} permission_department_list={permission_department_list} />
												</EditProvider>
											</ButtonGroup>
										</Col>
									</Row>
								))}
							</Col>
							:
							<Col className="font-italic">
								{t('structure.labels.no_structure')}
							</Col>
						}
					</Row>

				}

			</Container>
			<Footer />
		</>
	);
}

export default translate()(VariationRegisterView)






const planningHasParam = (room, param_list) => {
	console.log(room, param_list)
	for (const p of room.planning_event_list) {
		console.log(room.planning_event_list, p)
		if (param_list.indexOf(p.param) >= 0) {
			return true
		}
	}
	return false

}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalFilterDate = ({ t, filterAppliedListDate, setFilterAppliedListDate }) => {

	const [filterDate, setFilterDate] = useState({})
	const [showFilterDate, setShowFilterDate] = useState(false)

	const handleCloseFilterDate = () => {
		setShowFilterDate(false)
	}

	const handleShowFilterDate = () => {
		setFilterDate(filterAppliedListDate)
		setShowFilterDate(true)
	}


	const handleClearFilterDate = () => {
		setFilterDate({})
		setFilterAppliedListDate({})
		setShowFilterDate(false)
	}
	const updateAppliedFilter = (p, date) => {
		setFilterDate(pd => ({ ...pd, [p]: date }))
	}
	const handleConfirmFilterDate = () => {
		setShowFilterDate(false)
		setFilterAppliedListDate(filterDate)

	}





	return (
		<>
			<Button size="sm" variant="outline-info" onClick={() => handleShowFilterDate()}>
				filtra periodo
				{filterDate.startDate !== undefined && filterAppliedListDate.endDate !== undefined && <>({format(myTimestampToDate(filterDate.startDate), 'dd/MM/yy')}-{format(myTimestampToDate(filterDate.endDate), 'dd/MM/yy')})</>}
			</Button>
			<Modal show={showFilterDate} onHide={handleCloseFilterDate} backdrop="static" size="lg" className="border-info">
				<Modal.Header className='bg-info text-light' >
					<Modal.Title>
						Filtra periodo
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="border-info">
					<Container fluid style={{ minHeight: '300px' }}>
						<Row>
							<Col>
								inizio
							</Col>
							<Col>
								<DateTime initialValue={filterAppliedListDate.startDate? myTimestampToDate(filterAppliedListDate.startDate):undefined} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => updateAppliedFilter('startDate', myDateToTimestamp(e))} />
							</Col>
						</Row>
						<Row>
							<Col>
								fine
							</Col>
							<Col>
								<DateTime initialValue={filterAppliedListDate.endDate? myTimestampToDate(filterAppliedListDate.endDate):undefined} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => updateAppliedFilter('endDate', myDateToTimestamp(e))} />
							</Col>
						</Row>
						<Row>
							<Col className="text-danger">
								{isInvalidDateRange(filterDate.startDate, filterDate.endDate) && <>intervallo di date non corretto</>}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>

					<Button variant="warning" onClick={handleClearFilterDate}>
						Rimuovi filtro
					</Button>
					<Button variant="secondary" onClick={handleCloseFilterDate}>
						Chiudi
					</Button>
					<Button variant="primary" disabled={isInvalidDateRange(filterDate.startDate, filterDate.endDate)} onClick={handleConfirmFilterDate}>
						Conferma
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const isInvalidDateRange = (s, e) => {
	if (s === undefined && e === undefined) {

		return false
	}
	if (e > s) {
		return false
	}
	return true
}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalStructureComposition = ({ structure, ui_list, t, canWrite, permission_department_list }) => {
	const { structureElements, contract, setStructureElements, risk_area_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const edit = useContext(EditContext)

	const [showStructureCompositionEdit, setShowStructureCompositionEdit] = useState(false)
	const [curStructure, setCurStructure] = useState(structure)
	const [editedElement, setEditedElement] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [loadingOne, setLoadingOne] = useState(false)
	const [planned_events, setPlannedEvents] = useState([])
	const [room_list, setRoomList] = useState([])
	const [mystructureElements, setPageStructureElements] = useState({
		pavillon_list: []
	})
	const [filterAppliedList, setFilterAppliedList] = useState({ param: [] })
	const [filterAppliedListDate, setFilterAppliedListDate] = useState({ param: [], startDate: undefined, endDate: undefined })

	const status_dict = roomStatus_dict({ t })


	const updateAppliedFilter = (section, elem) => {
		console.log(section, elem)
		if (section === 'param') {

			const c = filterAppliedList[section].indexOf(elem)
			let f_data = [...filterAppliedList[section]]
			if (c === -1) {
				f_data.push(elem)
			} else {
				f_data.splice(c, 1)
			}
			edit.setEditParamList({ ...filterAppliedList, [section]: f_data })
			setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
			let filtered_list = room_list.filter(i => (f_data.length === 0 || planningHasParam(i, f_data)))
			console.log("FILTERED", filterAppliedList, filtered_list)
			setPageStructureElements({ ...mystructureElements, room_list: filtered_list })
		} else {

		}
	}



	const param_dict = {
		status: { label: t('global.labels.status'), type: 'list', param_dict: status_dict },
		risk_area: { label: contract.type === 'civil' ? t('structure.labels.standard_cleaning') : t('structure.labels.risk_area'), type: 'baselist', param_dict: risk_area_dict },
		//		glass_percentage: { label: t('structure.labels.glass_percentage'), type: 'value' },
		size: { label: t('structure.labels.size'), type: 'value' },
		size: { label: t('structure.labels.cdc'), type: 'value' }
	}



	useEffect(() => {
		if (showStructureCompositionEdit) {

			handleShowEditStructureComposition(filterAppliedListDate)
		}

	}, [filterAppliedListDate])

	const handleCloseStructureComposition = () => setShowStructureCompositionEdit(false);

	const handleShowEditStructureComposition = async (filterDate) => {
		setLoadingOne(true)
		setShowStructureCompositionEdit(true)
		console.log('edit structure composition', structure, structureElements)
		setCurStructure(structure)
		const pavillon_list = structureElements.pavillon_list
		const floor_list = structureElements.floor_list
		const department_list = structureElements.department_list
		console.log(department_list, pavillon_list, floor_list)
		let room_list = []
		if (permission_department_list && permission_department_list.length > 0) {
			for (const dep_id of permission_department_list) {
				//				const tmp_room_list = await getAllRoomList({ departmentId: dep_id })
				const tmp_room_list = structureElements.room_list.filter(r => r.parent_list[3] === dep_id)
				room_list = [...room_list, ...tmp_room_list]
			}

		} else {
			//room_list = await getAllRoomList({ structureId: structure.id })
			room_list = structureElements.room_list.filter(r => r.parent_list[0] === structure.id)
		}
		const planned_list = room_list.filter(r => filterValidRoom(r, filterDate))
		room_list = planned_list
		console.log(planned_list)
		setRoomList(room_list)
		let event_list = []
		for (const room of planned_list) {
			const p_list = room.planning_event_list.map(p => ({ ...p, room: room }))
			//console.log(p_list)
			event_list = [...event_list, ...p_list]

		}
		event_list.sort(propComparator('startDate'))
		setPlannedEvents(event_list)
		setPageStructureElements({ pavillon_list, floor_list, department_list, room_list, event_list })
		setEditedElement(false)
		setLoadingOne(false)
	}








	return (
		<>
			<AlertSection ref={childRefAlert} />
			<Button size="sm" variant="outline-info" onClick={() => handleShowEditStructureComposition(filterAppliedListDate)}>
				{loadingOne ? <Spinner animation="border" role="status" size="sm">
					<span className="sr-only">Loading...</span>
				</Spinner> :

					<FontAwesomeIcon icon="hospital-alt" />
				}
			</Button>
			<Modal show={showStructureCompositionEdit} onHide={handleCloseStructureComposition} fullscreen bsclass="full-modal" backdrop="static" scrollable={true}>
				{loadingOne ?
					<Modal.Body>
						<Container fluid>
							<Row>
								<Col className="text-center">
									<Spinner animation="border" role="status">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</Col>
							</Row>
							<Row>
								<Col className="text-center">
									Caricamento in corso <b>{curStructure.name}</b>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
					:
					<>
						<Modal.Header className={editedElement ? "bg-warning" : ""}>
							<Modal.Title>
								<FontAwesomeIcon icon="eye" /><span className="text-info">{t('structure.labels.view_planned_change')}</span> | <FontAwesomeIcon icon="hospital-alt" /> <b>{curStructure.name}</b> {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
								<ButtonGroup size="sm">
									{filterAppliedList.param.length > 0 ?
										<Button variant="outline-secondary" size="sm" onClick={() => setFilterAppliedList({ ...filterAppliedList, 'param': [] })}><FontAwesomeIcon icon="filter" /><FontAwesomeIcon icon="times" /></Button>
										:
										<Button disabled variant="secondary"><FontAwesomeIcon icon="filter" /></Button>
									}
									{Object.keys(param_dict).map((pk, k) => (
										<Button key={k} size="sm" disabled={planned_events.filter(f => (f.param === pk)).length === 0}
											variant={filterAppliedList['param'].indexOf(pk) === -1 ? 'outline-secondary' : "secondary"}
											onClick={(e) => updateAppliedFilter('param', pk)}>
											{param_dict[pk].label}
										</Button>
									))}
								</ButtonGroup>
								<ModalFilterDate t={t} setFilterAppliedListDate={setFilterAppliedListDate} filterAppliedListDate={filterAppliedListDate} />
							</Modal.Title>
						</Modal.Header>
						<Modal.Header className="bg-light">
							<Container fluid>
								<Row className="p-1 text-light">
									<Col className="bg-secondary ms-1"> {t('global.labels.name')}</Col>
									<Col className="bg-secondary ms-1 text-center" sm="1"> {t('structure.labels.room_count')}</Col>
									<Col className="bg-secondary ms-1 text-center" sm="1"> {t('structure.labels.cdc')}</Col>
								</Row>
							</Container>
						</Modal.Header>
						<Modal.Body>
							<Container fluid >
								{mystructureElements.room_list && mystructureElements.room_list.length>0 ?
								<ElementFormStructureComposition element={curStructure} t={t} functions={{ edit: setCurStructure, setedit: setEditedElement, setsaving: setIsSaving }} ui_list={ui_list} structureElements={mystructureElements} canWrite={canWrite} permission_department_list={permission_department_list} />
								:<Row>
									<Col className="font-italic">
										Nessuna variazione presente
									</Col>
									</Row>}
							</Container>
						</Modal.Body>
						<Modal.Footer>

							<Button variant="secondary" disabled={isSaving} onClick={handleCloseStructureComposition}>
								{t('modal.close')}
							</Button>

						</Modal.Footer>
					</>
				}
			</Modal>
		</>
	)
}





const filterValidRoom = (r, dateFilter) => {
	if (r.planning_event_list && r.planning_event_list.length > 0) {
		console.log("%c\t\tPLAN", 'background: #aaa; color: red', r.planning_event_list, dateFilter)
		if (dateFilter.startDate === undefined && dateFilter.endDate === undefined) {
			return true
		} else {
			if (r.planning_event_list.filter(p => p.startDate >= dateFilter.startDate && p.startDate <= dateFilter.endDate).length>0) {
				return true
			}
		}
	}
	return false
}




////////////////////////////////////////////////////////////////////////////filtered_room_list.length//////////////////////////////////
function ElementFormStructureComposition({ element, functions, t, ui_list, structureElements, canWrite, permission_department_list }) {
	const { contract } = useContext(GlobalDataContext)
	const contractId = contract.id

	const updateStructure = (structure) => {
		console.log("REFRESH STRUCTURE", structure)
		functions.edit(structure)
	}

	return (
		<>
			<ListStructureGroupItem
				item={element}
				is_summary={false}
				parent={element}
				setParent={updateStructure}
				level={0}
				isVariation={true}
				functions={{ setedit: functions.setedit, setsaving: functions.setsaving }}
				structureId={element.id}
				contractId={contractId}
				ui_list={ui_list}
				structureElements={structureElements}
				canWrite={canWrite}
				permission_department_list={permission_department_list}
			/>
		</>
	)
}
