import React, { useState, useEffect, useRef, useContext, createRef, useLayoutEffect } from 'react';
import { Modal, Container, Row, Col, Form, Button, Spinner, Badge, ButtonGroup, ProgressBar, Card, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import DateTime from 'react-datetime';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { addMinutes, format, getDay } from 'date-fns'
import { it } from 'date-fns/locale'

import styles from '../components/constants/styles'
import { serviceType_list } from "../components/constants/global_definitions"
import { messagePriority_dict, ticketType_dict } from "../components/constants/global_definitions"


import { firebaseAuth } from '../provider/AuthProvider'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import LocaleContext from '../components/contexts/locale.js'
import { InitContractHelper } from "../util/contract"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"

import { myTimestampToDate, getValue, getSelectedElementB, getSelectedElement, getElementCount, getTicketReceiver, getStructureFromWorkareas } from "../util/ui_utils.js"
import { ItemProgress, renderFrequency, ServiceBadge, RiskareaBadge, RoomInfoBadge, Frequency, MediaPreview, TicketPath } from '../components/StructureGraphicElements.js'

import firebase from 'firebase/compat/app'

import { getEventRange as getEventRange2, addEvent as addEvent2, editEvent as editEvent2 } from '../Firebase2/Storage2/dbAgenda';
import { getTicketById as getTicketById2 } from '../Firebase2/Storage2/dbTicket';


/*   const getMyDate = (d) => {
	if (d) {
	  return d
	} else {
	  return ''
	}
  } */



let curdate = { start: new Date(), end: new Date() }
let department_dict = {}
let used_service_list = []
let intervention_dict = {}

let filterAppliedList = { department: [], type: [] }
const defaultFilter = { department: [], type: [] }
let permission_department_list = []
let permission_structure_list = []

let load_page = false



const newEvent = {
	start: new Date(),
	end: new Date(),
	extendedProps: {
		subject: '',
		location: '',
		shared: -1,
		reminder: false,
		reminder_time: 15,
		note: '',
		periodic_data: {
			date_list: [],
			start: '6:00',
			end: '8:00'
		}
	}
};

const RenderEventContentRequest = ({ eventInfo }) => {
	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict, } = useContext(GlobalDataContext)
	let { cid } = useParams();
	let contractId = cid

	console.log("RenderEventContentRequest - eventInfo:", eventInfo)
	const [msg, setMsg] = useState("")

	useEffect(() => {
		async function fetchdata() {
			try {
				const response = await getTicketById2(domain, contractId, eventInfo.event.extendedProps.message_id)
				setMsg(response.message)
			} catch (e) {
				console.log("RenderEventContentRequest error:", e)
				setMsg("...")
			}
		}
		fetchdata()
	}, []
	)



	try {
		console.log("---msg", msg)
		return (
			<>
				{ eventInfo.event.extendedProps.type === 'request_ticket' ?
					<span className="text-warning"><FontAwesomeIcon icon="bell" /></span> :
					eventInfo.event.extendedProps.type === 'reporting_ticket' ?
						<span className="text-danger"><FontAwesomeIcon icon="exclamation-triangle" /> </span> :
						""
				}
				<b>{eventInfo.timeText}</b>
				<br />
				{msg}


				<br />
			</>
		)
	} catch (e) {
		console.warning("RenderEventContentRequest error2:", e)
		return (
			<>
				...
			</>
		)
	}
}



////////////////////////////////////////////////////////////////////////////////////////////////
const FilterButton = ({ d, filterAppliedList, department_dict, updateAppliedFilter, structureElements, show_inactive }) => {
	try {
		if (show_inactive) {

			return (

				<Button className="m-1" size="sm" variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
					{getValue(getSelectedElement(department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}:
					{department_dict[d].name}
				</Button>
			)
		} else {
			return (

				<Button size="sm" className={filterAppliedList.department.indexOf(department_dict[d].id) !== -1 ? '' : 'd-none'}
					variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
					onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
					{department_dict[d].name} <FontAwesomeIcon icon="times" />
				</Button>
			)

		}
	} catch (err) {
		return (

			<Button className="m-1" size="sm" variant="outline-secondary" disabled >
				{d}
			</Button>
		)

	}

}





////////////////////////////////////////////////////////////////////////////////////////////////






const AgendaView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict, } = useContext(GlobalDataContext)
	const { userData } = useContext(firebaseAuth)

	const { initContract } = InitContractHelper()
	const calendarRef = createRef()

	let { cid } = useParams();
	let contractId = cid
	const ticket_type_dict = ticketType_dict({ t })
	const ticket_type_list = Object.values(ticket_type_dict)
	const event_type_dict = Object.assign({}, ...ticket_type_list.map((x) => ({ [x.event]: x })));
	const ticket_type_list_id = ticket_type_list.filter(r => r.is_ticket).map(r => r.id)
	const event_type_list = Object.values(ticket_type_dict).filter(r => r.is_ticket).map(r => r.event)

	const service_type = serviceType_list({ t })
	const [title, setTitle] = useState('')
	const [showEdit, setShow] = useState(false);
	const [showView, setShowView] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [curEvent, setCurEvent] = useState(newEvent);
	const [curElement, setCurrentElement] = useState()
	const [editedElement, setEditedElement] = useState(false)
	const [currentEvents, setCurrentEvents] = useState([]);
	const [loading, setLoading] = useState(true)
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [showAnswerTicket, setShowAnswerTicket] = useState(false)
	const [uploadingState, setUploadingState] = useState(-1)
	const [globalAgendaData, setGlobalAgendaData] = useState({cref: null, cApi: null})
	const [curAnswer, setAnswer] = useState({})




	const onChangeHandler = (event) => {
		const { name, value } = event;
		if (name !== 'start' && name !== 'end') {
			console.log(name, value)
			let newprops = { ...curEvent.extendedProps, [name]: value }
			setCurEvent({ ...curEvent, extendedProps: newprops })
		} else {
			setCurEvent({ ...curEvent, [name]: value })
			console.log("newEventDate", curEvent)

		}
	}


	const handleClose = () => {
		setShow(false)
	}


	const handleCloseView = () => {
		setShowView(false)
	}

	const onChangeHandlerAnswer = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curAnswer[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'

		}
		console.log("messagechange", name, value)
		setAnswer({ ...curAnswer, [name]: value })
	}


	const handleSave = async () => {

		const {cref, cApi} = globalAgendaData
		console.log(globalAgendaData)
		console.log(curEvent, cref, cApi)


		try {
			curEvent.start = curEvent.start.toDate()
		} catch (err) {
			console.log(err)
		}

		try {
			curEvent.end = curEvent.end.toDate()
		} catch (err) {
			console.log(err)
		}

		if (curEvent.id) {

			console.log("EDIT")
			const return_data = await editEvent2(domain, contractId, curEvent.id, curEvent)
			if (return_data.error) {
				console.log(return_data)
			} else {
				handleEventAdd(curEvent)
			}
			console.log(return_data)
			setShow(false)

		} else {
			console.log("ADD")

			curEvent.extendedProps.type = 'manual_event'
			const return_data = await addEvent2(domain, contractId, curEvent)
			if (return_data.error) {
				console.log(return_data)
			} else {
				handleEventAdd(curEvent)
			}
			console.log(return_data)
			try {

				let calendarApi = calendarRef.current.getApi()
				calendarApi.refetchEvents()
			} catch (err) {
				console.log(err, calendarRef)
			}
			setShow(false)
		}


		try {
			let calendarApi = cref.current.getApi()
			calendarApi.refetchEvents()
		} catch (err) {
			console.log(err, cref)
			try {
				cApi.refetchEvents()
			} catch (err) {
				console.log(err, cApi)
			}
		}
	}


	const handleDateSelect = (selectInfo) => {
		console.log("handleDateSelect - selectInfo:", selectInfo)
			setGlobalAgendaData({cref: calendarRef, cApi:selectInfo.view.calendar })
		setCurEvent({ ...newEvent, start: selectInfo.start, end: selectInfo.end })
		setNew(true)
		setShow(true)
	}


	function renderEventContent(eventInfo) {
		console.log("rendere event content", eventInfo)



		if (eventInfo.timeText.length === 2) {
			eventInfo.timeText = eventInfo.timeText + ":00"
		}
		const ev_type = eventInfo.event.extendedProps.type
		if (eventInfo.view.type === 'dayGridMonth') {

			if (event_type_list.indexOf(ev_type) >= 0) {
				return (
					<span style={{borderBottomColor:eventInfo.backgroundColor, borderBottomWidth:'2px', borderBottomStyle:'solid'}}>
						<b>{eventInfo.timeText}</b>&nbsp;
						{eventInfo.event.extendedProps.forwarded_from? <FontAwesomeIcon icon="share" />:''}
						<b>{event_type_dict[ev_type].icon} {event_type_dict[ev_type].label}</b>
						<br />
						<i> {eventInfo.event.extendedProps.subject}</i>
					</span>
				)
			} else {
				return (
					<span style={{borderBottomColor:eventInfo.backgroundColor, borderBottomWidth:'1px', borderBottomStyle:'solid'}}>
						<b>{eventInfo.timeText}</b>&nbsp;
						<i>{eventInfo.event.title}</i>
					</span>
				)

			}
		} else {
			if (event_type_list.indexOf(ev_type) >= 0) {
				if (eventInfo.event.extendedProps.message_list && eventInfo.event.extendedProps.message_list.length > 0) {
					return (
						<>
							{format(eventInfo.event.start, 'HH:mm')}
							{eventInfo.event.extendedProps.forwarded_from? <FontAwesomeIcon icon="share" />:''}
							<b>{event_type_dict[ev_type].icon} {event_type_dict[ev_type].label}</b>
							<i> {eventInfo.event.extendedProps.subject}</i>
							{eventInfo.event.extendedProps.message_list[0].message}
						</>
					)

				} else {
					return (
						<>
							{format(eventInfo.event.start, 'HH:mm')}
							{eventInfo.event.extendedProps.forwarded_from? <FontAwesomeIcon icon="share" />:''}
							<b>{event_type_dict[ev_type].icon} {event_type_dict[ev_type].label}</b>
							<i> {eventInfo.event.extendedProps.subject}</i>
							{eventInfo.event.extendedProps.message}
						</>
					)

				}
			} else {
				return (
					<>
						<b>{eventInfo.timeText}</b>&nbsp;
						<i>{eventInfo.event.extendedProps.subject}</i>
					</>
				)

			}

		}
	}







	const getEventList = async (el) => {
		console.log("start data", el)
		if (el.start >= curdate.start && el.end <= curdate.end) {
			console.log('*************keep data')
		} else {
			curdate = el
		}
		curdate = el
		setLoading(true)

		console.log("...type dict", event_type_dict)
		let event_list = []

		if (contract && domain.length > 0) {

			console.log("start data", el)
			let return_data = await getEventRange2(domain, contractId, firebase.firestore.Timestamp.fromDate(el.start), firebase.firestore.Timestamp.fromDate(el.end))
			console.log("return data", return_data)
			console.log(userData)
			let message_group_id_list = []
			if (userData.messagegroups) {
				message_group_id_list = userData.messagegroups.map(m => m.message_group_id)
			}
			console.log("msssage group id list", localStorage.getItem('userId'), message_group_id_list)

			return_data = return_data.filter(e =>
			((event_type_list.indexOf(e.extendedProps.type) >= 0
				/* 				&& 
								(e.extendedProps.userId === localStorage.getItem('userId') 
									|| message_group_id_list.indexOf(e.extendedProps.receiver_group) >= 0 
									|| message_group_id_list.indexOf(e.extendedProps.sender_group) >= 0
								) */
			)
				|| e.extendedProps.type === 'manual_event'))
			console.log("FILTERED return data", return_data)

			for (const event of return_data) {
				const ev_type = event.extendedProps.type
				if (event_type_list.indexOf(ev_type) >= 0) {
					event.backgroundColor = event_type_dict[ev_type].color
					const response = await getTicketById2(domain, contractId, event.extendedProps.message_id)
					event.title = event_type_dict[ev_type].label
					event.extendedProps.response = response
					event.start = myTimestampToDate(event.start)
					event.end = addMinutes(event.start, 30)
					event.extendedProps.summary = response.message
				} else if (event.extendedProps.type === 'manual_event') {
					event.backgroundColor = event_type_dict[ev_type].color
					event.title = event.extendedProps.subject
					event.start = myTimestampToDate(event.start)
					event.end = myTimestampToDate(event.end)
					event.extendedProps.summary = event.extendedProps.subject
				}
				event_list.push(event)
			}
		}


		console.log("EVENT COUNT", event_list)
		setCurrentEvents(event_list)
		setLoading(false)

		return event_list

	}


	const handleEventClick = (clickInfo) => {
		console.log("-----------_ADDD------")
		console.log("myevent", clickInfo.event, )
		const event_type = clickInfo.event.extendedProps.type
			setGlobalAgendaData({cref: calendarRef, cApi:clickInfo.event._context.calendarApi })
			console.log("CREF,", calendarRef,clickInfo.event._context )
		if (event_type === 'request_ticket' || event_type === 'failure_ticket'
			|| event_type === 'noncompliance_ticket' || event_type === 'communication_ticket' || event_type === 'noncompliance_control_ticket') {
			setCurrentElement(clickInfo.event.extendedProps.response)
			setCurEvent({
				...newEvent,
				id: clickInfo.event.id,
				start: clickInfo.event.start,
				end: clickInfo.event.end,
				extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
			})
			setShowView(true)
		} else {
			setCurrentElement(clickInfo.event.extendedProps.response)
			setCurEvent({ ...newEvent, id: clickInfo.event.id, start: clickInfo.event.start, end: clickInfo.event.end, extendedProps: clickInfo.event.extendedProps })
			console.log(curEvent)
			setNew(false)
			setShow(true)
		}
	}


	const handleEventAdd = (addInfo) => {
		console.log(addInfo)
		setCurrentEvents([currentEvents, addInfo])

	}


	const handleEvents = (events) => {
		setCurrentEvents(events)
	}




	const initValues = async () => {
		console.log('AGENDA uData', uData)
		if ( domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('agenda') !== -1)) {
			setWritePage(true)
		}
		let userData = uData
		if (userData && userData.contract_dict) {
			console.log('userData contract', userData.contract_dict[contractId])
			if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
				if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
					permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
					console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
				}
				if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
					permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
					//structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)
				}
			}
		}

		load_page = true
		setUiList({ ...ui_list, risk_area_list, risk_area_dict, service_dict, roomtype_list, roomtype_dict })
		if (calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			calendarApi.refetchEvents()
		}

	}

	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("AGENDA reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("AGENDA init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()
		}
	}, [])




	useEffect(() => {
		console.log('AGENDA loadingcontract', contract)
		if (contract && loading) {
			initValues()
		}
	}, [contract])


	useEffect(() => {
		if (calendarRef && calendarRef.current) {
			let calendarApi = calendarRef.current.getApi()
			setTitle(calendarApi.view.title)
		}
	}, [calendarRef])












	//######/////####////calenda API external call
	function handleCalendarNext() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.next()
	}

	function handleCalendarPrev() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.prev()
	}

	function handleCalendarHome() {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.today()
	}

	function handleCalendarChangeView(view) {
		let calendarApi = calendarRef.current.getApi()
		calendarApi.changeView(view)
	}

	return (
		<>
			<Container className="titleStickyTop" fluid>
				<Row>
					<Col sm="6">
						<PageTitle title={t('navs.pages.agenda')} counter={<b>{title}</b>} />
						{loading ?
							<>
								<Spinner animation="border" role="status" />
           &nbsp;
          Caricamento in corso
          </>
							: ''}
					</Col>
					<Col className="text-end" >
						<ModalFilter t={t} defaultfilter={defaultFilter} calendarRef={calendarRef} className="me-2" uData={uData} />
						<ButtonGroup className="me-2">
							<Button variant="secondary" size="sm" onClick={handleCalendarPrev} ><FontAwesomeIcon icon="angle-left" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarHome} ><FontAwesomeIcon icon="home" /></Button>
							<Button variant="secondary" size="sm" onClick={handleCalendarNext} ><FontAwesomeIcon icon="angle-right" /></Button>
						</ButtonGroup>
						<ButtonGroup>
							<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('dayGridMonth')}>{t('global.labels.month')}</Button>
							<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('timeGridWeek')}>{t('global.labels.week')}</Button>
							<Button variant="secondary" size="sm" onClick={() => handleCalendarChangeView('timeGridDay')}>{t('global.labels.day')}</Button>

						</ButtonGroup>
					</Col>
				</Row>

			</Container>
			<Container fluid id="domContainer">
				<Row>

					<Col>
						<FullCalendar
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
							headerToolbar={false}
							buttonText={
								{
									today: 'oggi',
									month: 'mese',
									week: 'settimana',
									day: 'giorno',
									list: 'lista'
								}

							}
							allDaySlot={false}
							allDayText={'tutto il giorno'}
							firstDay="1"
							locale='it'
							ref={calendarRef}
							initialView='timeGridWeek'
							eventResize={false}
							editable={false}
							selectable={true}
							height='auto'
							selectMirror={true}
							dayMaxEvents={true}
							weekends={true}
							slotLabelFormat={{ hour: "numeric", minute: "2-digit", omitZeroMinute: false }}
							initialEvents={getEventList} // alternatively, use the `events` setting to fetch from a feed
							select={handleDateSelect}
							eventContent={renderEventContent} // custom render function
							eventClick={handleEventClick}
							eventsSet={handleEvents} // called after events are initialized/added/changed/removed
							eventAdd={handleEventAdd}

						/>
					</Col>
				</Row>



				<Modal show={showEdit} onHide={handleClose} size="xl">
					<Modal.Header closeButton>
						{newEdit && <Modal.Title>{t('agenda.labels.new_event')}</Modal.Title>}
						{!newEdit && <Modal.Title>{t('agenda.labels.edit_event')}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Form >
							<Form.Group as={Row} controlId="eventSubject">
								<Form.Label column sm="2">{t('agenda.labels.subject')}</Form.Label>
								<Col sm="8">
									<Form.Control size="sm" type="text" name="subject" value={curEvent.extendedProps.subject} onChange={(event) => onChangeHandler(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="eventLocation">
								<Form.Label column sm="2">{t('agenda.labels.location')}</Form.Label>
								<Col sm="8">
									<Form.Control size="sm" type="text" name="location" defaultValue={curEvent.extendedProps.location} onChange={(event) => onChangeHandler(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="">
								<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
								<Col sm="8">
									<Form.Control size="sm" type="text" name="note" defaultValue={curEvent.extendedProps.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="eventStart">
								<Form.Label column sm="2">{t('agenda.labels.start')}</Form.Label>
								<Col sm="8">
									<DateTime initialValue={myTimestampToDate(curEvent.start)} dateFormat='DD/MM/YYYY' timeFormat="HH:mm" name="start" onChange={(e) => onChangeHandler({ name: 'start', value: e })} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="eventStart">
								<Form.Label column sm="2">{t('agenda.labels.end')}</Form.Label>
								<Col sm="8">
									<DateTime initialValue={myTimestampToDate(curEvent.end)} dateFormat='DD/MM/YYYY' timeFormat="HH:mm" name="end" locale="it" onChange={(e) => onChangeHandler({ name: 'end', value: e })} />
								</Col>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className="btn-myprimary" form="companyForm" onClick={() => handleSave(calendarRef)}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>




				{curElement ?
					<Modal show={showView} onHide={handleCloseView} size="xl">
						<Modal.Header closeButton>
							<Modal.Title>
								<Modal.Title variant="info">{ticket_type_dict[curElement.type].icon}
								{curElement.forwardFromId ? <FontAwesomeIcon icon="share" />:''}
								{ticket_type_dict[curElement.type].label} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<TicketDetail ticket={curElement} t={t} />
							<Container>
								<Row>
									<Col className="bg-primary text-light p-3">
										{t('ticket.labels.message_list')}
									</Col>
								</Row>
								{curElement.message_list ?
									<>
										{curElement.message_list.map((a, ak) => (
											<Row key={ak} className="border-info mt-2">
												<Col sm="2">
													{format(myTimestampToDate(a.createdAt), 'dd/MM/yyyy HH:mm')}
												</Col>
												<Col sm="3">
													{a.userName}
													{ak===0 && a.userId !== curElement.userId? <FontAwesomeIcon icon="share" />:'' }
												</Col>
												<Col>{a.message}</Col>
												<Col sm="2">
													{a.media ?
														<MediaPreview i={a.media_tracker} />
														: ''}
												</Col>
											</Row>
										))}
									</>
									: ''}
								{showAnswerTicket ?
									<ElementFormAnswerDetails element={curAnswer} t={t} functions={{ edit: onChangeHandlerAnswer }} ui_list={ui_list} uploadingState={uploadingState} userData={userData} />
									: ''}
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleCloseView}>
								{t('modal.close')}
							</Button>
						</Modal.Footer>
					</Modal>
					: ''}


			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list: currentEvents, name: t('navs.registries.ticket') })} />
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilter = ({ t, defaultfilter, event_list, calendarRef, uData }) => {
	const { service_dict, structureElements } = useContext(GlobalDataContext)
	const ticket_type_dict = ticketType_dict({ t })

	const [showFilter, setShowFilter] = useState(false)


	const service_type = serviceType_list({ t })


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			filterAppliedList = { ...filterAppliedList, [section]: [] }
		} else {
			filterAppliedList = (defaultfilter)
		}
		let calendarApi = calendarRef.current.getApi()
		calendarApi.refetchEvents()
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		let calendarApi = calendarRef.current.getApi()
		filterAppliedList = { ...filterAppliedList, [section]: f_data }
		calendarApi.refetchEvents()
	}


	function checkInterventionPresence(d) {
		for (let i in intervention_dict) {
			if (intervention_dict[i].service && intervention_dict[i].service.id === d) {
				return true
			}
		}
		return false
	}

	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				<Button size="sm" className={filterAppliedList.type.indexOf('ordinary_intervention') !== -1 ? '' : 'd-none'}
					variant={filterAppliedList.type.indexOf('ordinary_intervention') === -1 ? "outline-info" : "secondary"}
					onClick={(e) => updateAppliedFilter('type', 'ordinary_intervention')}>
					{t('global.pages.ordinaryintervention')} <FontAwesomeIcon icon="times" />
				</Button>
				<Button size="sm" className={filterAppliedList.type.indexOf('periodic_intervention') !== -1 ? '' : 'd-none'} variant={filterAppliedList.type.indexOf('periodic_intervention') === -1 ? "outline-info" : "secondary"} onClick={(e) => updateAppliedFilter('type', 'periodic_intervention')}>{t('global.pages.programmedintervention')} <FontAwesomeIcon icon="times" /></Button>
				{filterAppliedList.type.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('type')}> <FontAwesomeIcon icon="times" /></Button>
					: ''}
				{Object.keys(department_dict).map((d, k) => (
					<FilterButton d={d} key={k} filterAppliedList={filterAppliedList} department_dict={department_dict} updateAppliedFilter={updateAppliedFilter} structureElements={structureElements} />
				))}
				{filterAppliedList.department.length > 0 ?
					<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>

						<Row>
							{t('global.labels.type')}
						</Row>
						<Row>
							<Col>
								{Object.keys(ticket_type_dict).map((tk, k) => (
									<span key={tk}>
										{uData.isGlobalAdmin || (uData.sendmessage && uData.sendmessage.indexOf(tk) >= 0) ?
											<Button className="m-1" key={k} size="sm" variant={filterAppliedList.type.indexOf(tk) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('type', tk)}>
												{ticket_type_dict[tk].icon}&nbsp;
										{ticket_type_dict[tk].label}
											</Button>
											: ''}
									</span>

								))}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormAnswerDetails({ element, functions, ui_list, t, uploadingState, userData }) {
	const { domain } = useContext(DomainContext)

	const [loading, setLoading] = useState(false)
	const { locale } = useContext(LocaleContext)
	const [department_list, setDepartmentList] = useState([])
	const [room_list, setRoomList] = useState([])


	const initValue = async () => {
		//if (ui_list.structure_list.length ===1){
		//	let tempdepartment_list = await getAllDepartmentName(ui_list.structure_list[0].id)
		//	setDepartmentList(tempdepartment_list)
		//functions.edit({name: 'structure', value:ui_list.structure_list[0] })
		//}
	}




	initValue()


	useLayoutEffect(() => {
		//setLoading(false)
	}, [room_list])

	useLayoutEffect(() => {
		//setLoading(false)
	}, [department_list])




	return (
		<>
			<Card className="mt-3 border-info">
				<Card.Header className="bg-info text-light">
					<Card.Title>
						{t('ticket.labels.answer')}
					</Card.Title>
				</Card.Header>
				<Card.Body className="pb-0">


					<Form.Group as={Row} controlId="contractName" className="border-bottom mb-1">
						<Form.Label column sm="2">{t('ticket.labels.message')}</Form.Label>
						<Col sm="8">
							<Form.Control as="textarea" rows="3" name="message" defaultValue={element.message} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="border-bottom">
						<Form.Label column sm="2">{t('ticket.labels.media')}</Form.Label>
						<Col>
							<Form.Control type="file" name="file" onChange={(event) => functions.edit(event.target)} />
						</Col>
						<Col>
							{element.file
								?
								<>
									<Image style={{ height: '50px' }} src={element.file} fluid />
									<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'logo', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
								</>
								: ''}
						</Col>
					</Form.Group>
					{uploadingState > -1 ?
						<Row>
							<Col>
								<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
							</Col>
						</Row>
						: <></>}
				</Card.Body>
				<Card.Footer>
					<Container fluid>
						<Row>
							<Col><i>{format(myTimestampToDate(element.createdAt), 'dd/MM/yyyy HH:mm')}</i></Col>
							{userData ?
								<Col className="text-end">
									<i>
										inviato da: {userData.isGlobalAdmin ? 'admin' : userData.displayName}
									</i>
								</Col>
								: ''}
						</Row>
					</Container>

				</Card.Footer>


			</Card>
		</>
	)
}








////////////////////////////////////////////////////////////////////////////////////////////////////////
const TicketDetail = ({ t, ticket }) => {
	const { contract, structureElements, messagegroup_list, service_dict } = useContext(GlobalDataContext)
	const priority_dict = messagePriority_dict({ t })
	return (
		<>
			<Card className="mb-3">
				<Card.Header>{ticket.message_list ?
					<> {ticket.message_list[0].userName}</> : ''} 
					{ticket.send_as_group ? <>({getTicketReceiver(ticket, messagegroup_list, 'sender_group')} )</> : ''}<FontAwesomeIcon icon="angle-right" />  {getTicketReceiver(ticket, messagegroup_list, 'receiver_group')} </Card.Header>
				<Card.Body>
					<Card.Text>
						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('ticket.labels.creation_date')}
							</Col>
							<Col>
								{format(myTimestampToDate(ticket.createdAt), 'dd/MM/yyyy HH:mm')}
							</Col>
						</Row>

						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('ticket.labels.structure_reference')}
							</Col>
							<Col>
								<TicketPath ticket={ticket} t={t} />
							</Col>
						</Row>
						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('ticket.labels.priority')}
							</Col>
							<Col>
								{priority_dict[ticket.priority_level]}
							</Col>
							<Col sm="1">
								{/* 								<ProgressBar now={33 * ticket.priority_level} />
 */}							</Col>
						</Row>
						<Row>
							<Col className={styles.subtitle} sm="3">
								{t('global.labels.service')}
							</Col>
							<Col>
								{ticket.service ?
									<ServiceBadge service={service_dict[ticket.service.id]} freq={true} />
									: ''}
							</Col>
						</Row>
					</Card.Text>
				</Card.Body>
			</Card>
		</>

	)
}




export default translate()(AgendaView)
