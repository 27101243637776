import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { Modal, Container, Image, ProgressBar, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TrainingDomainView from '../../pages/TrainingDomainView';
import TrainerDomainView from '../../pages/TrainerDomainView';
import TrainingDomainPlanningView from '../../pages/TrainingDomainPlanningView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalDomainTraining = ({ t, uData }) => {
    const [showEditUsers, setShowUsers] = useState(false);
    const handleCloseUsers = () => setShowUsers(false);
    const [editedElement, setEditedElement] = useState(false)

    const [curSection, setCurSection] = useState('')
    return (
        <>
            <Button variant="outline-info" onClick={() => setShowUsers(true)}>

        <FontAwesomeIcon icon="chalkboard-teacher" /> &nbsp;
                 Formazione</Button>

            <Modal show={showEditUsers} onHide={handleCloseUsers} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        Gestione formazione
                        <ButtonGroup>
                            <Button size="sm" onClick={() => setCurSection('trainer')} variant={curSection === 'trainer' ? 'info' : 'outline-secondary'}>Formatori</Button>
                            <Button size="sm" onClick={() => setCurSection('training')} variant={curSection === 'training' ? 'info' : 'outline-secondary'}>Corsi</Button>
                            <Button size="sm" onClick={() => setCurSection('training_planning')} variant={curSection === 'training_planning' ? 'info' : 'outline-secondary'}>Planning corsi</Button>

                        </ButtonGroup>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {curSection === 'training' &&
                            <TrainingDomainView t={t} uData={uData} />
                        }
                        {curSection === 'trainer' &&
                            <TrainerDomainView t={t} uData={uData} />
                        }
                        {curSection === 'training_planning' &&
                            <TrainingDomainPlanningView t={t} uData={uData} />
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUsers}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}