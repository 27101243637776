import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, ButtonGroup, Badge, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import update from 'immutability-helper'
import { myTimestampToDate, getSelectedElement, checkComponentPresence, compareName, getValue, getMediaIcon, getElementPreview, myDateToTimestamp } from "../util/ui_utils.js"
import styles from './constants/styles.js'
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import DateTime from 'react-datetime';
import { format, setYear, getYear, add } from 'date-fns'


import ElementFormMedia from "../components/FormMedia.js"
import firebase from 'firebase/compat/app'

const contractId = sessionStorage.getItem('contractId')
const companyId = '0'

const element_list = [];

function ModalClient({ item, setItem, t, is_new, small }) {
	const childRefAlert = useRef()
	const newclient = {
		code: '',
		startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(),
		name: '',
		logo: '',
		username: ''
	}
	const initValues = async () => {
		/* 		let newelement_list = await getClientList(contractId, companyId, 'client', null)
		const category_list = await getBaseObjectTypeList('client_category')
		setUiList({ ...ui_list, category_list })
				newelement_list.sort(compareName)
				setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newclient)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		setCurrentElement(newclient)
		setShow(true)
		setEditedElement(false)
	}
	const handleShowEdit = (el) => {
		setCurrentElement(el)
		setShow(true)
		setEditedElement(false)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } =el 
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}
	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		/*
		console.log("__/* __________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addClient(contractId, companyId, 'client', curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const newlist = [...element_list]
				newlist.push(return_data)
				setItem(curElement)

				setShow(false);
			}
		} else {
			let return_data = await editClient(contractId, companyId, 'client', curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker(contractId, editTrackData.id, editTrackData)
				}
				setItem(curElement)

				setShow(false);
			}
		} */
	}

	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('client.buttons.client_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					{is_new && <Modal.Title variant="primary">{t('clients.modal.titlenew')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title variant="info">{t('clients.modal.titleedit')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item variant="secondary">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('clients.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('clients.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} section='client' />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t }) {
	const { locale } = useContext(LocaleContext)
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}
	const updateCategories = (b) => {
		functions.updateData('client_category', b)
	}

	return (
		<>
			<Form.Group as={Row} controlId="clientName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="clientCode" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="eventStart" className="border-bottom">
				<Form.Label column sm="2">{t('contract.labels.length')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'startDate', value: myDateToTimestamp(e) })} />
					<Form.Text className="text-muted">
						{t('contract.labels.start')}
					</Form.Text>
				</Col>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'endDate', value:myDateToTimestamp(e) })} />
					<Form.Text className="text-muted">
						{t('contract.labels.end')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('contract.labels.creation_date')}</Form.Label>
				<Col sm="5">
					<DateTime className="mb-2" initialValue={myTimestampToDate(element.creation_date)} dateFormat='DD/MM/YYYY' timeFormat={false} locale="it" closeOnSelect={true} name="creation_date" onChange={(e) => functions.edit({ name: 'creation_date', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.username')} principale</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="username" value={element.username} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.password')}</Form.Label>
				<Col sm="8">
					<Form.Control type="password" name="password" value={element.password} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
export default translate()(ModalClient)