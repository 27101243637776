import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from '../contexts/locale.js'
import { Container, Row, Col, Button, Modal, Tooltip, Spinner, OverlayTrigger, Form, Nav, Tab, InputGroup, ButtonGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format, setDay, differenceInMinutes, addDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';

import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';
import SearchContext, { SearchProvider } from '../contexts/searchContext';
import InterventionContext from '../contexts/interventionContext.js';


import AlertSection from "../AlertSection.js"

import { getSelectedElement, checkComponentPresence, myTimestampToDate, setMyDate, makeid, getTimeStr, myDateToTimestamp, getTimeValue, compareName, propComparator, getBaseObjectValue } from "../../util/ui_utils.js"
import { ModalInitButton, WeekDays, ServiceBadge, renderActivityPeriodListPopover} from '../StructureGraphicElements.js'

import firebase from 'firebase/compat/app'

import { addWorkjob, editWorkjob } from '../../Firebase2/Storage2/dbDomainWorkjob.js';
import { editFileTracker as editFileTracker2 } from '../../Firebase2/Storage2/dbFileStorage.js';


import FrequencySelector from '../FrequencySelector'
import { getInterventionById } from '../../Firebase2/Storage2/dbIntervention'
import { addDomainTraining, deleteDomainTraining, editDomainTraining, getDomainTrainingList, getTrainingList } from '../../Firebase2/Storage2/dbTraining.js';
import { getClothingList, editClothing, addClothing, deleteClothing } from '../../Firebase2/Storage2/dbClothing.js';
import DeleteconfirmationSection from '../DeleteConfirmationSection.js';
import styles from '../constants/styles.js';
import ModalClothing from '../ModalClothing.js';


const section = 'workjob'
const defaultcolors = [
	'#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
]

const wd = ["1", "2", "3", "4", "5", "6", "0"]
let d_start = new Date()
let d_end = new Date()
d_start.setHours(8, 0, 0)
d_end.setHours(18, 0, 0)
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalWorkjob({ item, setItem, t, is_new, small, item_list, canWrite, element_list }) {
	const { domain } = useContext(DomainContext)


	const childRefAlert = useRef()
	const newworkjob = {
		name: '',
		level: {},
		title: '',
		depend_on: [],
		report_from: [],
		relates_to: [],
		active: true
	}
	const initValues = async () => {
	}



	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newworkjob)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newworkjob.media_list = []
		setCurrentElement(newworkjob)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	const updateElement = (el) => {
		setCurrentElement(el)
		setEditedElement(true)

	}
	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addWorkjob(domain, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editWorkjob(domain, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, undefined, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={"Aggiungi nuova mansione"} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">Nuova mansione</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">Modifica mansione</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">Info mansione</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="modal-tab-navigator" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={2}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabClothing">{t('workcontract.labels.clothing_list')}</Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabTraining">{t('workcontract.labels.training')}</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormQualificationDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} ui_list={ui_list} element_list={element_list}/>
										</Tab.Pane>
										<Tab.Pane eventKey="tabTraining">
											<ElementFormOneQualificationTrainings element={curElement} functions={{ edit: onChangeHandler}} t={t} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabClothing">
											<ElementFormOneQualificationClothings element={curElement} functions={{ edit: onChangeHandler }} t={t} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormQualificationDetails({ element, functions, t, ui_list, element_list }) {
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { service_dict } = useContext(GlobalDataContext)

	const [service_list, setServiceList] = useState([])

	const qualification_level_list = [
		{ Id: '1', name: '1°' },
		{ Id: '2', name: '2°' },
		{ Id: '3', name: '3°' },
		{ Id: '4', name: '4°' },
		{ Id: '5', name: '5°' },
		{ Id: '6', name: '6°' },
		{ Id: '7', name: '7°' },
		{ Id: '8', name: '8°' },
	]

	const initValues = async () => {
		let servicelist = Object.values(service_dict)
		servicelist.sort(compareName)
		setServiceList(servicelist)
	}

	useEffect(() => {
		initValues()
	}, [])
	return (
		<>
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.title')} </Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group> 
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.description')} </Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="description" defaultValue={element.description} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontract.labels.knowledge')} </Form.Label>
				<Col sm="8">
					<Form.Control type="textarea" rows="3" name="knowledge" value={element.knowledge} onChange={(event) => functions.edit(event.target)} className="mb-2" />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.qualification_level')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue={element.level.Id} name="level" onChange={(event) => functions.edit({ name: 'level', type: 'select', value: getSelectedElement(event.target.value, qualification_level_list) })} >
						<option value="-1" >{t('employee.labels.select_qualification_level')}</option>
						{qualification_level_list.map((i, k) => (
							<option value={i.Id} key={k}>{i.name}</option>
						))}
					</Form.Control>
				</Col>
			</Form.Group>
{/* 			<Form.Group as={Row} controlId="formEmployeeLevel" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.service')}</Form.Label>
				<Col>
					<Form.Group as={Row} className="pb-1">
						{service_list.map((a, k) => (
							<Col key={k} sm="3" className={element.service_list && element.service_list.indexOf(a.id) >= 0? "m-1 bg-info text-light":' m-1 bg-light'}>

								<Form.Check key={k} type="checkbox" inline name="service_list" checked={element.service_list && element.service_list.indexOf(a.id) >= 0} value={a.id} label="" alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								<ServiceBadge service={a} freq={true}/>
							</Col>
						))}
					</Form.Group>
				</Col>
			</Form.Group> */}
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontracts.labels.depend_on')}</Form.Label>
				<Col>
					{element_list?.length > 0 ?
						<Form.Group as={Row} className="pb-1">
							{element_list.filter(i => i.id !== element.id).map((a, k) => (
								<Col key={k} sm="3">
									<Form.Check key={k} type="checkbox" name="depend_on" checked={element.depend_on && element.depend_on.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						: <i>{t('workcontracts.messages.no_qualification')}</i>}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontracts.labels.report_from')}</Form.Label>
				<Col>
					{element_list?.length > 0 ?
						<Form.Group as={Row} className="pb-1">
							{element_list.filter(i => i.id !== element.id).map((a, k) => (
								<Col key={k} sm="3">
									<Form.Check key={k} type="checkbox" name="report_from" checked={element.report_from && element.report_from.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						: <i>{t('workcontracts.messages.no_qualification')}</i>}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontracts.labels.relates_to')}</Form.Label>
				<Col>

					{element_list?.length > 0 ?
						<Form.Group as={Row} className="pb-1">
							{element_list.filter(i => i.id !== element.id).map((a, k) => (
								<Col key={k} sm="3">
									<Form.Check key={k} type="checkbox" name="relates_to" checked={element.relates_to && element.relates_to.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						: <i>{t('workcontracts.messages.no_qualification')}</i>}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.qualification_active')}</Form.Label>
				<Col sm="8">
					<Form.Check type="radio" name="active" value={true} checked={(element.active === true)} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="active" value={false} checked={(element.active === false)} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}







function ElementFormOneQualificationClothings({ element, functions, t, ui_list }) {
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	const canWrite = true

	const newcomponent = {
		name: '',
		type: '',
		control_list: [],
		quantity: 1
	}
	const newgood = {
		name: '',
		control_list: [],
		category: '',
	}
	const newclothing = {
		name: '',
		category: '',
	}



	const economic_material_status = [
		'ottimo', 'buono', 'da sostituire'
	]
	const [clothing_list, setClothingList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showGoodEdit, setShowGoodEdit] = useState(false)
	const [curGood, setCurGood] = useState(newgood)
	const [newEdit, setNewEdit] = useState(true)



	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element[section]
		if (editlist) {
			const c = checkComponentPresence(el, element[section], 'id')
			if (c >= 0) {
				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				if (el.name.indexOf('cestino') >= 0) {
					el.is_trash = true
				}
				editlist.push({ ...el, quantity: 1 })
			}
		} else {
			editlist = [{ ...el, quantity: 1 }]
		}
		functions.edit({ name: section, value: editlist })
	}

	const handleSubtractEl = (el, section) => {
		console.log("SUB EL", section, el)
		let editlist = element[section]
		const c = checkComponentPresence(el, element[section], 'id')
		if (editlist[c].quantity > 1) {
			editlist[c].quantity = parseInt(editlist[c].quantity) - 1
		} else {

		}
		functions.edit({ name: section, value: editlist })
	}



	const handleDeleteEl = (el, list) => {
		console.log(el, list)
		const cb = () => {
			let editlist = element[list]
			const c = checkComponentPresence(el, element[list], 'id')
			editlist.splice(c, 1)
			functions.edit({ name: list, value: editlist })
		}

		if (list === 'clothing_list') {
			childRefDelete.current.confirmDelete(good_dict[list].delete_title, good_dict[list].delete, cb)
		}
	}


	//----good

	const handleShowNewGood = (list) => {
		newgood.good_type = list
		setCurGoodSection(good_dict[list])
		setCurGood(newgood)
		setNewEdit(true)
		setShowGoodEdit(true)
	}

	const handleShowEditGood = (i, list) => {
		i.good_type = list
		setCurGoodSection(good_dict[list])
		setCurGood(i)
		setNewEdit(false)
		setShowGoodEdit(true)
	}

	const handleCloseGood = () => setShowGoodEdit(false);

	const onChangeHandlerGood = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurGood({ ...curGood, [name]: value })
	}

	const handleSaveGood = async () => {
		console.log(curGoodSection, newEdit, curGood)
		if (curGoodSection.id === 'clothing_list') {
			if (newEdit) {
				let return_data = await addClothing(domain, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...clothing_list]
					newlist.push(return_data)
					setClothingList(newlist)
					setShowGoodEdit(false);
				}
			} else {
				let return_data = await editClothing(domain, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...clothing_list]
					const c = checkComponentPresence(curGood, clothing_list, 'id')
					newlist.splice(c, 1, curGood)
					setClothingList(newlist)
					setShowGoodEdit(false);
				}
			}



		}

	}

	const handleDeleteGood = (el, category_type) => {
		console.log('delete')
		const cb = async () => {
			let return_data
			if (category_type === 'clothing_list') {
				const editlist = [...clothing_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				return_data = await deleteClothing(domain, el.id)
				setClothingList(editlist)
			}
			console.log(return_data)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(good_dict[category_type].delete_title, good_dict[category_type].delete, cb)
	}


	const initValues = async () => {
		let newclothing_list = await getClothingList(domain)
		setClothingList(newclothing_list.sort(compareName))
	}


	const good_dict = {
		clothing_list: {
			id: 'clothing_list',
			title: t('global.labels.clothing'),
			available: t('workcontract.labels.avaiable_clothing'),
			empty: t('workcontract.messages.no_clothing'),
			add: t('workcontract.labels.clothing_add'),
			new: t('workcontracts.labels.new_clothing'),
			edit: t('workcontracts.labels.edit_clothing'),
			delete_title: t('workcontract.labels.delete_clothing_title'),
			delete: t('workcontract.labels.delete_clothing')

		},
	}

	const [curGoodSection, setCurGoodSection] = useState(good_dict['clothing_list'])




	const OneQualificationClothing = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="border-bottom mb-1 pb-1">
				<Col className="font-weight-bold">{i.name}</Col>
				<Col sm="3">{getBaseObjectValue(i.category, 'name', locale)}	</Col>
				<Col className="p-0 text-center" sm="5" md="4" xl="3" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i, section)} /> </Button>
				</Col>
			</Row>
		)

	}



	const OneGoodElement = ({ i, k, list }) => {
		return (
			<Row key={i.id + k} className={styles.source_row}>
				<Col sm="1" className="p-0 text-center">
					<Col className="p-0 text-center" sm="1">
						{checkComponentPresence(i, list, 'id') !== -1 ?
							<Button size="sm" variant="secondary" disabled><FontAwesomeIcon icon="check" /><FontAwesomeIcon icon="plus-square" /> </Button>
							:
							<Button size="sm" variant="light" onClick={() => handleAddEl(i, list)}><FontAwesomeIcon icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /> </Button>
						}
					</Col>
				</Col>
				<Col sm="3">{getBaseObjectValue(i.category, 'name', locale)}	</Col>
				<Col>{i.name}</Col>
			</Row>
		)
	}


	const OneSection = ({ i, k, list }) => {
		let good_list = clothing_list

		const [searchTerm, setSearchTerm] = useState('')
		const handleChangeSearch = (section, s) => {
			console.log(section, s)
			if (s && s.value.length > 0) {
				setSearchTerm(s.value)
			} else {
				setSearchTerm('')
			}
		}
		return (
			<>
				<Row className="border-bottom h5 mt-1">
					<Col sm="7" className="text-primary">
						{i.title}
					</Col>
					{showComponentEdit ?
						<>
							<Col className="text-info">{i.available}</Col>
							<Col className="text-end">
								<ModalClothing is_new={true} t={t} item={clothing_list} setItem={setClothingList} item_list={clothing_list} canWrite={canWrite} />
							</Col>
						</>
						: ''}
				</Row>
				<Row className="border-bottom border-dark mb-4 pb-2">


					{element[list] && element[list].length > 0 ?
						<Col className="border-end">
							<Row >
								<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
								{list === 'clothing_list' ?
									<>
										<Col className="bg-secondary text-light ms-1" sm="4" md="3" xl="3" >{t('global.labels.actions')} </Col>
									</>
									: ''}
							</Row>
							{element[list].map((i, key) => (

								<OneQualificationClothing i={i} key={key} k={key} section={list} showComponentEdit={showComponentEdit} element_list={element[list]} />

							))}

						</Col>
						: <Col> {i.empty}</Col>
					}
					{showComponentEdit
						?
						<>
							<Col sm="1"></Col>
							<Col className="border-start editSection">
								{good_list && good_list.length > 0 ?
									<>
										<Row >
											<Col className="bg-secondary text-light ms-1" sm="1">&nbsp;</Col>
											<Col className="bg-secondary text-light ms-1" sm="3">{t('structure.labels.component_category')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
										</Row>
										{good_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm[list].toLowerCase()) >= 0))).map((i, key) => (
											<OneGoodElement i={i} key={k} k={k} list={list} />
										))}
									</>
									: <> {i.empty}</>
								}
							</Col>
						</>
						: ''
					}
				</Row>
			</>
		)

	}




	useEffect(() => {
		initValues()
	}, [])

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteconfirmationSection ref={childRefDelete} />
			<Row className="mb-1">
				<Col className="text-end">
					{showComponentEdit ?
						<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
						:
						<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('structure.labels.component_mod')}</Button>
					}
				</Col>
			</Row>

			{Object.keys(good_dict).map((section, k) => (
				<OneSection i={good_dict[section]} list={section} key={k} />
			))}






			<Modal show={showGoodEdit} onHide={handleCloseGood} >
				<Modal.Header closeButton>
					{newEdit && <Modal.Title>{curGoodSection.new}</Modal.Title>}
					{!newEdit && <Modal.Title>{curGoodSection.edit}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.title')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.name} type="text" name="name" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.category} type="text" name="category" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseGood}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveGood}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}







function ElementFormOneQualificationTrainings({ element, functions, t, ui_list }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const newcomponent = {
		name: '',
		type: '',
		control_list: [],
		quantity: 1
	}
	const newgood = {
		name: '',
		control_list: [],
		category: '',
	}
	const newtraining = {
		name: '',
		description: '',
		hour_length: 4,
		expiration: true,
		expiration_months: 24,
	}


	const [training_list, setTrainingList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showTrainingEdit, setShowTrainingEdit] = useState(false)
	const [curTraining, setCurTraining] = useState(newtraining)
	const [newEdit, setNewEdit] = useState(true)



	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element[section]
		if (editlist) {
			const c = checkComponentPresence(el, element[section], 'id')
			if (c >= 0) {
				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				editlist.push({ ...el })
			}
		} else {
			editlist = [{ ...el}]
		}
		functions.edit({ name: section, value: editlist })
	}




	const handleDeleteEl = (el, list) => {
		console.log(el, list)
		const cb = () => {
			let editlist = element[list]
			const c = checkComponentPresence(el, element[list], 'id')
			editlist.splice(c, 1)
			functions.edit({ name: list, value: editlist })
		}

		if (list === 'training_list') {
			childRefDelete.current.confirmDelete(source_element_dict[list].delete_title, source_element_dict[list].delete, cb)
		}
	}

	//----good

	const handleShowNewGood = (list) => {
		newgood.good_type = list
		setCurGoodSection(source_element_dict[list])
		setCurTraining(newgood)
		setNewEdit(true)
		setShowTrainingEdit(true)
	}

	const handleShowEditGood = (i, list) => {
		i.good_type = list
		setCurGoodSection(source_element_dict[list])
		setCurTraining(i)
		setNewEdit(false)
		setShowTrainingEdit(true)
	}

	const handleCloseGood = () => setShowTrainingEdit(false);

	const onChangeHandlerTraining = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurTraining({ ...curTraining, [name]: value })
	}

	const handleSaveElement = async () => {
		console.log(curGoodSection, newEdit, curTraining)
		if (curGoodSection.id === 'training_list') {
			if (newEdit) {
				let return_data = await addDomainTraining(domain, curTraining)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...training_list]
					newlist.push(return_data)
					setTrainingList(newlist)
					setShowTrainingEdit(false);
				}
			} else {
				let return_data = await editDomainTraining(domain, curTraining.id, curTraining)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...training_list]
					const c = checkComponentPresence(curTraining, training_list, 'id')
					newlist.splice(c, 1, curTraining)
					setTrainingList(newlist)
					setShowTrainingEdit(false);
				}
			}



		}

	}

	const handleDeleteTraining = (el, category_type) => {
		console.log('delete')
		const cb = async () => {
			let return_data
			if (category_type === 'training_list') {
				const editlist = [...training_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				return_data = await deleteDomainTraining(domain,  el.id)
				setTrainingList(editlist)
			}
			console.log(return_data)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(source_element_dict[category_type].delete_title, source_element_dict[category_type].delete, cb)
	}


	const initValues = async () => {
		let newtraining_list = await getDomainTrainingList(domain)
		console.log("tr", newtraining_list)
		setTrainingList(newtraining_list.sort(propComparator("name")))
	}


	const source_element_dict = {
		training_list: {
			id: 'training_list',
			title: t('global.labels.training'),
			available: t('workcontract.labels.avaiable_training'),
			empty: t('workcontract.messages.no_training'),
			add: t('workcontract.labels.training_add'),
			new: t('workcontracts.labels.new_training'),
			edit: t('workcontracts.labels.edit_training'),
			delete_title: t('workcontract.labels.delete_training_title'),
			delete: t('workcontract.labels.delete_training')

		},
	}

	const [curGoodSection, setCurGoodSection] = useState(source_element_dict['training_list'])




	const OneQualificationTraining = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="p-1">
				<Col sm="3">{i.code}</Col>
				<Col>{i.name}</Col>
				<Col>{i.description}</Col>
				<Col className="p-0" sm="5" md="4" xl="3" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i, section)} /> </Button>
				</Col>
			</Row>
		)

	}


	const OneTraining = ({ i, k, list }) => {
		return (
			<Row key={k} className="border-bottom p-1">
				<Col sm="1" className="p-0 text-center">
					<Button size="sm" variant="light" onClick={() => handleAddEl(i, list)}><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /> </Button>
				</Col>
				<Col sm="3">{i.code}</Col>
				<Col>{i.name}</Col>
				<Col sm="1" className="p-0 text-center">
					<Button size="sm" variant="outline-info" onClick={() => handleShowEditGood(i, list)}><FontAwesomeIcon icon="pencil-alt" /></Button>
{/* 					<Button size="sm" variant="outline-danger" onClick={() => handleDeleteTraining(i, list)}><FontAwesomeIcon icon="trash" /> </Button> */}
				</Col>
			</Row>
		)
	}


	const OneSection = ({ i, k, list }) => {
		let source_element_list = training_list

		const [searchTerm, setSearchTerm] = useState('')
		const handleChangeSearch = (section, s) => {
			console.log(section, s)
			if (s && s.value.length > 0) {
				setSearchTerm(s.value)
			} else {
				setSearchTerm('')
			}
		}
		return (
			<>
				<Row className="border-bottom h5 mt-1">
					<Col sm="7" className="text-primary">
						{i.title}
					</Col>
					{showComponentEdit ?
						<>
							<Col className="text-info">{i.available}</Col>
							<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewGood(list)}><FontAwesomeIcon icon="plus-square" /> {i.add}</Button></Col>
						</>
						: ''}
				</Row>
				<Row className="border-bottom border-dark mb-4 pb-2">


					{element[list] && element[list].length > 0 ?
						<Col className="border-end">
							<Row >
								<Col className="bg-secondary text-light ms-1" sm="3">{t('global.labels.code')} </Col>
								<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
							</Row>
							{element[list].sort(propComparator('code')).map((i, key) => (

								<OneQualificationTraining i={i} key={key} k={key} section={list} showComponentEdit={showComponentEdit} element_list={element[list]} />

							))}

						</Col>
						: <Col className="font-italic"> {i.empty}</Col>
					}
					{showComponentEdit
						?
						<>
							<Col className="border-start editSection">
								{source_element_list && source_element_list.length > 0 ?
									<>
										<Row >
										
											<Col className="bg-secondary text-light ms-1" sm="3">{t('global.labels.code')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
											<Col className="bg-secondary text-light ms-1" sm="2">{t('global.labels.actions')} </Col>
										</Row>
										{source_element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm[list].toLowerCase()) >= 0))).map((i, key) => (
											<OneTraining i={i} key={key} k={key} list={list} />
										))}
									</>
									: <i> {i.empty}</i>
								}
							</Col>
						</>
						: ''
					}
				</Row>
			</>
		)

	}




	useEffect(() => {
		initValues()
	}, [])

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteconfirmationSection ref={childRefDelete} />
			<Row className="mb-1">
				<Col className="text-end">
					{showComponentEdit ?
						<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
						:
						<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('workcontracts.labels.training_mod')}</Button>
					}
				</Col>
			</Row>

			{Object.keys(source_element_dict).map((section, k) => (
				<OneSection i={source_element_dict[section]} list={section} key={k} />
			))}






			<Modal show={showTrainingEdit} onHide={handleCloseGood} >
				<Modal.Header closeButton>
					{newEdit && <Modal.Title>{curGoodSection.new}</Modal.Title>}
					{!newEdit && <Modal.Title>{curGoodSection.edit}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curTraining.name} type="text" name="name" onChange={(event) => onChangeHandlerTraining(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curTraining.description} type="text" name="description" onChange={(event) => onChangeHandlerTraining(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.hour_length')}</Form.Label>
							<Col sm="4">
								<InputGroup className="mb-2">
									<Form.Control size="sm" value={curTraining.hour_length} type="number" min="1" max="24" name="hour_length" onChange={(event) => onChangeHandlerTraining(event.target)} />
									
										<InputGroup.Text size="sm"> {t('global.labels.hours')} </InputGroup.Text>
									
								</InputGroup>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('workcontracts.labels.expiration')}</Form.Label>
							<Col sm="4">
								<Form.Check type="radio" name="expiration" value={true} checked={curTraining.expiration} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerTraining(event.currentTarget)} />
								<Form.Check type="radio" name="expiration" value={false} checked={!curTraining.expiration} label={t('global.labels.no')} onChange={(event) => onChangeHandlerTraining(event.currentTarget)} />
							</Col>
							{curTraining.expiration ?
								<Col sm="4">
									<InputGroup className="mb-2">
										<Form.Control size="sm" value={curTraining.expiration_months} type="number" min="1" max="72" name="expiration_months" onChange={(event) => onChangeHandlerTraining(event.target)} />
										
											<InputGroup.Text size="sm"> {t('global.labels.months')} </InputGroup.Text>
										
									</InputGroup>
								</Col>
								: ''
							}
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseGood}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveElement}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>


		</>
	)
}




export default translate()(ModalWorkjob)