import React, { useState,  useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup } from 'react-bootstrap';
//import DateTime from 'react-datetime';
import { format  } from 'date-fns'
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
//import { format, setDay } from 'date-fns'
//import { it } from 'date-fns/locale'
//import { SketchPicker } from 'react-color';
//import reactCSS from 'reactcss'
//import "@nateradebaugh/react-datetime/scss/styles.scss";
//import CurrencyInput from 'react-currency-input-field';
import { contractScope_dict, contractSector_dict, contractType_dict } from "../components/constants/global_definitions"

import { myTimestampToDate, checkComponentPresence, compareName, getValue } from "../util/ui_utils.js"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalTemplate from "../components/ModalTemplate"
import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"

import { getTemplateList, deleteTemplate } from '../components/FirebaseApi/template_api';





const TemplatesView = ({ t, uData }) => {


	const childRefAlert = useRef()
	const childRefDelete = useRef()



	const contract_scope_dict = contractScope_dict({t})
	const contract_sector_dict = contractSector_dict({t})
	const contract_type_dict = contractType_dict({t})

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [newelement_list, setNewElementList] = useState([])
	const [oldelement_list, setOldElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)
	const [showArchived, setShowArchived] = useState(false)

	const initValues = async () => {
		console.log(uData)
		setLoading(true)
		let telement_list = await getTemplateList(false)
		telement_list = telement_list.templateList.sort(compareName)
		setElementList(telement_list)
		setNewElementList([...telement_list])
		setLoading(false)
	}


	const handleShowArchived = async (show) => {
		setLoading(true)
		if (show) {
			if (oldelement_list.length > 0) {
				setElementList(oldelement_list)
			}
			try {

				let telement_list = await getTemplateList(true)
				telement_list = telement_list.templateList.sort(compareName)
				setOldElementList(telement_list)
				setElementList([...element_list, ...telement_list])
				setShowArchived(true)
			} catch (err) { console.log(err) }
		} else {
			setElementList(element_list)
			setShowArchived(false)
		}
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteTemplate(el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('template.messages.delete_template_title'), t('template.messages.delete_template'), cb)
	}






	return (
		<div>

			{/* <SubNavBarRegistry curpage={'templates'} t={t} /> */}
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.template')} admin={true}/>
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{!showArchived?
						<ButtonGroup>
							<Button onClick={() => handleShowArchived(true)} variant={showArchived ? 'secondary' : 'outline-secondary'}  >{t('template.buttons.show_archived')}</Button>
						</ButtonGroup>
						:''}
					</Col>
				</Row>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ms-1">{t('global.labels.name')}</Col>
							<Col className="bg-secondary ms-1">{t('global.labels.category')}</Col>
							<Col className="bg-secondary ms-1">{t('global.labels.creation_date')}</Col>
							<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
							<Row key={key} className="element_row p-1 border-bottom " >
								{i.isArchived ?
									<Col><FontAwesomeIcon icon="archive" /><s>{i.name}</s></Col>
									:
									<Col><b>{i.name}</b></Col>
								}
								<Col>
									{getValue(contract_sector_dict[i.sector],'label')}
									<img height="20" src={contract_scope_dict[i.scope].icon} alt='icon' /> 
									{getValue(contract_scope_dict[i.scope],'label')}
								</Col>
								<Col>
                                	{format(myTimestampToDate(i.creation_date), 'dd/MM/yyyy')}
								</Col>
								<Col sm="1" className="text-center">
									<ButtonGroup>
										<ModalTemplate is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
										{i.isArchived ?
											<Button variant="danger" onClick={() => handleDelete(i)} ><FontAwesomeIcon icon="trash" /></Button>
											: ''}
									</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <> <Row><Col>
						{t('templates.messages.no_template')}
					</Col></Row>
					</>
				}




			</Container>

		</div >
	);
}

export default translate()(TemplatesView)



