import { Container, Row, Col, } from 'react-bootstrap';
import React from 'react';
import systemparams from "../components/constants/systemparams"

const ErrorView = () => {
	document.title = systemparams.sw_name + " | Pagina non trovata"
	/* 		const [pageStatus, setPageStatus] = useState('Caricamento pagina')
			setTimeout(() => {
				setPageStatus('Dominio non disponibile')
				
			}, 3000); */
	return (
		<Container fluid>
			<Row>
				<Col className="h1 text-center">
					{/* {pageStatus} */}
							404 <br/>
							<b>Pagina non trovata</b>
							<br />
							torna alla <a href="/">homepage</a>
				</Col>
			</Row>
			<Row>
				<Col className="mt-2 text-center border-top pt-3">
					<img
						src="/Logo.Claudit.registrato.png"
						height="80"
						className="d-inline-block align-middle"
						alt="Claudit"
					/>
				</Col>
			</Row>
		</Container>
	);
}
export default ErrorView;