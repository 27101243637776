import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addWorkjob = async (domainId,  data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workjobs')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addWorkjob error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getWorkjobById = async (domainId,  workjobId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workjobs')
			.doc(workjobId)
            .get();

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getWorkjob error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const getWorkjobList = async (domainId, jobId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workjobs')
            .get();

        const workjobList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id : doc.id,
            }
            return data
        })
        return workjobList;  
    } catch (e) {
        console.log("getWorkjob error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const editWorkjob = async (domainId,  workjobId, data) => {
    try {
        const workjob = firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workjobs')
            .doc(workjobId);

        const res = await workjob.set(
            data
        ).then( () => {
			console.log("editWorkjob - edit successfully")
			return data
		}).catch( error => {
			console.log("editWorkjob - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editWorkjob error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const deleteWorkjob = async (domainId,  workjobId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('workjobs')
            .doc(workjobId).delete();
        return res
    }  catch (e) {
        console.log("deleteWorkjob error: ", e)
        return { error: e }
    }
}