import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, InputGroup, Nav, Tab, ProgressBar, Image } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import DateTime from 'react-datetime';
import { myDateToTimestamp, myTimestampToDate } from "../util/ui_utils.js"
import ElementFormUsers from './FormUsers'
import PhoneList from "../components/PhoneList.js"

import firebase from 'firebase/compat/app'
import { addDomain as addDomain2, editDomain as editDomain2 } from '../Firebase2/Storage2/dbDomain';
import { uploadFileComplete as uploadFileComplete2 } from '../Firebase2/Storage2/dbFileStorage';
import { licence_status_dict } from './constants/global_definitions.js';


let primary_domain = '.claudit.net'


function ModalClauditlicence({ item, setItem, t, is_new, small, index, item_list, setParentList, checkDomain, ui_list }) {

	const licence_status = licence_status_dict({ t })
	const newclauditlicence = {
		name: '',
		code: '',
		element_list: [],
		logo: '',
		domain: '',
		startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(),
		use_max_contract: false,
		max_contract: 10,
		warning_days: 15,
		admin_user_id: -1,
		address: {
			street: '',
			number: '',
			zip: '',
			city: '',
			state: ''
		},
		licence_status: 'active',
		phone_list: [],
		email: '',
		pec: '',
		website: '',
		vat: '',
		cf: '',
		registro_imprese: '',
		capitale_sociale: '',
		rea: '',
		certification_logo: '',

	}

	const [showClauditlicenceEdit, setShowClauditlicenceEdit] = useState(false)
	const [curElement, setCurElement] = useState(newclauditlicence)
	const [editedElement, setEditedElement] = useState(false)

	const [isInvalid, setIsInvalid] = useState(false)
	const [uploadingState, setUploadingState] = useState(-1)

	const handleCloseClauditlicence = () => setShowClauditlicenceEdit(false);

	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			setUploadingState(100 * byte / total)
		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}
	const section = 'domain'
	const handleSaveClauditlicence = async () => {

		setShowClauditlicenceEdit(false);

		if (is_new) {
			const cb_saveb = async (url, tracker) => {
				if (url) {
					curElement.logo_certificate = url
					curElement.logo_certificate_tracker = tracker
				}
				end_save(curElement)
			}
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.logo = url
					curElement.logo_tracker = tracker
				}
				if (curElement.file_certificate) {
					const mypath = curElement.domain + '/' + section
					const mediainfo = { description: 'logo', source_name: curElement.file_certificate[0].name, section: section, item_reference: {} }
					let file_certificate_data = uploadFileComplete2(curElement.domain, undefined, mypath, curElement.file_certificate[0], curElement.file_certificate[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_saveb })
					console.log("file_certificate", file_certificate_data)
				} else {
					end_save(curElement)
				}
			}
			const end_save = async (el) => {
				delete el.file
				delete el.file_certificate
				const return_data = await addDomain2(el.domain, el)
				let newclauditlicence_list = [...item]
				newclauditlicence_list.push(curElement)
				console.log(newclauditlicence_list)
				setItem(newclauditlicence_list)
				setEditedElement(false)
			}

			if (curElement.file) {
				const mypath = curElement.domain + '/' + section
				const mediainfo = { description: 'logo', source_name: curElement.file[0].name, section: section, item_reference: {} }
				let file_data = uploadFileComplete2(curElement.domain, undefined,  mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)
			} else {
				cb_save()
			}

		} else {
			const cb_saveb = async (url, tracker) => {
				if (url) {
					curElement.logo_certificate = url
					curElement.logo_certificate_tracker = tracker
				}
				end_save(curElement)
			}
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.logo = url
					curElement.logo_tracker = tracker
				}
				delete curElement.file
				if (curElement.file_certificate) {
					const mypath = curElement.domain + '/' + section
					const mediainfo = { description: 'logo', source_name: curElement.file_certificate[0].name, section: section, item_reference: {} }
					let file_certificate_data = uploadFileComplete2(curElement.domain, '0', mypath, curElement.file_certificate[0], curElement.file_certificate[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_saveb })
					console.log("file_certificate", file_certificate_data)
				} else {
					end_save(curElement)
				}
			}
			const end_save = async (el) => {
				delete el.file
				delete el.file_certificate
				let newclauditlicence_list = [...item_list]
				newclauditlicence_list.splice(index, 1, el)
				setParentList(newclauditlicence_list)
				const return_data = await editDomain2(el.domain, el)
				setEditedElement(false)
			}
			if (curElement.file) {
				const mypath = curElement.domain + '/' + section
				const mediainfo = { description: 'logo', source_name: curElement.file[0].name, section: section, item_reference: {} }
				let file_data = uploadFileComplete2(curElement.domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
	}


	const handleShowNewClauditlicence = () => {
		console.log('show new')
		setIsInvalid(false)
		setCurElement(newclauditlicence)
		setShowClauditlicenceEdit(true)
		setEditedElement(false)
	}


	const handleShowEditClauditlicence = () => {
		if (!item.color) {
			item.color = { ...newclauditlicence.color }
		}
		setIsInvalid(false)
		setCurElement({ ...newclauditlicence, ...item })
		setShowClauditlicenceEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewClauditlicence()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewClauditlicence()}><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.clauditlicence_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditClauditlicence()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showClauditlicenceEdit} onHide={handleCloseClauditlicence} fullscreen backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{is_new && <Modal.Title className="text-primary">{t('licence.labels.new_clauditlicence')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title className="text-info">{t('licence.labels.edit_clauditlicence')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey={curElement.id === 'admin' ? "tabUsers" : "tabDetails"}>
							<Row>
								<Col sm={2} >
									<Nav variant="pills" className="flex-column">
										{curElement.id !== 'admin' ?
											<Nav.Item variant="secondary" className="mb-1">
												<Nav.Link eventKey="tabDetails">{t('company.labels.details')} </Nav.Link>
											</Nav.Item>
											: ''}
										<Nav.Item className="mb-1">
											<Nav.Link disabled={is_new} eventKey="tabUsers">{t('licence.labels.users')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col>
									<Form.Group as={Row} controlId="roomtypeName" className="border-bottom pb-1">
										<Form.Label column sm="2">{t('licence.labels.status')}</Form.Label>
										<Col sm="4">
											{Object.entries(licence_status).map((s, k) => (
												<Form.Check key={k} type="radio" name="licence_status" value={s[0]} checked={curElement.licence_status === s[0]} label={s[1].label} onChange={(event) => onChangeHandler(event.currentTarget)} />
											))}
										</Col>
									</Form.Group>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler, checkDomain: checkDomain, setIsInvalid: setIsInvalid, isInvalid: isInvalid }} is_new={is_new} uploadingState={uploadingState} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabUsers">
											<ElementFormUsers elem={curElement} t={t} functions={{ edit: onChangeHandler, setIsInvalid: setIsInvalid, isInvalid: isInvalid }} ui_list={ui_list} domain={curElement.domain} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseClauditlicence}>
						{t('modal.close')}
					</Button>
					<Button form="companyForm" disabled={isInvalid} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveClauditlicence}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t, is_new, uploadingState }) {
	const { locale } = useContext(LocaleContext)

	const onChangeDomain = (el) => {
		const res = functions.checkDomain(el.value)
		if (!res) {
			el.value = el.value.toLowerCase()
			functions.edit(el)
			functions.setIsInvalid(false)
		} else {
			functions.setIsInvalid(true)
		}
	}



	return (
		<>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.target)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.domain')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control type="text" disabled={!is_new} name="domain" value={element.domain} onChange={(event) => onChangeDomain(event.target)} />
						
							<InputGroup.Text>
								{primary_domain}
							</InputGroup.Text>
						
					</InputGroup>
					<Form.Text className="text-warning">
						<FontAwesomeIcon icon="info-circle" />{t('licence.labels.cantedit')}
					</Form.Text>
					{functions.isInvalid ?
						<Form.Text className="text-danger">
							<FontAwesomeIcon icon="exclamation-triangle" />{t('licence.messages.domainpresent')}
						</Form.Text>
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.target)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formProductCF" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.logo')}</Form.Label>
				<Col>
					<Form.Control type="file" name="file" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.logo
						?
						<>

							<Image style={{ height: '50px' }} src={element.logo} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'logo', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.loginbg')}</Form.Label>
				<Col>
					<Form.Control type="file" name="file_background" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.loginbg
						?
						<>

							<Image style={{ height: '50px' }} src={element.loginbg} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'loginbg', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} id="formSupplierBusinessname" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.businessname')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="ragione_sociale" defaultValue={element.ragione_sociale} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierVat" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.vat')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="vat" defaultValue={element.vat} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierCf" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.cf')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="cf" defaultValue={element.cf} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.address')} </Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" sub="address" controlId="supplierStreet" name="street" defaultValue={element.address.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							</Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" controlId="supplierZip" name="zip" value={element.address.zip} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col >
							<Form.Control type="text" sub="address" controlId="supplierCity" name="city" value={element.address.city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" controlId="supplierState" name="state" value={element.address.state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<PhoneList element={element} functions={functions} t={t} canWrite={true} />
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">Email</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="email" value={element.email} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.website')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" placeholder="www.mywebsite.it" name="website" value={element.website} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">Pec</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="pec" value={element.pec} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">Capitale sociale</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="capitale_sociale" value={element.capitale_sociale} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">rea</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="rea" value={element.rea} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">SDI</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="sdi" value={element.sdi} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.certification_logo')}</Form.Label>
				<Col>
					<Form.Control type="file" name="file_certificate" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.logo_certificate
						?
						<>

							<Image style={{ height: '50px' }} src={element.logo_certificate} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'logo_certificate', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			{uploadingState > 0 ?
				<Row>
					<Col>
						<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
					</Col>
				</Row>
				: <></>}
			<Form.Group as={Row} controlId="roomtypeName" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('licence.labels.limit_contracts')}</Form.Label>
				<Col sm="4">
					<Form.Check type="radio" name="has_max_contract" value={true} checked={element.has_max_contract} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="has_max_contract" value={false} checked={!element.has_max_contract} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col sm="4">
					<Form.Control type="number" min="0" disabled={!element.has_max_contract} name="max_contract" value={element.max_contract} onChange={(event) => functions.edit(event.currentTarget)} className="mb-2" />
					<Form.Text className="text-muted">
						{t('licence.labels.max_contract_number')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('licence.labels.licence_start')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.startDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="startDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'startDate', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="eventStart" className="border-bottom pb-1">
				<Form.Label column sm="2">{t('licence.labels.licence_end')}</Form.Label>
				<Col sm="3">
					<DateTime initialValue={myTimestampToDate(element.endDate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="endDate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'endDate', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('global.labels.warning_days')}</Form.Label>
				<Col sm="3">
					<InputGroup>
						<Form.Control type="number" min="1" max="90" name="warning_days" value={element.warning_days} onChange={(event) => functions.edit(event.target)} />
						
							<InputGroup.Text>
								{t('global.labels.days')}
							</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
		</>
	)
}


export default translate()(ModalClauditlicence)