import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button,  Image } from 'react-bootstrap';
import { differenceInMinutes, format } from 'date-fns'
import { useReactToPrint } from 'react-to-print';

import { timingMode_dict, appRole_dict } from "../components/constants/global_definitions"

import GlobalDataContext from './contexts/globaldataContext.js';


import { ResultBadge } from '../components/StructureGraphicElements.js'
import {  getValue, myTimestampToDate, getFloatNumber } from "../util/ui_utils.js"

let styles = {
    width: 450,
    position: 'relative',
}
const style_normal = {
    position: 'absolute',
    color: 'deeppink',
    padding: '0.2rem 0.1em',
    cursor: 'move',
}


const ImagePreview = ({ activity, roomtype, t, result_dict }) => {
    const { operation_dict } = useContext(GlobalDataContext)
    const zoom = 1.0
    const zoomstyle = { ...styles, width: styles.width * zoom }

    // ---------------------------------------
    const checkPointStatusColor = (point_id) => {
        let point_status = 'lightgreen'
        for (const op of activity.operation_list) {
            const operation = operation_dict[op.id]
            if (operation.furniture_list && operation.furniture_list.filter(e => e.id === point_id && e.control_list && e.control_list.length>0).length > 0) {
                const element = operation.furniture_list.filter(e => e.id === point_id)[0]
                console.log("element", element)
                for (const ckey in element.control_list) {
                    const control_result = result_dict[operation.id + "_" + element.id + "_" + ckey]
                    if (!control_result) {
                        point_status = 'cyan'
                    } else {
                        if (control_result === 'false') {
                            return 'red'
                        } else if (control_result === 'unavailable') {
                            point_status = 'yellow'
                        }
                    }
                }
            }
            if (operation.economic_material_list && operation.economic_material_list.filter(e => e.id === point_id && e.control_list && e.control_list.length>0).length > 0) {
                const element = operation.economic_material_list.filter(e => e.id === point_id)[0]
                console.log("elementB", element)
                for (const ckey in element.control_list) {
                    const control_result = result_dict[operation.id + "_" + element.id + "_" + ckey]
                    if (!control_result) {
                        point_status = 'cyan'
                    } else {
                        if (control_result === 'false') {
                            return 'red'
                        } else if (control_result === 'unavailable') {
                            point_status = 'yellow'
                        }
                    }
                }
            }
            if (operation.environment_element_list && operation.environment_element_list.filter(e => e.id === point_id && e.control_list && e.control_list.length>0).length > 0) {
                const element = operation.environment_element_list.filter(e => e.id === point_id)[0]
                console.log("elementC", element)
                for (const ckey in element.control_list) {
                    const control_result = result_dict[operation.id + "_" + element.id + "_" + ckey]
                    if (!control_result) {
                        point_status = 'cyan'
                    } else {
                        if (control_result === 'false') {
                            return 'red'
                        } else if (control_result === 'unavailable') {
                            point_status = 'yellow'
                        }
                    }
                }
            }
        }
        return point_status
    }

    const checkPointPresence = (point_id) => {

        for (const op of activity.operation_list) {
            const operation = operation_dict[op.id]
            if (operation.furniture_list && operation.furniture_list.filter(e => e.id === point_id && e.control_list && e.control_list.length>0).length > 0) {
                return true
            }
            if (operation.environment_element_list && operation.environment_element_list.filter(e => e.id === point_id && e.control_list && e.control_list.length>0).length > 0) {
                return true
            }
            if (operation.economic_material_list && operation.economic_material_list.filter(e => e.id === point_id && e.control_list && e.control_list.length>0).length > 0) {
                return true
            }
        }
    }

    return (

        <div style={zoomstyle}>
            <Image src={roomtype.image_reference} fluid />
            {Object.keys(activity.check_list).filter(k => checkPointPresence(k)).map((key) => {
                const { left, top, point_id, control_list } = activity.check_list[key]
                console.log("(ImagePreview k):", key, control_list )
                //console.log('selected', selectedElement, key)
                //   if (selectedElement === key) { selected = true }
                let mystyle = style_normal
                const selected = true
                return (
                    <div style={{ ...mystyle, left, top, color: checkPointStatusColor(key) }} className={selected ? "h4" : "h4"} key={key}>
                        <FontAwesomeIcon icon="map-marker-alt" /> {point_id}
                    </div>
                )
            })}
        </div>

    )
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ServiceCheckPreview = ({ activity, ui_list, t, roomType_name, roomType_elements, canWrite, roomtype, res_data, secondary }) => {
	const componentRef = useRef();
    const { operation_dict } = useContext(GlobalDataContext)
    const [showPreview, setShowPreview] = useState(false)
    const handleClosePreview = () => {
        setShowPreview(false)
    }
//    console.log("(ServiceCheckPreview) ", res_data)
    const handleShowPreview = () => {
        setShowPreview(true)

    }

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
		documentTitle: `Scheda Controllo: ${roomType_name} | Scheda controllo | ${getValue(activity.service, 'name')}`
	});

    const checkElementPresence = (e, res_data) => {
        const e_id = e.id
        if (activity.check_list && activity.check_list[e_id]) {
            if (res_data) {
                return e.control_list && e.control_list.length > 0
            } else {
                return true
            }
        }

        return false
    }

    const getElementColor = (r, result_dict, operation_id) => {
        let color = " text-dark"
        for (const control_key of r.control_list) {
            if (result_dict[operation_id + "_" + r.id + "_" + control_key] === 'false') {
                return " text-danger"
            }
        }
        return color
    }

    const goodSection = (section_list, operation_id) => {
        return (
            <>
                {section_list.map((r, kf) => (
                    <Row key={kf} className=" border-bottom border-info mb-1 pb-1">
                        <Col xs="3" className={res_data ? getElementColor(r, res_data.result_dict, operation_id) : ''}>{r.description} {r.name}</Col>
                        <Col >
                            {r.control_list && r.control_list.length > 0 ?
                                <>
                                    {r.control_list.map((c, ckey) => (
                                        <Row key={ckey}>
                                            <Col>{c.description} {c.id}</Col>
                                            <Col xs="1">{c.coefficient}</Col>
                                            {res_data ?
                                                <>
                                                    <Col xs="1">
                                                        <ResultBadge res={res_data.result_dict[operation_id + "_" + r.id + "_" + ckey]} ratio={c.coefficient} />
                                                    </Col>
                                                    <Col>
                                                        {res_data.corrective_action_dict && res_data.corrective_action_dict[operation_id + "_" + r.id + "_" + ckey] ?
                                                            <CorrectiveAction act={res_data.corrective_action_dict[operation_id + "_" + r.id + "_" + ckey]} t={t} />
                                                            : ''
                                                        }
                                                    </Col>
                                                </>

                                                :
                                                <Col>
                                                    {c.action_list && c.action_list.length > 0 ?
                                                        <>
                                                            <Row className="section_title element_row">
                                                                <Col className="bg-secondary text-light ms-1">{t('workarea.labels.action')} </Col>
                                                                <Col className="bg-secondary text-light ms-1">{t('workarea.labels.responsability')} </Col>
                                                                <Col className="bg-secondary text-light ms-1">{t('workarea.labels.timing')} </Col>
                                                            </Row>
                                                            {c.action_list.map((i, ikey) => (
                                                                <Row key={ikey} className="element_row border-bottom mb-1 pt-1">
                                                                    <Col className="font-weight-bold">{i.action}</Col>
                                                                    <Col>{i.responsability}</Col>
                                                                    <Col>{i.timing}</Col>
                                                                </Row>))}
                                                        </>
                                                        : <i> {t('workarea.messages.no_action')} </i>}
                                                </Col>
                                            }
                                        </Row>))
                                    }
                                </>
                                : <> <i>{t('operation.messages.no_control')}</i></>
                            }
                        </Col>

                    </Row>
                ))}
            </>
        )
    }





    //------------------------------------------------------------------------------------
    const operationRow = (operation, k) => {
        const i = operation_dict[operation.id] || operation




        return (
            <>
                <Col xs="3">
                    <Badge bg="secondary">{k + 1}</Badge> {i.name}
                </Col>
                <Col>
                    {!(i.furniture_list && i.furniture_list.filter(r => checkElementPresence(r, res_data)).length > 0) && !(i.environment_element_list && i.environment_element_list.filter(r => checkElementPresence(r)).length > 0) && !(i.economic_material_list && i.economic_material_list.filter(r => checkElementPresence(r)).length > 0) ?
                        <i>
                            nessun elemento associato a questa operazione
						</i>
                        : ''}
                    {i.furniture_list && i.furniture_list.filter(r => checkElementPresence(r, res_data)).length > 0 ?
                        <>
                            {goodSection(i.furniture_list.filter(r => checkElementPresence(r, res_data)), i.id)}
                        </>
                        : ''
                    }
                    {i.environment_element_list && i.environment_element_list.filter(r => checkElementPresence(r, res_data)).length > 0 ?
                        <>
                            {goodSection(i.environment_element_list.filter(r => checkElementPresence(r, res_data)), i.id)}
                        </>
                        : ''
                    }
                    {i.economic_material_list && i.economic_material_list.filter(r => checkElementPresence(r, res_data)).length > 0 ?
                        <>
                            {goodSection(i.economic_material_list.filter(r => checkElementPresence(r, res_data)), i.id)}
                        </>
                        : ''
                    }

                </Col>
            </>
        )
    }



    //=============================================================================================
    return (
        <>
            <Button variant="outline-secondary" size="sm" onClick={() => handleShowPreview()}><FontAwesomeIcon icon="eye" /> {secondary? <>scheda ri-eseguita</>: t('structure.labels.show_preview')}</Button>
            <Modal show={showPreview} onHide={handleClosePreview} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton>
                    {res_data ?
                        <Modal.Title >{roomType_name} | <span className="text-primary"><FontAwesomeIcon icon="eye" /> {t('roomtypes.labels.service_check')}</span> | {getValue(activity.service, 'name')} </Modal.Title>
                        :
                        <Modal.Title >{roomType_name} | <span className="text-primary"><FontAwesomeIcon icon="eye" /> {t('roomtypes.labels.service_check_preview')}</span> | {getValue(activity.service, 'name')} </Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Container fluid ref={componentRef}>
                        <Row className="border-bottom mb-2 h5">
                            <Col xs="8">
                                <Row className="border-bottom mb-1 pb-1">
                                    <Col>
                                        {t('global.labels.lqa')}
                                    </Col>
                                    <Col className="h5">
                                        <Badge bg="primary">
                                            {activity.lqa}
                                        </Badge>
                                    </Col>
                                </Row>
                                {res_data ?
                                    <>
                                        <Row className="border-bottom mb-1 pb-1">
                                            <Col>
                                                {t('global.labels.lqr')}
                                            </Col>
                                            <Col className="h5">
                                                <Badge bg="info">{res_data.lqr.toFixed(2)}</Badge>
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom mb-1 pb-1">
                                            <Col>
                                                {t('global.labels.delta')}
                                            </Col>
                                            <Col className="h5">
                                                {res_data.lqr && activity.lqa?
                                                <DeltaBadge lqr={parseFloat(res_data.lqr)} lqa={getFloatNumber(activity.lqa)} />
                                                :''}
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom mb-1 pb-1">
                                            <Col>
                                                {res_data.timing_validity && (res_data.timing_validity === 'post' || res_data.timing_validity === 'post') ?
                                                    'Controllo non valido (fuori tempo massimo)'
                                                    : ''}

                                            </Col>
                                        </Row>
                                    </>
                                    : ''}
                            </Col>
                            {res_data ?
                                <Col xs="4">
                                    <ImagePreview activity={activity} roomtype={roomtype} t={t} result_dict={res_data.result_dict} />
                                </Col>
                                : ''}
                        </Row>
                        <Row className="mb-1 text-light">
                            <Col className="bg-secondary ms-1" xs="3">{t('global.labels.operations')} </Col>
                            <Col className="bg-secondary ms-1" xs="2">{t('global.labels.element')} </Col>
                            <Col className="bg-secondary ms-1" >
                                <Row>
                                    <Col className="text-center">
                                        {t('operation.labels.controls')}
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col className="ms-1">{t('global.labels.description')} </Col>
                                    <Col className="ms-1 text-center" xs="1">{t('operation.labels.coefficient')} </Col>
                                    {res_data ?
                                        <Col className="ms-1" xs="1">{t('check.labels.result')} </Col>
                                        : ''}
                                    <Col className="ms-1 text-center">{t('check.labels.corrective_action')} </Col>
                                </Row>
                            </Col>
                        </Row>
                        {activity.operation_list ?
                            <>
                                {activity.operation_list.map((i, k) => (
                                    <Row key={k} className="border-bottom mb-1 pb-1">
                                        {operationRow(i, k)}
                                    </Row>
                                ))}
                            </>
                            : ''}

                        {ui_list.global_question_list && ui_list.global_question_list.length > 0 ?
                            <>
                                <Row className="mt-4">
                                    <Col className="text-primary border-bottom h5">
                                        {t('checks.labels.global_questions')}
                                    </Col>
                                </Row>
                                <Row className="mb-1 text-light">
                                    <Col className="bg-secondary ms-1">{t('global.labels.text')}</Col>
                                    <Col className="bg-secondary ms-1" xs="2">{t('operation.labels.coefficient')} </Col>
                                </Row>
                                {ui_list.global_question_list.map((q, k) => (
                                    <Row key={k} className="border-bottom mb-1 pb-1">
                                        <Col>
                                            <b>{q.name}</b><br />
                                            {q.text}
                                        </Col>
                                        <Col xs="2">
                                            {q.coefficient}
                                        </Col>
                                    </Row>
                                ))}
                            </>
                            : ''}
                        {res_data ?
                            <ControlEndSection t={t} res_data={res_data} />
                            : ''}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreview}>
                        {t('modal.close')}
                    </Button>
									<Button variant="info" onClick={handlePrint}>
										<FontAwesomeIcon icon="print" />  {t('modal.print')}
									</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


const DeltaBadge = ({ lqr, lqa }) => {
    return (
        <Badge bg={lqr < lqa ? 'danger' : 'success'}>{(lqr - lqa).toFixed(2)}</Badge>
    )
}

///////////////////////////////////////////////////
const CorrectiveAction = ({ act, t }) => {
    const timing_mode_dict = timingMode_dict({ t })
    console.log("-------AZIONE", act)
    const ActionDate = () => {

        try {
            if (act.timing === 'delayed') {
                return (<>({format(myTimestampToDate(act.date), 'HH:mm dd/MM/yy')}</>)
            } else {
                return ''
            }

        } catch (err) {
            console.log(err)
            return ('')
        }
    }

    return (
        <>
            <Row>
                <Col className="border-start border-danger">
                    {act.action.action}
                </Col>
            </Row>
            <Row>
                <Col className="border-start border-danger">
                    <i>{t('workarea.labels.timing')}:</i>
                    {getValue(timing_mode_dict[act.timing], 'label')} &nbsp;
                    <ActionDate />

                </Col>
            </Row>
            <Row>
                <Col className="border-start border-danger">
                    <i>{t('workarea.labels.responsability')}:</i>  {act.action.responsability}
                </Col>
            </Row>
        </>
    )
}


////////////////////////////////////////////////////////////////////
export const ControlEndSection = ({ res_data, t, }) => {

    const app_roles = appRole_dict({ t })
    return (
        <>
            {res_data.note && res_data.note.length > 0 ?
                <Row>
                    <Col xs="3">
                        {t('global.labels.note')}
                    </Col>
                    <Col>
                        {res_data.note}
                    </Col>
                </Row>
                : ''}
            <Row className="border-bottom mb-3 mt-2 pb-1">
                <Col className="text-end">
                    Inizio controllo:
            </Col>
                <Col>
                    {format(myTimestampToDate(res_data.creation_date), 'HH:mm dd/MM/yyyy')}
                </Col>
                <Col className="text-end border-start">
                    Fine controllo:
            </Col>
                <Col>
                    {format(myTimestampToDate(res_data.end_check), 'HH:mm dd/MM/yyyy')}
                </Col>
                <Col className="text-end border-start">
                    Durata controllo:
            </Col>
                <Col>
                    {differenceInMinutes(myTimestampToDate(res_data.end_check), myTimestampToDate(res_data.creation_date))} minuti
            </Col>
            </Row>
            <Row>
                <Col xs="3">
                </Col>
                <Col>
                    {res_data.member_list.map((s, k) => (
                        <Row key={k} className="border-bottom mb-1 pb-1">
                            <Col className="font-weight-bold">
                                {s.name}
                            </Col>
                            <Col>
                                {getValue(app_roles[s.role], 'name')}
                            </Col>
                            <Col xs="3">
                            {console.log("SIGN DATA", res_data)}
                                {res_data.sign_data && res_data.sign_data[s.id]?
                                    <SignImage sign={res_data.sign_data[s.id].sign}/>
                                    : ''}
                            </Col>

                        </Row>

                    ))}
                </Col>
            </Row>
        </>

    )
}



const SignImage = ({sign}) => {
    if (sign && sign.encoded){
        console.log("ENCODED", sign.encoded)
        return <Image src={`data:image/png;base64,${sign.encoded}`} fluid />

    }else if (sign){
        console.log("PATH")
        return <Image src={sign} fluid />
    }else{
        return <></>
    }

}

const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;
