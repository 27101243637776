import React, { useState,  useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   Container, Row, Col,  Button, ButtonGroup,  Spinner, Badge } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import { translate } from 'react-polyglot'
//import CurrencyInput from 'react-currency-input-field';
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import { compareCode } from "../util/ui_utils.js"

import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"
import ModalSystemgroup from '../components/ModalSystemgroup'
import {getSystemMenu_list} from "../components/constants/systemsections"


import { getSystemGroupList as getSystemGroupList2 } from '../Firebase2/Storage2/dbGroup';






const newsystemgroup = {
	name: '',
	/* 	startDate: firebase.firestore.Timestamp.now(),
		endDate: firebase.firestore.Timestamp.now(),
		creation_date: firebase.firestore.Timestamp.now(), */
}



const SystemgroupView = ({  t }) => {

	const permission_dict = getSystemMenu_list({ t: t, config:true })
	console.log(permission_dict)

	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [curElement, setCurrentElement] = useState(newsystemgroup)
	const [searchTerm, setSearchTerm] = useState('')

	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}





	const [ui_list, setUiList] = useState({})


	const initValues = async () => {
		setLoading(true)
		let newlist = await getSystemGroupList2()
		console.log(newlist)
		newlist.sort(compareCode)
		console.log("source systemgroup_list", newlist)
		setElementList(newlist)
		setUiList({permission_dict})
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])


	const getMenuValue = (v) => {
		console.log()
		if (permission_dict){
			for (let p=0;p<permission_dict.length; p++){
				for (let s=0; s<permission_dict[p].menulist.length; s++){
					if (permission_dict[p].menulist[s].id === v){
						return permission_dict[p].menulist[s].label
					}
				}
			}
		}

		return (<></>)
	}
	return (
		<div>
			{/* <SubNavBarSystemgroup curpage={'systemgroups'} t={t} /> */}
			<Container fluid>
				<Row className="mt-1">
					<Col className="h3">
						<PageTitle title={t('global.pages.systemgroups')}  admin={true}/>
					</Col>
					<Col sm={4}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalSystemgroup is_new={true} t={t} item={element_list} setItem={setElementList} ui_list={ui_list} />
					</Col>
				</Row>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ms-1">{t('global.labels.name')}</Col>
							<Col className="bg-secondary ms-1">{t('global.labels.permission')} {t('global.labels.readwrite')}</Col>
							<Col className="bg-secondary ms-1">{t('global.labels.permission')} {t('global.labels.read')}</Col>
							<Col className="bg-secondary ms-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
						</Row>
						{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
							<Row key={key} className="oneCompany p-1 border-bottom">
								<Col>
									{i.name}
								</Col>
								<Col>
									{Object.keys(Object.fromEntries(Object.entries(i.permission_dict).filter(([k,v]) => v=="1"))).map((d, k) => (
										<Badge key={k} className="me-1" bg="success">{getMenuValue(d)}</Badge>
									))}
								</Col>
								<Col>
									{Object.keys(Object.fromEntries(Object.entries(i.permission_dict).filter(([k,v]) => v=="0"))).map((d, k) => (
										<Badge key={k} className="me-1" bg="primary">{getMenuValue(d)}</Badge>
									))}
								</Col>
								<Col className="text-center" sm="2" md="1">
									<ButtonGroup>
										<ModalSystemgroup t={t} item={i} setItem={onChangeHandler} ui_list={ui_list} item_list={element_list} setParentList={setElementList} index={key} />
										{/* FIXME delete function */}
										<Button variant="danger" size="sm" disabled><FontAwesomeIcon icon='trash' /></Button>
									</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <i> {t('licence.messages.no_systemgroup')}
					</i>
				}
			</Container>
		</div>
	);
}


export default translate()(SystemgroupView)


