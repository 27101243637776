import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addSamplingParam = async (domainId, contractId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId).collection('samplingplans')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addSamplingParam error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getSamplingParamById = async (domainId, contractId, samplingplansId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('samplingplans')
            .get(samplingplansId)

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getSamplingParam error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getSamplingPlanList = async (domainId, contractId, structureId=null) => {
    try {
        if (structureId === null || structureId === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('samplingplans')
                .get();

            const samplingparamList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return samplingparamList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('samplingplans')
                .where('structureId', '==', structureId)
                .get();

            const samplingparamList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return samplingparamList;
        }
    } catch (e) {
        console.log("getSamplingParamList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getSamplingPlanListTime = async (domainId, contractId, _now, structureId=null) => {
    try {
        if (structureId === null || structureId === undefined) {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('samplingplans')
                .where("startDate", "<=", _now)
                .where("endDate", ">=", _now)
                .get();

            const samplingparamList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return samplingparamList;
        } else {
            const res = await firestore.collection('DOMAINS')
                .doc(domainId).collection('contracts')
                .doc(contractId)
                .collection('samplingplans')
                .where('structureId', '==', structureId)
                .where("startDate", "<=", _now)
                .where("endDate", ">=", _now)
                .get();

            const samplingparamList = res.docs.map(doc => {
                const firebaseData = doc.data()
                const data = {
                    ...firebaseData,
                    id : doc.id,
                }
                return data
            })
            return samplingparamList;
        }
    } catch (e) {
        console.log("getSamplingParamList error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const editSamplingParam = async (domainId, contractId, samplingplansId, data) => {
    try {
        const samplingparam =  firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('samplingplans')
            .doc(samplingplansId);

        const res = await samplingparam.set(data)
            .then( () => {
                console.log("editSamplingParam - edit successfully")
                return data
            }).catch( error => {
                console.log("editSamplingParam - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editSamplingParam error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteSamplingParam = async (domainId, contractId, samplingplansId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('samplingplans')
            .doc(samplingplansId).delete();
        return res
    }  catch (e) {
        console.log("deleteSamplingParam error: ", e)
        return { error: e }
    }
}




