import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, InputGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import { useParams } from "react-router-dom"

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"

import { getSelectedElement, checkComponentPresence, myTimestampToDate } from "../util/ui_utils.js"

import firebase from 'firebase/compat/app'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addProduct as addProduct2, editProduct as editProduct2, } from '../Firebase2/Storage2/dbWarehouse';
import { addCatalogProduct, editCatalogProduct } from '../Firebase2/Storage2/dbCatalogWarehouse';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';
import { ModalInitButton } from './StructureGraphicElements.js';


const companyId = '0'
//const contractId = sessionStorage.getItem('contractId')
const section = 'equipment'


////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalEquipment({ item, setItem, t, is_new, small, item_list,  canWrite, ui_list, setUiList  }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()

	let { cid } = useParams();
	let contractId = cid


	const newequipment = {
		code: '',
		brand: {},
		name: '',
		material: {},
		type: {},
		size_w: 0,
		size_h: 0,
		size_d: 0,
		net_weight: 0,
		capacity: 0,
		capacity_quantity: 0,
		color: { r: 10, g: 10, b: 90, a: 1 }
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newequipment)
	const [editedElement, setEditedElement] = useState(false)

	const initValues = async () => {
		//	const brand_list =  await getBaseObjectTypeList2(domain, 'brands')
		//	const category_list = await getBaseObjectTypeList2(domain, 'equipment_category')
		//	setUiList({ ...ui_list, brand_list, category_list })
	}

	const onUpdateData = (data, b) => {
		setUiList({ ...ui_list, [data]: b.list })
	}


	const handleShowNew = () => {
		newequipment.media_list = []
		setCurrentElement(newequipment)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		let return_data
		if (is_new) {
			if (domain === 'catalog') {
				return_data = await addCatalogProduct(contractId, section, curElement)
			} else {
				return_data = await addProduct2(domain, contractId, companyId, section, curElement)
			}
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			if (domain === 'catalog') {
				return_data = await editCatalogProduct(contractId, section, curElement.id, curElement)
			} else {
				return_data = await editProduct2(domain, contractId, companyId, section, curElement.id, curElement)
			}
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('equipment.buttons.equipment_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('equipments.labels.new_equipment')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('equipments.labels.edit_equipment')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('equipments.labels.view_equipment')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('equipments.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia">{t('global.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler }} section={section} canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////

function ElementFormDetails({ element, functions, ui_list, t }) {
	const { locale } = useContext(LocaleContext)
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 100, g: 0, b: 0, a: 1 })
	const [myuilist, setMyUilist] = useState(ui_list)
	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: `rgba(${element.color.r}, ${element.color.g}, ${element.color.b}, ${element.color.a})`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
		//		setMyUilist({...myuilist, brand_list: b})
	}
	const updateEquipmentCategory = (b) => {
		functions.updateData('category_list', b)
		//		setMyUilist({...myuilist, category_list: b})
	}
	const updateEquipmentMaterial = (b) => {
		functions.updateData('material_list', b)
		//		setMyUilist({...myuilist, material_list: b})
	}

	return (
		<>
			<Form.Group as={Row} controlId="equipmentCode" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" className="mb-2" name="code" defaultValue={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="equipmentDescription" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.description')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" className="mb-2" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEquipmentCategory" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.type')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue={element.type.Id} name="type" className="mb-2" onChange={(event) => functions.edit({ name: 'type', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('products.labels.select_category')}</option>
						{ui_list.category_list
							? (Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							)))
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="2">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'equipment_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateEquipmentCategory }} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('products.labels.select_brand')}</option>
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name? f.name[locale]: '--'}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="2">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEquipmentComposition" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.material')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue={element.material.Id} name="material" className="mb-2" onChange={(event) => functions.edit({ name: 'material', type: 'select', value: getSelectedElement(event.target.value, ui_list.material_list) })}  >
						<option value="-1">{t('products.labels.select_material')}</option>
						{ui_list.material_list
							? [Object(ui_list.material_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="2">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'equipment_material', list: ui_list.material_list }} t={t} functions={{ setCurSection: updateEquipmentMaterial }} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEquipmentColor" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.color')} </Form.Label>
				<Col sm="8">
					<Button variant="outline-info" onClick={handleShowColorPicker}>
						<div style={styles.color} />
					</Button>
					{displayColorPicker ? <div style={styles.popover}>
						<div style={styles.cover} onClick={handleCloseColorPicker} />
						<SketchPicker color={element.color} onChange={handleChangeColor} onChangeComplete={(e) => functions.edit({ name: 'color', value: e.rgb })} />
					</div> : null}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEquipmentColor" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.size')}</Form.Label>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="size_w" min="0" aria-label="size_w" defaultValue={element.size_w} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
						
					</InputGroup>
					<Form.Text className="text-muted">
						{t('equipments.labels.size_w')}
					</Form.Text>
				</Col>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="size_h" min="0" defaultValue={element.size_h} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
						
					</InputGroup>
					<Form.Text className="text-muted" >
						{t('equipments.labels.size_h')}
					</Form.Text>
				</Col>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="size_d" min="0" defaultValue={element.size_d} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
						
					</InputGroup>
					<Form.Text className="text-muted">
						{t('equipments.labels.size_d')}
					</Form.Text>
				</Col>
				<Col>
					<InputGroup>
						<Form.Control type="number" name="net_weight" min="0" step="0.1" defaultValue={element.net_weight} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
						
					</InputGroup>
					<Form.Text className="text-muted">
						{t('equipments.labels.weight')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="equipmentCapacity" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.capacity')}</Form.Label>
				<Col sm="8">
					<InputGroup className="mb-2">
						<Form.Control type="number" name="capacity" defaultValue={element.capacity} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">{t('equipments.labels.liter')}</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="equipmentQuantity" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.quantitiy')}</Form.Label>
				<Col sm="8">
					<Form.Control type="number" name="capacity_quantity" defaultValue={element.capacity_quantity} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Text className="text-muted">
						{t('equipments.labels.quantity_note')}
					</Form.Text>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="equipmentNote" className="border-bottom">
				<Form.Label column sm="2">{t('equipments.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" className="mb-2" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}



export default translate()(ModalEquipment)