import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, InputGroup, Nav, Tab, ProgressBar, Image } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import DateTime from 'react-datetime';



import { myTimestampToDate } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'

import firebase from 'firebase/compat/app'
import { addNews, editNews } from '../Firebase2/Storage2/dbNews';
import { uploadFileComplete as uploadFileComplete2 } from '../Firebase2/Storage2/dbFileStorage';



const section = 'news'
const domain = 'admin'

function ModalNews({ item, setItem, t, is_new, small, index, item_list, setParentList, checkDomain, ui_list, canWrite }) {

	const newnews = {
		title: '',
		photo:'',
		text: '',
		is_public: true,
		type_list: []
	}

	const [showNewsEdit, setShowNewsEdit] = useState(false)
	const [curElement, setCurElement] = useState(newnews)
	const [editedElement, setEditedElement] = useState(false)

	const [isInvalid, setIsInvalid] = useState(false)
	const [uploadingState, setUploadingState] = useState(0)

	const handleCloseNews = () => setShowNewsEdit(false);

	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			setUploadingState(100 * byte / total)
		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}
	const handleSaveNews = async () => {

		setShowNewsEdit(false);

		if (is_new) {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.photo = url
					curElement.photo_tracker = tracker
				}
				delete curElement.file
				const return_data = await addNews(curElement)
				let newnews_list = [...item]
				newnews_list.push(return_data)
				console.log(newnews_list)
				setItem(newnews_list)
				setEditedElement(false)
			}

			if (curElement.file) {
				const mypath = 'news/' + section
				const mediainfo = { description: 'news_image', source_name: curElement.file[0].name, section: section, item_reference: {} }
				let file_data = uploadFileComplete2(domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}

		} else {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.photo = url
					curElement.photo_tracker = tracker
				}
				delete curElement.file
				let newnews_list = [...item_list]
				newnews_list.splice(index, 1, curElement)
				setParentList(newnews_list)
				const return_data = await editNews(curElement.id, curElement)
				setEditedElement(false)
			}
			if (curElement.file) {
				const mypath = 'news/' + section
				const mediainfo = { description: 'news_image', source_name: curElement.file[0].name, section: section, item_reference: {} }
				console.log(mediainfo, curElement.file[0])
				let file_data = uploadFileComplete2(domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
	}


	const handleShowNewNews = () => {
		console.log('show new')
		setIsInvalid(false)
		setCurElement(newnews)
		setShowNewsEdit(true)
		setEditedElement(false)
	}


	const handleShowEditNews = () => {
		if (!item.color) {
			item.color = { ...newnews.color }
		}
		setIsInvalid(false)
		setCurElement(item)
		setShowNewsEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'

		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('news.buttons.news_add')} action_new={handleShowNewNews} action_edit={handleShowEditNews} action_info={handleShowEditNews} />
			<Modal show={showNewsEdit} onHide={handleCloseNews} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{is_new && <Modal.Title className="text-primary">{t('news.labels.new_news')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title className="text-info">{t('news.labels.edit_news')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom pb-1">
							<Form.Label column sm="2">{t('news.labels.title')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="title" value={curElement.title} onChange={(event) => onChangeHandler(event.target)} />
							</Col>
						</Form.Group>
					</Container>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom pb-1">
							<Form.Label column sm="2">{t('news.labels.text')}</Form.Label>
							<Col sm="8">
								<Form.Control as="textarea" rows="5" name="text" value={curElement.text} onChange={(event) => onChangeHandler(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
							<Col>
								<Form.Check type="checkbox" className="ms-2" name="type_list" value="hospital" checked={curElement.type_list && curElement.type_list.indexOf("hospital") !== -1} label={t('contract.labels.type_hospital')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Check type="checkbox" className="ms-2" name="type_list" value="civil" checked={curElement.type_list && curElement.type_list.indexOf("civil") !== -1} label={t('contract.labels.type_civil')} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('news.labels.is_public')}</Form.Label>
							<Col>
								<Form.Check type="radio" name="is_public" value={true} checked={curElement.is_public} label={t('global.labels.yes')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Check type="radio" name="is_public" value={false} checked={!curElement.is_public} label={t('global.labels.no')} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.image')}</Form.Label>
							<Col>
								<Form.Control type="file" name="photo" onChange={(event) => onChangeHandler(event.target)} />
							</Col>
							<Col>
								{curElement.photo
									?
									<>

										<Image style={{ height: '50px' }} src={curElement.photo} fluid />
										<Button variant="outline-danger" onClick={(event) => onChangeHandler({ name: 'photo', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
									</>
									: ''}
							</Col>
						</Form.Group>
						{uploadingState > -1 ?
							<Row>
								<Col>
									<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
								</Col>
							</Row>
							: <></>}

						<Form.Group as={Row} className="border-bottom pb-1">
							<Form.Label column sm="2">{t('news.labels.url')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" rows="5" name="url" value={curElement.url} defaultValue={'http://'}  onChange={(event) => onChangeHandler(event.target)} />
										<Form.Text className="text-muted">
											inserire l'url completo
                                        </Form.Text>
										</Col>
										<Col>
										{curElement.url && curElement.url.length>0?
								<Button size="sm" variant="outline-primary" href={curElement.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" />{t('media.buttons.open')}</Button>
										:''}
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseNews}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" disabled={isInvalid} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveNews}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}




export default translate()(ModalNews)