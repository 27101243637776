import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Spinner, Table, Form, Modal, InputGroup, Card, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import { useParams } from "react-router-dom"


import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"


import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"



import { checkComponentPresence, compareName } from "../util/ui_utils.js"
import { ItemProgress, StructureGroupIcon, ServiceBadge, RiskareaBadge, RoomInfoBadge, ModalInitButton } from '../components/StructureGraphicElements.js'


import { addDefinitionItem, deleteDefinitionItem, getDefinitionItemList, editDefinitionItem } from "../Firebase2/Storage2/dbDefinition"





let load_page = false


////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const CheckDefinitionsView = ({ t, uData }) => {


	const { domain } = useContext(DomainContext)
	const { contract, structureElements, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	let { cid } = useParams();
	let contractId = cid

	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)



	const initValues = async () => {
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('checkparamsdefinitions') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {

			let newelement_list = await getDefinitionItemList(domain, contractId)
			newelement_list.sort(compareName)
			setElementList(newelement_list)
			setLoading(false)
		}
	}




	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("DEFINITIONS reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("DEFINITIONS init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])

	useEffect(() => {
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])



	const handleDelete = (el) => {
		const cb = async () => {
			let return_data = await deleteDefinitionItem(domain, contractId, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('definitions.messages.delete_value_title'), t('definitions.messages.delete_value'), cb)

	}













	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col sm="6">
						<PageTitle title={t('global.pages.checkparamsdefinitions')} />
					</Col>
				</Row>
			</Container>


			<Card className="m-3">
				<Card.Header>
					<Container fluid>
						<Row className="mt-1">
							<Col className="h5 text-primary">
								{t('checks.labels.introduction_and_goals')}
							</Col>
							{canWrite ?
								<Col style={{ textAlign: 'right' }}>
									<ModalGoal is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
								</Col>
								: ''}
						</Row>
					</Container>
				</Card.Header>
				<Card.Body className="p-2">
					<Container fluid>
					{element_list.filter(e => e.category === 'goal').map((i, key) => (
						<Row key={key} className="element_row p-1 border-bottom " >
							<Col >{i.goal}</Col>
							<Col sm="1" className="text-center">
								<ButtonGroup>
									{canWrite ?
										<>
											<ModalGoal is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
											<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
										</>
										: ''}
								</ButtonGroup>
							</Col>
						</Row>
					))}
					</Container>
				</Card.Body>
			</Card>





			<Card className="m-3">
				<Card.Header>
					<Container fluid>
						<Row className="mt-1">
							<Col className="h5 text-primary">
								{t('checks.labels.glossary')}
							</Col>
							{canWrite ?
								<Col style={{ textAlign: 'right' }}>
									<ModalGlossary is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} />
								</Col>
								: ''}
						</Row>
					</Container>
				</Card.Header>
				<Card.Body className="p-2">
					<Container fluid>
						{element_list && element_list.filter(e => e.category === 'glossary').length > 0 ?
							<>
								<Row className="p-1 text-light">
									<Col className="bg-secondary ms-1" sm="3">{t('global.labels.word')}</Col>
									<Col className="bg-secondary ms-1">{t('global.labels.definition')}</Col>
									<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
								</Row>
								{element_list.filter(e => e.category === 'glossary').map((i, key) => (
									<Row key={key} className="element_row p-1 border-bottom " >
										<Col className="font-weight-bold" sm="3">{i.word}</Col>
										<Col >{i.definition}</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												{canWrite ?
													<>
														<ModalGlossary is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
														<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													</>
													: ''}
											</ButtonGroup>
										</Col>
									</Row>
								))}
							</>
							: <>
								<Row>
									<Col className="font-italic">
										{t('checks.messages.no_defitions')}
									</Col>
								</Row>
							</>
						}
					</Container>
				</Card.Body>
			</Card>



			<Card className="m-3">
				<Card.Header>
					<Container fluid>
						<Row className="mt-1">
							<Col className="h5 text-primary">
								{t('checks.labels.global_parameters')}
							</Col>
						</Row>
					</Container>
				</Card.Header>
				{contract ?
					<Card.Body className="p-2">
						{contractHasCheckType(contract, 'results') ?
							<GlobalParamSection t={t} setElementList={setElementList} element_list={element_list} check_type={"results"} canWrite={canWrite} handleDelete={handleDelete}/>
							: 'noresults'}
						{contractHasCheckType(contract, 'process') ?
							<GlobalParamSection t={t} setElementList={setElementList} element_list={element_list} check_type={"process"} canWrite={canWrite} handleDelete={handleDelete}/>
							: 'noprocess'}
						{contract.has_microbiological_check ?
							<GlobalParamSection t={t} setElementList={setElementList} element_list={element_list} check_type={"microbiological"} canWrite={canWrite} handleDelete={handleDelete}/>
							: 'nomicrobio'}
						{contract.has_customer_satisfaction_check ?
							<GlobalParamSection t={t} setElementList={setElementList} element_list={element_list} check_type={"customer_satisfaction"} canWrite={canWrite} handleDelete={handleDelete}/>
							: ''}
					</Card.Body>
					: ''}
			</Card>



			<Footer count_text={''} />
		</>
	);
}

const contractHasCheckType = (contract, check_type) => {
	console.log("ccc", contract, check_type)
	if (contract && contract.autocheck && contract.autocheck.indexOf(check_type) >= 0) {
		return true
	} else if (contract && contract.contradictory && contract.contradictory.indexOf(check_type) >= 0) {
		return true
	} else if (contract && contract.client_checks && contract.client_checks.indexOf(check_type) >= 0) {
		return true
	}
	return false
}






const GlobalParamSection = ({ t, check_type, element_list, setElementList, canWrite, handleDelete }) => {
	const { contract } = useContext(GlobalDataContext)
	const { domain} = useContext(DomainContext)
	const contractId = contract.id




	const check_type_dict = {
		results: { label: t('global.pages.resultscheck') },
		process: { label: t('global.pages.processcheck') },
		microbiological: { label: t('global.pages.microbiologicalcheck') },
		customer_satisfaction: { label: t('global.pages.customer_satisfactioncheck') }
	}



	const check_mode_dict = {
		autocheck: { label: t('contract.labels.autocheck') },
		contradictory: { label: t('contract.labels.contradictory') },
		client_checks: { label: t('contract.labels.client_checks') },
	}

	return (

		<Container fluid>
			<Row>
				<Col className="text-primary">
					{check_type_dict[check_type].label}
				</Col>
				{canWrite ?
					<Col style={{ textAlign: 'right' }}>
						<ModalGlobalParam is_new={true} t={t} item={element_list} setItem={setElementList} item_list={element_list} check_type={check_type} />
					</Col>
					: ''}
			</Row>

			{element_list && element_list.filter(e => e.category === 'global_param' && e.check_type === check_type).length > 0 ?
				<>
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1">{t('global.labels.definition')}</Col>
						<Col className="bg-secondary ms-1">{t('checks.labels.references')}</Col>
						<Col className="bg-secondary ms-1">{t('checks.labels.scope_and_exclusions')}</Col>
						<Col className="bg-secondary ms-1">{t('checks.labels.person_in_change')}</Col>
						{check_type !=='customer_satisfaction' &&
						<Col className="bg-secondary ms-1">{t('global.labels.active_for')}</Col>
				}
						<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					{element_list.filter(e => e.category === 'global_param' && e.check_type === check_type).map((i, key) => (
						<Row key={key} className="element_row p-1 border-bottom " >
							<Col >{i.definition}</Col>
							<Col >{i.references}</Col>
							<Col >{i.scope_and_exclusions}</Col>
							<Col >{i.person_in_charge}</Col>
						{check_type !=='customer_satisfaction' &&
							<Col >{i.check_mode.map((d, dkey) => (
								<Badge key={dkey} variant="info">{check_mode_dict[d].label}</Badge>

							))}</Col>
}
							<Col sm="1" className="text-center">
								<ButtonGroup>
									{canWrite ?
										<>
											<ModalGlobalParam is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} check_type={check_type} canWrite={canWrite}/>
											<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
										</>
										: ''}
								</ButtonGroup>
							</Col>
						</Row>
					))}
				</>
				: <>
					<Row className="border-bottom">
						<Col className="font-italic">
							{t('checks.messages.no_param')}
						</Col>
					</Row>
				</>
			}
		</Container>
	)
}





////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalGlossary = ({ t, is_new, canWrite, item, setItem, item_list }) => {
	const new_element = {
		category: 'glossary',
		definition: '',
		word: ''
	}
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)


	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const [showElementEdit, setShowElementEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curElement, setCurrentElement] = useState(new_element)
	let contractId = contract.id

	const handleCloseElement = () => { setShowElementEdit(false) }
	const handleSaveElement = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addDefinitionItem(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShowElementEdit(false)
			}
		} else {
			let return_data = await editDefinitionItem(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShowElementEdit(false)
			}
		}
	}
	const handleShowNew = () => {
		setCurrentElement(new_element)
		setShowElementEdit(true)
		setEditedElement(false)

	}
	const handleShowEdit = () => {
		setCurrentElement(item)
		setShowElementEdit(true)
		setEditedElement(false)

	}


	const onChangeHandlerElement = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={false} title_new={t('checks.buttons.definitions_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showElementEdit} onHide={handleCloseElement} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('checks.labels.new_definition')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('checks.labels.edit_definition')}</span>
							</>
						}
                        &nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.word')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="word" value={curElement.word} onChange={(event) => onChangeHandlerElement(event.target)} />
								</InputGroup>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.definition')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="definition" value={curElement.definition} onChange={(event) => onChangeHandlerElement(event.target)} />
								</InputGroup>
							</Col>
						</Form.Group>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseElement}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveElement}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>

	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalGoal = ({ t, is_new, canWrite, item, item_list, setItem }) => {
	const new_element = {
		category: 'goal',
		goal: '',
	}
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const [showElementEdit, setShowElementEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curElement, setCurrentElement] = useState(new_element)

	let contractId = contract.id

	const handleCloseElement = () => { setShowElementEdit(false) }
	const handleSaveElement = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addDefinitionItem(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShowElementEdit(false)
			}
		} else {
			let return_data = await editDefinitionItem(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShowElementEdit(false)
			}
		}
	}
	const handleShowNew = () => {
		setCurrentElement(new_element)
		setShowElementEdit(true)
		setEditedElement(false)

	}
	const handleShowEdit = () => {
		setShowElementEdit(true)
		setCurrentElement(item)
		setEditedElement(false)

	}


	const onChangeHandlerElement = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={false} title_new={t('checks.buttons.goal_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showElementEdit} onHide={handleCloseElement} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('checks.labels.new_definition')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> </span>
							</>
						}
                        &nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Col>
								<InputGroup className="mb-2" >
									<Form.Control as="textarea" rows={5} value={curElement.goal} name="goal" onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
								</InputGroup>
							</Col>
						</Form.Group>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseElement}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveElement}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>

	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalGlobalParam = ({ t, is_new, canWrite, item, setItem, item_list, check_type }) => {
	let new_global_param = {
		category: 'global_param',
		check_type: check_type,
		check_mode: [],
		definition: '',
		references: '',
		scope_and_exclusions: '',
		person_in_charge: '',
	}
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const [showElementEdit, setShowElementEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curElement, setCurrentElement] = useState(new_global_param)

	let contractId = contract.id

	const handleCloseElement = () => { setShowElementEdit(false) }
	const handleSaveElement = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addDefinitionItem(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShowElementEdit(false)
			}
		} else {
			let return_data = await editDefinitionItem(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShowElementEdit(false)
			}
		}
	}
	const handleShowNew = () => {
		setCurrentElement(new_global_param)
		setShowElementEdit(true)
		setEditedElement(false)

	}
	const handleShowEdit = () => {
		setCurrentElement(item)
		setShowElementEdit(true)
		setEditedElement(false)

	}


	const onChangeHandlerElement = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={false} title_new={t('checks.buttons.param_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showElementEdit} onHide={handleCloseElement} backdrop="static" className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" /> {t('checks.labels.new_param')}</span>
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('checks.labels.edit_param')}</span>
							</>
						}
                        &nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.definition')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="definition" value={curElement.definition} onChange={(event) => onChangeHandlerElement(event.target)} />
								</InputGroup>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('checks.labels.references')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="references" value={curElement.references} onChange={(event) => onChangeHandlerElement(event.target)} />
								</InputGroup>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('checks.labels.scope_and_exclusions')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="scope_and_exclusions" value={curElement.scope_and_exclusions} onChange={(event) => onChangeHandlerElement(event.target)} />
								</InputGroup>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('checks.labels.person_in_change')}</Form.Label>
							<Col sm="8">
								<InputGroup className="mb-2" >
									<Form.Control type="text" name="person_in_charge" value={curElement.person_in_charge} onChange={(event) => onChangeHandlerElement(event.target)} />
								</InputGroup>
							</Col>
						</Form.Group>
						{check_type !=='customer_satisfaction' &&
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">

							<Form.Label column sm="2">{t('global.labels.active_for')}</Form.Label>

							<Col>
								{contract && contract.autocheck && contract.autocheck.length > 0 ?
									<Form.Check type="checkbox" name="check_mode" value="autocheck" checked={curElement.check_mode.indexOf('autocheck') >= 0} label={t('contract.labels.autocheck')} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
									: ''}
								{contract && contract.contradictory && contract.contradictory.length > 0 ?
									<Form.Check type="checkbox" name="check_mode" value="contradictory" checked={curElement.check_mode.indexOf('contradictory') >= 0} label={t('contract.labels.contradictory')} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
									: ''}
								{contract && contract.client_checks && contract.client_checks.length > 0 ?
									<Form.Check type="checkbox" name="check_mode" value="client_checks" checked={curElement.check_mode.indexOf('client_checks') >= 0} label={t('contract.labels.client_checks')} onChange={(event) => onChangeHandlerElement(event.currentTarget)} />
									: ''}
							</Col>
						</Form.Group>
						}

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseElement}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveElement}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>

	)
}




export default translate()(CheckDefinitionsView)

