import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import DomainContext from '../components/contexts/domainContext'
import LocaleContext from '../components/contexts/locale.js'

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"
import { SearchSection } from "../components/SearchSection"
import ModalProduct from "../components/ModalProduct"
import ModalImportCatalog from '../components/ModalImportCatalog';

import { checkComponentPresence, compareName, getElementPreview, getElementCount, getBaseObjectValue, rgba2hex } from "../util/ui_utils.js"
import { MyBadge, RenderItemFileBadge } from '../components/StructureGraphicElements.js'


import { getProductList as getProductList2, deleteProduct as deleteProduct2 } from '../Firebase2/Storage2/dbWarehouse';
import { getCatalogProductList, deleteCatalogProduct } from '../Firebase2/Storage2/dbCatalogWarehouse';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getDilutionsystemList } from '../Firebase2/Storage2/dbDilutionsystem';



let load_contract = true
const companyId = '0'
const section = 'product'

const ProductsView = ({ t, uData }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)


	const childRefAlert = useRef()
	const childRefDelete = useRef()

	let { cid } = useParams();
	let contractId = cid

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('products') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'product_category')
		const colorcode_list = await getBaseObjectTypeList2(domain, 'product_colorcode')
		const dilution_system_list = await getDilutionsystemList(domain, contractId)
		setUiList({ ...ui_list, brand_list, category_list, dilution_system_list, colorcode_list })
		let newelement_list = []
		if (domain === 'catalog') {
			newelement_list = await getCatalogProductList(contractId, section, null)
		} else {
			newelement_list = await getProductList2(domain, contractId, companyId, section, null)
		}
		console.log(newelement_list)
		newelement_list.sort(compareName)
		setElementList(newelement_list)
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])


	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data
			if (domain === 'catalog') {
				return_data = await deleteCatalogProduct(contractId, section, el.id)
			} else {
				return_data = await deleteProduct2(domain, contractId, companyId, section, el.id)

			}
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('product.messages.delete_product_title'), t('product.messages.delete_product', { name: el.name }), cb)
	}


	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('navs.registries.products')} />
					</Col>
					<Col sm="3">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						{canWrite ?
							<>
								<ModalImportCatalog t={t} section={section} ui_list={ui_list} item_list={element_list} setItem={setElementList} />
								<ModalProduct is_new={true} t={t} item_list={element_list} item={element_list} setItem={setElementList} canWrite={canWrite} ui_list={ui_list} setUiList={setUiList} />
							</>
							: ''}
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" sm="1"></Col>
						<Col className="bg-secondary ms-1" sm="2">{t('products.labels.code')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.name')}</Col>
						<Col className="bg-secondary ms-1">{t('products.labels.brand')}</Col>
						<Col className="bg-secondary ms-1">{t('products.labels.category')}</Col>
						<Col className="bg-secondary ms-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' />&nbsp;<FontAwesomeIcon icon='file' /></Col>
						<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					: <>
						{element_list && element_list.length > 0 ?
							<>
								{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
									<Row key={key} className="p-1 border-bottom" >
										<Col sm="1">{getElementPreview(i)} </Col>
										<Col sm="2">{i.code}</Col>
										<Col>

											{i.catalog_id ?
												<OverlayTrigger
													placement="right"
													delay={{ show: 250, hide: 400 }}
													overlay={renderTooltip}
												>
													<FontAwesomeIcon icon="clipboard-list" />
												</OverlayTrigger>
												: ''
											}
											<b>{i.name}</b>
											{i.intended_use && i.intended_use.indexOf('economic_material') >= 0 ?
												<>
													<OverlayTrigger trigger={['focus', 'hover']} placement="right-start" overlay={<Tooltip>Ricarica dispenser</Tooltip>}>
														<FontAwesomeIcon icon="pump-soap" />
													</OverlayTrigger>
												</>
												: ''}
										</Col>
										<Col>{getBaseObjectValue(i.brand, 'name', locale)}</Col>
										<Col>
											{getBaseObjectValue(i.category, 'name', locale)}
											{i.colorcode && i.colorcode.Id ?
												<MyBadge color={i.colorcode.color } />
												: ''
											}
										</Col>
										<Col sm="1" className="p0 text-center">
											<RenderItemFileBadge media_list={i.media_list} section={section} position="left-start" />
										</Col>
										<Col sm="1" className="text-center">
											<ButtonGroup>
												<ModalProduct is_new={false} t={t} item={i} setItem={setElementList} canWrite={canWrite && !i.catalog_id} item_list={element_list} ui_list={ui_list} setUiList={setUiList} />
												{canWrite ?
													<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
													: ''}
											</ButtonGroup>
										</Col>

									</Row>
								))}
							</>
							:
							<Row>
								<Col className="font-italic">
									{t('products.messages.no_product')}
								</Col>
							</Row>
						}
					</>
				}
			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('navs.registries.products') })} />



		</ >
	);
}



const renderTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Prodotto da catalogo
	</Tooltip>
);


export default translate()(ProductsView)


