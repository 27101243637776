import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, ButtonGroup, Form, Modal, ProgressBar } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { MediaPreview } from "../components/StructureGraphicElements"
import firebase from 'firebase/compat/app'

import { mediaType_dict } from "../components/constants/global_definitions"

import { uploadFileComplete as uploadFileComplete2, editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage';

import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import { ModalDocument } from './modal/ModalDocumentPreview';






function ElementFormMedia({ element, functions, t, section, canWrite, label }) {
	const type_dict = mediaType_dict({t:t, section:section})
	
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)
	const contractId = contract ? contract.id : element.id

	const newmedia = {

		description: '',
		type: 'file',
		category: '',
		preview: ''
	}

	const [showMediaEdit, setShowMediaEdit] = useState(false)
	const [is_new, setIsNew] = useState(true)
	const [curMedia, setCurMedia] = useState(newmedia)
	const [uploadingState, setUploadingState] = useState(-1)
	const handleCloseMedia = () => setShowMediaEdit(false);
	const handleSaveMedia = async () => {
		console.log("START SAVE")

		const cb_set_download = (url, tracker) => {
			console.log(url)
			curMedia.url = url
			curMedia.tracker = tracker
			let newmedia_list = element.media_list
			curMedia.file = ''
			curMedia.section = section
			//					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
			if (newmedia_list) {
				newmedia_list.push(curMedia)
			} else {
				newmedia_list = [curMedia]
			}
			console.log("MEDIALIST", newmedia_list)
			functions.edit({ name: 'media_list', value: newmedia_list })
			setShowMediaEdit(false);
		}

		const cb_progress = (status, byte, total) => {
			console.log(status, byte, total)
			try {
				setUploadingState(100 * byte / total)
			} catch (e) {
				console.warn("handleSaveMedia - error: ", handleSaveMedia)
			}
		}


		if (is_new) {
			console.log(curMedia)
			if (curMedia.type !== 'link') {

				if (curMedia.file) {

					console.log(curMedia.file)
					const mypath = contractId + '/' + section
					console.log(mypath)

					if (element.id) {
						const mediainfo = { upload_date: firebase.firestore.Timestamp.now(),  type: curMedia.type, description: curMedia.description, source_name: curMedia.file[0].name, section: section, item_reference: { id: element.id, name: element.name, category: element.category || '' } }
						let file_data = uploadFileComplete2(domain, contractId, mypath, curMedia.file[0], curMedia.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_set_download })
						console.log('file_data', file_data)

					} else {
						const mediainfo = { upload_date: firebase.firestore.Timestamp.now(), type: curMedia.type, description: curMedia.description, source_name: curMedia.file[0].name, section: section }
						const file_data = uploadFileComplete2(domain, contractId, mypath, curMedia.file[0], curMedia.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_set_download })
						console.log('file_data', file_data)

					}
					curMedia.file = ''
					curMedia.path = mypath

				}
			} else {
				let newmedia_list = element.media_list
				curMedia.file = ''
				curMedia.section = section
				//					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
				if (newmedia_list) {
					newmedia_list.push(curMedia)
				} else {
					newmedia_list = [curMedia]
				}
				console.log("MEDIALIST", newmedia_list)
				functions.edit({ name: 'media_list', value: newmedia_list })
				setShowMediaEdit(false);

			}
		} else {
			if (curMedia.type !== 'link') {

				const tracker_data = { ...curMedia.tracker, item_reference: { id: element.id, name: element.name, category: element.category || '' } }
				const ret_data = await editFileTracker2(domain, contractId, curMedia.tracker.id, tracker_data)
				if (ret_data.error) {

				} else {

					let newmedia_list = [...element.media_list]
					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
					console.log(curMedia)
					newmedia_list.splice(curMedia.index, 1, curMedia)
					console.log(newmedia_list)
					functions.edit({ name: 'media_list', value: newmedia_list })
					setShowMediaEdit(false);
				}
			} else {
				let newmedia_list = [...element.media_list]
				curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
				console.log(curMedia)
				newmedia_list.splice(curMedia.index, 1, curMedia)
				console.log(newmedia_list)
				functions.edit({ name: 'media_list', value: newmedia_list })
				setShowMediaEdit(false);

			}
		}
	}


	const handleDeleteMedia = (index) => {
		let newmedia_list = element.media_list
		newmedia_list.splice(index, 1)
		functions.edit({ name: 'media_list', value: newmedia_list })

	}
	const handleShowNewMedia = () => {
		setCurMedia(newmedia)
		setIsNew(true)
		setShowMediaEdit(true)
		setUploadingState(-1)
	}
	const handleShowEditMedia = (i, docindex) => {
		console.log(element.media_list)
		i.index = docindex
		console.log(i)
		setCurMedia(i)
		setIsNew(false)
		setShowMediaEdit(true)
		setUploadingState(-1)
	}

	const onChangeHandlerMedia = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'
		}
		if (name !== undefined) {
			console.log(name, value)
			if (curMedia.description.length === 0 && curMedia.type !== 'link' && el.files) {
				setCurMedia({ ...curMedia, description: el.files[0].name, file: value })
			}else{
				setCurMedia({ ...curMedia, [name]: value })
			}
		}
	}
	/* 	const getDownloadFile = (path, key) => {
			downloadFile(path, (url) => { setMediaPreview({ ...mediaPreview, [key]: url }) })
		} */


	const getFileLabel = (i_type) => {
		try {
			return type_dict[i_type].label
		} catch (err) {
			return i_type
		}
	}


	return (
		<>
			<Form.Group as={Row} controlId="formProductMedia" className="border-bottom">
				<Col>
					<Row className="mb-1">
						{canWrite &&
							<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewMedia()}><FontAwesomeIcon icon="plus-square" />{label? label : "Aggiungi documento/immagine"} </Button></Col>
						}
					</Row>
					{element.media_list && element.media_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col sm="2" className="bg-secondary text-light ms-1">{t('media.labels.preview')} </Col>
								<Col sm="2" className="bg-secondary text-light ms-1">{t('global.labels.type')} </Col>
								<Col className="bg-secondary text-light ms-1">{t('global.labels.description')} </Col>
								<Col sm="2" className="bg-secondary text-light ms-1">{t('global.labels.actions')}</Col>
							</Row>
							{element.media_list.map((i, key) => (
								<Row key={key} className="border-bottom mb-1 pb-1">
									{i.type !== 'link' ?
										<Col sm="2">
											<MediaPreview i={i} />
											<ModalDocument t={t} photo={i.url} name={i.tracker && i.tracker.source_name} />
											</Col>
										:
										<Col sm="2">
											
										</Col>
									}
									<Col sm="2" className="text-center"><FontAwesomeIcon icon={i.type} /> {getFileLabel(i.type)}</Col>
									<Col>{i.description}</Col>
									<Col sm="2" className="text-center p-1">
										<ButtonGroup>
											<Button size="sm" variant="outline-primary" href={i.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" />{t('media.buttons.open')}</Button>
											<Button size="sm" variant="info" onClick={() => handleShowEditMedia(i, key)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
											{canWrite ?
												<Button size="sm" variant="danger" onClick={() => handleDeleteMedia(key)}><FontAwesomeIcon icon="trash" /> </Button>
												: ''}
										</ButtonGroup>
									</Col>
								</Row>))
							}
						</>
						: <> {t('products.messages.no_media')}</>
					}
				</Col>
			</Form.Group>




			<Modal show={showMediaEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header >
					{is_new && <Modal.Title>{t('products.modal.titlenewmedia')} </Modal.Title>}
					{!is_new && <Modal.Title>{t('products.modal.titleeditmedia')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.title')}</Form.Label>
							<Col>
								<Form.Control type="text" className="mb-2" value={curMedia.description} name="description" onChange={(event) => onChangeHandlerMedia(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
							<Col>
								<Form.Control as="select" value={curMedia.type} name="status" className="mb-2" onChange={(event) => onChangeHandlerMedia({ name: 'type', type: 'select', value: event.target.value })}  >

									{Object.keys(type_dict).map((td, k) => (
										<option key={k} value={type_dict[td].value}>{type_dict[td].label} </option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curMedia.type === 'link' ?
							<>
								<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
									<Form.Label column sm="2">{t('global.labels.url')}</Form.Label>
									<Col>
										<Form.Control type="text" className="mb-2" defaultValue={'http://'} value={curMedia.url} name="url" onChange={(event) => onChangeHandlerMedia(event.target)} />
										<Form.Text className="text-muted">
											inserire l'url completo
										</Form.Text>
									</Col>
								</Form.Group>
							</>



							: <>
								{is_new ?
									<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
										<Form.Label column sm="2">{t('global.labels.media_file')}</Form.Label>
										<Col>
											<Form.Control type="file" name="file" onChange={(event) => onChangeHandlerMedia(event.target)} />
										</Col>
									</Form.Group>
									:
									<Row>
										<Col sm="2">
											{t('global.labels.media_file')}
										</Col>
										<Col>
											{curMedia.tracker.source_name}
										</Col>
									</Row>
								}
								{uploadingState > -1 ?
									<Row>
										<Col>
											<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
										</Col>
									</Row>
									: <></>}
							</>}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMedia}> {t('modal.close')}</Button>
					{canWrite ?
						<Button className="btn-myprimary" form="mediaForm" onClick={handleSaveMedia}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


export function ElementDomainFormMedia({ element, functions, t, section, canWrite, label }) {
	const type_dict = mediaType_dict({t:t, section:section})
	
	const { domain } = useContext(DomainContext)

	const newmedia = {

		description: '',
		type: 'file',
		category: '',
		preview: ''
	}

	const [showMediaEdit, setShowMediaEdit] = useState(false)
	const [is_new, setIsNew] = useState(true)
	const [curMedia, setCurMedia] = useState(newmedia)
	const [uploadingState, setUploadingState] = useState(-1)
	const handleCloseMedia = () => setShowMediaEdit(false);
	const handleSaveMedia = async () => {
		console.log("START SAVE")

		const cb_set_download = (url, tracker) => {
			console.log(url)
			curMedia.url = url
			curMedia.tracker = tracker
			let newmedia_list = element.media_list
			curMedia.file = ''
			curMedia.section = section
			//					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
			if (newmedia_list) {
				newmedia_list.push(curMedia)
			} else {
				newmedia_list = [curMedia]
			}
			console.log("MEDIALIST", newmedia_list)
			functions.edit({ name: 'media_list', value: newmedia_list })
			setShowMediaEdit(false);
		}

		const cb_progress = (status, byte, total) => {
			console.log(status, byte, total)
			try {
				setUploadingState(100 * byte / total)
			} catch (e) {
				console.warn("handleSaveMedia - error: ", handleSaveMedia)
			}
		}


		if (is_new) {
			console.log(curMedia)
			if (curMedia.type !== 'link') {

				if (curMedia.file) {

					console.log(curMedia.file)
					const mypath = section
					console.log(mypath)

					if (element.id) {
						const mediainfo = { upload_date: firebase.firestore.Timestamp.now(),  type: curMedia.type, description: curMedia.description, source_name: curMedia.file[0].name, section: section, item_reference: { id: element.id, name: element.name, category: element.category || '' } }
						let file_data = uploadFileComplete2(domain, undefined, mypath, curMedia.file[0], curMedia.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_set_download })
						console.log('file_data', file_data)

					} else {
						const mediainfo = { upload_date: firebase.firestore.Timestamp.now(), type: curMedia.type, description: curMedia.description, source_name: curMedia.file[0].name, section: section }
						const file_data = uploadFileComplete2(domain, undefined, mypath, curMedia.file[0], curMedia.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_set_download })
						console.log('file_data', file_data)

					}
					curMedia.file = ''
					curMedia.path = mypath

				}
			} else {
				let newmedia_list = element.media_list
				curMedia.file = ''
				curMedia.section = section
				//					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
				if (newmedia_list) {
					newmedia_list.push(curMedia)
				} else {
					newmedia_list = [curMedia]
				}
				console.log("MEDIALIST", newmedia_list)
				functions.edit({ name: 'media_list', value: newmedia_list })
				setShowMediaEdit(false);

			}
		} else {
			if (curMedia.type !== 'link') {

				const tracker_data = { ...curMedia.tracker, item_reference: { id: element.id, name: element.name, category: element.category || '' } }
				const ret_data = await editFileTracker2(domain, undefined, curMedia.tracker.id, tracker_data)
				if (ret_data.error) {

				} else {

					let newmedia_list = [...element.media_list]
					curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
					console.log(curMedia)
					newmedia_list.splice(curMedia.index, 1, curMedia)
					console.log(newmedia_list)
					functions.edit({ name: 'media_list', value: newmedia_list })
					setShowMediaEdit(false);
				}
			} else {
				let newmedia_list = [...element.media_list]
				curMedia.item_reference = { id: element.id, name: element.name, category: element.category || '' }
				console.log(curMedia)
				newmedia_list.splice(curMedia.index, 1, curMedia)
				console.log(newmedia_list)
				functions.edit({ name: 'media_list', value: newmedia_list })
				setShowMediaEdit(false);

			}
		}
	}


	const handleDeleteMedia = (index) => {
		let newmedia_list = element.media_list
		newmedia_list.splice(index, 1)
		functions.edit({ name: 'media_list', value: newmedia_list })

	}
	const handleShowNewMedia = () => {
		setCurMedia(newmedia)
		setIsNew(true)
		setShowMediaEdit(true)
		setUploadingState(-1)
	}
	const handleShowEditMedia = (i, docindex) => {
		console.log(element.media_list)
		i.index = docindex
		console.log(i)
		setCurMedia(i)
		setIsNew(false)
		setShowMediaEdit(true)
		setUploadingState(-1)
	}

	const onChangeHandlerMedia = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'
		}
		if (name !== undefined) {
			console.log(name, value)
			if (curMedia.description.length === 0 && curMedia.type !== 'link' && el.files) {
				setCurMedia({ ...curMedia, description: el.files[0].name, file: value })
			}else{
				setCurMedia({ ...curMedia, [name]: value })
			}
		}
	}
	/* 	const getDownloadFile = (path, key) => {
			downloadFile(path, (url) => { setMediaPreview({ ...mediaPreview, [key]: url }) })
		} */


	const getFileLabel = (i_type) => {
		try {
			return type_dict[i_type].label
		} catch (err) {
			return i_type
		}
	}


	return (
		<>
			<Form.Group as={Row} controlId="formProductMedia" className="border-bottom">
				<Col>
					<Row className="mb-1">
						{canWrite &&
							<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewMedia()}><FontAwesomeIcon icon="plus-square" />{label? label : "Aggiungi documento/immagine"} </Button></Col>
						}
					</Row>
					{element.media_list && element.media_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col sm="2" className="bg-secondary text-light ms-1">{t('media.labels.preview')} </Col>
								<Col sm="2" className="bg-secondary text-light ms-1">{t('global.labels.type')} </Col>
								<Col className="bg-secondary text-light ms-1">{t('global.labels.description')} </Col>
								<Col sm="2" className="bg-secondary text-light ms-1">{t('global.labels.actions')}</Col>
							</Row>
							{element.media_list.map((i, key) => (
								<Row key={key} className="border-bottom mb-1 pb-1">
									{i.type !== 'link' ?
										<Col sm="2">
											<MediaPreview i={i} />
											<ModalDocument t={t} photo={i.url} name={i.tracker && i.tracker.source_name} />
											</Col>
										:
										<Col sm="2">
											
										</Col>
									}
									<Col sm="2" className="text-center"><FontAwesomeIcon icon={i.type} /> {getFileLabel(i.type)}</Col>
									<Col>{i.description}</Col>
									<Col sm="2" className="text-center p-1">
										<ButtonGroup>
											<Button size="sm" variant="outline-primary" href={i.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" />{t('media.buttons.open')}</Button>
											<Button size="sm" variant="info" onClick={() => handleShowEditMedia(i, key)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
											{canWrite ?
												<Button size="sm" variant="danger" onClick={() => handleDeleteMedia(key)}><FontAwesomeIcon icon="trash" /> </Button>
												: ''}
										</ButtonGroup>
									</Col>
								</Row>))
							}
						</>
						: <> {t('products.messages.no_media')}</>
					}
				</Col>
			</Form.Group>




			<Modal show={showMediaEdit} className="border-primary bg-secondary" size="lg">
				<Modal.Header >
					{is_new && <Modal.Title>{t('products.modal.titlenewmedia')} </Modal.Title>}
					{!is_new && <Modal.Title>{t('products.modal.titleeditmedia')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.title')}</Form.Label>
							<Col>
								<Form.Control type="text" className="mb-2" value={curMedia.description} name="description" onChange={(event) => onChangeHandlerMedia(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
							<Col>
								<Form.Control as="select" value={curMedia.type} name="status" className="mb-2" onChange={(event) => onChangeHandlerMedia({ name: 'type', type: 'select', value: event.target.value })}  >

									{Object.keys(type_dict).map((td, k) => (
										<option key={k} value={type_dict[td].value}>{type_dict[td].label} </option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curMedia.type === 'link' ?
							<>
								<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
									<Form.Label column sm="2">{t('global.labels.url')}</Form.Label>
									<Col>
										<Form.Control type="text" className="mb-2" defaultValue={'http://'} value={curMedia.url} name="url" onChange={(event) => onChangeHandlerMedia(event.target)} />
										<Form.Text className="text-muted">
											inserire l'url completo
										</Form.Text>
									</Col>
								</Form.Group>
							</>



							: <>
								{is_new ?
									<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
										<Form.Label column sm="2">{t('global.labels.media_file')}</Form.Label>
										<Col>
											<Form.Control type="file" name="file" onChange={(event) => onChangeHandlerMedia(event.target)} />
										</Col>
									</Form.Group>
									:
									<Row>
										<Col sm="2">
											{t('global.labels.media_file')}
										</Col>
										<Col>
											{curMedia.tracker.source_name}
										</Col>
									</Row>
								}
								{uploadingState > -1 ?
									<Row>
										<Col>
											<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
										</Col>
									</Row>
									: <></>}
							</>}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseMedia}> {t('modal.close')}</Button>
					{canWrite ?
						<Button className="btn-myprimary" form="mediaForm" onClick={handleSaveMedia}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


export default translate()(ElementFormMedia)