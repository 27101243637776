import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Image, Row, Col, Button, ButtonGroup, Spinner, Card, Accordion } from 'react-bootstrap';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'
import { useTranslate } from 'react-polyglot';
import { format } from 'date-fns'

import GlobalDataContext from '../components/contexts/globaldataContext.js';
import DomainContext from '../components/contexts/domainContext'

import AlertSection from "../components/AlertSection.js"
import PageTitle from "../components/PageTitle"



import ElementFormMedia from "../components/FormMedia.js"
import Footer from "../components/Footer"

import { checkComponentPresence, myTimestampToDate, getValue } from "../util/ui_utils.js"




import { getProductList as getProductList2, getProductById as getProductById2 } from '../Firebase2/Storage2/dbWarehouse';
import { getDpiList as getDpiList2, getDpiById as getDpiById2 } from '../Firebase2/Storage2/dbDpi';



//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'
let type_dict = {
    'file': { value: 'file', label: 'global.labels.document', icon: "file" },
    'image': { value: 'image', label: 'global.labels.image', icon: "image" },
    'data_sheet': { value: 'data_sheet', label: 'global.labels.data_sheet', icon: "file" },
    'manual': { value: 'manual', label: 'global.labels.manual', icon: "file" },
    'certification': { value: 'certification', label: 'global.labels.certification', icon: "file" },
    'security_sheet': { value: 'security_sheet', label: 'global.labels.security_sheet', icon: "file" },
    'administrative': { value: 'administrative', label: 'global.labels.administrative', icon: "file" }

}
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const DocumentsView = ({ t, uData }) => {

    const { locale } = useContext(LocaleContext)
    const { domain } = useContext(DomainContext)
    const { contract, contractId, structureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict } = useContext(GlobalDataContext)

    const childRefAlert = useRef()
    const [loading, setLoading] = useState(true)
    const [docList, setDocList] = useState([])

    const [showEdit, setShow] = useState(false);
    const [newEdit, setNew] = useState(true);
    const [curElement, setCurrentElement] = useState(sessionStorage.getItem('contract'))
    const [element_list, setElementList] = useState({})
    const [viewMode, setViewMode] = useState('groupElement')
    const [canWrite, setWritePage] = useState(false)

    const [doc_count, setDocCount] = useState(0)

    const handleShowNew = () => {
        setShow(true)
    }

    const handleClose = () => setShow(false);



    const checkMediaList = (media_list, doc_id) => {
        for (let a = 0; a < media_list.length; a++) {
            if (media_list[a].tracker && media_list[a].tracker.id === doc_id) {
                return media_list[a]
            }
        }
        console.log("---______>noooot PRESENT")
        return false
    }

    const section_list = [
        { type: "dpi", href: "/dpi", id: "dpi", icon: '', label: t('navs.registries.dpi'), disabled: false, doc_list: [], not_image_count: 0 },
        { type: "storage", href: "/machines", id: "machine", icon: '', label: t('navs.registries.machines'), disabled: false, doc_list: [], not_image_count: 0 },
        { type: "storage", href: "/carts", id: "cart", icon: '', label: t('navs.registries.carts'), disabled: false, doc_list: [], not_image_count: 0 },
        { type: "storage", href: "/equipments", id: "equipment", icon: '', label: t('navs.registries.equipments'), disabled: false, doc_list: [], not_image_count: 0 },
        { type: "storage", href: "/products", id: "product", icon: '', label: t('navs.registries.products'), disabled: false, doc_list: [], not_image_count: 0 },
        { type: "storage", href: "/cleaningmaterials", id: "cleaningmaterial", icon: '', label: t('navs.registries.cleaningmaterials'), disabled: false, doc_list: [], not_image_count: 0 }
    ]

    const initValues = async () => {
        //document_count = 0
        if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('documentsstorage') !== -1)) {
            setWritePage(true)
        }
        setLoading(true)
        let temp_element_list = {}
        let cur_section = -1
        let document_count = 0
        if (contractId && domain.length > 0) {

            for (let s = 0; s < section_list.length; s++) {
                let newelement_list = []
                if (section_list[s].type === 'storage') {
                    newelement_list = await getProductList2(domain, contractId, companyId, section_list[s].id, null)
                } else {
                    newelement_list = await getDpiList2(domain, contractId, companyId, 'dpi', null)
                }
                console.log(newelement_list)
                let newfilter_element_list = newelement_list.filter(e => e.media_list)
                newfilter_element_list.map(e => {
                    if (e.media_list && e.media_list.length > 0) {

                        e.media_list.map(f => {

                            section_list[s].doc_list.push(f)
                            if (f.type !== 'image') {
                                section_list[s].not_image_count += 1
                                document_count += 1
                            }
                            return true
                        })
                    }
                    return true
                })
                section_list[s].element_list = newfilter_element_list

            }

            //        const v = await Promise.all(promises)
            //console.log("t ",v,"a", temp_element_list) 
            //        setElementList(v)
            console.log("-------sectionlist", section_list, document_count)
            setDocCount(document_count)
            setDocList(section_list)
            setLoading(false)
        }
    }


    const onChangeHandler = async (el) => {
    }


    useEffect(() => {
        initValues()
    }, [])

    return (
        <>
            <AlertSection ref={childRefAlert} />
            <Container className="titleStickyTop" fluid>
                <Row className="mt-1">
                    <Col>
                        <PageTitle title={t('global.pages.storagedocument_management')} />
                    </Col>
                    <Col sm="4">
                    </Col>
                    {/*                     <Col>
                        <ButtonGroup>
                            <Button size="sm" variant={viewMode==='groupElement'? 'secondary': 'outline-secondary'} onClick={() => setViewMode('groupElement')}>{t('documents.buttons.group_element')}</Button>
                            <Button size="sm" variant={viewMode==='singleFile'? 'secondary': 'outline-secondary'} onClick={() => setViewMode('singleFile')}>{t('documents.buttons.view_element')}</Button>
                        </ButtonGroup>
                    </Col> */}
                </Row>

            </Container>
            <Container fluid>
                {loading ?
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col>
                            {Object.keys(docList).map((section, index) => (
                                <OneSection key={index} index={index} section={section} docList={docList[section]} t={t} element_list={element_list} viewMode={viewMode} />
                            ))}
                        </Col>
                    </Row>
                }
            </Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, document_count: doc_count })} />




            <Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
                <Modal.Header closeButton  >
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ElementFormMedia element={curElement} t={t} functions={{ edit: onChangeHandler }} section='contract' />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneSection = ({ docList, section, index, t, viewMode, element_list }) => {
    // console.log("section element list ", docList, element_list)
    const { locale } = useContext(LocaleContext)

    let category_list = []
    let f_count = docList.doc_list.filter((f => f.type !== 'image')).length

    for (let d = 0; d < docList.doc_list.length; d++) {
        if (docList.doc_list[d].item_reference && docList.doc_list[d].item_reference.category && checkComponentPresence(docList.doc_list[d].item_reference.category, category_list, 'Id') === -1) {
            category_list.push(docList.doc_list[d].item_reference.category)
        }
    }

    const [fileCount, setFileCount] = useState(docList.not_image_count)

    const [categoryList, setCategoryList] = useState(category_list)
    const [filterCategoryList, setFilterCategoryList] = useState([])
    const [showAdvance, setShowAdvance] = useState(false)

    const toggleShow = () => {
        setShowAdvance(s => !s)
    }

    const updateCategoryFilter = (cat) => {
        const c = checkComponentPresence(cat, filterCategoryList, 'Id')
        console.log('index', c)
        if (c === -1) {
            setFilterCategoryList([...filterCategoryList, cat])
        } else {
            let editFilterList = [...filterCategoryList]
            editFilterList.splice(c, 1)
            setFilterCategoryList(editFilterList)
        }
    }

    const clearCategoryFilter = () => {
        setFilterCategoryList([])
    }

    return (

        <Card key={index}>
            <Card.Header onClick={toggleShow} style={{cursor: 'pointer'}}>
                <span className="h3">{docList.label}</span> <Badge bg="secondary">{fileCount} {t('global.labels.documents')}</Badge> &nbsp;<Badge bg="info">{docList.element_list.length} {t('global.labels.goods')}</Badge>
            </Card.Header>
            {showAdvance &&
            <Card.Body>
                {categoryList.length > 0 ?
                    <Row className="mb-1">
                        <Col>
                            <ButtonGroup aria-label="filter group">
                                <Button size="sm" variant='info' disabled><FontAwesomeIcon icon="filter" /></Button>
                                {categoryList.map((c, k) => (

                                    <Button size="sm" variant={checkComponentPresence(c, filterCategoryList, 'Id') === -1 ? 'outline-info' : "info"} onClick={() => updateCategoryFilter(c)}>{getValue(c.name, locale)}</Button>
                                ))}
                                <Button size="sm" variant='outline-info' onClick={() => clearCategoryFilter()}><FontAwesomeIcon icon="times" /></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    : ''
                }
                <Row>
                    {console.log(viewMode, section, element_list, element_list)}
                    {viewMode === 'groupElement' && (section !== 'contract') && docList.element_list ?
                        <>
                            {Object.keys(docList.element_list).map((elem, e_index) => (
                                <>
                                    {filterCategoryList.length === 0 || (docList.element_list[elem].category && checkComponentPresence(docList.element_list[elem].category, filterCategoryList) > -1) ?
                                        <OneElement element={docList.element_list[elem]} key={e_index} />
                                        : ''}
                                </>

                            ))}

                        </>
                        :
                        <>
                            {docList.doc_list.map((doc, d_index) => (
                                ((section === 'contract' && doc.type === 'image') ?
                                    <>
                                    </>
                                    :
                                    (filterCategoryList.length === 0 || (doc.item_reference && doc.item_reference.category && checkComponentPresence(doc.item_reference.category, filterCategoryList) > -1) ?
                                        <OneFile doc={doc} key={d_index} />
                                        : ''
                                    )
                                )
                            ))}
                        </>
                    }
                </Row>
            </Card.Body>
            }
        </Card>
    )

}

////////////////////////////////////////////////////////////////////////////////////////////////////////
const getElementCount = ({ document_count, t }) => {
    console.log("COUNT TOTAL DOC", document_count)
    return (<>{document_count} {t('global.labels.documents')}</>)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneElement = ({ element }) => {
    const { locale } = useContext(LocaleContext)
    const t = useTranslate();
    //console.log('ELEMENT', element)
    const getBrandName = (brand) => {
        try {
            return brand.name[locale]
        } catch (err) {
            return ''
        }
    }
    return (
        <Col sm="12" md="6" xl="4" className="mb-2">
            <Card border="primary" className="h-100">
                <Card.Header><b>{element.name}</b>&nbsp; {getBrandName(element.brand)}</Card.Header>
                <Card.Body>
                    <Card.Title></Card.Title>
                    <Row>
                        <Col sm="3" xl="2">
                            {element.media_list.filter(f => f.type === 'image').map((e, k) => (
                                <OneImage doc={e} key={k} />
                            ))}
                        </Col>
                        <Col>
                            <Row className="mb-1 section_title" >
                                <Col className="bg-light text-secondary ms-1">{t('global.labels.description')} </Col>
                                <Col className="bg-light text-secondary ms-1">{t('global.labels.type')}</Col>
                                <Col className="bg-light text-secondary ms-1" sm="2"></Col>
                            </Row>
                            {element.media_list.filter(f => f.type !== 'image').map((e, k) => (
                                <OneDocument doc={e} key={k} />
                            ))}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <i>{element.media_list.filter(f => f.type !== 'image').length} {element.media_list.filter(f => f.type !== 'image').length === 1 ? 'documento' : 'documenti'}</i>
                </Card.Footer>
            </Card>
        </Col>
    )

}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneImage = ({ doc }) => {
    return (
        <Image className="imageDocumentViewerSmall" src={doc.url} />
    )
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneDocument = ({ doc }) => {
    const t = useTranslate();
    const getLabel = (doc) => {
        try {
            return t(type_dict[doc.type].label)
        } catch (err) {
            return doc.type
        }
    }
    return (

        <Row className="border-bottom mb-1 pb-1">
            <Col>
                {doc.description.length > 0 ? doc.description : <i>doc.source_name</i>}
            </Col>
            <Col>
                {getLabel(doc)}
            </Col>
            <Col sm="2" className="p-1 text-center">
                <Button size="sm" className="me-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
            </Col>
        </Row>

    )
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneFile = ({ doc }) => {
    const { locale } = useContext(LocaleContext)
    const t = useTranslate();
    const getLabel = (doc) => {
        try {
            return t(type_dict[doc.type].label)
        } catch (err) {
            return doc.type
        }
    }
    const FileIcon = ({ doc }) => {
        if (type_dict[doc.type].icon !== null) {
            return (
                <FontAwesomeIcon icon={type_dict[doc.type].icon} />
            )
        } else {
            return (
                ''
            )
        }

    }
    return (
        <Col sm="6" md="4" xl="2" className="mb-2">
            <Card border="primary" className="h-100">
                {doc.type === 'image' ?
                    <span className="d-flex" style={{ maxHeight: '150px' }}>
                        <Image className="imageDocumentViewer" src={doc.url} />
                    </span>
                    : <Card.Header> {doc.description.length > 0 ? doc.description : <i>{doc.source_name}</i>}</Card.Header>
                }
                <Card.Body>
                    <Card.Title></Card.Title>
                    <h3>{doc.name}</h3>
                    <Row>
                        <Col className="text-secondary">{t('documents.labels.' + doc.section)}: {doc.item_reference && doc.item_reference.category ? <>{getValue(doc.item_reference.category.name, locale)}</> : ''}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {doc.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/*  <FileIcon doc={doc} />  */}
                            {getLabel(doc)}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>
                            <small className="text-muted">data inserimento: {doc.insered_at ? format(myTimestampToDate(doc.insered_at), 'dd/MM/yyyy HH:mm') : "non disponibile"}</small>
                        </Col>
                        <Col className="text-end" sm="4">
                            <Button size="sm" className="me-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>


    )

}


export default translate()(DocumentsView)