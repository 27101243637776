import React, {  useState,  useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  InputGroup, Container, FormControl, Row, Col,  Button, ButtonGroup,  Spinner, Image } from 'react-bootstrap';
//import DateTime from 'react-datetime';
//import DateTime from '@nateradebaugh/react-datetime';
import { translate } from 'react-polyglot'
import { format, subDays } from 'date-fns'
//import CurrencyInput from 'react-currency-input-field';
//import "@nateradebaugh/react-datetime/scss/styles.scss";
import { compareName,  myTimestampToDate, checkComponentPresence } from "../util/ui_utils.js"

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"
import ModalNews from '../components/ModalNews'


import { getNewsList, deleteNews } from '../Firebase2/Storage2/dbNews';







let query_cursor = undefined


const NewsView = ({ mylocation, t  }) => {

	const [loading, setLoading] = useState(false)
	const [element_list, setElementList] = useState([])
	const [searchTerm, setSearchTerm] = useState('')

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const handleChangeSearch = (s) => {
		console.log(s)
		if (s.value.length > 0) {
			setSearchTerm(s.value)
		} else {
			setSearchTerm('')

		}
	}

	const [ui_list, setUiList] = useState({})


	const initValues = async () => {
		setLoading(true)
		let newlist = await getNewsList({limit:40})
		setElementList(newlist.newsList)
		setLoading(false)
	}

	useEffect(() => {
		initValues()
	}, [])

	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data =await deleteNews(el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('news.messages.delete_news_title'), t('news.messages.delete_news', { name: el.title }), cb)
	}


	return (
		<div>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col className="h3">
						<PageTitle title={t('global.pages.news')}  admin={false}/>
					</Col>
									<Col className="text-center">
										<ButtonGroup>
											<Button variant="outline-secondary" disabled><FontAwesomeIcon icon="arrow-left" /></Button>
											<Button variant="outline-secondary" disabled><FontAwesomeIcon icon="arrow-right" /></Button>
										</ButtonGroup>
									</Col>
					<Col sm={4}>
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalNews is_new={true} t={t} item={element_list} setItem={setElementList} ui_list={ui_list} canWrite={true}/>
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
						<Row className="p-1 text-light">
							<Col className="bg-secondary ms-1" sm="2">{t('news.labels.title')}</Col>
							<Col className="bg-secondary ms-1">{t('news.labels.text')}</Col>
							<Col className="bg-secondary ms-1" sm="2">{t('news.labels.createdAt')}</Col>
							<Col className="bg-secondary ms-1 text-center" sm="2" md="1">{t('global.labels.actions')}</Col>
						</Row>
				:''}

			</Container>
			<Container fluid>
				{loading ? <Spinner animation="border" role="status">
					<span className="sr-only">Loading...</span>
				</Spinner> : ''}
				{element_list && element_list.length > 0 ?
					<>
						{element_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0))).map((i, key) => (
							<Row key={key} className={"p-1 border-bottom" + (i.is_public ? '': ' text-warning')}>
								<Col sm="2">
									{i.title}
								</Col>
								<Col>
									{i.text}
								</Col>
								<Col>
									{i.logo && i.logo.length>0
									?
									<Image style={{ height: '50px' }} src={i.logo} fluid  className="me-1" />
									:''}
									{i.name}
								</Col>
										<Col sm="2">
											{format(myTimestampToDate(i.createdAt), 'dd/MM/yyyy')}
										</Col>
								<Col className="text-center" sm="2" md="1">
											<ButtonGroup>
									<ModalNews t={t} item={i} setItem={setElementList} ui_list={ui_list} item_list={element_list} setParentList={setElementList} index={key} canWrite={true}/>
									<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
											</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <> {t('news.messages.no_news')}
					</>
				}
			</Container>
		</div>
	);
}


export default translate()(NewsView)


