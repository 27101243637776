import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner, ButtonGroup } from 'react-bootstrap';

import QRCode from 'qrcode.react'
import { useReactToPrint } from 'react-to-print';

import EditContext from "../contexts/edit.js"
import DomainContext from '../contexts/domainContext.js';
import LocaleContext from '../contexts/locale.js'
import GlobalDataContext from '../contexts/globaldataContext.js';


import { getBaseObjectValue } from "../../util/ui_utils.js"

import AlertSection from "../AlertSection.js"
import { RoomInfoBadge } from '../StructureGraphicElements.js';



let load_page = false
const popoverTiming = { show: 250, hide: 100 }
let curdate = { start: new Date(), end: new Date() }


let department_dict = {}

let permission_department_list = []
let permission_structure_list = []


let filterAppliedList = { department: [], type: [], is_deleted: false }
const defaultFilter = { department: [], type: [] }


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalUnassignedRoom = ({ item, structure, workarea, canWrite, t, item_list, setItem, year, month, uData }) => {
	const { contract, structureElements, workarea_list } = useContext(GlobalDataContext)

	const edit = useContext(EditContext)
	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)

	const componentRef = useRef();
	const footerRef = useRef();
	const childRefAlert = useRef()

	const [showPlanEdit, setShowPrePrint] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curStructure, setCurStructure] = useState()
	const [event_list, setEventList] = useState([])
	const [full_event_list, setFullEventList] = useState([])
	const [viewRoomList, setViewRoomList] = useState([])
	const [viewRoomSummary, setViewRoomSummary] = useState([])
	const [printParams, setPrintParam] = useState([])
	const [showZ, setShowZ] = useState(false)
	const [curMonthValidate, setCurMonthValidate] = useState({})
	const [filtered_room_list, setFilteredRoomList] = useState([])




	const handleShowPrePrint = async () => {
		const f_list = Object.values(structureElements.all_room_dict).filter(r => workarea_list.filter(w => w.room_list.filter(wr =>wr.id ===r.id ).length > 0).length===0)
		setFilteredRoomList(f_list)
		setShowPrePrint(true)

	}



	const handleClosePrePrint = () => {
		setShowPrePrint(false)
	}


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle
		/* 		content: () => {
					const tableStat = componentRef.current.cloneNode(true);
					const PrintElem = document.createElement('div');
					PrintElem.appendChild(tableStat);
					return PrintElem;
				},
				pageStyle: pageStyle,
				documentTitle: `qrcode`, */
	});


	const changeSelectedParam = (el_id, param, setParam) => {
		let _param = [...param]
		if (param.indexOf(el_id) >= 0) {
			const c = param.indexOf(el_id)
			_param.splice(c, 1)
		} else {
			_param.push(el_id)
		}
		console.log("UPDATE WA", _param)
		setParam(_param)
	}





	useEffect(() => {
		if (printParams.length === 0 || printParams.length === 2) {
			setEventList(full_event_list)
		} else {
			try {
				if (printParams.indexOf('ordinary') >= 0) {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'ordinary_intervention'))
				} else if (printParams.indexOf('periodic') >= 0) {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'periodic_intervention'))
				} else {
					setEventList(full_event_list.filter(e => e.extendedProps.type === 'requested_intervention' || e.extendedProps.type === 'request_ticket'))
				}

			} catch (err) {
			}
		}

	}, [printParams])

	useEffect(() => {

	}, [])




	return (
		<>
			<Button variant={"outline-primary"} onClick={() => handleShowPrePrint()}>
			Locali non inseriti in aree di lavoro	
			</Button>
			<Modal show={showPlanEdit} onHide={handleClosePrePrint} fullscreen bsclass="full-modal" backdrop="static" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<AlertSection ref={childRefAlert} />
					<Container fluid>
						<Row className="border-bottom mb-1">
							<Col>
								<Modal.Title>
									Locali non inseriti in area di lavoro ({filtered_room_list.length}/{Object.values(structureElements.all_room_dict).length})
								</Modal.Title>
							</Col>
						</Row>
						<Row>
							<Col sm="1"><FontAwesomeIcon icon="cogs" /></Col>
						</Row>
					</Container>
				</Modal.Header>
				<Modal.Body>
					<Container fluid >
						{filtered_room_list.map((r, rk) => (
							<Row key={rk} >
								<Col>
								<RoomInfoBadge parent_list={r.parent_list} expanded={true}/>
								</Col>
								<Col>
								{r.name}
								</Col>
							</Row>
						))}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Container fluid>
						<Row className="h4">
							<Col className="text-end">
								<Button variant="secondary" onClick={handleClosePrePrint} className="me-1">
									{t('modal.close')}
								</Button>
								<Button variant="info" onClick={handlePrint} className="ms-1">
									<FontAwesomeIcon icon="print" />  {t('modal.print')}
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		</>
	)
}




const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
	.print_page {
	  width:210mm !important;
	  height: 297mm;
	  
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  @media print {
	html, body, .preprint_page {
	  width:210mm !important;
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
	  .single_label {
		width: 50mm;
		height:50mm;
	  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
	  .single_label {
		width: 50mm !important;
		height: 50mm !important;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


