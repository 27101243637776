import React, { useContext, useState, useEffect, useLayoutEffect } from 'react';

import { Container, Row, Col, Button, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from "react-router-dom"


import LocaleContext from "../contexts/locale.js"
import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { ticketType_dict } from "../constants/global_definitions"

import { filterWeekDay, getValue, myTimestampToDate, compareName, getFloatNumber, getBaseObjectValue, getSelectedElementB, getTimeValue, compareDateParam, compareStartDateParam, rgba2hex, getSelectedElement } from "../../util/ui_utils.js"

import { getTicketListForUser as getTicketListForUser2, getTicketListForGroup as getTicketListForGroup2 } from "../../Firebase2/Storage2/dbTicket"

let load_page = false
const mailcount = 6;

let userData = {}
try {
    userData = JSON.parse(localStorage.getItem('userData'))
} catch (err) {
    console.log('homeview userdataERROR', userData)
}

//----------------------------------------
const unread = (m) => {
    if (!m.read && m.message_list && m.message_list[m.message_list.length - 1].userId !== localStorage.getItem('userId')) {
        return true
    } else {
        return false
    }
}
//----------------------------------------
//----------------------------------------
export const TicketSummary = ({ t, uData }) => {
//    console.log("-------------------init page TICKET -------------------")
    const { domain } = useContext(DomainContext)
    const { contract } = useContext(GlobalDataContext)

    let { cid } = useParams();
    let contractId = cid

    const [message_list, setMessageList] = useState([])
    const [sentmessage_list, setSentMessageList] = useState([])
    const ticket_type_dict = ticketType_dict({ t })

    useEffect(() => {

        const initMessageValues = async () => {
            userData = uData
            console.log("mesg", contract, domain)
            if (contract && domain.length > 0) {
                console.log("MESSAGE INIT", domain, contract.id, localStorage.getItem('userId'), userData.id)
                let _sent_message_list = await getTicketListForUser2(domain, contract.id, userData.id || localStorage.getItem('userId'))
                console.log("SENT", _sent_message_list)
                let _received_message_list = []
                //let send_message_list = await getMessageList(domain, contract.id)
                if (_sent_message_list.error) {
                    console.error("ERROR", _sent_message_list)

                } else {

                    //				setSentMessageList(send_message_list.slice(1, mailcount))
                    setSentMessageList(_sent_message_list.filter(m => unread(m)).sort(compareDateParam).reverse())

                    /* 				if (userData.receiver_group) {
                	
                                        let receive_message_list = []
                                        for (let g = 0; g < userData.receiver_group.length; g++) {
                                            let treceive_message_list = await getTicketListForGroup2(domain, contract.id, userData.receiver_group[g], mailcount)
                                            receive_message_list = [...receive_message_list, ...treceive_message_list]
                                        }
                                        setMessageList(receive_message_list)
                                    } */
                }
                if (userData.messagegroups) {
                    for (const group of userData.messagegroups) {
                        console.log("GFROUP, ", group)
                        let treceive_message_list = await getTicketListForGroup2(domain, contractId, group.message_group_id, mailcount)
                        console.log("REC-MESSAGE", treceive_message_list)
                        if (!treceive_message_list.error) {
                            _received_message_list = [..._received_message_list, ...treceive_message_list.messageList]
                        }
                    }
                    console.log("RECEIVED MESAGE LIST", _received_message_list)
                    setMessageList(_received_message_list.filter(m => unread(m)).sort(compareDateParam).reverse())
                }
            }
        }
        console.log('HOME loadingcontract', contract, load_page)
        if (contract) {
            initMessageValues()
        }
    }, [contract])

    useEffect(() => {
        console.log("UPDATE MESSAGE LIST", message_list)

    }, [message_list])



    return (

        <>
            <Row className="p-1">
                <Col className="text-center border-end">
                    <Row>
                        <Col className="h3">
                            {ticket_type_dict.request.icon}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {ticket_type_dict.request.label}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="h3">
                            {message_list.filter(m => m.type === 'request').length + sentmessage_list.filter(m => m.type === 'request').length}
                        </Col>
                    </Row>
                </Col>
                <Col className="text-center border-end">
                    <Row>
                        <Col className="h3">
                            {ticket_type_dict.noncompliance.icon}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {ticket_type_dict.noncompliance.label}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="h3">
                            {message_list.filter(m => m.type === 'noncompliance').length + sentmessage_list.filter(m => m.type === 'noncompliance').length}
                        </Col>
                    </Row>
                </Col>
                <Col className="text-center">
                    <Row>
                        <Col className="h3">
                            {ticket_type_dict.communication.icon}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {ticket_type_dict.communication.label}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="h3">
                            {message_list.filter(m => m.type === 'communication').length + sentmessage_list.filter(m => m.type === 'communication').length}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="border-top mb-1 pt-1">
                <Col className="text-center">
                    <Button href={"#/" + contractId + "/ticket"} variant="info" size="sm"><FontAwesomeIcon icon="external-link-alt" /> Tutte le comunicazioni</Button>
                </Col>
            </Row>
        </>
    )
}