import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Spinner, ButtonGroup, Badge, Card } from 'react-bootstrap';
import { translate, useTranslate } from 'react-polyglot'
import QRCode from 'qrcode.react'

import { appRole_dict, reportType_dict, ticketType_dict } from "../components/constants/global_definitions"

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalUserPassword from "../components/ModalUserPassword"
import { ModalInitButton } from '../components/StructureGraphicElements.js'

import { getSelectedElement, checkComponentPresence, compareName, getValue } from "../util/ui_utils.js"


import { editUserData as editUserData2 } from '../Firebase2/Storage2/dbUsers';
import { getAllDepartmentInStructure as getAllDepartmentInStructure2, getAllFloorInStructure, getFloorById, getPavillonById, getStructureList as getStructureList2 } from "../Firebase2/Storage2/dbBaseCompany"


import { getUserByEmail, addUserToDomain, createDomainUser, updateUserData } from '../components/FirebaseApi/user_api'

import firebase from 'firebase/compat/app'
import ModalUserPreview from './ModalUserPreview';
import { getWorkareaList } from '../Firebase2/Storage2/dbWorkarea';
import { ModalUserMessageGroup } from './modal/ModalUserMessageGroup';
import { firebaseAuth } from '../provider/AuthProvider';
import ModalAdvanceUserPermission from './modal/ModalAdvanceUserPermission';
import { ModalUserContractPermission } from './modal/ModalUserContractPermission';

let preload = false

const dashboard_section_list = [
	{ id: 'calendar', label: 'programmazione servizi' },
	{ id: 'operator', label: 'operatori' },
	{ id: 'working_state', label: 'grafici lavorazione' },
	{ id: 'checks', label: 'controlli' },
	{ id: 'ticket', label: 'comunicazioni' },
]

function ModalUser({ item, setItem, is_new, small, ui_list, domain, index, item_list, setParentList, contract_list, not_contract, }) {
	const t = useTranslate();

	const { userData } = useContext(firebaseAuth)

	const childRefAlert = useRef()
	const childRefConfirm = useRef()

	const newuser = {
		code: '',
		creation_date: firebase.firestore.Timestamp.now(),
		name: '',
		avatar: '',
		email: '',
		username: '',
		displayName: '',
		role: {},
		password: '',
		confirmPassword: '',
		status: 'active',
		disabled: false,
		systemgroups: [],
		contractgroups: []
	}

	const initValues = async () => {
		console.log("--..--..--..NOT CONTRACT", not_contract)
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(item)
	const [editedElement, setEditedElement] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const handleShowNew = () => {
		setCurrentElement(newuser)
		setShow(true)
		setEditedElement(false)
	}
	const handleShowEdit = (el) => {
		setCurrentElement(el)
		setShow(true)
		setEditedElement(false)
	}


	const onChangeHandler = (el, is_multi) => {
		console.log(el)
		let { name, value, type } = el
		if (is_multi) {
			setCurrentElement(ce => ({ ...curElement, ...el }))
			setEditedElement(true)
		} else {
			if (type === 'radio') {
				if (value === 'true') {
					value = true
				} else if (value === 'false') {
					value = false
				}
			} else if (type === 'checkbox') {
				let oldcheck = []
				try {
					oldcheck = [...curElement[name]]
				} catch (err) { }
				if (el.checked && oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				} else {
					oldcheck.splice(oldcheck.indexOf(value), 1);
				}
				value = oldcheck
			}
			console.log(name, value)
			setCurrentElement((ce) => ({ ...ce, [name]: value }))
			setEditedElement(true)
		}
	}
	useEffect(() => {
		initValues()
	}, [])


	const handleClose = () => setShow(false);

	const handleSave = async () => {
		setIsSaving(true)
		console.log("__/* __________________________________SAVE_____________________________is_new", is_new, curElement)
		if (is_new) {
			const return_check = await getUserByEmail(curElement.email, domain)
			if (return_check) {
				if (return_check.error) {
					childRefAlert.current.addAlert('danger', 'getUserByEmail:'+ t('global.amessages.error') + return_check.error.response.data.message + "." + return_check.error.response.data.error.message)
				} else {
					if (return_check.result.code === 'user-found-in-domain') {
						childRefAlert.current.addAlert('danger', "Utente (email) già inserito in questa licenza Claudit.")
					} else if (return_check.result.code === 'user-found-in-system') {
						//add domain
						console.log("update user domain", domain, return_check.result, curElement)
						const cb = async () => {
							const return_data = await addUserToDomain(return_check.result.uid, domain, curElement.systemgroups, curElement.contractgroups, return_check.result.displayName, curElement.email)
							console.log("RETURN DATA", return_data)
							if (return_data.error) {
								childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error.response.data.message + "." + return_data.error.response.data.error.message)
								setIsSaving(false)
							} else {
								curElement.id = return_data.result.uid
								delete curElement.password
								delete curElement.confirmPassword
								setCurrentElement(curElement)

								const newlist = [...item_list]
								newlist.push(curElement)
								setItem(newlist)

								setEditedElement(false)
								childRefAlert.current.addAlert('success', "Utente (email) già presente su Claudit (" + (return_check.displayName || curElement.displayName) + "). Dominio aggiunto a questa email. La password resterà quella già in possesso dell'utente")
								setShow(false)
								setIsSaving(false)
							}
						}
						console.log(return_check)
						childRefConfirm.current.confirmDelete(t('users.messages.client-user-present_title'), t('users.messages.client-user-present', { domain: domain, name: return_check.displayName || curElement.displayName, email: curElement.email }), cb, 'doubleConfirm', 'exclamation-triangle')
					} else {
						console.log("DOMAIN", domain)
						const return_data = await createDomainUser(curElement.email, curElement.password, curElement.confirmPassword, curElement.displayName, domain, curElement.systemgroups, curElement.contractgroups)
						console.log("RETURN DATA", return_data)
						if (return_data.error) {
							try {
								childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_check.error.response.data.message + "." + return_check.error.response.data.error.message)
							} catch (err) {
								console.log(err)
							}
							setIsSaving(false)
						} else {
							curElement.id = return_data.result.uid
							delete curElement.password
							delete curElement.confirmPassword
							const newlist = [...item_list]
							newlist.push(curElement)
							setItem(newlist)
							setCurrentElement(curElement)

							setEditedElement(false)
							childRefAlert.current.addAlert('success', "Utente inserito correttamente")
							setShow(false)
							setIsSaving(false)
						}
					}

				}
			}
		} else {
			let return_data = await editUserData2(domain, curElement.id, curElement)
			await updateUserData(curElement.uid, curElement.displayName)
			console.log("edit", item_list, curElement)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				setIsSaving(false)
			} else {
				const newlist = [...item_list]
				newlist.splice(index, 1, curElement)
				setEditedElement(false)
				setParentList(newlist)
				//setShow(false);
				setIsSaving(false)
			}
		}
	}




	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('users.buttons.user_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit(item)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<DeleteConfirmationSection ref={childRefConfirm} />
			{showEdit ?
				<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl" scrollable={true}>
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
						<Modal.Title>
							{is_new ?
								<>
									<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('users.modal.titlenew')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('users.modal.titleedit')}</span>
								</>
							}
							: {curElement.displayName}&nbsp;
							{editedElement ? <FontAwesomeIcon icon="save" /> : ''}

						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AlertSection ref={childRefAlert} />
						<Container fluid>
							<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} is_new={is_new} domain={domain} contract_list={contract_list} childRefAlert={childRefAlert} not_contract={not_contract} />
							{!is_new &&
								<QRCode value={curElement.id} />}
							{ }
							{userData && (userData.isGlobalAdmin) &&
								<>
									<Form.Group as={Row} className="border-bottom">
										<Form.Label column sm="4">DEBUG User</Form.Label>
										<Col>
											<Form.Check type="radio" name="debug_user" value={false} checked={!curElement.debug_user} label="No" onChange={(event) => onChangeHandler(event.currentTarget)} />
											<Form.Check type="radio" name="debug_user" value={true} checked={curElement.debug_user} label="Si" onChange={(event) => onChangeHandler(event.currentTarget)} />
										</Col>
									</Form.Group>
								</>}
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<ModalUserPreview item={curElement} />
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button disabled={checkUserValidity(curElement, is_new)} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSave}>
							{isSaving ?
								<Spinner size="sm" animation="border" role="status" />
								: <FontAwesomeIcon icon="save" />
							}&nbsp;
							{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkUserValidity(element, is_new) {
	let isvalid = true
	try {

		if (!element.displayName || element.displayName.length === 0 || !element.email || element.email.length === 0 || element.email.indexOf(' ') >= 0) {
			isvalid = isvalid && false
		}
		if (is_new && element) {
			if ((!element.password && element.password.length === 0) || (!element.confirmPassword && element.confirmPassword.length === 0)) {
				isvalid = false
			}
			if (element.password && element.confirmPassword && element.password !== element.confirmPassword) {
				isvalid = false
			}
		}
	} catch (err) {

	}
	console.log(is_new, isvalid)
	return !isvalid
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
const ElementFormDetails = ({ element, functions, ui_list, t, is_new, domain, contract_list, childRefAlert, not_contract }) => {

	const [showPassword, setShowPassword] = useState(false)

	const report_type_dict = reportType_dict({ t })

	const checkData = (d) => {
		console.log("verify", d)
	}


	const setPassword = (p) => {
		setShowPassword(true)
		functions.edit({ password: p, confirmPassword: p }, true)
	}
	useEffect(() => {
		setShowPassword(false)

	}, [])

	return (
		<>
			<Form.Group as={Row} controlId="userName" className={`${is_new ? 'text-info' : ''} border-bottom pb-1 mb-1`}>
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="displayName" value={element.displayName} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="userName" className="border-bottom pb-1 mb-1 ">
				<Form.Label column sm="2" className={is_new ? 'text-info' : ''} >{t('users.labels.email')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="email" value={element.email} onChange={(event) => functions.edit(event.currentTarget)} disabled={!is_new} />
					{is_new &&
						<Form.Text className="text-info">
							La mail non può essere modificata successivamente in quanto parte del login
						</Form.Text>
					}
				</Col>
			</Form.Group>

			{/* 			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.two_factor_login')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="two_factor_login" value={true} checked={element.two_factor_login} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="two_factor_login" value={false} checked={!element.two_factor_login} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col>
					{element.two_factor_login &&
						<Button onClick={() => checkData('email')} size="sm" mode="outline-info">Verifica email</Button>
					}
				</Col>
			</Form.Group> */}
			{/* 			<Form.Group as={Row} controlId="userphone_number" className="border-bottom">
				<Form.Label column sm="2">{t('user_settings.labels.phone_number')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="phone_number" value={element.phone_number} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>

				<Col>
					{element.two_factor_login &&
					<Button onClick={() => checkData('phone')} size="sm" mode="outline-info">Verifica numero di telefono</Button>
}
				</Col>
			</Form.Group> */}
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.enabled')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="disabled" value={false} checked={!element.disabled} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="disabled" value={true} checked={element.disabled} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
				<Col>
					<Form.Check account_type="radio" name="account_type" value={'supplier'} checked={element.account_type && element.account_type.indexOf('supplier') >= 0} label={t('global.labels.account_supplier')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check account_type="radio" name="account_type" value={'client'} checked={element.account_type && element.account_type.indexOf('client') >= 0} label={t('global.labels.account_client')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{domain !== 'admin' ?
				<>
					<Form.Group as={Row} controlId="userGroups" className="pb-1 border-bottom mb-1">
						<Form.Label column sm="2">Dashboard</Form.Label>
						<Col>
							<Row>
								{dashboard_section_list.map((g, k) => (
									<Col key={k} sm="3">
										<Form.Check type="checkbox" name="dashboardsection" checked={element.dashboardsection?.indexOf(g.id) >= 0} value={g.id} label={g.label} onChange={(event) => functions.edit(event.currentTarget)} />
									</Col>
								))}

							</Row>
						</Col>
					</Form.Group>
					<PermissionSection element={element} t={t} ui_list={ui_list} functions={functions} contract_list={contract_list} domain={domain} />
					<Form.Group as={Row} className="border-bottom mb-1">
						<Form.Label column sm="4">Visualizza dettaglio ore da Claudit Trace</Form.Label>
						<Col>
							<Form.Check type="radio" name="view_trace_details" value={false} checked={!element.view_trace_details} label="No" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Check type="radio" name="view_trace_details" value={true} checked={element.view_trace_details} label="Si" onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>
					</Form.Group>
					<Card className="mb-3">
						<Card.Header>
							<Container fluid>
								<Row>
									<Col className="text-primary h4">
										Validazione Report
									</Col>
								</Row>
							</Container>
						</Card.Header>
						<Card.Body>
							<Container fluid>
								<Form.Group as={Row} className="pb-0">
									{Object.keys(report_type_dict).map((g, k) => (
										<Col key={k} >
											<Form.Check type="checkbox" name="reportsign" checked={element.reportsign && element.reportsign.indexOf(report_type_dict[g].id) >= 0} value={report_type_dict[g].id} label={report_type_dict[g].label} onChange={(event) => functions.edit(event.currentTarget)} />
										</Col>
									))}
								</Form.Group>
							</Container>
						</Card.Body>
					</Card>
					{!is_new && !not_contract ?
						<>
							<ElementFormMessageGroup element={element} t={t} ui_list={ui_list} functions={functions} contract_list={contract_list} domain={domain} />
							<ElementFormAppContractGroup element={element} t={t} ui_list={ui_list} functions={functions} contract_list={contract_list} domain={domain} />
						</>
						: ''}
				</> : ''}
			{is_new ?
				<>
					<Form.Group as={Row} className="border-bottom mb-1 pb-1">
						<Form.Label column sm="2" className='text-info'>{t('global.labels.password')}</Form.Label>
						<Col sm="6">
							<Form.Control type={showPassword ? "text" : "password"} name="password" value={element.password} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
							{element.password && element.password.length < 6 ?
								<Form.Text className="text-danger">
									{t('users.messages.password_problem_short')}
								</Form.Text>
								: ''}
						</Col>
						<Col sm="1">
							{showPassword ?
								<Button size="sm" variant="secondary" onClick={() => setShowPassword(false)}><FontAwesomeIcon icon="eye-slash" /></Button>
								:
								<Button size="sm" variant="secondary" onClick={() => setShowPassword(true)}><FontAwesomeIcon icon="eye" /></Button>
							}
						</Col>
						<Col sm="3">
							<GeneratePassword setPassword={setPassword} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="border-bottom mb-1 pb-1">
						<Form.Label column className='text-info' sm="2">{t('global.labels.password_check')}</Form.Label>
						<Col sm="6">
							<Form.Control type={showPassword ? "text" : "password"} name="confirmPassword" value={element.confirmPassword} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
							{element.password && element.password.length > 0 && element.confirmPassword !== element.password ?
								<Form.Text className="text-danger">
									{t('users.messages.password_problem')}
								</Form.Text>
								: ''}
						</Col>
					</Form.Group>
				</>
				:
				<Row className="mt-2">
					<Col className="text-end">
						<ModalUserPassword name={element.displayName} user_id={element.id} childRefAlertOr={childRefAlert} />
					</Col>
				</Row>
			}




		</>
	)
}



const PermissionSection = ({ element, ui_list, functions, t, contract_list, not_contract, domain }) => {
	const [permissionMode, setPermissionMode] = useState('system')
	const [permissionModeDisabled, setPermissionModeDisabled] = useState(false)
	useEffect(() => {
		if (element.contractgroups && element.contractgroups.length > 0){
			setPermissionMode('contract')
			setPermissionModeDisabled(true)
		}
		if (element.systempermission){
			setPermissionModeDisabled(true)
		}
		if (element.systemgroups && element.systemgroups.length > 0){
			setPermissionMode('system')
			setPermissionModeDisabled(true)
		}


	}, [element])

	return (
		<>
			<Row>
				<Col>
					Permessi di visualizzazione:
				</Col>
				<Col>
					<ButtonGroup >
						<Button disabled={permissionModeDisabled} onClick={() => setPermissionMode('system')} variant={permissionMode === 'system' ? 'info' : 'outline-info'}>Sistema</Button>
						<Button disabled={permissionModeDisabled} onClick={() => setPermissionMode('contract')} variant={permissionMode === 'contract' ? 'info' : 'outline-info'}>Contratto</Button>
					</ButtonGroup>
				</Col>
			</Row>
			{permissionMode === 'system' ?
				<>
					<Card className="mb-2">
						<Card.Header>
							<Container fluid>
								<Row>
									<Col className="text-primary h4">
										Permessi di sistema
									</Col>
									<Col sm="4">
										<ModalAdvanceUserPermission item={element.systempermission} setItem={(p) => functions.edit({ name: 'systempermission', value: p })} />
									</Col>
								</Row>

							</Container>
						</Card.Header>
						<Card.Body>
							<Container fluid>
								{!(element.contractgroups && element.contractgroups.length > 0) && !not_contract ?
									<Form.Group as={Row} >
										{Object.keys(ui_list.group_list).map((g, k) => (
											<Col key={k} sm="3">
												<Form.Check type="checkbox" disabled={element.systempermission} name="systemgroups" checked={!element.systempermission && element.systemgroups.indexOf(ui_list.group_list[g].id) >= 0} value={ui_list.group_list[g].id} label={ui_list.group_list[g].name} onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										))}
									</Form.Group>
									: ''}
							</Container>
						</Card.Body>
					</Card>
				</>
				:
				<>
					{!(element.systemgroups && element.systemgroups.length > 0) ?
						<ElementFormContractGroup element={element} t={t} ui_list={ui_list} functions={functions} contract_list={contract_list} domain={domain} not_contract={not_contract} />
						: ''}
				</>
			}

		</>
	)
}



export default translate()(ModalUser)


function GeneratePassword({ setPassword }) {
	const [passwordLength, setPasswordLength] = useState(12);
	const [useSymbols, setUseSymbols] = useState(true);
	const [useNumbers, setUseNumbers] = useState(true);
	const [useLowerCase, setUseLowerCase] = useState(true);
	const [useUpperCase, setUseUpperCase] = useState(true);
	const [successMessage, setSuccessMessage] = useState("");
	const generatePassword = () => {
		let charset = "";
		let newPassword = "";

		if (useSymbols) charset += "!@#$%^&*()";
		if (useNumbers) charset += "0123456789";
		if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
		if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

		for (let i = 0; i < passwordLength; i++) {
			newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
		}

		setPassword(newPassword);
	};



	return (<Button onClick={generatePassword}>Genera password</Button>)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormMessageGroup({ element, functions, t, contract_list, domain }) {


	const handleDeleteMessagegroup = (param_name, el, index) => {
		console.log(el)
		let editlist = element[param_name]
		editlist.splice(index, 1)
		functions.edit({ name: param_name, value: editlist })
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = element[name]
			if (el.checked) {

				if (oldcheck) {
					if (oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					}
				} else {
					oldcheck = [value]

				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}

		functions.edit({ name: name, value: value })
	}



	const ticket_type_dict = ticketType_dict({ t })




	return (
		<>
			<Card className="mb-3">
				<Card.Header>
					<Container fluid>
						<Row>
							<Col className="text-primary h4">
								{t('user.labels.message_send_permission')}
							</Col>
							<Col className="text-end">
								<ModalUserMessageGroup is_new={true} item_list={element.sendmessagegroups} setItem={functions.edit} t={t} domain={domain} contract_list={contract_list} param_name="sendmessagegroups" />
							</Col>

						</Row>

					</Container>
				</Card.Header>
				<Card.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="userGroups" className="pb-1 mb-1 border-bottom">
							<Col className="">
								{t('user.labels.message_type')}
							</Col>
							{Object.keys(ticket_type_dict).map((g, k) => (
								<Col key={k} className={ticket_type_dict[g].disabled ? 'd-none' : ''}>
									<Form.Check type="checkbox" name="sendmessage" checked={element.sendmessage && element.sendmessage.indexOf(ticket_type_dict[g].id) >= 0} value={ticket_type_dict[g].id} label={ticket_type_dict[g].label} onChange={(event) => onChangeHandler(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						<Row >
							{element.sendmessagegroups && element.sendmessagegroups.length > 0 ?
								<Col>
									<Row >
										<Col className="bg-secondary text-light ms-1">{t('global.labels.contract')} </Col>
										<Col className="bg-secondary text-light ms-1">{t('global.labels.group')} </Col>
										<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
									</Row>
									{element.sendmessagegroups.map((i, key) => (
										<Row key={key} className="border-bottom mb-1 pt-1">
											<Col className="font-weight-bold">{getValue(getSelectedElement(i.contract_id, contract_list, 'id'), 'name')}</Col>
											<Col className="font-weight-bold">{i.message_group_name} </Col>
											<Col className="text-center" sm="1">
												<ButtonGroup>
													<ModalUserMessageGroup is_new={false} canWrite={true} item_list={element.sendmessagegroups} item={i} index={key} setItem={functions.edit} t={t} domain={domain} contract_list={contract_list} param_name="sendmessagegroups" />
													<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteMessagegroup("sendmessagegroups", i, key)} /> </Button>
												</ButtonGroup>
											</Col>
										</Row>))
									}
								</Col>
								: <Col className="font-italic">
									{t('user.messages.no_sendmessagegroup')}
								</Col>
							}
						</Row>
					</Container>
				</Card.Body>
			</Card>
			<Card className="mb-3">
				<Card.Header>
					<Container fluid>
						<Row>
							<Col className="text-primary h4">
								{t('user.labels.message_group')}
							</Col>

							<Col className="text-end">
								<ModalUserMessageGroup is_new={true} item_list={element.messagegroups} setItem={functions.edit} t={t} domain={domain} contract_list={contract_list} param_name="messagegroups" />
							</Col>
						</Row>

					</Container>
				</Card.Header>
				<Card.Body>
					<Container fluid>
						<Row >
							{element.messagegroups && element.messagegroups.length > 0 ?
								<Col>
									<Row >
										<Col className="bg-secondary text-light ms-1">{t('global.labels.contract')} </Col>
										<Col className="bg-secondary text-light ms-1">{t('global.labels.group')} </Col>
										<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
									</Row>
									{element.messagegroups.map((i, key) => (
										<Row key={key} className="border-bottom mb-1 pt-1">
											<Col className="font-weight-bold">{getValue(getSelectedElement(i.contract_id, contract_list, 'id'), 'name')}</Col>
											<Col className="font-weight-bold">{i.message_group_name} </Col>
											<Col className="text-center" sm="1">
												<ButtonGroup>
													<ModalUserMessageGroup is_new={false} canWrite={true} item_list={element.messagegroups} item={i} index={key} setItem={functions.edit} t={t} domain={domain} contract_list={contract_list} param_name="messagegroups" />
													<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteMessagegroup("messagegroups", i, key)} /> </Button>
												</ButtonGroup>
											</Col>
										</Row>))
									}
								</Col>
								: <Col className="font-italic"> {t('user.messages.no_messagegroup')}</Col>
							}
						</Row>

					</Container>
				</Card.Body>
			</Card>





		</>
	)
}




////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormContractGroup({ element, functions, ui_list, t, contract_list, domain, not_contract }) {



	const handleDeleteContractgroup = (el, index) => {
		console.log(el)
		let editlist = element.contractgroups
		const c = checkComponentPresence(el, element.contractgroups, 'id')
		if (c >= 0) {

			editlist.splice(index, 1)
			functions.edit({ name: 'contractgroups', value: editlist })
		}
	}





	return (
		<Card className="mb-2">
			<Card.Header>
				<Container fluid>
					<Row>
						<Col className="text-primary h4">
							{not_contract ?
								t('user.labels.permissions')
								:
								t('user.labels.contract_permissions')
							}
						</Col>
						<Col className="text-end">
{/* 							<ModalUserContractPermission is_new={true} element={element} functions={functions} ui_list={ui_list} t={t} element_list={element.contractgroups} user_displayname={element.displaName} domain={domain} contract_list={contract_list} not_contract={not_contract} />
 */}						</Col>
					</Row>

				</Container>
			</Card.Header>
			<Card.Body>
				<Container fluid>
					<Row >
						{element.contractgroups && element.contractgroups.length > 0 ?
							<Col>
								<Row >
									<Col className="bg-secondary text-light ms-1">{t('global.labels.contract')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.structure')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.department')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.workarea')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.role')} </Col>
									<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
								</Row>
								{element.contractgroups.map((i, key) => (
									<Row key={key} className="border-bottom mb-1 pt-1">
										<Col className="font-weight-bold">{getValue(getSelectedElement(i.contract_id, contract_list, 'id'), 'name')}</Col>
										<Col>
											{getValue(i.structure, 'name')}
										</Col>
										<Col>
											{getValue(i.department, 'name')}
										</Col>
										<Col>
											{getValue(i.workarea, 'name')}
										</Col>
										<Col>
											{i.permissiongroup.map((p, kk) => (
												<Badge key={key + kk} variant="primary" className="me-1">{ui_list?.group_dict?.[p]?.name}</Badge>
											))}
										</Col>
										<Col className="text-center" sm="1">
											<ButtonGroup>
												<ModalUserContractPermission is_new={false} element={i} index={key} element_list={element.contractgroups} functions={functions} ui_list={ui_list} t={t} user_displayname={element.displaName} domain={domain} contract_list={contract_list} not_contract={not_contract} />
												<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteContractgroup(i, key)} /> </Button>
											</ButtonGroup>
										</Col>
									</Row>))
								}
							</Col>
							: <Col className="font-italic">{not_contract ? t('user.messages.no_cataloggroup') : t('user.messages.no_contractgroup')}</Col>
						}
					</Row>

				</Container>

			</Card.Body>





		</Card>
	)
}





////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormAppContractGroup({ element, functions, ui_list, t, contract_list, domain }) {


	const handleDeleteElement = (el, index) => {
		console.log(el)
		let editlist = [...element.appcontractgroups]
		const c = checkComponentPresence(el, element.appcontractgroups, 'id')
		console.log(c, el, index)
		const e = editlist.splice(index, 1)
		console.log("EDITLIST", editlist, e)
		functions.edit({ name: 'appcontractgroups', value: editlist })
	}

	const app_roles = appRole_dict({ t })
	return (

		<Card>
			<Card.Header>
				<Container fluid>
					<Row>
						<Col className="text-primary h4">
							{t('user.labels.appcontract_permissions')}
						</Col>

						<Col className="text-end">
							<ModalAppContractPermission is_new={true} element={element} functions={functions} ui_list={ui_list} t={t} element_list={element.appcontractgroups} user_displayname={element.displaName} domain={domain} contract_list={contract_list} />
						</Col>
					</Row>

				</Container>
			</Card.Header>
			<Card.Body>
				<Container fluid>
					<Row >
						{element.appcontractgroups && element.appcontractgroups.length > 0 ?
							<Col>
								<Row >
									<Col className="bg-secondary text-light ms-1">{t('global.labels.contract')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.structure')} </Col>
									<Col className="bg-secondary text-light ms-1">{t('global.labels.role')} </Col>
									<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
								</Row>
								{element.appcontractgroups.map((i, key) => (
									<Row key={key} className="border-bottom mb-1 pt-1">
										<Col className="font-weight-bold">{getValue(getSelectedElement(i.contract_id, contract_list, 'id'), 'name')}</Col>
										<Col>
											{getValue(i.structure, 'name')}
										</Col>
										<Col>
											{i.permissiongroup.map((p, kk) => (
												<Badge key={key + kk} variant="primary" className="me-1">{app_roles[p].name}</Badge>
											))}
										</Col>
										<Col className="text-center" sm="1">
											<ButtonGroup>
												<ModalAppContractPermission is_new={false} element={i} index={key} element_list={element.appcontractgroups} functions={functions} ui_list={ui_list} t={t} user_displayname={element.displaName} domain={domain} contract_list={contract_list} />
												<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i, key)} /> </Button>
											</ButtonGroup>
										</Col>
									</Row>))
								}
							</Col>
							: <Col className="font-italic"> {t('user.messages.no_appcontractgroup')}</Col>
						}
					</Row>

				</Container>

			</Card.Body>
		</Card>


	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalAppContractPermission = ({ is_new, element, t, functions, ui_list, user_displayname, index, domain, contract_list, element_list }) => {

	const newappcontractgroup = {
		contract_id: -1,
		structure: {},
		department: {},
		permissiongroup: [],

	}

	const [structure_list, setStructureList] = useState([])
	const [department_list, setDepartmentList] = useState([])

	const [showAppcontractgroupEdit, setShowAppcontractgroupEdit] = useState(false)
	const [curAppcontractgroup, setCurAppcontractgroup] = useState(newappcontractgroup)
	const [editedElement, setEditedElement] = useState(false)
	const [loading, setLoading] = useState(false)


	const handleCloseAppcontractgroup = () => setShowAppcontractgroupEdit(false);

	const handleSaveAppcontractgroup = async () => {
		console.log(domain, curAppcontractgroup)
		if (is_new) {
			let newappcontractgroups = element.appcontractgroups || []
			newappcontractgroups.push(curAppcontractgroup)
			console.log(newappcontractgroups)
			functions.edit({ name: 'appcontractgroups', value: newappcontractgroups })
		} else {
			let editlist = element_list
			//            const c = checkComponentPresence(curAppcontractgroup, element.appcontractgroups)
			editlist.splice(index, 1, curAppcontractgroup)
			functions.edit({ name: 'appcontractgroups', value: editlist })
		}
		setShowAppcontractgroupEdit(false);
	}

	const handleShowNew = () => {
		preload = false
		console.log("new appcontractgroup (preload)", preload)
		setCurAppcontractgroup(newappcontractgroup)
		setShowAppcontractgroupEdit(true)
		setEditedElement(false)
	}


	const handleShowEdit = async () => {
		preload = true
		console.log('edit appcontractgroup (element, index, preload)', element, index, preload)
		setShowAppcontractgroupEdit(true)
		setCurAppcontractgroup(element)
		setEditedElement(false)
	}


	useEffect(() => {
		console.log("UPDATE contract contract group: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeContract({ value: element.contract_id })
			if (!element.structure.id) {
				console.log("END LOAD INITIAL DATA contract")
				preload = false
			}
		}
		if (preload && showAppcontractgroupEdit) {
			preloadNext()
		}
	}, [curAppcontractgroup.contract_id])




	useEffect(() => {
		console.log("UPDATE contract group structure: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeStructure({ value: getValue(element.structure, 'id') })
			if (!element.department.id) {
				console.log("END LOAD INITIAL DATA structure")
				preload = false
			}

		}
		if (preload && showAppcontractgroupEdit) {
			preloadNext()
		}
	}, [structure_list])



	useEffect(() => {
		console.log("UPDATE contract group, department: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeDepartment({ value: getValue(element.department, 'id') })
			console.log("END LOAD INITIAL DATA department")
			preload = false
		}
		if (preload && showAppcontractgroupEdit) {
			preloadNext()
		}
	}, [department_list])




	const onChangeContract = async (el) => {
		console.log("Change contractid", domain, el)
		if (preload) {
			setCurAppcontractgroup({ ...curAppcontractgroup, contract_id: el.value })
		} else {
			setCurAppcontractgroup({ ...curAppcontractgroup, contract_id: el.value, structure: {}, department: {} })
			setEditedElement(true)

		}

		const return_data = await getStructureList2(domain, el.value) || []
		if (return_data && return_data.error) {
			console.log(return_data)
			return false
			//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
		} else {
			setStructureList(return_data)
			console.log('structure_list', return_data)
			return return_data
		}

	}


	const onChangeStructure = async (el) => {
		//	setLoading(true)
		console.log("structure", el)
		if (el) {
			setLoading(true)

			console.log(" structureid", el, el.value, structure_list)
			//			let return_data = await getAllDepartmentInStructure2(el.value)
			const structure = getSelectedElement(el.value, structure_list, 'id')
			if (preload) {
				setCurAppcontractgroup({ ...curAppcontractgroup, structure: { id: structure.id, name: structure.name } })

			} else {
				setCurAppcontractgroup({ ...curAppcontractgroup, structure: { id: structure.id, name: structure.name }, department: {} })
				setEditedElement(true)

			}
			/* 			if (return_data && return_data.error) {
							console.log(return_data)
						} else {
							let f_dict = {}
							let p_dict = {}
							for (let d of return_data){
								if (f_dict[d.parent_list[2]]){
									d.floor_name = f_dict[d.parent_list[2]].name
								}else{
									const f = await getFloorById(domain, curContractgroup.contract_id, structure_id, d.parent_list[1], d.parent_list[2])
									if (!f.error){
										f_dict[d.parent_list[2]] = f
										d.floor_name = f.name
									}
								}
								if (p_dict[d.parent_list[1]]){
									d.pavillon_name = p_dict[d.parent_list[1]].name
								}else{
									const _p = await getPavillonById(domain,curContractgroup.contract_id, structure_id, d.parent_list[1])
									console.log("PAV", _p)
									if (!_p.error){
										p_dict[d.parent_list[1]] = _p
										d.pavillon_name = _p.name
									}
								}
			
							}
							setDepartmentList(return_data)
						setLoading(false)
							//			setRoomList([])
							console.log('department_list', el.value, return_data) 
						}*/
		}
	}


	const onChangeDepartment = async (el) => {
		console.log("deparmtn id", el, department_list)
		if (!preload) {
			setEditedElement(true)
		}
		const department = getSelectedElement(el.value, department_list, 'id')
		setCurAppcontractgroup({ ...curAppcontractgroup, department: { id: department, name: department.name } })
	}


	const app_roles = {
		'customer_controller': { name: t('contract.labels.role_customer_controller'), id: 'customer_controller' },
		'supplier_controller': { name: t('contract.labels.role_supplier_controller'), id: 'supplier_controller' },
		'external_controller': { name: t('contract.labels.role_external_controller'), id: 'external_controller' },
	}



	const onChangeHandlerAppcontractgroup = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curAppcontractgroup[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(value)
		setCurAppcontractgroup({ ...curAppcontractgroup, [name]: value })
	}





	return (
		<>

			<ModalInitButton t={t} is_new={is_new} canWrite={true} title_new={t('user.labels.add_app_permission')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} is_secondary={true} />
			<Modal show={showAppcontractgroupEdit} onHide={handleCloseAppcontractgroup} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('users.modal.title_newappcontractgroupassing')}</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('users.modal.editappcontractgrouppassing')} </span>
							</>
						}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.contract')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" value={curAppcontractgroup.contract_id} name="contract_id" className="mb-2" onChange={(event) => onChangeContract({ name: 'contract_id', type: 'select', value: event.target.value })}  >
									<option value="-1" >{t('contract.labels.select_contract')}</option>
									{contract_list.map((f, k) => (
										<option key={k} value={f.id} >{f.name}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curAppcontractgroup.contract_id !== -1 ?
							<>
								<Form.Group as={Row} controlId="userGroups" className="pb-1">
									<Form.Label column sm="2">{t('global.labels.roles')}</Form.Label>
									{Object.keys(app_roles).map((g, k) => (
										<Col key={k} >
											<Form.Check type="checkbox" name="permissiongroup" checked={curAppcontractgroup.permissiongroup.indexOf(app_roles[g].id) >= 0} value={app_roles[g].id} label={app_roles[g].name} onChange={(event) => onChangeHandlerAppcontractgroup(event.currentTarget)} />
										</Col>
									))}
								</Form.Group>

								<Form.Group as={Row} controlId="userGroups" className="pb-1">
									<Form.Label column sm="2">{t('users.labels.filter_structure')}
										{loading ? <Spinner animation="border" role="status">
											<span className="sr-only">Loading...</span>
										</Spinner> : ''}</Form.Label>
									<Col sm="3">
										<Form.Control as="select" className="mb-2" name="structure" value={getValue(curAppcontractgroup.structure, 'id')} onChange={(event) => onChangeStructure(event.currentTarget)} >
											<option value="-1" >{t('structure.labels.all_structure')}</option>
											{structure_list
												? [Object(structure_list).map((f, k) => (
													<option key={k} value={f.id} >{f.name}</option>
												))]
												: ''
											}
										</Form.Control>
									</Col>
								</Form.Group>
							</> : ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAppcontractgroup}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveAppcontractgroup}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
