import {firestore} from '../firebaseIndex'

///////////////////////////////////////////////////////////////////////////////
export const addClothing = async (domainId, data ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('clothingcollection')
            .add(data);
        const newdata = {
            ...data,
            id: res.id,
        }
        return newdata
    } catch (e) {
        console.log("addClothing error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const getClothingById = async (domainId,  clothingId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('clothingcollection')
            .doc(clothingId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id : res.id,
        }
        return data;
    } catch (e) {
        console.log("getClothing error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const getClothingList = async (domainId ) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('clothingcollection')
            .get();

        const clothingList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return clothingList;
        
    } catch (e) {
        console.log("getClothingList error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const editClothing = async (domainId, clothingId, data) => {
    try {
        const clothing =  firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('clothingcollection')
            .doc(clothingId);

        const res = await clothing.set(data)
            .then( () => {
                console.log("editClothing - edit successfully")
                return data
            }).catch( error => {
                console.log("editClothing - edit error: ", error)
                return {error: error}
            });

        return res
    }  catch (e) {
        console.log("editClothing error: ", e)
        return { error: e }
    }
}


///////////////////////////////////////////////////////////////////////////////
export const deleteClothing = async (domainId, clothingId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('clothingcollection')
            .doc(clothingId).delete();
        return res
    }  catch (e) {
        console.log("deleteClothing error: ", e)
        return { error: e }
    }
}


