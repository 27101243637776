import React, { useState, useContext, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, FormControl, ButtonGroup, Nav, Tab, ProgressBar, Image } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { format, addMonths, endOfMonth } from 'date-fns'
import DateTime from 'react-datetime';
import QRCode from 'qrcode.react'
import { useParams, useLocation, useHistory } from "react-router-dom"

import { getSelectedElement, getValue, myTimestampToDate, checkComponentPresence, makeid, propComparator, myDateToTimestamp } from "../util/ui_utils.js"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import PhoneList from "../components/PhoneList.js"
import ModalBaseObject from "../components/ModalBaseObject"
import { ModalInitButton } from '../components/StructureGraphicElements.js'

import firebase from 'firebase/compat/app'

import { addEmployer as addEmployer2, editEmployer as editEmployer2 } from '../Firebase2/Storage2/dbEmployers';
import { uploadFileComplete as uploadFileComplete2 } from '../Firebase2/Storage2/dbFileStorage';



const section = 'employee'
export const newemployee = {
	firstname: '',
	lastname: '',
	cleaningcompany: {},
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	residence: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	phone_list: [],
	register: '',
	photo: '',
	timing: 'permanentjob',
	birthdate: firebase.firestore.Timestamp.now(),
	id_expiration: firebase.firestore.Timestamp.now(),
	work_expiration: firebase.firestore.Timestamp.now(),
	assumption_date: firebase.firestore.Timestamp.now(),
	work_contract_id: -1,
	qualification_id: -1,
	training_list: [],
	clothing_list: [],
	structure_list: [],
	study_title: {}
}

const ModalEmployee = forwardRef(({ item, setItem, t, is_new, small, index, item_list, setParentList, ui_list, canWrite, functions }, ref) => {
	const { domain } = useContext(DomainContext)
	const [fromExt, setFromExt] = useState(false)

	let { cid } = useParams();
	let history = useHistory();
	let location = useLocation()

	let contractId = cid
	const childRefAlert = useRef()


	const [showEmployeeEdit, setShowEmployeeEdit] = useState(false)
	const [curElement, setCurElement] = useState(newemployee)
	const [editedElement, setEditedElement] = useState(false)

	const [isInvalid, setIsInvalid] = useState(false)
	const [uploadingState, setUploadingState] = useState(-1)

	const clearQuery = () => {
		const queryParams = new URLSearchParams(location.search)
		if (queryParams.has('elemId')) {
			queryParams.delete('elemId')
			history.replace({
				search: queryParams.toString(),
			});
		}

	}

	const handleCloseEmployee = () => {
		clearQuery()

		setShowEmployeeEdit(false);
	}

	const cb_progress = (status, byte, total) => {
		console.log(status, byte, total)
		try {
			console.log(byte / total)
			if (byte < total) {
				setUploadingState(100 * byte / total)
			} else {
				setUploadingState(-1)
			}

		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}
	const handleSaveEmployee = async () => {

		setShowEmployeeEdit(false);

		let error = false;
		/* 		if (curElement.cleaningcompany === '-1' || Object.keys(curElement.cleaningcompany).length === 0 && curElement.cleaningcompany.constructor === Object) {
					error = true;
					childRefAlert.current.addAlert('danger', 'impresa non valida')
				} */
		console.log("SAVE EMPLOYER", curElement)
		if (is_new) {
			console.log(curElement)
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.photo = url
					curElement.photo_tracker = tracker
				}
				delete curElement.file
				const return_data = await addEmployer2(domain, contractId, curElement)
				let newemployee_list = [...item]
				newemployee_list.push(return_data)
				console.log(newemployee_list)
				setParentList(newemployee_list)
				setEditedElement(false)
			}

			if (curElement.file) {
				const mypath = contractId + '/' + section
				const mediainfo = { description: 'photo', source_name: curElement.file[0].name, section: section, item_reference: {} }
				let file_data = uploadFileComplete2(curElement.domain, '0', mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}

		} else {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.photo = url
					curElement.photo_tracker = tracker
				}
				delete curElement.file
				let newemployee_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				newemployee_list.splice(c, 1, curElement)
				setParentList(newemployee_list)
				console.log("SAVE EMPLOYER", curElement, domain, contractId)
				const return_data = await editEmployer2(domain, contractId, curElement.id, curElement)
				setEditedElement(false)
			}
			if (curElement.file) {
				const mypath = contractId + '/' + section
				const mediainfo = { description: 'image', source_name: curElement.file[0].name, section: section, item_reference: {} }
				console.log(mediainfo, curElement.file[0])
				let file_data = uploadFileComplete2(domain, contractId, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
		clearQuery()
	}



	const handleShowNewEmployee = (_w) => {
		console.log('show new')
		setIsInvalid(false)
		setCurElement(newemployee)
		setShowEmployeeEdit(true)
		setEditedElement(false)
	}


	const handleShowEditEmployee = (el) => {
		let _el = item
		if (el) {
			_el = el
		}
		if (!_el.color) {
			_el.color = { ...newemployee.color }
		}
		setIsInvalid(false)
		setCurElement(_el)
		setShowEmployeeEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		} else if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'

		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}


	useImperativeHandle(ref, () => ({
		onEdit(_el) {
			handleShowEditEmployee(_el)
			setFromExt(true)
		}
	}))

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('structure.labels.employee_add')} action_new={handleShowNewEmployee} action_edit={handleShowEditEmployee} action_info={handleShowEditEmployee} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEmployeeEdit} onHide={handleCloseEmployee} scrollable={true} fullscreen backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('employes.labels.new_employee')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('employes.labels.edit')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('employes.labels.info_employee')}</span>
								</>
							]
						}
						: {curElement.firstname} {curElement.lastname}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1">
							<Form.Label column sm="2">{t('employes.labels.work_contract')}</Form.Label>
							<Col sm="5">
								<Form.Control as="select" defaultValue={curElement.domain_employer} name="domain_employer" onChange={(event) => onChangeHandler(event.currentTarget)} >
									<option value="-1" >{t('employee.labels.select_work_contract')}</option>
									{ui_list.domain_employer_list?.map((i, k) => (
										<option value={i} key={i.id}>{i.register} - {i.lastname} {i.firstname}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						<ElementFormWorkContract element={curElement} t={t} functions={{ edit: onChangeHandler, setElement: setCurElement, updateData: functions.updateData }} ui_list={ui_list} canWrite={canWrite} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseEmployee}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button disabled={isInvalid} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveEmployee}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
})





function ElementFormGeneral({ element, functions, cleaningcompany_list, t, ui_list, canWrite }) {
	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)

	return (
		<>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.register')}</Form.Label>
				<Col sm="8">
					<Form.Control size="sm" type="text" name="register" value={element.register} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control size="sm" type="text" name="firstname" value={element.firstname} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeLastName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.lastname')}</Form.Label>
				<Col sm="8">
					<Form.Control size="sm" type="text" name="lastname" value={element.lastname} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeAddress" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.address')} </Form.Label>
				<Col >
					<Row>
						<Col>
							<Form.Control type="text" name="street" sub="address" value={element.address.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col sm="2">
							<Form.Control type="text" name="zip" sub="address" value={element.address.zip} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							</Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" name="city" sub="address" value={element.address.city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="state" sub="address" value={element.address.state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeAddressResidence" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.residence')}</Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" name="street" sub="residence" value={element.residence.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col sm="2">
							<Form.Control type="text" name="zip" sub="residence" value={element.residence.zip} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							</Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" name="city" sub="residence" value={element.residence.city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="state" sub="residence" value={element.residence.state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<PhoneList element={element} functions={functions} t={t} canWrite={canWrite} />
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">Email</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="email" value={element.email} onChange={(event) => functions.edit(event.currentTarget)} />

				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">CF</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="cf" value={element.cf} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('employes.labels.birthdate')}</Form.Label>
				<Col sm="8">
					<DateTime initialValue={myTimestampToDate(element.birthdate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="birthdate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'birthdate', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="employeeAddressResidence" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.birthplace')}</Form.Label>
				<Col>
					<Row>
						<Col >
							<Form.Control type="text" name="birth_city" value={element.birth_city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="birth_state" value={element.birth_state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
						<Col sm="3">
							<Form.Control type="text" name="birth_nation" value={element.birth_nation} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								Stato
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			{/* 		<Form.Group as={Row} controlId="formEmployeeCF" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.id_card')}</Form.Label>
				<Col>
					<Row>

						<Col>
							<Form.Control type="text" name="id_type" value={element.id_type} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.id_card_type')}</Form.Text>
						</Col>
						<Col>
							<Form.Control type="text" name="id_code" value={element.id_code} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.id_card_code')}</Form.Text>
						</Col>
					</Row>
					<Row>

						<Col>
							<Form.Control type="text" name="id_issuedby" value={element.id_issuedby} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.id_card_issued')}</Form.Text>
						</Col>
						<Col>
							<DateTime initialValue={myTimestampToDate(element.id_expiration)} dateFormat='DD/MM/YYYY' timeFormat={false} name="id_expiration" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'id_expiration', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
							<Form.Text className="text-muted">{t('employes.labels.id_card_expiration')}</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group> */}
			{console.log("QRCODE", domain, contractId)}
			{element.id ?
				<QRCode value={JSON.stringify({ domain: domain, contract_id: contractId, employee_id: element.id })} />
				:
				<QRCode value={"test"} fgColor={"#eeeeee"} />

			}
		</>
	)
}




function ElementFormOtherInfo({ element, functions, t, ui_list, uploadingState }) {

	const { locale } = useContext(LocaleContext)

	const updateStudy = (b) => {
		functions.updateData('study_titles', b)
	}
	return (
		<>
			<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.photo')}</Form.Label>
				<Col>
					<Form.Control type="file" name="photo" onChange={(event) => functions.edit(event.target)} />
				</Col>
				<Col>
					{element.photo
						?
						<>

							<Image style={{ height: '50px' }} src={element.photo} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'photo', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: ''}
				</Col>
			</Form.Group>
			{uploadingState > -1 ?
				<Row>
					<Col>
						<ProgressBar animated now={uploadingState} label={`${uploadingState.toFixed(1)}%`} />
					</Col>
				</Row>
				: <></>}
			<Form.Group as={Row} controlId="formEmployeeNote" className="border-bottom mb-1">
				<Form.Label column sm="2">Note</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{/* <Form.Group as={Row} controlId="formEmployeeTradeUnion" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.trade_union')} </Form.Label>
				<Col>
					<Row>

						<Col>
							<Form.Control type="text" name="tradeunion_name" value={element.tradeunion_name} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('global.labels.name')}</Form.Text>
						</Col>
						<Col>
							<Form.Control type="text" name="tradeunion_inscriptiondate" value={element.tradeunion_inscriptiondate} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.inscription')}</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group> 
			<Form.Group as={Row} controlId="formEmployeeHealt" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.healthistitution_name')}</Form.Label>
				<Col>
					<Row>

						<Col>
							<Form.Control type="text" name="healthistitution_id" value={element.healtistitution_id} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.healthistitution_id')} </Form.Text>
						</Col>
						<Col>
							<Form.Control type="text" name="healthisitution_cardnumber" value={element.healthistitution_cardnumber} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.healthistitution_card')} </Form.Text>
						</Col>
					</Row>
					<Row>

						<Col>
							<Form.Control type="text" name="cf" />
							<Form.Text className="text-muted">{t('employes.labels.healthistitution_releasedate')} </Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" name="cf" />
							<Form.Text className="text-muted">{t('employes.labels.healthistitution_expirationdate')} </Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEmployeeCF" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.residency_permit')}</Form.Label>
				<Col>
					<Row>

						<Col>
							<Form.Control type="text" name="residency_issued_by" value={element.residency_issued_by} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.issued_by')}</Form.Text>
						</Col>
					</Row>
					<Row>

						<Col >
							<Form.Control type="text" name="residency_location" value={element.residency_location} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.released_city')}</Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" name="residency_expiration" value={element.residency_expiration} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">{t('employes.labels.expiration')}</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEmployeeCF" className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.disability')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="disability" value={false} checked={element && !element.disability} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col>
					<Form.Check type="radio" name="disability" value={true} checked={element && element.disability} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.disability ?
					<Col>
						<Form.Control type="number" min="0" max="100" name="disablity_level" value={element.disability_level} onChange={(event) => functions.edit(event.currentTarget)} />
						<Form.Text className="text-muted">{t('employes.labels.disability_level')}</Form.Text>
					</Col>
					: ''
				}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('employes.labels.study_title')} </Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue={getValue(element.study_title, 'Id')} name="study_title" onChange={(event) => functions.edit({ name: 'study_title', type: 'select', value: getSelectedElement(event.target.value, ui_list.study_titles) })} >
						<option value="-1" >{t('employee.labels.select_study_titles')}</option>
						{ui_list.study_titles.map((i, k) => (
							<option value={i.Id} key={k}>{i.name[locale]}</option>

						))}
					</Form.Control>
				</Col>
				<Col sm="1">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'study_titles', list: ui_list.study_titles }} t={t} functions={{ setCurSection: updateStudy }} />
				</Col>
						</Form.Group>*/}
		</>
	)
}


function ElementFormWorkContract({ element, functions, t, ui_list, canWrite }) {
	const { structureElements } = useContext(GlobalDataContext)

	const onChangeHandlerQualification = (q_id) => {

		const q = getSelectedElement(q_id, getSelectedElement(element.work_contract_id, ui_list.contract_list, 'id').qualification_list, 'id')
		console.log(q)
		if (q.id) {

			//			functions.edit({ name: 'qualification_id', value: q_id })

			if (q.training_list) {
				const _need_training_list = q.training_list.map(t => ({ ...t, execution_date: firebase.firestore.Timestamp.now(), expiration_date: firebase.firestore.Timestamp.now() }))
				//FIXME cerca tra eventuali titoli validi _> inserisci data di scadenza su lista nuova
				for (let nt of _need_training_list) {
					const training_list = element.training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
					if (training_list.length > 0) {
						const training = training_list[0]
						nt.done = training.done
						nt.execution_id = training.id
						nt.execution_date = training.execution_date
						nt.expiration_date = training.expiration_date || ''
					} else {
						nt.done = false
						delete nt.execution_id
					}
				}
				functions.setElement({ ...element, clothing_list: q.clothing_list ? q.clothing_list : [], qualification_id: q_id, need_training_list: _need_training_list ? _need_training_list : [] })
			} else {
				functions.setElement({ ...element, clothing_list: q.clothing_list ? q.clothing_list : [], qualification_id: q_id, need_training_list: [] })
			}
		} else {

			functions.setElement({ ...element, clothing_list: [], qualification_id: -1, need_training_list: [] })
		}

	}



	const checkTrainingValidity = () => {
		let _need_training_list = element.need_training_list
		if (_need_training_list) {
			console.log(_need_training_list)
			for (let nt of _need_training_list) {
				const training_list = element.training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
				if (training_list.length > 0) {
					const training = training_list[0]
					nt.done = training.done
					nt.execution_id = training.id
					nt.execution_date = training.execution_date
					nt.expiration_date = training.expiration_date || ''
				} else {
					nt.done = false
					delete nt.execution_id
				}
			}
		}
		functions.edit({ name: 'need_training_list', value: _need_training_list || [] })
	}


	const onChangeHandlerWear = (value, index) => {
		let _clothing_list = [...element.clothing_list]
		_clothing_list[index]['clothing_size'] = value
		functions.edit({ name: 'clothing_list', value: _clothing_list })
	}
	const setTrainingList = (_training_list) => {
		console.log("SET TRAININT LIST")
		functions.edit({ name: 'training_list', value: _training_list })
	}
	useEffect(() => {
		checkTrainingValidity()

	}, [element.training_list])
	const updateTrainingList = (_training_list) => {
		let _need_training_list = element.need_training_list
		for (let nt of _need_training_list) {
			const training_list = _training_list.filter(ct => ct.training_id === nt.id).sort(propComparator('expiration_date'))
			if (training_list.length > 0) {
				const training = training_list[0]
				nt.done = training.done
				nt.execution_id = training.id
				nt.execution_date = training.execution_date
				nt.expiration_date = training.expiration_date || ''
			} else {
				nt.done = false
				delete nt.execution_id
			}
		}
		functions.setElement({ ...element, 'need_training_list': _need_training_list ? _need_training_list : [], 'training_list': _training_list })
	}


	return (
		<>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">Stato contratto</Form.Label>
				<Col>
					<Form.Control as="select" value={element.contract_status} name="contract_status" onChange={(event) => functions.edit(event.target)} >
						<option value={-2} >...seleziona stato</option>
						<option value={0} >Neo assunto</option>
						<option value={1} >In organico</option>
						<option value={-1}>Cessato</option>
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.assumption_date')}</Form.Label>
				<Col>
					<DateTime initialValue={myTimestampToDate(element.assumption_date)} dateFormat='DD/MM/YYYY' timeFormat={false} name="assumption_date" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'assumption_date', value: myDateToTimestamp(e) })} />
				</Col>
			</Form.Group>
			{element.contract_status < 0 &&
				<Form.Group as={Row} className="border-bottom mb-1 pb-1">
					<Form.Label column sm="2">{t('employes.labels.contractexpiration_date')}</Form.Label>
					<Col>
						<DateTime initialValue={myTimestampToDate(element.expiration_date)} dateFormat='DD/MM/YYYY' timeFormat={false} name="expiration_date" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'expiration_date', value: myDateToTimestamp(e) })} />
					</Col>
				</Form.Group>
			}
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.work_contract')}</Form.Label>
				<Col sm="5">
					<Form.Control as="select" defaultValue={element.work_contract_id} name="work_contract_id" onChange={(event) => functions.edit(event.currentTarget)} >
						<option value="-1" >{t('employee.labels.select_work_contract')}</option>
						{ui_list.contract_list?.map((i, k) => (
							<option value={i.id} key={i.id}>{i.name}</option>
						))}
					</Form.Control>
				</Col>
				<Col>
					<Form.Check type="radio" name="timing" value="permanentjob" checked={(element && element.timing === 'permanentjob')} label={t('employes.labels.permanent_job')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="timing" value="temporarywork" checked={(element && element.timing === 'temporarywork')} label={t('employes.labels.temporarywork')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				<Col>
					{element.timing === 'temporarywork'
						?
						<>
							<DateTime initialValue={myTimestampToDate(element.work_expiration)} dateFormat='DD/MM/YYYY' timeFormat={false} name="work_expiration" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'work_expiration', value: myDateToTimestamp(e) })} />
							<Form.Text className="text-muted">{t('employes.labels.workexpiration')}</Form.Text>
						</>
						: ''}
				</Col>
			</Form.Group>
			{element.work_contract_id && element.work_contract_id !== '-1' && element.work_contract_id !== -1 ?
				<>
					<Form.Group as={Row} controlId="formEmployeeCF" className="border-bottom mb-1 pb-1">
						<Form.Label column sm="2">{t('employes.labels.work_qualification')} </Form.Label>
						<Col>
							<Form.Control as="select" value={element.qualification_id} name="qualification" onChange={(event) => onChangeHandlerQualification(event.target.value)} >
								<option value="-1" >{t('employee.labels.select_qualification')}</option>
								{element.work_contract_id && element.work_contract_id !== -1 ?
									<>
										{getSelectedElement(element.work_contract_id, ui_list.contract_list, 'id').qualification_list.map((i, k) => (
											<option value={i.id} key={k}>{i.name} - {t('employes.labels.qualification_level')} {getValue(i.level, 'name')}</option>
										))}
									</> : ''}
							</Form.Control>
						</Col>
					</Form.Group>
					{element.clothing_list && element.clothing_list.length > 0 ?
						<Row className="border-bottom mb-1 pb-1">
							<Col sm="2">{t('workcontract.labels.clothing_list')}</Col>
							<Col>
								<Row>
									<Col className="bg-secondary text-light ms-1" sm="8">
										{t('global.labels.name')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.clothing_size')}
									</Col>
								</Row>
								{element.clothing_list.map((w, k) => (
									<Row key={k} className="border-bottom mb-1 pb-1">
										<Col sm="8">
											{w.name}
										</Col>
										<Col>
											<FormControl aria-describedby="basic-addon1" value={w.clothing_size || ''} onChange={(event) => onChangeHandlerWear(event.target.value, k)} />
										</Col>
									</Row>
								))}
							</Col>
						</Row>
						: <i>Nessun abbigliamento richiesto.</i>}
					{element.need_training_list && element.need_training_list.length > 0 ?
						<Row className="border-bottom mb-1 pb-1">
							<Col sm="2">{t('workcontract.labels.need_training_list')}</Col>
							<Col>
								<Row>
									<Col className="bg-secondary text-light ms-1" sm="5">
										{t('global.labels.name')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.done')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.execution_date')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.expiration_date')}
									</Col>
									<Col sm="2" className="bg-secondary text-light ms-1 text-center">{t('global.labels.actions')}</Col>
								</Row>
								{element.need_training_list.map((w, k) => (
									<OneNeedTraining training={w} t={t} index={k} key={k} functions={{ setTrainingList: setTrainingList, updateTrainingList: updateTrainingList }} element={element} training_list={element.need_training_list} ui_list={ui_list} canWrite={canWrite} />
								))}
							</Col>
						</Row>
						: <i> Nessun titolo richiesto.</i>}

					<Row className="border-bottom mb-1 pb-1">
						<Col sm="2">{t('workcontract.labels.title_list')}</Col>
						{/* 						<Col>
							<Button onClick={checkTrainingValidity}>check</Button>
						</Col> */}
					</Row>
					{console.log('training_list', element.training_list)}
					{element.training_list && element.training_list.length > 0 ?
						<Row className="border-bottom mb-1 pb-1">
							<Col sm="2"></Col>
							<Col>
								<Row>
									<Col className="bg-secondary text-light ms-1" sm="5">
										{t('global.labels.name')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.done')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.execution_date')}
									</Col>
									<Col className="bg-secondary text-light ms-1">
										{t('employes.labels.expiration_date')}
									</Col>
									<Col sm="2" className="bg-secondary text-light ms-1 text-center">{t('global.labels.actions')}</Col>
								</Row>
								{element.training_list.map((w, k) => (
									<OneTraining training={w} t={t} index={k} key={k} functions={{ setTrainingList: setTrainingList, updateTrainingList: updateTrainingList }} element={element} training_list={element.training_list} ui_list={ui_list} canWrite={canWrite} />
								))}
							</Col>
						</Row>
						: <i>Nessun titolo conseguito</i>}

				</>
				: ''
			}
			<Form.Group as={Row} controlId="formEmployeeLevel" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.structures')}</Form.Label>
				<Col>
					<Form.Group as={Row} className="pb-1">
						{structureElements.structure_list.map((a, k) => (
							<Col key={k} sm="3" className={element.structure_list && element.structure_list.indexOf(a.id) >= 0 ? "m-1 bg-info text-light" : ' m-1 bg-light'}>
								<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
						))}
						{element.structure_list.filter(s_id => !structureElements.all_structure_dict[s_id]).map((s, k) => (
							<Col key={k} sm="3" className={element.structure_list && element.structure_list.indexOf(s) >= 0 ? "m-1 bg-warning text-light" : ' m-1 bg-light'}>
								<Form.Check key={k} type="checkbox" inline name="structure_list" checked={element.structure_list && element.structure_list.indexOf(s) >= 0} value={s} label={'---struttura non presente---'} alt={"---"} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>

						))}

					</Form.Group>
				</Col>
			</Form.Group>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneNeedTraining = ({ t, training: need_training, index, training_list, functions, element, ui_list, canWrite }) => {


	const setItem = (el) => {
		let _titem_list = [...element.training_list]
		console.log("TRAINING LIST", _titem_list)
		if (need_training.done) {
			const c = checkComponentPresence(el, element.training_list, 'id', 'training_id')
			console.log("do", c)
			_titem_list.splice(c, 1, el)
		} else {
			el.training_id = el.id
			el.id = makeid(6)
			console.log("renew")
			_titem_list.push(el)
		}
		functions.setTrainingList(_titem_list)
	}

	const updateItem = (el) => {
		console.log("update")
		let _item_list = [...element.training_list]
		_item_list.splice(index, 1, el)
		functions(_item_list)

	}

	const getTrainingClass = (text) => {
		if (!need_training.done || (need_training.expiration && myTimestampToDate(need_training.expiration_date) < new Date())) {
			return text ? " text-danger" : " border-danger"
		} else {
			return ""
		}
	}

	return (
		<Row className={`border-bottom mb-1 pb-1` + getTrainingClass()}>
			<Col sm="5" className={getTrainingClass(true)}>
				{need_training.name}
			</Col>
			<Col>
				{need_training.done ?
					<>{t('employes.labels.done')}
					</> :
					<>{t('employes.labels.undone')}
					</>}
			</Col>
			<Col>

				{need_training.done ?
					<>
						{format(myTimestampToDate(need_training.execution_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col className={getTrainingClass(true)}>
				{need_training.expiration && need_training.done ?
					<>
						{format(myTimestampToDate(need_training.expiration_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col sm="2" className="text-center">
				{need_training.done ?
					<>
						{console.log("TR id", need_training.execution_id, need_training, element.training_list, getSelectedElement(need_training.id, element.training_list, 'training_id'))}
						<ModalOneTraining item={getSelectedElement(need_training.id, element.training_list, 'training_id')} is_new={false} setItem={setItem} t={t} ui_list={ui_list} mode={'edit'} canWrite={canWrite} />
						{need_training.expiration ?
							<>
								<ModalOneTraining item={need_training} is_new={true} setItem={setItem} t={t} small={true} ui_list={ui_list} mode={"renew"} canWrite={canWrite} />
							</>
							: <></>}
					</>
					:
					<>
						<ModalOneTraining item={need_training} is_new={true} small={true} setItem={setItem} t={t} ui_list={ui_list} mode={"do"} canWrite={canWrite} />
					</>
				}
			</Col>
		</Row>

	)

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneTraining = ({ t, training, index, functions, element, ui_list, canWrite }) => {



	const getTrainingClass = (text) => {
		if (!training.done || (training.expiration && myTimestampToDate(training.expiration_date) < new Date())) {
			return text ? " text-danger" : " border-danger"
		} else {
			return ""
		}
	}
	const handleDeleteTraining = () => {
		let _titem_list = [...element.training_list]
		console.log(_titem_list)
		const c = checkComponentPresence(training, element.training_list, 'id')
		_titem_list.splice(c, 1)
		functions.setTrainingList(_titem_list)

	}

	const setItem = (el) => {
		let _titem_list = [...element.training_list]
		console.log(_titem_list)
		console.log("do")
		const c = checkComponentPresence(el, element.training_list, 'id')
		_titem_list.splice(c, 1, el)
		functions.setTrainingList(_titem_list)
	}

	return (
		<Row className={`border-bottom mb-1 pb-1` + getTrainingClass()}>
			{console.log(training)}
			<Col sm="5" className={getTrainingClass(true)}>
				{training.name}
			</Col>
			<Col>
				{training.done ?
					<>{t('employes.labels.done')}
					</> :
					<>{t('employes.labels.undone')}
					</>}
			</Col>
			<Col>

				{training.done ?
					<>
						{format(myTimestampToDate(training.execution_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col className={getTrainingClass(true)}>
				{training.expiration && training.done ?
					<>
						{format(myTimestampToDate(training.expiration_date), 'dd/MM/yyyy')}
					</> : ''}
			</Col>
			<Col sm="2" className="text-center">
				<ButtonGroup>
					<ModalOneTraining item={training} is_new={false} setItem={setItem} t={t} ui_list={ui_list} mode={'edit'} canWrite={canWrite} />
					<Button size="sm" variant="danger" onClick={handleDeleteTraining}><FontAwesomeIcon icon="trash" /></Button>
				</ButtonGroup>

			</Col>
		</Row>

	)

}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalOneTraining({ item, setItem, t, is_new, small, item_list, index, canWrite, all_training_list, ui_list, mode }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()

	const newtraining = {
		name: '',
		code: '',
		objectives: '',
		teaching_methodology: '',
		description: '',
		hour_length: 4,
		expiration: true,
		expiration_months: 24,
		execution_date: firebase.firestore.Timestamp.now(),
		expiration_date: firebase.firestore.Timestamp.now()
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newtraining)
	const [editedElement, setEditedElement] = useState(false)



	const handleShowEdit = () => {
		console.log("SHOW EDIT", is_new)
		if (is_new) {
			let new_t = { ...newtraining, ...item }
			console.log(new_t)
			setCurrentElement(new_t)

		} else {
			console.log(item)
			setCurrentElement(item)
		}
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 200)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (name === 'done' && value === true && curElement.expiration) {
			setCurrentElement({ ...curElement, [name]: value, expiration_date: addMonths(new Date(), item.expiration_months) })
		} else {
			setCurrentElement({ ...curElement, [name]: value })

		}
		console.log(name, value)
		setEditedElement(true)
	}




	const onChangeTrainingDate = (value) => {
		let _date = new Date()
		try {
			_date = value
		} catch (err) {
			console.log("ERROR DATE", err)
		}
		if (curElement.expiration) {
			const _expiration_date = addMonths(_date, curElement.expiration_months)
			setCurrentElement({ ...curElement, execution_date: firebase.firestore.Timestamp.fromDate(_date), expiration_date: firebase.firestore.Timestamp.fromDate(_expiration_date) })

		} else {
			setCurrentElement({ ...curElement, 'execution_date': firebase.firestore.Timestamp.fromDate(_date) })

		}
	}

	const handleClose = () => setShow(false);

	const handleSave = async () => {
		setItem(curElement)
		setShow(false)
		setEditedElement(false)
	}

	const ModeTitle = () => {
		if (mode === 'renew') {
			return ("rinnova")
		} else if (mode === 'do') {
			return ("esegui")
		} else if (mode === 'edit') {
			return ("modifica")
		} else {
			return ("error")
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={true} small={small} title_new={t('workcontract.labels.renew_training')} action_new={handleShowEdit} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						<ModeTitle />

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('employer.labels.new_training')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('employer.labels.edit_training')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('employer.labels.view_training')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						{is_new ?
							<Form.Group as={Row} controlId="eventSubject">
								<Form.Label column sm="2"> {t('global.labels.training')}</Form.Label>
								<Col>
									<Form.Control as="select" value={item.id} className="mb-2" name="training"
										onChange={(event) => onChangeHandler(event.currentTarget.value)} >
										<option value={"-1"} >{t('training.labels.select_training')}</option>
										{ui_list.training_list && ui_list.training_list.length > 0
											? [ui_list.training_list.map((f, k) => (
												<option key={k} value={f.id} >{f.name}</option>
											))]
											: ''
										}
									</Form.Control>
								</Col>
							</Form.Group>
							:
							<>
							</>
						}
						<Form.Group as={Row} className="border-bottom mb-1 pb-1" >
							<Form.Label column sm="2">{t('employes.labels.done')}</Form.Label>
							<Col sm="4">
								<Form.Check type="radio" name="done" value={true} checked={curElement.done} label={t('global.labels.yes')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Check type="radio" name="done" value={false} checked={!curElement.done} label={t('global.labels.no')} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						{curElement.done ?
							<Form.Group as={Row} className="border-bottom mb-1 pb-1" >
								<Form.Label column sm="2">{t('employes.labels.execution_date')}</Form.Label>
								<Col sm="4">
									<DateTime initialValue={myTimestampToDate(curElement.execution_date)} dateFormat='DD/MM/YYYY' timeFormat={false} name={"execution_date"} locale="it" closeOnSelect={true} onChange={(e) => onChangeTrainingDate(e.toDate())} />
								</Col>
							</Form.Group>
							: ''
						}
						{(curElement.done && curElement.expiration) || !curElement.done ?
							<Form.Group as={Row} className="border-bottom mb-1 pb-1" >
								<Form.Label column sm="2">{t('employes.labels.expiration_date')}</Form.Label>
								<Col sm="4" className={myTimestampToDate(curElement.expiration_date) < new Date() ? 'text-danger' : ''}>
									{format(myTimestampToDate(curElement.expiration_date), 'dd/MM/yyyy')}
								</Col>
								<Col>
									{curElement.expiration_months} {t('global.labels.months')}
								</Col>
							</Form.Group>
							: <></>}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}




/* 
function ElementFormEducation({ element, functions, t, ui_list }) {

	const newqualification = {
		trainingdate: new Date(),
		name: {},
		level: {},
		active: true
	}
	const [showTrainingEdit, setShowTrainingEdit] = useState(false)
	const [newTrainingEdit, setNewTrainingEdit] = useState(true)
	const [curTraining, setCurTraining] = useState(newqualification)
	const handleCloseTraining = () => setShowTrainingEdit(false);
	const handleSaveTraining = () => {
		setShowTrainingEdit(false);
		let newtraining_list = element.qualification_list
		newtraining_list.push(curTraining)
		console.log(newtraining_list)
		functions.edit({ name: 'training_list', value: newtraining_list })
	}
	const handleShowNewTraining = () => {
		setCurTraining(newqualification)
		setNewTrainingEdit(true)
		setShowTrainingEdit(true)
	}
	const onChangeHandlerTraining = (event) => {
		console.log(event)
		const { name, value } = event
		console.log(name, value)
		setCurTraining({ ...curTraining, [name]: value })
	}
	return (
		<>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="2">
					<Row>
						<Form.Label column sm="2">{t('employes.labels.training')}</Form.Label>
					</Row>
					<Row>
						<Col><Button disabled variant="outline-primary" size="sm" onClick={() => handleShowNewTraining()}><FontAwesomeIcon icon="plus-square" />{t('employes.buttons.add_training')}</Button></Col>
					</Row>
				</Col>
				<Col>
					{element.training_list && element.training_list.length > 0 ?

						<>
							<Row className="bg-secondary text-light">
								<Col sm="2" small>{t('employes.labels.date')} </Col>
								<Col>{t('employes.labels.qualification_level')} </Col>
								<Col>&nbsp;</Col>
							</Row>
							{element.training_list.map((i, key) => (
								<Row key={key}>
									<Col>{i.active}</Col>
									<Col>{i.level.name}</Col>
									<Col>{i.name.name}</Col>
									<Col>{format(i.trainigdate, 'dd/MM/yy')}</Col>
									<Col>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: ''
					}
				</Col>
			</Form.Group>
		</>
	)
} */


export default ModalEmployee

//export default translate()(ModalEmployee)