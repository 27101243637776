import React, { useState, useContext, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, ButtonGroup, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { getSystemMenu_list } from '../constants/systemsections';
import { popoverTiming } from '../constants/global_definitions';




function ModalAdvanceUserPermission({ item, setItem, t, small, index, item_list, ui_list, setParentList }) {
    const permission_dict = getSystemMenu_list({ t: t, config: true })

    const newsystemgroup = {
        permission_dict: {}
    }
    const is_new = item === undefined

    const [showSystemgroupEdit, setShowSystemgroupEdit] = useState(false)
    const [curElement, setCurElement] = useState(newsystemgroup)
    const [editedElement, setEditedElement] = useState(false)


    const handleCloseSystemgroup = () => setShowSystemgroupEdit(false);

    const handleSaveSystemgroup = async () => {

        setShowSystemgroupEdit(false);
        if (is_new) {
            setItem(curElement)
        } else {
            setItem(curElement)
        }
    }

    const handleDeleteSystemgroup = () => {
        setItem(undefined)

    }


    const handleShowNewSystemgroup = () => {
        console.log('show new')
        setCurElement(newsystemgroup)
        setShowSystemgroupEdit(true)
        setEditedElement(false)
    }


    const handleShowEditSystemgroup = () => {
        if (!item.color) {
            item.color = { ...newsystemgroup.color }
        }
        setCurElement(item)
        setShowSystemgroupEdit(true)
        setTimeout(function () {
            setEditedElement(false)

        }, 100)
    }


    const onChangeHandler = (el) => {
        let { name, value, type } = el
        if (type === 'radio') {
            if (value === 'true') {
                value = true
            } else if (value === 'false') {
                value = false
            }
        } else if (type === 'checkbox') {
            let oldcheck = []
            try {
                oldcheck = [...curElement[name]]
            } catch (err) { }
            if (el.checked) {
                if (oldcheck.indexOf(value) < 0) {
                    oldcheck.push(value)
                }
            } else {
                if (oldcheck.indexOf(value) >= 0) {
                    oldcheck.splice(oldcheck.indexOf(value), 1)
                }
            }
            value = oldcheck
        }
        console.log(name, value)
        setCurElement({ ...curElement, [name]: value })
        setEditedElement(true)
    }

    const getMenuValue = (v) => {
        console.log()
        if (permission_dict) {
            for (let p = 0; p < permission_dict.length; p++) {
                for (let s = 0; s < permission_dict[p].menulist.length; s++) {
                    if (permission_dict[p].menulist[s].id === v) {
                        return permission_dict[p].menulist[s].label
                    }
                }
            }
        }

        return (<></>)
    }

    const renderPopover = (props) => (
        
        <Tooltip id="button-tooltip" {...props}>
            Lettura/Scrittura:
            {Object.keys(Object.fromEntries(Object.entries(item.permission_dict).filter(([k, v]) => v == "1"))).map((d, k) => (
                <Badge key={k} className="me-1" bg="success">{getMenuValue(d)}</Badge>
            ))}
            Sola lettura:
            {Object.keys(Object.fromEntries(Object.entries(item.permission_dict).filter(([k, v]) => v == "0"))).map((d, k) => (
                <Badge key={k} className="me-1" bg="warning">{getMenuValue(d)}</Badge>
            ))}

        </Tooltip>
    );

    return (
        <>
            {is_new ?
                (small ?
                    <Button onClick={() => handleShowNewSystemgroup()} variant="outline-primary" size="sm"><FontAwesomeIcon icon='plus-square' /> </Button>
                    :
                    <Button onClick={() => handleShowNewSystemgroup()} size="sm"><FontAwesomeIcon icon='plus-square' /> Aggiungi ruolo custom</Button>
                )
                : <ButtonGroup>
                    <OverlayTrigger
                        placement="right"
                        delay={popoverTiming}
                        overlay={renderPopover}
                    >

                        <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditSystemgroup()}><FontAwesomeIcon icon="pencil-alt" />Modifica ruolo custom  </Button>
                    </OverlayTrigger>
                    <Button className="me-1" variant="danger" size="sm" onClick={() => handleDeleteSystemgroup()}><FontAwesomeIcon icon="trash" /> </Button>
                </ButtonGroup >
            }
            <Modal show={showSystemgroupEdit} onHide={handleCloseSystemgroup} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        {is_new ?
                            <>
                                <FontAwesomeIcon icon="plus-square" /> <span className="text-primary">Nuovo ruolo personalizzato</span>
                            </>
                            :
                            <>
                                <FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">Modifica ruolo personalizzato</span>
                            </>
                        }
                        : {curElement.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} is_new={is_new} ui_list={ui_list} />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSystemgroup}>
                        {t('modal.close')}
                    </Button>
                    <Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveSystemgroup}>
                        <FontAwesomeIcon icon="save" /> {t('modal.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function ElementFormDetails({ element, functions, ui_list, t, is_new }) {

    const permission_dict = getSystemMenu_list({ t: t, config: true })

    const OneMenuSection = ({ i, k, element, functions }) => {
        return (

            <Row key={k} className="border-start border-end mb-1 mt-1 border-bottom border-secondary">
                <Col>
                    <Row className="bg-secondary text-light">
                        <Col>
                            {i.label}
                        </Col>
                    </Row>
                    {i.menulist.filter(m => m.type === 'link').map((m, km) => (
                        <>
                            {console.log(m)}
                            <OnePermissionSection p={m} key={km} k={km} element={element} functions={functions} />
                        </>
                    ))}
                </Col>
            </Row>
        )
    }


    const OnePermissionSection = ({ p, k, element, functions }) => {
        const handleUpdatePermission = (elem) => {
            element.permission_dict[p.id] = elem.value
            functions.edit({ name: 'permission_dict', value: element.permission_dict })
        }
        const getPermissionClass = (c) => {
            if (c === '0') {
                return 'text-light bg-primary border-bottom'
            } else if (c === '1') {
                return 'text-light bg-success border-bottom'
            } else {
                return 'text-secondary bg-light border-bottom'
            }
        }

        console.log(p)

        return (
            <Form.Group as={Row} key={k} controlId={p.id}>
                <Col className={getPermissionClass(element.permission_dict[p.id])}>
                    <i>
                        {p.href}
                    </i>
                </Col>
                <Col sm="6">
                    <b>{p.label}</b>
                </Col>
                <Col>
                    <Button variant={!element.permission_dict[p.id] || element.permission_dict[p.id] === '-1' ? 'secondary' : 'outline-secondary'} onClick={() => handleUpdatePermission({ name: "permission" + p.id, value: '-1' })}>
                        {t('global.labels.no')}
                    </Button>
                </Col>
                <Col>
                    <Button variant={element.permission_dict[p.id] === '0' ? 'primary' : 'outline-primary'} onClick={() => handleUpdatePermission({ name: "permission" + p.id, value: '0' })}>
                        {t('global.labels.read')}
                    </Button>
                </Col>
                <Col>
                    <Button variant={element.permission_dict[p.id] === '1' ? 'success' : 'outline-success'} onClick={() => handleUpdatePermission({ name: "permission" + p.id, value: '1' })}>
                        {t('global.labels.readwrite')}
                    </Button>
                </Col>
            </Form.Group>
        )
    }

    return (
        <>
            <Row className="border-bottom">
                <Form.Label column sm="2">{t('licence.labels.permissions')}</Form.Label>
                <Col >
                    {permission_dict ?
                        <>
                            {permission_dict.map((i, k) => (
                                <OneMenuSection i={i} key={k} element={element} functions={functions} />
                            ))}
                        </>
                        : ''
                    }
                </Col>
            </Row>
        </>
    )
}


export default translate()(ModalAdvanceUserPermission)