import {firestore} from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const addDomain = async (domainId, data) => {
    console.log("addDomain: start")
    const domains = await firestore.collection('DOMAINS')
                                    
    console.log("addDomain - domains: " , domains)
    if(domains === null){
        console.log("addDomain: collection('DOMAINS') not available")
    }


    const docRef = await firestore.collection('DOMAINS').doc(domainId)
    const res = await docRef.get()
            .then( function(doc){
                if(doc.exists){
                    console.log("addDomain error: DOMAIN '", domainId, "' already exist")
                    let error = new Error("domain ", domainId, " allready exist")
                    return {error: error}
                } else{
                    doc.ref.set(data)
                    return {data}
                }

            }).catch(function(error){
                    console.log("addDomain (get) - error: ", error)
                    return {error: error}
            });

    console.log("addDomain - res: ", res)
    return res
}


///////////////////////////////////////////////////////////////////////////////
export const editDomain = async (domainId, data) => {
    try {
        const domain = firestore.collection('DOMAINS')
            .doc(domainId)
            
        const res = await domain.set(
            data
        ).then( () => {
			console.log("editDomain - edit successfully")
			return data
		}).catch( error => {
			console.log("editDomain - edit error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("editDomain error: ", e)
        return { error: e }
    }
}




///////////////////////////////////////////////////////////////////////////////
export const getDomainData = async (domainId) => {
    try {
        const domain = firestore.collection('DOMAINS')
            .doc(domainId)
            
        const res = await domain.get()
        .then( (doc) => {
            if(doc.exists){
			    console.log("getDomain:", doc)
                return doc.data()
            }else{
                console.error("getDomain: domain '", domainId, "' doesn't exist")
                let error = new Error("domain ", domainId, " doesn't exist")
                return {error: error}
            }
		}).catch( error => {
			console.log("getDomain - get error: ", error)
			return {error: error}
		});

        return res
    }  catch (e) {
        console.log("getDomain error: ", e)
        return { error: e }
    }
}







///////////////////////////////////////////////////////////////////////////////
export const getDomainList = async () => {
    const snapshot = await firestore.collection('DOMAINS')
                                        .get()

    const domainList = snapshot.docs.map( doc => {
        const firebaseData = doc.data()
        const data = {
            ...firebaseData,
            id: doc.id,
        }
        return data
    })
    return domainList;
    
}






