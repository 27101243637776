import React, {  useState, forwardRef, useImperativeHandle, useReducer } from 'react';
import { Alert, } from 'react-bootstrap';
const AlertSection = forwardRef((props, ref) => {
    const initialId = {count: 0}
    function reducer(state, action){
        return {count: state.count +1}
    }
    const [alerts, setAlerts] = useState({})
    const [alert_id, dispatch] = useReducer(reducer,initialId)
    useImperativeHandle(ref, () => ({
        addAlert(type, message) {
            dispatch()
            setAlerts({...alerts, [alert_id.count]: { id:alert_id.count, variant: type, text: message, show:true }})
            setTimeout(function () {
                hideAlert(alert_id.count)
            }, 10000)
        }
    }));
    const hideAlert = (alert_id) => {
        console.log("hideAlert", alert_id)
        setAlerts({...alerts , [alert_id]: {...alerts[alert_id], show:false}})
    }

    return <div className="alert_container">
        {Object.keys(alerts).map((keyName, i) => (
            <Alert key={i} show={alerts[keyName].show} variant={alerts[keyName].variant} dismissible onClose={(i) => hideAlert(alerts[keyName].id)}>
                {alerts[keyName].text}
            </Alert>
        ))}
    </div>
})
export default AlertSection