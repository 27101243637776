import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Modal, Form, FormControl, InputGroup, Nav, Tab, Badge,  Popover } from 'react-bootstrap';
import { format,  differenceInDays } from 'date-fns'
import { it } from 'date-fns/locale'
import { translate } from 'react-polyglot'
import QRCode from 'qrcode.react'
import DateTime from 'react-datetime';
import firebase from 'firebase/compat/app'

import GlobalDataContext from '../../contexts/globaldataContext.js';
import DomainContext from '../../contexts/domainContext.js';
import { makeid, checkComponentPresence, getSelectedElement, getFloatNumber, myTimestampToDate, rgba2hex, getValue, getBaseObjectValue, isEditable, propComparator, checkDatePresence, getTimeValue, getFormattedDate, getSelectedElementB } from '../../../util/ui_utils.js';
import { MultipleToggleEdit, renderItemNote, ServiceBadge, WeekDays, renderActivityPeriodListPopover, Frequency, ResultBar } from '../../StructureGraphicElements.js'
import { checkNextUpdate, getPlannedDays } from './sectionModalRoom.js';
import { popoverTiming } from '../../constants/global_definitions.js';
import { ModalProcessCheck } from '../../ModalProcessCheck.js';
import { ServiceCheckPreview } from '../../CheckPreview.js';
import { getInterventionById } from '../../../Firebase2/Storage2/dbIntervention.js';


let intervention_dict = {}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ElementFormFrequencySummary({ element, functions, t, ui_list, canWrite, is_new, log_list, check_dict }) {
	const { service_dict, roomtype_dict, contract } = useContext(GlobalDataContext)

	const { domain } = useContext(DomainContext)
	const contractId = contract.id

	const OneResult = ({ a, check_type }) => {
		//const room = getSelectedElement(a.room_id, ui_list.room_structure_dict[structure.id], 'id')
		const room = element
		const [intervention, setIntervention] = useState({ service: {} })
		const [counts, setCounts] = useState({})
		const [mydata, setData] = useState([1, 0, 2])
		const [options, setOptions] = useState({})
		let roomtype = {}
		if (room && room.roomtype) {
			roomtype = roomtype_dict[room.roomtype.id]

		}
		const [curActivity, setCurActivity] = useState({})


		const initResult = async () => {
			let _intervention = intervention_dict[a.intervention_id]

			if (!_intervention) {
				_intervention = await getInterventionById(domain, contractId, a.intervention_id)
				//					console.log("SET", domain, contractId, intervention_id, intervention)
				//updateIntervention(intervention)
				intervention_dict[a.intervention_id] = _intervention
				setIntervention(_intervention)
			} else {
				setIntervention(_intervention)
			}
			setCurActivity(getSelectedElementB(_intervention.service.id, roomtype.service_list, 'service', 'id'))
			//console.log("ROOM, intervention", room, _intervention)
			const res_array = Object.values(a.result_dict)
			let _counts = {}
			for (const res_a of res_array) {
				_counts[res_a] = _counts[res_a] ? _counts[res_a] + 1 : 1
			}
			setCounts(_counts)

			const series = [
				{
					name: 'Lqa',
					data: [

						{
							x: '2013',
							y: [54, 52],
							goals: [
								{
									name: 'Lqr',
									value: 65,
									strokeWidth: 10,
									strokeColor: '#775DD0'
								}
							]
						},
						{
							x: '2014',
							y: [66, 65],
							goals: [
								{
									name: 'Lqr',
									value: 65,
									strokeWidth: 10,
									strokeColor: '#775DD0'
								}
							]
						},
					]
				}
			]
			const myoption = {
				chart: {
					toolbar: { show: false },
					height: 50,
					type: 'bar'
				},
				plotOptions: {
					bar: {
						horizontal: true,
					}
				},


				colors: a.lqa < a.lqr ? ['#00ff00'] : ['#ff0000'],
				dataLabels: {
					formatter: function (val, opt) {
						const goals =
							opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
								.goals
						if (goals && goals.length) {
							return `${val} / ${goals[0].value}`
						}
						return val
					}
				},

				legend: {
					show: true,
					showForSingleSeries: true,
					customLegendItems: ['Lqr', 'Lqa'],
					markers: {
						size: 1,
						colors: ['#000000'],
						strokeWidth: 10,
						shape: 'square'
					}
				}
			}


			setData(series)
			setOptions(myoption)
		}





		useEffect(() => {
			initResult()
		}, [])






		if (check_type === 'results') {
			return (
				<Row className="element_row p-1 border-bottom">
					<Col>
						Controllo di risultato
					</Col>
					<Col>
						{intervention.name}<br />
						<ServiceBadge service={service_dict[intervention.service.id]} freq={true} />
					</Col>
					<Col>
						{a.timing_creation && (a.timing_creation === 'post' || a.timing_creation === 'pre') ?
							<>

								{a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre') ?
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo fuori orario e non conteggiato</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="exclamation-triangle" className="text-danger" />
									</OverlayTrigger>
									:
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo creato fuori orario ma considerato in orario</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="info" />
									</OverlayTrigger>
								}</>
							: ''}&nbsp;
						{format(myTimestampToDate(a.end_check), 'dd/MM/yyyy HH:mm')}
					</Col>
					<Col>
						{a.result_dict ?
							<ResultBar length={Object.keys(a.result_dict).length} res_dict={counts} />
							: <></>}
					</Col>
					<Col className="h5">
						<Badge className={a.lqr < getFloatNumber(a.lqa) ? 'bg-danger text-light' : 'bg-success'}>{a.lqr.toFixed(2)} / {a.lqa}</Badge>
					</Col>
					<Col>
						<ServiceCheckPreview roomtype={roomtype} activity={curActivity} ui_list={ui_list} t={t} roomType_name={room.name + " " + roomtype.name} roomType_elements={[...roomtype.furniture_list, ...roomtype.environment_element_list, ...roomtype.economic_material_list]} res_data={a} />
					</Col>
				</Row>

			)
		} else {
			return (
				<Row className="element_row p-1 border-bottom">
					<Col>
						<Row>
							<Col>
								{intervention.name}
							</Col>
						</Row>
						<Row>
							<Col>
								<ServiceBadge service={service_dict[intervention.service.id]} freq={true} />
							</Col>
						</Row>
					</Col>
					<Col>
						{a.timing_creation && (a.timing_creation === 'post' || a.timing_creation === 'pre') ?
							<>

								{a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre') ?
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo fuori orario e non conteggiato</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="exclamation-triangle" />
									</OverlayTrigger>
									:
									<OverlayTrigger
										placement="right"
										delay={popoverTiming}
										overlay={<Tooltip id="button-tooltip">controllo creato fuori orario ma considerato in orario</Tooltip>}
										message=""
									>
										<FontAwesomeIcon icon="info" />
									</OverlayTrigger>
								}</>
							: ''}&nbsp;
						{format(myTimestampToDate(a.end_check), 'dd/MM/yyyy HH:mm')}
					</Col>
					<Col>
						<ResultBar length={Object.keys(a.result_dict).length} res_dict={counts} />
					</Col>
					<Col>
						<Badge className={a.lqr < parseFloat(a.lqa.replace(/,/, '.')) ? 'bg-danger text-light' : 'bg-success'}>{a.lqr.toFixed(2)} /{a.lqa}</Badge>
					</Col>
					<Col>
						{intervention ?
							<ModalProcessCheck element={intervention} ui_list={ui_list} t={t} _preview={a.timing_type} _res_data={a} />
							: <></>}
					</Col>

				</Row>
			)
		}
	}



	return (
		<>
			<Row>
				<Col className="text-primary h4">
					Interventi programmati
				</Col>
			</Row>
			<Row className="p-1 text-light">
				<Col className="bg-secondary ms-1" >Nome</Col>
				<Col className="bg-secondary ms-1" >Tipologia</Col>
				<Col className="bg-secondary ms-1" >Prestazione</Col>
				<Col className="bg-secondary ms-1" sm="1" >LQA</Col>
				<Col className="bg-secondary ms-1 text-center" sm="1">{t('workarea.labels.operating_units')}</Col>
				<Col className="bg-secondary ms-1 text-centerp-0" sm="2">{t('workareas.labels.frequency')}</Col>
			</Row>
			{Object.values(ui_list.intervention_dict).filter(i => i.room_list.map(r => r.id).indexOf(element.id) >= 0).map((intervention, k) => (
				<InterventionRow intervention={intervention} service_dict={service_dict} roomtype_dict={roomtype_dict} key={k} t={t} element={element} />
			))}
			<Row>
				<Col className="text-primary h4">
					Ultimo intervento eseguito
				</Col>
			</Row>
			<Row className="p-1 text-light">
				<Col className="bg-secondary ms-1" >Nome</Col>
				<Col className="bg-secondary ms-1" >Tipologia</Col>
				<Col className="bg-secondary ms-1" >Prestazione</Col>
				<Col className="bg-secondary ms-1" sm="1" >LQA</Col>
				<Col className="bg-secondary ms-1 text-center" sm="1">{t('workarea.labels.operating_units')}</Col>
				<Col className="bg-secondary ms-1 text-centerp-0" sm="2">Orario Esecuzione</Col>
			</Row>
			{log_list && log_list.length > 0 &&  ui_list.intervention_dict[log_list[0].intervention_id]?
				<InterventionRow intervention={ui_list.intervention_dict[log_list[0].intervention_id]} service_dict={service_dict} roomtype_dict={roomtype_dict} ext_data={log_list[0]} t={t} element={element} />
				:
				<>Nessun intervento registrato</>
			}
			<Row>
				<Col className="text-primary h4">
					Ultimo Controllo eseguito
				</Col>
			</Row>

			{check_dict && (check_dict.results || check_dict.process) ?
				<>
					<Row className="border-bottom mb-1 pb-2">
						<Col className="bg-secondary text-light ms-1" >Tipologia di controllo</Col>
						<Col className="bg-secondary text-light ms-1" >{t('global.labels.intervention')} | {t('global.labels.service')}</Col>
						<Col className="bg-secondary text-light ms-1" >{t('global.labels.date')}</Col>
						<Col className="bg-secondary text-light ms-1" >{t('global.labels.results')}</Col>
						<Col className="bg-secondary text-light ms-1" >LQR/LQA</Col>
						<Col className="bg-secondary text-light ms-1" ></Col>
					</Row>
					{check_dict.results &&
						<OneResult check_type='results' a={check_dict.results} />
					}
					{check_dict.process &&
						<OneResult check_type='process' a={check_dict.process} />
					}
				</>

				:
				<>Nessun controllo effettuato su questo locale</>
			}
		</>

	)
}





const InterventionRow = ({ intervention, service_dict, roomtype_dict, ext_data, t, element }) => {
	const { contract } = useContext(GlobalDataContext)

	const rt = element ? roomtype_dict[element.roomtype ? element.roomtype.id : -1] : undefined
	const activity = getSelectedElementB(intervention.service.id, rt ? rt.service_list : [], 'service', 'id')
	if (!activity.operation_list) {
		console.warn("DEBUG----", rt, intervention.service.id)
	}
	console.log("ACTIVITY", activity)
	return (
		<Row className="mb-1 pb-1 border-bottom">
			<Col>
				<OperationSummaryTooltip operation_list={activity.operation_list} />&nbsp;
				{intervention.name}
			</Col>
			<Col >
				{intervention.type === 'ordinary' ?
					t('workarea.labels.frequency_ordinary')
					:
					t('workarea.labels.frequency_periodic')
				}
			</Col>
			<Col>
				<ServiceBadge service={service_dict[intervention.service.id]} />
			</Col>
			<Col sm="1">
				{activity.lqa}
			</Col>
			<Col sm="1">
				<FontAwesomeIcon icon="user" /> &times; {intervention.operating_units}
			</Col>
			{ext_data && contract.room_lastintervention_detail_mode === 'beacon' ?
				<Col sm="2" className="text-center">
					{format(myTimestampToDate(ext_data.start), 'dd/MM/yy HH:mm')} - {format(myTimestampToDate(ext_data.end), 'HH:mm')}
				</Col>
				:
				<Col sm="2" className="text-center">
					{intervention.type === 'ordinary' || intervention.periodic_data.isSameTime ?
						<>{getTimeValue(intervention.start)} - {getTimeValue(intervention.end)}</>
						:
						<>orari variabili</>
					}
					{intervention.type === 'periodic' && <ModalInterventionDetail t={t} periodic_data={intervention.periodic_data} />}
					<Frequency roomtype_id={element.roomtype ? element.roomtype.id : -1} service_id={intervention.service.id} roomtype_dict={roomtype_dict} customFrequency={intervention.custom_weekdays && intervention.custom_weekdays[element.id] ? intervention.custom_weekdays[element.id] : undefined} />
				</Col>
			}
		</Row>
	)
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalInterventionDetail({ t, periodic_data }) {
	const now = new Date()
	const [showDetailDialog, setShowDetailDialog] = useState(false)

	const handleCloseAction = () => setShowDetailDialog(false);
	const handleSaveAction = async () => {
		setShowDetailDialog(false);
	}


	const handleShowEditAction = () => {
		setShowDetailDialog(true)
	}



	return (
		<>
			<Button className="me-1" variant="outline-info" size="sm" onClick={() => handleShowEditAction()}><FontAwesomeIcon icon="info-circle" /> </Button>
			<Modal show={showDetailDialog} onHide={handleCloseAction} backdrop="static" className="bg-secondary" size="xl" scrollable={true}>
				<Modal.Header closeButton>
					<Modal.Title>
						<span className="text-info"><FontAwesomeIcon icon="info-circle" /> {t('interventions.labels.intervention_detail')}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						{periodic_data.date_list && periodic_data.date_list.length > 0 ?
							<>
								<Row className="section_title">
									<Col></Col>
									<Col></Col>
									<Col sm="3">{t('workareas.labels.start_time')}</Col>
									<Col sm="3">{t('workareas.labels.end_time')}</Col>
								</Row>
								{periodic_data.date_list
									.sort((a, b) => myTimestampToDate(a.date) - myTimestampToDate(b.date))
									.map((i, k) => (
										<Row key={k} className="border-bottom mb-1 pb-1">
											<Col className={myTimestampToDate(i.date) < now ? "bg-secondary text-light" : ""}>
												{format(myTimestampToDate(i.date), 'dd/MM/yyyy')}
											</Col>
											<Col>
												{k > 0 &&
													<span className="text-info">[+ {differenceInDays(myTimestampToDate(i.date), myTimestampToDate(periodic_data.date_list[k - 1].date))}gg]</span>
												}
											</Col>
											<Col sm="3">
												{getFormattedDate(myTimestampToDate(i.start), 'HH:mm')}
											</Col>
											<Col sm="3" className={myTimestampToDate(i.end) <= myTimestampToDate(i.start) ? 'text-danger' : ''}>
												{getFormattedDate(myTimestampToDate(i.end), 'HH:mm')}
											</Col>
										</Row>
									))}
							</>
							: ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseAction}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


const OperationSummaryTooltip = ({ operation_list }) => {
	console.log("op", operation_list)
	return (
		<>
			<OverlayTrigger
				trigger={["hover", "focus"]}
				placement="bottom"
				rootClose
				overlay={
					<Popover id="tooltip-top" content="html">
						<Popover.Header as="h3">Lista operazioni</Popover.Header>
						{operation_list && operation_list.length > 0 &&
							<Popover.Body>
								{operation_list.map((op, k) => (
									<Row key={k}>
										<Col>
											<Badge bg="secondary">{k + 1}</Badge> {op.name}
										</Col>
									</Row>
								))}
							</Popover.Body>
						}
					</Popover>
				}
			>
				<FontAwesomeIcon icon="info-circle" />
			</OverlayTrigger>
		</>
	)
}