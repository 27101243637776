import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Image, Row, Col, Button, ButtonGroup, Spinner, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useTranslate } from 'react-polyglot';
import { format } from 'date-fns'

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'

import { MediaPreview } from '../components/StructureGraphicElements.js';


import ModalAdminUploader from '../components/modal/ModalAdminUploader.js'
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"


import { myTimestampToDate, getValue, checkComponentPresence, propComparator } from "../util/ui_utils.js"
import { mediaType_dict } from '../components/constants/global_definitions.js';


import { deleteSystemDocument, getSystemDocumentList } from '../Firebase2/Storage2/dbSystemDocument.js';
import { deleteFileTracker } from '../Firebase2/Storage2/dbFileStorage.js'




let load_system = true

const section = 'system'

const domain = 'admin'
const contractId = '0'


////////////////////////////////////////////////////////////////////////////////////////////////////////
////
////
////////////////////////////////////////////////////////////////////////////////////////////////////////
const DocumentsSystemView = ({ t, uData }) => {

    const type_dict = mediaType_dict({ t: t, section: section })
    const childRefAlert = useRef()
	const childRefDelete = useRef()

    const [loading, setLoading] = useState(true)

    const [canWrite, setWritePage] = useState(false)
    const [media_list, setMediaList] = useState([])




    useEffect(() => {
        const initValues = async () => {
            let ret_data = await getSystemDocumentList({})
            if (!ret_data.error) {
                setMediaList(ret_data.systemdocumentList.sort(propComparator('description')))
            }
            setLoading(false)
        }
        initValues()
    }, [])


    const editMediaList = (el, is_new) => {
		const editlist = [...media_list]
        if (is_new){
            editlist.push(el)
        }else{
		    const c = checkComponentPresence(el, editlist, 'id')
		    editlist.splice(c, 1, el)
        }
		setMediaList(editlist.sort(propComparator('description')))

    }



    const getFileLabel = (i_type) => {
        try {
            return type_dict[i_type].label
        } catch (err) {
            return i_type
        }
    }

    const getElementCount = (t) => {

        if (media_list) {
            return (<>{media_list.filter((f => f.type !== 'image')).length} {t('global.labels.documents')}</>)
        } else {
            return (<>0 {t('global.labels.documents')}</>)
        }
    }



	const handleDeleteMedia = (el) => {
		console.log(el)
		const cb = async () => {
            const d = deleteFileTracker(domain, contractId, el.tracker.id)
			let return_data = await deleteSystemDocument(el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...media_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setMediaList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('systemdoc.messages.delete_systemdoc_title'), t('systemdocs.messages.delete_systemdoc', { systemdoc: el.description }), cb)
	}


    return (
        <>
            <AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
            <Container className="titleStickyTop" fluid>
                <Row className="mt-1">
                    <Col className="h3">
                        <PageTitle title={t('global.pages.systemdocument_management')} admin={true} />
                    </Col>
                    <Col className="text-end">
                <ModalAdminUploader t={t} is_new={true} section="system" functions={{edit: editMediaList}}/>
                        </Col>
                </Row>
                {media_list && media_list.length>0?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" sm="2"></Col>
						<Col className="bg-secondary ms-1">{t('global.labels.type')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.title')}</Col>
						<Col className="bg-secondary ms-1">{t('global.labels.file')}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="2">{t('global.labels.actions')}</Col>
					</Row>
                :<></>}

            </Container>
            <Container fluid>

                {loading ?
                    <Row>
                        <Col>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    :


                    media_list  && media_list.length>0?
                        <>
                            {media_list.map((f, key) => (
                                <Row key={key} className="border-bottom mb-1 pb-1">
                                    {f.type !== 'link' ?
                                        <Col sm="2"><MediaPreview i={f} /></Col>
                                        :
                                        <Col sm="2"></Col>
                                    }
                                    <Col><FontAwesomeIcon icon={f.type} /> {getFileLabel(f.type)}</Col>
                                    <Col>{f.description}</Col>
                                    <Col>{f.tracker.source_name}</Col>
                                    <Col sm="2" className="text-center p-1">
                                        <ButtonGroup>
                                            <Button size="sm" variant="outline-primary" href={f.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" />{t('media.buttons.open')}</Button>
                                            <ModalAdminUploader t={t} element={f} section="system"  functions={{edit: editMediaList}}/>
                                            <Button size="sm" variant="danger" onClick={() => handleDeleteMedia(f)}><FontAwesomeIcon icon="trash" /> </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            ))}
                        </>
                        : <i>Nessun documento presente</i>
                }
            </Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount(t)} />
        </>
    );
}






/*const OneImage = ({ doc }) => {
    return (
        <Image className="imageDocumentViewerSmall" src={doc.url} />
    )
}

const OneDocument = ({ doc }) => {
    const t = useTranslate();
    const getLabel = (doc) => {
        try {
            return t(type_dict[doc.type].label)
        } catch (err) {
            return doc.type
        }
    }
    return (

        <Row className="border-bottom mb-1 pb-1">
            <Col>
                {doc.description.length > 0 ? doc.description : <i>doc.source_name</i>}
            </Col>
            <Col>
                {getLabel(doc)}
            </Col>
            <Col sm="2" className="p-1 text-center">
                <Button size="sm" className="me-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
            </Col>
        </Row>

    )
} */


const OneFile = ({ doc }) => {
    const { locale } = useContext(LocaleContext)
    const t = useTranslate();
    const type_dict = mediaType_dict({ t, section: 'contract' })
    const getLabel = (doc) => {
        try {
            return type_dict[doc.type].label
        } catch (err) {
            return doc.type
        }
    }

    return (
        <Col sm="6" md="4" xl="2" className="mb-2">
            <Card border="primary" className="h-100">
                {doc.type === 'image' ?
                    <span className="d-flex" style={{ maxHeight: '150px' }}>
                        <Image className="imageDocumentViewer" src={doc.url} />
                    </span>
                    : <Card.Header> {doc.description.length > 0 ? doc.description : <i>{doc.source_name}</i>}</Card.Header>
                }
                <Card.Body>
                    <h3>{doc.name}</h3>
                    <Row>
                        <Col className="text-secondary">{t('documents.labels.' + doc.section)}: {doc.item_reference && doc.item_reference.category ? <>{getValue(doc.item_reference.category.name, locale)}</> : ''}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {doc.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/*  <FileIcon doc={doc} />  */}
                            {getLabel(doc)}
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>
                            <small className="text-muted">data inserimento: {doc.insered_at ? format(myTimestampToDate(doc.insered_at), 'dd/MM/yyyy HH:mm') : "non disponibile"}</small>
                        </Col>
                        <Col className="text-end" sm="4">
                            <Button size="sm" className="me-1" variant="primary" href={doc.url} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> {t('media.buttons.open')}</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>


    )

}


export default translate()(DocumentsSystemView)