import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, Spinner, Table } from 'react-bootstrap';
import { translate } from 'react-polyglot'


import DomainContext from '../components/contexts/domainContext.js'


import PageTitle from "../components/PageTitle.js"

import readXlsxFile from 'read-excel-file'
import { roomStatus_dict } from "../components/constants/global_definitions.js"

import { getSelectedElement, compareName, checkComponentPresence, myDateToTimestamp, myTimestampToDate } from "../util/ui_utils.js"





import { newemployee } from '../components/ModalEmployee.js';
import { format, parse } from 'date-fns';
import {  addDomainBatchEmployer } from '../Firebase2/Storage2/dbEmployers.js';







const parseDate = (d) => {
    try {
        if (d instanceof Date) {
            return (myDateToTimestamp(d))
        } else {

            console.log(d)
            const dateParts = d.split("/");
            const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            console.log("DATE", dateObject)
            return myDateToTimestamp(dateObject)
        }
    } catch (err) {
        console.log(err)
        return myDateToTimestamp(new Date())
    }
}


const ImportEmployersView = ({ t, switchSection }) => {

    const { domain } = useContext(DomainContext)



    const [loading, setLoading] = useState(false)

    const status_dict = roomStatus_dict({ t })


    const [itemCounter, setItemCounter] = useState(0)
    const [totalItem, setTotalItem] = useState(0)

    const [allData, setAllData] = useState([])
    const [showImporting, setShowImporting] = useState(false)
    const [showImportDone, setShowImportDone] = useState(false)
    const [curImportData, setCurImportData] = useState('')
    const [invalid, setInvalid] = useState(false)




    const handleCloseImporting = async () => {
        switchSection("employes");
    }


    const validElement = (el) => {
        let valid = true
        if (el[0].length === 0) {
            valid = valid && false
        }
        if (el[1].length === 0) {
            valid = valid && false
        }
        if (el[2].length === 0) {
            valid = valid && false
        }
        /*         if (el[3].length === 0) {
                    valid = valid && false
                }
                if (el[4].length === 0) {
                    valid = valid && false
                } */
        return valid

    }

    const onChangeHandlerMedia = async (el) => {
        console.log(el)
        let { name, value, type } = el
        console.log(el.files)
        value = el.files
        if (name !== undefined) {
            console.log(name, value)
        }

        setLoading(true)
        let tmpImportedData = []


        const imported_data = await readXlsxFile(el.files[0])
            .then((rows) => {
                // `rows` is an array of rows
                // each row being an array of cells.
                let skip_first = false

                let tmp_counter = 0
                setTotalItem(rows.length)


                var curDate = new Date();
                var lastMilliseconds = curDate.getTime();
                var curMilliseconds = lastMilliseconds


                rows.forEach(element => {
                    if (skip_first) {
                        if (validElement(element)) {
                            let employer = {
                                ...newemployee,
                                register: element[0],
                                firstname: element[1],
                                lastname: element[2],
                                address: {
                                    street: element[3],
                                    zip: element[4],
                                    city: element[5],
                                    state: element[6],
                                },
                                residence: {
                                    street: element[7],
                                    zip: element[8],
                                    city: element[9],
                                    state: element[10]
                                },
                                phone_list:
                                    element[11] && element[11].length > 0 ?
                                        [{ description: 'tel', number: element[11] }]
                                        : []
                                ,
                                email: element[12],
                                cf: element[13],
                                birthdate: parseDate(element[14]),
                                birth_city: element[15],
                                birth_state: element[16],
                                birth_nation: element[17],
                                id_type: element[18],
                                id_code: element[19],
                                id_issuedby: element[20],
                                id_expiration: parseDate(element[21])
                            }
                            tmpImportedData.push(employer)
                            curDate = new Date();
                            curMilliseconds = curDate.getTime();
                            if (curMilliseconds > lastMilliseconds + 250) {
                                setItemCounter(tmp_counter)
                                lastMilliseconds = curMilliseconds
                            }
                        } else {
                            setInvalid(true)
                        }
                    } else {
                        skip_first = true;
                    }
                });
                setItemCounter(tmp_counter)
                return tmpImportedData
            }).catch(error => {
                console.log("error importing data: ", error)
                return { error: error }
            })


        console.log("imported_data: ", imported_data)

        imported_data.sort(compareName)
        setAllData(imported_data)
        setLoading(false)

    }



    const onImportData = async () => {
        console.log('importing fase 1')
        setShowImporting(true)
        await addDomainBatchEmployer(domain, allData)
        setItemCounter(0);
        setAllData({})
        console.log('importing fase 4')
        setShowImportDone(true)
    }




    return (
        <div>
            <Container className="titleStickyTop" fluid>
                <Row className="mb-2 text-start">
                    <Col>
                    {loading ? <Spinner animation="border" role="status" /> : <>righe nel file: {itemCounter} &nbsp;</>}  
                    </Col>
                    <Col className="text-end">
                        <Button variant="outline-info" href="/TemplateDipendenti.xlsx" download><FontAwesomeIcon icon="file-excel" /> {t('import.buttons.download_template')}</Button>
                    </Col>
                </Row>
                <Form.Group as={Row} className="border-bottom pb-1">
                    <Form.Label column sm="2" className="text-end"><FontAwesomeIcon icon="file-excel" /> {t('global.labels.media_file')}</Form.Label>
                    <Col>
                        <Form.Control type="file" name="file" label="carica file *.xlsx" accept=".xlsx" onChange={(event) => onChangeHandlerMedia(event.target)} />
                    </Col>
                    {allData.length > 0 ?

                        <Col className="text-end">
                            <Button variant="success" onClick={onImportData} disabled={invalid}><FontAwesomeIcon icon="cogs" /> AVVIA IMPORTAZIONE</Button>
                        </Col>
                        : ''}
                </Form.Group>


                <Row>
                    {loading ?
                        <Row>
                            <Col className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>&nbsp;Caricamento in corso
                            </Col>
                        </Row>
                        : ''}
                </Row>
                {invalid ?
                    <Row>
                        <Col className="text-danger">
                            {t('employers.labels.incomplete_data')}
                        </Col>
                    </Row>
                    : ''}
                {itemCounter > 0 &&
                    <>
                        <Row>
                            <Col>
                                Elementi rilevati
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <Container fluid>


                {allData.length > 0 &&
                    <>
                        <Row>
                            <Col sm="1" className="bg-secondary mt-1 mb-1 text-light me-1">
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                Matricola
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                Nome
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                Cognome
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                Indirizzo
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                Residenza
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                email
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                cf
                            </Col>
                            <Col className="bg-secondary mt-1 mb-1 text-light me-1">
                                data di nascita (luogo)
                            </Col>
                        </Row>
                        {allData.map((i, k) => (
                            <Row key={k}>
                                <Col sm="1">
                                    {k + 1}
                                </Col>
                                <Col>
                                    {i.register}
                                </Col>
                                <Col><b>{i.firstname}</b></Col>
                                <Col><b>{i.lastname}</b></Col>
                                <Col>{i.address.street} {i.address.zip} {i.address.city} {i.address.state}</Col>
                                <Col>{i.residence.street} {i.residence.zip} {i.residence.city} {i.residence.state}</Col>
                                <Col>{i.email}</Col>
                                <Col>{i.cf}</Col>
                                <Col>{i.birthdate && format(myTimestampToDate(i.birthdate), 'dd/MM/yyyy')} ({i.birth_city} {i.birth_state} {i.birth_nation})</Col>
                            </Row>

                        ))}
                    </>
                }


                <Modal show={showImporting} backdrop="static">
                    <Modal.Header>
                        Importazione in corso
                    </Modal.Header>
                    <Modal.Body>
                        Importazione dei dipendenti in corso.
                        <br />... {curImportData} <br />
                        <i>(La procedura può richiedere diversi minuti: si prega di attendere senza chiudere la pagina fino al termine dell'importazione)</i>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>
            </Container>
            <Modal show={showImportDone}>
                <Modal.Body>
                    Importazione eseguita correttamente
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseImporting}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}


export default translate()(ImportEmployersView)