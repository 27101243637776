import React, { useContext, useState, useEffect  } from 'react';

import { Spinner } from 'react-bootstrap';
import Chart from "react-apexcharts";
import DashboardContext from '../contexts/dashboardContext.js';


//----------------------------------------
//----------------------------------------
export const OperatorSummary = ({ t }) => {
    const {loadingGraph, loading_step, operating_unit} = useContext(DashboardContext)
	const [mydata, setData] = useState([1, 0, 2])
	const [options, setOptions] = useState({})

	useEffect(() => {
		//const d_total = operating_unit.done + operating_unit.undone + operating_unit.doing
		const series = [
			operating_unit.done,
			operating_unit.doing,
			operating_unit.undone
		]

		const myoption = {
			labels: ['servizio terminato', 'in servizio', 'in attesa di servizio'],
			chart: {
				type: 'donut',
			},
			colors: ['#20c997', '#ffc107', '#007bff'],
			tooltip: {
				enable: false,
			},
			plotOptions: {
				pie: {
					donut: {
						labels: {
							show: false,
							name: {
								show: false,
								fontSize: '8px'
							},
							value: {
								fontSize: '14px',
							},
							total: {
								show: true,
								color: '#000',
								label: 'operatori',
							},
						}
					}
				}
			},
			legend: {
				show: true,
				formatter: function (myvalue, opts) {
					console.log("OPERATOR", myvalue, opts, series)
					return myvalue + ": " + series[opts.seriesIndex]
				},
				markers: {
					size: 5,
					shape: undefined,
					strokeWidth: 1,
					fillColors: undefined,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				},
			},
			dataLabels: {
				enabled: true,
				enabledOnSeries: undefined,
				textAnchor: 'middle',
				distributed: false,
				offsetX: 0,
				offsetY: 0,
				style: {
					fontSize: '12px',
					fontFamily: 'Helvetica, Arial, sans-serif',
					fontWeight: 'bold',
					colors: undefined
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: '#fff',
					opacity: 1,
					dropShadow: {
						enabled: false,
					}
				},
				dropShadow: {
					enabled: false,
				}
			}
		}
		setData(series)
		setOptions(myoption)
	}, [operating_unit])






    return (


		loadingGraph ?
			<>
				<Spinner animation="border" role="status" />&nbsp;Caricamento in corso ({loading_step})
			</>
			:
		mydata.reduce((a,b) =>a+b)>0?
		<Chart
			options={options}
			series={mydata}
			labels={['servizio terminato', 'in servizio', 'in attesa di servizio']}
			type="donut"
			height="180"
			width={"100%"}
		/>
		:<i>Nessun operatore presente oggi</i>
	)

}