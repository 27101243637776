import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, Badge, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot';
import { BlockPicker } from 'react-color';
import reactCSS from 'reactcss'
import { getObjectType_list } from "../components/constants/baseobjecttypes"
import { checkComponentPresence } from "../util/ui_utils.js"
import { addBaseObjectType as addBaseObjectType2, editBaseObjectType as editBaseObjectType2 } from "../Firebase2/Storage2/dbBaseObject"
import DomainContext from './contexts/domainContext'



const newElement = {
	name: { it: '', en: '' },
	is_enabled: true,
	order: 0,
	color: { r: 255, g: 255, b: 255, a: 1 }
}

const ModalBaseObject = (props) => {
	const { domain } = useContext(DomainContext)


	const t = props.t
	let defaultcolors = [
		'#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#607d8b", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800",
	]
	if (props.cursection.name === 'risk_areas') {
		defaultcolors = [
			'#FF0000', '#00FF00', "#03a9f4", "#a3a3a3", "#ff8300", "#795548", '#803e7e', '#ffff00'
		]

	}
	const objectType_list = getObjectType_list({ t: t })
	const cursection = props.cursection
	const [curElement, setCurrentElement] = useState(newElement)
	const [editedElement, setEditedElement] = useState(false)


	const styles = reactCSS({
		'default': {
			color: {
				width: '36px',
				height: '14px',
				borderRadius: '2px',
				background: curElement.color? `rgba(${curElement.color.r}, ${curElement.color.g}, ${curElement.color.b}, ${curElement.color.a})`: `rgba(100,100,100,1)`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				position: 'absolute',
				zIndex: '2',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		},
	});
	const [showEdit, setShow] = useState(false);
	const [newEdit, setNew] = useState(true);
	const [displayColorPicker, setShowColorPicker] = useState(false)
	const [color, setCurColor] = useState({ r: 100, g: 0, b: 0, a: 1 })

	const handleClose = () => setShow(false);
	const handleSave = async () => {
		if (props.is_new) {
			let return_data =  await addBaseObjectType2(domain, cursection.name, curElement)
			console.log( cursection, return_data)
			if (return_data && return_data.error) {
				//		childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newData = [...cursection.list]
				newData.push(return_data)
				props.functions.setCurSection({ ...cursection, list: newData, lastId: return_data.Id })
				setShow(false);
			}
		} else {
			const return_data = await editBaseObjectType2(domain, cursection.name, curElement.Id, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				//		childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let editlist = [...cursection.list]
				const c = checkComponentPresence(curElement, cursection.list, 'Id')
				editlist.splice(c, 1, curElement)
				props.functions.setCurSection({ ...cursection, list: editlist})
				setShow(false);
			}
		}
	}
	const handleShowNew = () => {
		setCurrentElement(newElement)
		setNew(true)
		setShow(true)
	}
	const handleShowEdit = (el) => {
		setCurrentElement(props.element)
		setNew(false)
		setShow(true)
	}
	const copyFromOtherLanguage = () => {

	}
	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el;
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')

		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curElement[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
	}
	const onChangeHandlerColor = (el) => {
		setCurrentElement({ ...curElement, color: el.rgb })
	}
	const handleChangeColor = (c) => {
		console.log('set color', c)
		setCurColor(c.rgb)
	}
	const handleShowColorPicker = () => {
		setShowColorPicker(!displayColorPicker)
	}
	const handleCloseColorPicker = () => {
		setShowColorPicker(false)
	}


	const checkValidity = () => {
		let validity = true
		try {
			if (curElement.name === undefined || curElement.name.it === undefined || curElement.name.it.length === 0){
				validity = false
			}

		} catch (err) {
			validity = false

		}
		return validity
	}



	return (
		<>
			{props.is_new ?
				(props.small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('global.labels.add')} <span className="text-lowercase font-weight-bold">{objectType_list[cursection.name].label_one}</span></Button>
				)
				:
				<Button onClick={() => handleShowEdit()} variant="info" className="me-1" size="sm" ><FontAwesomeIcon icon='pencil-alt' /> </Button>
			}
			<Modal show={showEdit} onHide={handleClose} size="xl" backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{props.is_new && <Modal.Title className="text-primary">{objectType_list[props.cursection.name].label_one} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!props.is_new && <Modal.Title className="text-info">{objectType_list[props.cursection.name].label_one} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid >
						<Form.Group as={Row} controlId="name">
							<Form.Label column sm="2">{t('objecttype.labels.name')}</Form.Label>
							<Col sm="8">
								<Row>
{/* 									<Col sm="1">
										<i>IT</i>
									</Col>
									<Col> */}
										<Form.Control size="sm" type="text" value={curElement.name? curElement.name.it: ''} sub="name" name="it" onChange={(event) => onChangeHandler(event.currentTarget)} />
{/* 									</Col>
 */}								</Row>
								{/* <Row>
									<Col sm="1">
										<i>EN</i>
									</Col>
									<Col>
										<Form.Control size="sm" type="text" value={curElement.name.en} sub="name" name="en" onChange={(event) => onChangeHandler(event.target)} />
									</Col>
								</Row>
								<Row>
									<Col sm="1">
										<i>DE</i>
									</Col>
									<Col>
										<Form.Control size="sm" type="text" value={curElement.name.de} sub="name" name="de" onChange={(event) => onChangeHandler(event.target)} />
									</Col>
								</Row> */}
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="name">
							<Form.Label column sm="2">{t('objecttype.labels.order')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="number" value={curElement.order || 0} name="order" onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						{cursection !== 'brands' ?
							<Row>
								<Col sm="2">{t('objecttype.labels.color')}</Col>
								<Col>
									<Button variant="outline-info" onClick={handleShowColorPicker}>
										<div style={styles.color} />
									</Button>
									{displayColorPicker ? <div style={styles.popover}>
										<div style={styles.cover} onClick={handleCloseColorPicker} />
										<BlockPicker color={color} colors={defaultcolors} triangle="hide" onChange={handleChangeColor} onChangeComplete={onChangeHandlerColor} />
									</div> : null}
								</Col>
							</Row>
							: ''
						}
 						<Form.Group as={Row}>
							<Form.Label column sm="2">{t('objecttype.labels.group')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" value={curElement.group || curElement.group} name="group" onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('global.labels.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} disabled={!checkValidity()} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('global.labels.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
export default translate()(ModalBaseObject)