import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, FormControl, Row, Col, Button, Spinner, ButtonGroup, Tooltip, OverlayTrigger, InputGroup, Modal, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams, useLocation } from "react-router-dom"
import { format, subMonths } from 'date-fns'
import DateTime from 'react-datetime';

import DomainContext from '../components/contexts/domainContext'
import LocaleContext from '../components/contexts/locale.js'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"

import writeXlsxFile from 'write-excel-file'

import ModalEmployeePreview from "../components/ModalEmployeePreview"
import ModalEmployee from "../components/ModalEmployee"
import PageTitle from "../components/PageTitle"
import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import Footer from "../components/Footer"
import { SearchSection, SearchSectionTest } from '../components/SearchSection';
import { popoverTiming } from '../components/constants/global_definitions';

import { checkComponentPresence, getElementCount, getSelectedElement, getValue, propComparator, getQualificationParam, compareName, myTimestampToDate } from "../util/ui_utils.js"



import { getDomainEmployerList, deleteDomainEmployer } from '../Firebase2/Storage2/dbEmployers';
import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getWorkjobList } from '../Firebase2/Storage2/dbDomainWorkjob';
import { getDomainTrainerList } from '../Firebase2/Storage2/dbTrainers';
import ModalDomainEmployee from '../components/modal/ModalDomainEmployee';
import { getDomainTrainingList } from '../Firebase2/Storage2/dbTraining';

function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

let load_page = false



const EmployesDomainView = ({ t, uData, contract_list, switchSection }) => {
	const { domain, } = useContext(DomainContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	const childRefElement = useRef(null)
	const [filterAppliedList, setFilterAppliedList] = useState({ status: [] })
	const [date_range, setDateRange] = useState({ start: subMonths(new Date(), 1), end: new Date() })

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}

	const { initContract } = InitContractHelper()
	let location = useLocation()
	let query = useQuery();


	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [employer_list, setEmployerList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [searchTerm, setSearchTerm] = useState('')
	const [canWrite, setWritePage] = useState(false)

	const initValues = async () => {
		setWritePage(true)
		setLoading(true)
		if (domain.length > 0) {
			const qualification_title = await getBaseObjectTypeList2(domain, 'qualification_title')
			const study_titles = await getBaseObjectTypeList2(domain, 'study_titles')
			let workjob_list = await getWorkjobList(domain)
			let newtraining_list = await getDomainTrainingList(domain)
			console.log("TRAINING_LIST", newtraining_list)
			const workjob_dict = Object.assign({}, ...workjob_list.map((x) => ({ [x.id]: x })));
			setUiList(l => ({ ...l, study_titles, qualification_title, workjob_list, training_list: newtraining_list.sort(compareName), workjob_dict }))
			let _employers_list = await getDomainEmployerList(domain, {})

			console.log("employeslist", _employers_list)
			_employers_list = _employers_list.sort(propComparator('lastname'))
			console.log("REF---", location.search, childRefElement)
			const elemId = query.get("elemId")
			if (location && elemId && childRefElement.current && getSelectedElement(elemId, _employers_list, 'id')) {
				childRefElement.current.onEdit(getSelectedElement(elemId, _employers_list, 'id'))
			}
			setElementList(_employers_list)
			setLoading(false)
		} else {
			console.log('problem')
		}
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}



	useEffect(() => {
		initValues()
	}, [])








	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {
			let return_data = await deleteDomainEmployer(domain, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('employes.messages.delete_employes_title'), t('employes.messages.delete_employes'), cb)
	}



	// -----------------------------------------------
	const onHandleExport = async () => {
		const data = element_list
			.filter(i => filterAppliedList.status.indexOf('all') >= 0 || i.contract_status > -1)
			.filter(i => (searchTerm.length === 0 || (i.firstname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.lastname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.register.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)))

		const schema = [
			// --------
			{
				column: 'Nome',
				type: String,
				value: user => user.firstname
			},
			// --------
			{
				column: 'Cognome',
				type: String,
				value: user => user.lastname
			},
			// --------
			{
				column: 'matricola',
				type: String,
				value: user => user.register
			},
			// --------
			{
				column: 'Indirizzo (via)',
				type: String,
				value: user => user.address.street
			},
			// --------
			{
				column: 'Indirizzo (cap)',
				type: String,
				value: user => user.address.cap
			},
			// --------
			{
				column: 'Indirizzo (città)',
				type: String,
				value: user => user.address.city
			},
			// --------
			{
				column: 'Indirizzo (provincia)',
				type: String,
				value: user => user.address.state
			},
			// --------
			{
				column: 'Residenza (via)',
				type: String,
				value: user => user.residence.street
			},
			// --------
			{
				column: 'Residenza (cap)',
				type: String,
				value: user => user.residence.cap
			},
			// --------
			{
				column: 'Residenza (città)',
				type: String,
				value: user => user.residence.city
			},
			// --------
			{
				column: 'Residenza (provincia)',
				type: String,
				value: user => user.residence.state
			},
			// --------
			{
				column: 'Email',
				type: String,
				value: user => user.email
			},
			{
				column: 'Telefono 1 (tipo)',
				type: String,
				value: user => user.phone_list.length > 0 ? user.phone_list[0].description : ''
			},
			{
				column: 'Telefono 1 (numero)',
				type: String,
				value: user => user.phone_list.length > 0 ? user.phone_list[0].number : ''
			},
			{
				column: 'Telefono 2 (tipo)',
				type: String,
				value: user => user.phone_list.length > 1 ? user.phone_list[1].description : ''
			},
			{
				column: 'Telefono 2 (numero)',
				type: String,
				value: user => user.phone_list.length > 1 ? user.phone_list[1].number : ''
			},



			// --------
			{
				column: 'CF',
				type: String,
				value: user => user.cf
			},
			// --------
			{
				column: 'doc identita (tipo)',
				type: String,
				value: user => user.id_type
			},
			// --------
			{
				column: 'doc identita (rilasciato da)',
				type: String,
				value: user => user.id_code
			},
			// --------
			{
				column: 'doc identita (numero)',
				type: String,
				value: user => user.id_issuedby
			},
			// --------
			{
				column: 'doc identita (scadenza)',
				type: Date,
				format: 'dd/mm/yyyy',
				value: user => myTimestampToDate(user.id_expiration)
			},
			// --------
			{
				column: 'data di nascita',
				type: Date,
				format: 'dd/mm/yyyy',
				value: user => myTimestampToDate(user.birthdate)
			},
			// --------
			{
				column: 'luogo di nascita (citta)',
				type: String,
				value: user => user.birth_city
			},
			// --------
			{
				column: 'luogo di nascita (provincia)',
				type: String,
				value: user => user.birth_state
			},
			// --------
			{
				column: 'luogo di nascita (nazione)',
				type: String,
				value: user => user.birth_nation
			},
			// --------
			{
				column: 'data assunzione',
				type: String,
				value: user => user.assumption_data ? format(myTimestampToDate(user.assumption_data), 'dd/MM/yyyy') : ''
			},
			// --------
			{
				column: 'data cessazione',
				type: String,
				value: user => user.expiration_data ? format(myTimestampToDate(user.expiration_data), 'dd/MM/yyyy') : ''
			},
			// --------
			{
				column: 'note',
				type: String,
				value: user => user.note
			},

		]

		const fileXlsx = await writeXlsxFile(data, {
			schema,
			fileName: `Claudit-${domain}_dipendenti_${format(new Date(), 'yyyyMMdd')}.xlsx`
		})

		//const blob = new Blob(fileXlsx)
		//saveAs (blob, "export.xlsx");

	};


	const updateDate = (el, p) => {
		console.log(el)
		if (p === 'start') {

			setDateRange(d => ({
				start: el.toDate(),
				end: d.end
			}))
		} else {
			setDateRange(d => ({
				start: d.start,
				end: el.toDate()
			}))

		}
	}
	const importUrl = "/#/" + '/import_employes'

	return (
		<>
			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col sm={4}>
						<Row>
							<Col>

								<ButtonGroup size="sm" className="me-1">
									<Button disabled variant="secondary"><FontAwesomeIcon icon="filter" /></Button>
									<Button size="sm"
										variant={filterAppliedList['status'].indexOf('all') !== -1 ? 'outline-secondary' : "secondary"}
										onClick={(e) => updateAppliedFilter('status', 'all')}>
										Solo attivi
									</Button>
									<Button size="sm"
										variant={filterAppliedList['status'].indexOf('all') === -1 ? 'outline-secondary' : "secondary"}
										onClick={(e) => updateAppliedFilter('status', 'all')}>
										Tutti
									</Button>
								</ButtonGroup>
							</Col>
							{filterAppliedList['status'].indexOf('all') === -1 &&
								<>
									<Col sm="1">Da</Col>
									<Col sm="3">
										<DateTime initialValue={date_range.start} dateFormat={"DD/MM/yyyy"} timeFormat={false} name="start" locale="it" closeOnSelect={true} onChange={(v) => updateDate(v, 'start')} />
									</Col>
									<Col sm="1">a</Col>
									<Col sm="3">
										<DateTime initialValue={date_range.end} dateFormat={"DD/MM/yyyy"} timeFormat={false} name="end" locale="it" closeOnSelect={true} onChange={(v) => updateDate(v, 'end')} />
									</Col>
								</>
							}
						</Row>
					</Col>
					<Col sm="2">
						<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
					</Col>
					<Col style={{ textAlign: 'right' }} sm>
						<ButtonGroup>
							{element_list && element_list.length > 0 &&
								<Button variant="outline-info" onClick={onHandleExport} ><FontAwesomeIcon icon="file-export" />Esporta xlsx</Button>
							}
							<Button variant="outline-info" onClick={() => switchSection('import')} ><FontAwesomeIcon icon="file-import" />{t('company.labels.import')}</Button>
							<ModalDomainEmployee t={t} ref={childRefElement} is_new={true} item_list={element_list} ui_list={ui_list} functions={{ updateData: onUpdateData }} setParentList={setElementList} canWrite={canWrite} contract_list={contract_list} />
						</ButtonGroup>
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<Row className=" p-1 text-light">
						<Col className="bg-secondary ms-1" sm="2">{t('employes.labels.register')}</Col>
						<Col className="bg-secondary ms-1" sm="2">{t('employes.labels.firstname')}</Col>
						<Col className="bg-secondary ms-1">{t('employes.labels.lastname')}</Col>
						<Col className="bg-secondary ms-1">{t('employes.labels.qualification')}</Col>
						<Col className="bg-secondary ms-1">Contratti</Col>
						<Col className="bg-secondary ms-1">{t('employes.labels.structures')}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
					</Row>
					: ''}

				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{element_list && element_list.length > 0 ?
							<>
								{element_list
									.filter(i => (filterAppliedList.status.indexOf('all') >= 0
										|| i.contract_status > -1
										|| isWorkingInDateRange(i, date_range))

									)
									.filter(i => (searchTerm.length === 0 || (i.firstname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.lastname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) || (i.register.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)))
									.map((i, key) => (
										<OneEmployer i={i} ui_list={ui_list} key={key} element_list={element_list} setElementList={setElementList} canWrite={canWrite} onUpdateData={onUpdateData} handleDelete={handleDelete} t={t} contract_list={contract_list} />
									))}
							</>
							:
							<Row>
								<Col className="font-italic">
									{t('employes.messages.no_employe')}
								</Col>
							</Row>
						}
					</>
				}

			</Container>

		</>
	);
}


const getExpirationTime = (i) => {
	try {
		if (i.timing === 'temporarywork') {
			console.log(i.work_expiration)
			return (<>({format(myTimestampToDate(i.work_expiration), 'dd/MM/yyyy')})</>)
		} else {
			return ('')
		}

	} catch (err) {
		return (<></>)
	}

}



const OneEmployer = ({ ui_list, i, element_list, setElementList, canWrite, onUpdateData, t, handleDelete, contract_list }) => {
	console.log(i)
	return (
		<Row className="element_row p-1 border-bottom">
			<Col sm="2">
				<EmployerStatus status={i.contract_status} />
				{i.register}
			</Col>
			<Col sm="2"><b>{i.firstname}</b></Col>
			<Col><b>{i.lastname}</b></Col>
			<Col>
				{getExpirationTime(i)}
				{ui_list.workjob_dict[i.qualification_id]?.name}
			</Col>
			<Col>
				{i.contract_list?.map((c) => (
					<Badge bg="info">{getSelectedElement(c, contract_list, 'id')?.name}</Badge>
				))}
			</Col>
			<Col>
				{i.structure_list && i.structure_list.length > 0 ?
					<>
					<Badge bg="info">{i.structure_list.length}</Badge>
					</>
					: <></>				}

			</Col>
			<Col className="text-center" sm="1">
				<ButtonGroup>
					<ModalEmployeePreview item={i} />
					<ModalDomainEmployee t={t} item={i} is_new={false} item_list={element_list} ui_list={ui_list} functions={{ updateData: onUpdateData }} setParentList={setElementList} canWrite={canWrite} contract_list={contract_list} />
					{canWrite ?
						<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
						: ''}
				</ButtonGroup>
			</Col>

		</Row>

	)
}

const isWorkingInDateRange = (employer, date_range) => {
	const st = myTimestampToDate(employer.assumption_date)
	const end = myTimestampToDate(employer.expiration_date)
	if (
		date_range.start <= st && (employer.contract_status > 0 || date_range.end >= end)
	) {
		return true
	} else {
		return false
	}

}



const EmployerStatus = ({ status }) => {
	const _status = status + ""
	if (_status === '-1') {
		return (
			<OverlayTrigger
				placement="right"
				delay={popoverTiming}
				overlay={<Tooltip id="button-tooltip">
					Contratto cessato
				</Tooltip>}
				message="contratto cessato"
			>
				<FontAwesomeIcon icon="stop" />
			</OverlayTrigger>
		)

	} else if (_status === '0') {
		return (
			<OverlayTrigger
				placement="right"
				delay={popoverTiming}
				overlay={<Tooltip id="button-tooltip">
					Neoassunto
				</Tooltip>}
				message="Neoassunto"
			>
				<FontAwesomeIcon icon="play-circle" />
			</OverlayTrigger>
		)
	} else if (_status === '1') {
		return (
			<OverlayTrigger
				placement="right"
				delay={popoverTiming}
				overlay={<Tooltip id="button-tooltip">
					In organico
				</Tooltip>}

				message="in organico"
			>
				<FontAwesomeIcon icon="forward" />
			</OverlayTrigger>
		)
	} else {
		return (<></>)
	}

}



////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
export const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, defaultfilter, intervention_type }) => {
	//	const { intervention_dict } = useContext(InterventionContext)
	const { service_dict, structureElements } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.contract.length > 0 ?
					<>
						{ui_list.contract_list.map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.contract.indexOf(d.id) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.contract.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('contract', d.id)}>
								{d.name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.contract.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('contract')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.contracts')}
							</Col>
						</Row>
						<Row>
							{ui_list.contract_list && ui_list.contract_list.length > 0 ?
								<>
									{ui_list.contract_list.map((d, k) => (
										<Col sm="4" key={k}>
											<Button block size="sm" className="ms-1 mb-1 text-start" variant={filterAppliedList.contract.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('contract', d.id)}>
												{d.name}
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
						<Row>
							<Col>
								{t('global.labels.qualification')}
							</Col>
						</Row>
						<Row>
							<Col>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



export default translate()(EmployesDomainView)





