import React, { useState, useContext, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import { addSystemGroup as addSystemGroup2, editSystemGroup as editSystemGroup2 } from '../Firebase2/Storage2/dbGroup';




function ModalSystemgroup({ item, setItem, t, is_new, small, index, item_list, ui_list, setParentList }) {

	const newsystemgroup = {
		name: '',
		permission_dict: {}
	}

	const [showSystemgroupEdit, setShowSystemgroupEdit] = useState(false)
	const [curElement, setCurElement] = useState(newsystemgroup)
	const [editedElement, setEditedElement] = useState(false)


	const handleCloseSystemgroup = () => setShowSystemgroupEdit(false);

	const handleSaveSystemgroup = async () => {

		setShowSystemgroupEdit(false);
		if (is_new) {
			const return_data = await addSystemGroup2(curElement)
			let newsystemgroup_list = [...item]
			newsystemgroup_list.push(return_data)
			console.log(newsystemgroup_list)
			setItem(newsystemgroup_list)

		} else {
			let newsystemgroup_list = [...item_list]
			newsystemgroup_list.splice(index, 1, curElement)
			setParentList(newsystemgroup_list)
			const return_data = await editSystemGroup2(curElement.id, curElement)
		}
	}


	const handleShowNewSystemgroup = () => {
		console.log('show new')
		setCurElement(newsystemgroup)
		setShowSystemgroupEdit(true)
		setEditedElement(false)
	}


	const handleShowEditSystemgroup = () => {
		if (!item.color) {
			item.color = { ...newsystemgroup.color }
		}
		setCurElement(item)
		setShowSystemgroupEdit(true)
		setTimeout(function () {
			setEditedElement(false)

		}, 100)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curElement[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}



	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNewSystemgroup()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNewSystemgroup()}><FontAwesomeIcon icon='plus-square' /> {t('structure.labels.systemgroup_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditSystemgroup()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<Modal show={showSystemgroupEdit} onHide={handleCloseSystemgroup} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('licence.labels.new_systemgroup')}</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('licence.labels.edit_systemgroup')}</span>
							</>
						}
					: {curElement.name}&nbsp;
					{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} is_new={is_new} ui_list={ui_list} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseSystemgroup}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveSystemgroup}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

function ElementFormDetails({ element, functions, ui_list, t, is_new }) {


	const OneMenuSection = ({ i, k, element, functions }) => {
		return (

			<Row key={k} className="border-start border-end mb-1 mt-1 border-bottom border-secondary">
				<Col>
					<Row className="bg-secondary text-light">
						<Col>
							{i.label}
						</Col>
					</Row>
					{i.menulist.filter(m => m.type === 'link').map((m, km) => (
						<>
							{console.log(m)}
							<OnePermissionSection p={m} key={km} k={km} element={element} functions={functions} />
						</>
					))}
				</Col>
			</Row>
		)
	}


	const OnePermissionSection = ({ p, k, element, functions }) => {
		const handleUpdatePermission = (elem) => {
			element.permission_dict[p.id] = elem.value
			functions.edit({ name: 'permission_dict', value: element.permission_dict })
		}
		const getPermissionClass = (c) => {
			if (c === '0') {
				return 'text-light bg-primary border-bottom'
			} else if (c === '1') {
				return 'text-light bg-success border-bottom'
			} else {
				return 'text-secondary bg-light border-bottom'
			}
		}

		console.log(p)

		return (
			<Form.Group as={Row} key={k} controlId={p.id}>
				<Col className={getPermissionClass(element.permission_dict[p.id])}>
					<i>
						{p.href}
					</i>
				</Col>
				<Col sm="6">
					<b>{p.label}</b>
				</Col>
				<Col>
					<Button variant={!element.permission_dict[p.id] || element.permission_dict[p.id] === '-1' ? 'secondary' : 'outline-secondary'} onClick={() => handleUpdatePermission({ name: "permission" + p.id, value: '-1' })}>
						{t('global.labels.no')}
					</Button>
				</Col>
				<Col>
					<Button variant={element.permission_dict[p.id] === '0' ? 'primary' : 'outline-primary'} onClick={() => handleUpdatePermission({ name: "permission" + p.id, value: '0' })}>
						{t('global.labels.read')}
					</Button>
				</Col>
				<Col>
					<Button variant={element.permission_dict[p.id] === '1' ? 'success' : 'outline-success'} onClick={() => handleUpdatePermission({ name: "permission" + p.id, value: '1' })}>
						{t('global.labels.readwrite')}
					</Button>
				</Col>
			</Form.Group>
		)
	}

	return (
		<>
			<Form.Group as={Row} className="border-bottom mb pb-1">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.target)} />
				</Col>
			</Form.Group>
			<Row className="border-bottom">
				<Form.Label column sm="2">{t('licence.labels.permissions')}</Form.Label>
				<Col >
					{ui_list.permission_dict ?
						<>
							{ui_list.permission_dict.map((i, k) => (
								<OneMenuSection i={i} key={k} element={element} functions={functions} />
							))}
						</>
						: ''
					}
				</Col>
			</Row>
		</>
	)
}


export default translate()(ModalSystemgroup)