import { firestore } from '../firebaseIndex'


///////////////////////////////////////////////////////////////////////////////
export const migrateTrainingList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainings')
            .get();


        let count = 0;
        for (const doc of res.docs) {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }

            // write in Domain
            await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('trainings')
                .doc(doc.id)
                .set(data)
            count = count + 1
        }

        return count

    } catch (e) {
        console.log("migrateTrainingList error: ", e)
        return { error: e }
    }
}

///////////////////////////////////////////////////////////////////////////////
export const migrateTrainingEventList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('trainingagenda')
            .get();

        let count = 0;
        for (const doc of res.docs) {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }

            await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('trainingagenda')
                .doc(doc.id)
                .set(data)
            count = count + 1
        }
        return count

    } catch (e) {
        console.log("migrateTrainingEventList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const migrateTrainerList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('trainers')
            .get();

        let count = 0;
        for (const doc of res.docs) {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }

            // write in Domain
            await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('trainers')
                .doc(doc.id)
                .set(data)
            count = count + 1
        }

        return count
    } catch (e) {
        console.log("migrateTrainerList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const migrateEmployerList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('employers')
            .get();

        let count = 0
        for (const doc of res.docs) {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }

            // write in Domain
            await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('employers')
                .doc(doc.id)
                .set(data)
            count = count + 1
        }

        return count
    } catch (e) {
        console.log("migrateEmployerList error: ", e)
        return { error: e }
    }
}



///////////////////////////////////////////////////////////////////////////////
export const migrateClothingCollectionList = async (domainId, contractId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('clothingcollection')
            .get();

        let count = 0;
        for (const doc of res.docs) {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }

            // write in Domain
            await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('clothingcollection')
                .doc(doc.id)
                .set(data)
                count = count + 1
        }

        return count
    } catch (e) {
        console.log("migrateClothingCollectionList error: ", e)
        return { error: e }
    }
}