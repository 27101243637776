import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, InputGroup, FormControl, Tooltip, OverlayTrigger, Spinner, Card, Badge } from 'react-bootstrap';

import { goods_labels, materialIntendedUse_dict } from "../components/constants/global_definitions"

import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import LocaleContext from '../components/contexts/locale.js'
import { translate } from 'react-polyglot'

import styles from './constants/styles.js'

import { getSelectedElement, checkComponentPresence, getBaseObjectValue, getFloatNumber } from "../util/ui_utils.js"


import { editIntervention as editIntervention2, } from '../Firebase2/Storage2/dbIntervention'
import { AddListButton } from './StructureGraphicElements';




//************************************************************
export const OneGood = ({ i, k, increment, cart_element_list, handleAddElement, t, suggested_good_id_list }) => {
	const { locale } = useContext(LocaleContext)
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Elemento necessario per le operazioni inserite in quest'area di lavoro
			{checkComponentPresence(i, cart_element_list, 'id') !== -1 ? "" : " (non ancora inserito nel carello)"}
		</Tooltip>
	);


	console.log("A-pre", i)
	return (
		<Row key={k} className={styles.source_row}>
			<Col className="p-0 text-center" sm="1">
				<AddListButton i={i} add_action={handleAddElement} element_list={cart_element_list} increment={increment} />
			</Col>
			<Col>
				{suggested_good_id_list.indexOf(i.id) !== -1 ?
					<OverlayTrigger
						placement="right"
						delay={{ show: 250, hide: 400 }}
						overlay={renderTooltip}
					>
						<FontAwesomeIcon className={checkComponentPresence(i, cart_element_list, 'id') !== -1 ? "text-success" : "text-warning"} icon="star" />
					</OverlayTrigger>
					: ''}
				<ElementIcon el={i} t={t} /> <i>{getBaseObjectValue(i.brand, 'name', locale)}</i>&nbsp;{i.name}</Col>
		</Row>

	)
}





const renderNeedTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Elemento necessario per le operazioni inserite in quest'area di lavoro
	</Tooltip>
);



//************************************************************
export const ElementIcon = ({ el, t }) => {
	const intended_use_dict = materialIntendedUse_dict({ t })
	const goods_labels_dict = goods_labels({ t })
	const a = goods_labels_dict[el.category_type]
	console.log("A", a, goods_labels_dict)
	if (el.category_type === 'cleaningmaterial') {
		if (intended_use_dict[el.subtype] !== undefined) {
			return <span className={intended_use_dict[el.subtype].color}><FontAwesomeIcon icon={a.icon} className="margin-right-icon" /></span>
		} else {
			console.log(intended_use_dict, el)
			return <span className={a.color}><FontAwesomeIcon icon={a.icon} className="margin-right-icon" /></span>
		}
	} else {
		if (a){

		return <span className={a.color}><FontAwesomeIcon icon={a.icon} className="margin-right-icon" /></span>
		}else{
			return <>--</>
		}
	}
}




export const OneSummaryElement = ({ i, k }) => {

	return (
		<Row key={k} className="border-bottom pb-1">
			<Col>
				{i.name}
			</Col>
			<Col>
				{i.category}</Col>
			<Col className="text-center">
				{i.quantity}
			</Col>

		</Row>

	)
}




//************************************************************
export const OneCartElement = ({ i, k,t , suggested_good_id_list, canWrite, changeQcart, handleAddElement, handleSubtractEl, handleDeleteElement }) => {

	const { locale } = useContext(LocaleContext)



	return (
		<Row key={k} className="border-bottom pb-1">

			<Col>
				{suggested_good_id_list.indexOf(i.id) !== -1 ?
					<OverlayTrigger
						placement="right"
						delay={{ show: 250, hide: 400 }}
						overlay={renderNeedTooltip}
					>
						<FontAwesomeIcon className="text-info" icon="star" />
					</OverlayTrigger>
					:
					''}
				<ElementIcon el={i} t={t} /> <i>{getBaseObjectValue(i.brand, 'name', locale)}</i> {i.name}</Col>
			<Col className="p-0 text-center" sm="5" md="4" xl="2" >
				{canWrite ?
					<>
						{i.category_type === 'cart' || i.category_type === 'machine' || i.category_type === 'product' ?
							<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i)} /> </Button>
							:
							<InputGroup>
								
									<Button size="sm" variant="outline-info" onClick={() => handleSubtractEl(i, 'cart_element_list')}><FontAwesomeIcon icon="minus" /> </Button>
								
								<FormControl aria-describedby="basic-addon1" value={i.quantity} onChange={changeQcart} />
								{i.quantity < i.suggested_quantity ?
									
										<InputGroup.Text className="bg-danger text-light" >
											<FontAwesomeIcon icon="exclamation-triangle" />
										</InputGroup.Text>
									
									: ''}
								
									<Button size="sm" variant="outline-info" onClick={() => handleAddElement(i, 'cart_element_list')}><FontAwesomeIcon icon="plus" /> </Button>
								
								
									<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteElement(i)} /> </Button>
								
							</InputGroup>
						}
					</>
					:
					[i.category_type === 'cart' || i.category_type === 'machine' ?
						''
						:
						[i.category_type === 'product' ?
							''
							:
							<>
								{i.quantity}
								{i.quantity < i.suggested_quantity ? <FontAwesomeIcon icon="exclamation-triangle" className="text-danger" /> : ''}
							</>
						]
					]

				}
			</Col>

		</Row>

	)
}