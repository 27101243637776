import React, { useState, useContext, useEffect } from 'react';
import { Badge, Modal, Container, Row, Col, Form, Button, Tab, Nav, Image, OverlayTrigger, Tooltip, Spinner, ButtonGroup, InputGroup, Card } from 'react-bootstrap';
import { SearchSection } from '../SearchSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const ModalSelectRoomTypeImage = ({ curImage, t, chooseImage, image_list }) => {
	const [showImage, setShowImage] = useState(false)
	const [editedElement, setEditedElement] = useState(false)

	//const image_reference = getImageReferenceList(contract)
	const [searchFilter, setSearchFilter] = useState('')

	const handleCloseImage = () => {
		setShowImage(false)
	}
	const handleOpenImage = () => {
		setShowImage(true)
	}

	const handleChooseImage = (i) => {
		chooseImage(i)
		setShowImage(false)
	}

	return (
		<>
        {curImage && curImage.description}
			<Button onClick={handleOpenImage} className="ms-2">{t('roomtypes.buttons.choose_image')}</Button>
			<Modal show={showImage} onHide={handleCloseImage} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Container>
						<Row>
							<Col>
								<Modal.Title>
									{t('global.labels.image_reference')}
								</Modal.Title>
							</Col>
							<Col>
								<SearchSection setSearchTerm={setSearchFilter} searchTerm={searchFilter} />
							</Col>
							<Col>
								<Badge bg="info">

									{image_list?.filter(f => searchFilter.length === 0 || f.description.toLowerCase().indexOf(searchFilter) >= 0).length} / {image_list.length}
								</Badge>
							</Col>
						</Row>
					</Container>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>

						<Row>
							{image_list?.filter(f => searchFilter.length === 0 || f.description.toLowerCase().indexOf(searchFilter) >= 0).map((f, k) => (
								<Col sm="2" className="p-1">
									<Card className="h-100">
										<Card.Header className={curImage && curImage.Id === f.Id ? "bg-primary text-white" : ''}>
											{f.from_global? <FontAwesomeIcon icon="lock" />: ''} {f.description}
										</Card.Header>
										<Card.Body>
											<Image src={f.media} thumbnail />
										</Card.Body>
										<Card.Footer>
											<Button variant={curImage && curImage.Id === f.Id ? "primary" : "outline-primary"} className="btn-block" onClick={() => handleChooseImage(f)}>seleziona</Button>
										</Card.Footer>
									</Card>
								</Col>

							))}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseImage}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}