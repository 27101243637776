import React, { useState, useContext, useEffect, useRef, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, InputGroup, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';
import { format, setDay } from 'date-fns'


import LocaleContext from './contexts/locale.js'
import DomainContext from './contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';


import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import ElementFormMedia from "../components/FormMedia.js"
import DilutionList from "../components/DilutionList.js"

import { materialIntendedUse_dict } from "../components/constants/global_definitions"

import { getSelectedElement, checkComponentPresence, myTimestampToDate, getValue, rgba2hex } from "../util/ui_utils.js"
import { ModalInitButton } from '../components/StructureGraphicElements.js'

import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { addProduct as addProduct2, editProduct as editProduct2 } from '../Firebase2/Storage2/dbWarehouse';
import { addCatalogProduct, editCatalogProduct } from '../Firebase2/Storage2/dbCatalogWarehouse';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';
import firebase from 'firebase/compat/app';
import { biocida_productType_list } from './constants/global_definitions.js';



//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'
const section = 'product'

////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalProduct({ item, setItem, t, is_new, small, item_list, canWrite, ui_list, setUiList }) {
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const childRefAlert = useRef()

	let { cid } = useParams();
	let contractId = cid

	const newproduct = {
		code: '',
		brand: {},
		model: '',
		note: '',
		category: {},
		colorcode: {},
		minvalue: 0,
		media_list: [],
		description: '',
		order_code: '',
		company_url: '',
		url: '',
		diluition_system: {},
		has_ecolabel: false,
		ecolabel_contract_code: '',
		ecolabel_contract_expirationdatebase: firebase.firestore.Timestamp.now(),
		//intended_use: 'dusting_furniture',
		intended_use: [],
		certification: ''

	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newproduct)
	const [editedElement, setEditedElement] = useState(false)

	const initValues = async () => {
		//		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		//		const category_list = await getBaseObjectTypeList2( domain, 'product_category')
		//		setUiList({ ...ui_list, brand_list, category_list })
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}


	const handleShowNew = () => {
		newproduct.media_list = []
		setCurrentElement(newproduct)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			if (curElement[name]) {
				oldcheck = curElement[name]
			}
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}
	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		let return_data
		if (is_new) {
			if (domain === 'catalog') {
				return_data = await addCatalogProduct(contractId, section, curElement)
			} else {
				return_data = await addProduct2(domain, contractId, companyId, section, curElement)
			}
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			if (domain === 'catalog') {
				return_data = await editCatalogProduct(contractId, section, curElement.id, curElement)
			} else {
				return_data = await editProduct2(domain, contractId, companyId, section, curElement.id, curElement)
			}
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('product.buttons.product_add')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('products.labels.new_product')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('products.labels.edit_product')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('products.labels.view_product')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('products.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabData">{t('products.labels.data')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDpi">{t('products.labels.dpi')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('products.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData, setEditedElement: setEditedElement, setItem: setCurrentElement }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabData">
											<ElementFormData element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabDpi">
											<ElementFormDpi element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, updateData: onUpdateData }} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curElement} t={t} ui_list={ui_list} functions={{ edit: onChangeHandler, save: handleSave }} section={section} canWrite={canWrite} />
										</Tab.Pane>

									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}
	const updateCategories = (b) => {
		functions.updateData('category_list', b)
	}
	const updateColorcode = (b) => {
		functions.updateData('colorcode_list', b)
	}
	const updateDilutions = (b) => {
		functions.updateData('dilution_system_list', b)
	}

	let intended_use_dict = materialIntendedUse_dict({ t })
	const onChangeIntendedUse = (el) => {
		const use = intended_use_dict[el.value]
		console.log(el.value, use, intended_use_dict)
		if (use) {
			//FIXME correggere hextorgb
			functions.setItem({ ...element, intended_use: el.value })
		} else {
			functions.setItem({ ...element, intended_use: el.value })
		}
		functions.setEditedElement(true)
	}

	return (
		<>
			<Form.Group as={Row} controlId="productName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="productCode" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.order_code')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="order_code" value={element.order_code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formProductBrand" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('products.labels.select_brand')}</option>
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name ? f.name[locale]: '---'}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formProductCategory" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category.Id} name="category" className="mb-2" onChange={(event) => functions.edit({ name: 'category', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('products.labels.select_category')}</option>
						{ui_list.category_list
							? [Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'product_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateCategories }} />
						: ''}
				</Col>
			</Form.Group>
			{/* for REFILL */}
			<Form.Group as={Row} controlId="formCleaningmaterialBrand" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.intended_use')}</Form.Label>
				<Col>
					<Form.Check type="checkbox" className="ms-2" name="intended_use" value="economic_material" 
					checked={element.intended_use && element.intended_use.indexOf("economic_material") !== -1} 
					label={t('product.labels.economic_material')} onChange={(event) => functions.edit(event.currentTarget)} 
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-2">
				<Form.Label column sm="2">{t('products.labels.colorcode')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control as="select" value={element.colorcode ? element.colorcode.Id : -1} name="colorcode" onChange={(event) => functions.edit({ name: 'colorcode', type: 'select', value: getSelectedElement(event.target.value, ui_list.colorcode_list) })}  >
							<option value="-1">{t('products.labels.select_colorcode')}</option>
							{ui_list.colorcode_list
								? [Object(ui_list.colorcode_list).map((f, k) => (
									<option key={k} value={f.Id} >{f.name[locale]}</option>
								))]
								: ''
							}
						</Form.Control>
						{element.colorcode && element.colorcode.Id ?
							<InputGroup.Text style={{ backgroundColor: rgba2hex(element.colorcode.color) }}>&nbsp;</InputGroup.Text>
							: ''
						}

					</InputGroup>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'product_colorcode', list: ui_list.colorcode_list }} t={t} functions={{ setCurSection: updateColorcode }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.description_and_use')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" as="textarea" rows={3} name="description" value={element.description} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.company_url')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="company_url" value={element.company_url} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.url')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="url" value={element.url} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">
					{t('products.labels.certSa8000')}
				</Form.Label>
				<Col>
					<Form.Check type="radio" name="certSa8000" value={true} checked={element.certSa8000} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="certSa8000" value={false} checked={!element.certSa8000} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">
					{t('products.labels.certIso14067')}
				</Form.Label>
				<Col sm="8">
					<Form.Check type="radio" name="certIso14067" value={true} checked={element.certIso14067} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="certIso14067" value={false} checked={!element.certIso14067} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('product.labels.whitout_fragrances')}</Form.Label>
				<Col>
					<Form.Check type="radio" name="whitout_fragrances" value={true} checked={element.whitout_fragrances} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="whitout_fragrances" value={false} checked={!element.whitout_fragrances} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{domain !== 'catalog' ?
				<Form.Group as={Row} controlId="productStock" className="border-bottom">
					<Form.Label column sm="2">{t('products.labels.minstock')}</Form.Label>
					<Col sm="8">
						<Form.Control type="number" name="minstock" value={element.minvalue} min="0" className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
				</Form.Group>
				: ''}
			<Form.Group as={Row} controlId="productNote" className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormDpi({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)



	/* 
	per la testa
	per l'udito
	per occhi e viso
	per le vie respiratorio
	per mani e braccia
	per piedi e gambe
	per la pelle
	per tronco e addome
	per l'intero corpo
	
	*/
	return (


		<>

			<Row>
				<Col className="text-light bg-info">
					{t('products.labels.dpi_pure')}
				</Col>
			</Row>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_head" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_head") !== -1} label={t('product.labels.dpi_head')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_head") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_head" value={element.dpi_pure_dpi_head} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_hear" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_hear") !== -1} label={t('product.labels.dpi_hear')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_hear") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_hear" value={element.dpi_pure_dpi_hear} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_face" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_face") !== -1} label={t('product.labels.dpi_face')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_face") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_face" value={element.dpi_pure_dpi_face} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_respiratory" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_respiratory") !== -1} label={t('product.labels.dpi_respiratory')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_respiratory") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_respiratory" value={element.dpi_pure_dpi_respiratory} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_hands" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_hands") !== -1} label={t('product.labels.dpi_hands')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_hands") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_hands" value={element.dpi_pure_dpi_hands} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_foot" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_foot") !== -1} label={t('product.labels.dpi_foot')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_foot") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_foot" value={element.dpi_pure_dpi_foot} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_trunk" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_trunk") !== -1} label={t('product.labels.dpi_trunk')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_trunk") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_trunk" value={element.dpi_pure_dpi_trunk} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_pure_list" value="dpi_body" checked={element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_body") !== -1} label={t('product.labels.dpi_body')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_pure_list && element.dpi_pure_list.indexOf("dpi_body") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_pure_dpi_body" value={element.dpi_pure_dpi_body} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Row>
				<Col className="text-light bg-info">
					{t('products.labels.dpi_dilued')}
				</Col>
			</Row>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_head" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_head") !== -1} label={t('product.labels.dpi_head')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_head") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_head" value={element.dpi_dilued_dpi_head} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_hear" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_hear") !== -1} label={t('product.labels.dpi_hear')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_hear") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_hear" value={element.dpi_dilued_dpi_hear} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_face" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_face") !== -1} label={t('product.labels.dpi_face')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_face") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_face" value={element.dpi_dilued_dpi_face} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_respiratory" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_respiratory") !== -1} label={t('product.labels.dpi_respiratory')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_respiratory") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_respiratory" value={element.dpi_dilued_dpi_respiratory} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_hands" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_hands") !== -1} label={t('product.labels.dpi_hands')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_hands") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_hands" value={element.dpi_dilued_dpi_hands} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_foot" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_foot") !== -1} label={t('product.labels.dpi_foot')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_foot") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_foot" value={element.dpi_dilued_dpi_foot} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_trunk" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_trunk") !== -1} label={t('product.labels.dpi_trunk')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_trunk") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_trunk" value={element.dpi_dilued_dpi_trunk} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Col sm="4">
					<Form.Check type="checkbox" className="ms-2" name="dpi_dilued_list" value="dpi_body" checked={element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_body") !== -1} label={t('product.labels.dpi_body')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{element.dpi_dilued_list && element.dpi_dilued_list.indexOf("dpi_body") !== -1 ?
					<Col>
						<Form.Control className="mb-2" as="textarea" rows={3} name="dpi_dilued_dpi_body" value={element.dpi_dilued_dpi_body} onChange={(event) => functions.edit(event.currentTarget)} />
					</Col>
					: ''}
			</Form.Group>
		</>
	)
}



////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormData({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const biocida_pt_list = biocida_productType_list({ t })

	return (
		<>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('products.labels.active_substance')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control type="number" name="active_substance" value={element.active_substance} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('products.labels.cov')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control type="number" name="cov" value={element.cov} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('products.labels.primary_net_plastic_weight')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control type="number" name="primary_net_plastic_weight" value={element.primary_net_plastic_weight} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">Kg/l</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('products.labels.primay_net_recycled_plastic')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control type="number" name="primay_net_recycled_plastic" value={element.primay_net_recycled_plastic} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">CO<sub>2</sub> {t('products.labels.co2')}</Form.Label>
				<Col sm="8">
					<InputGroup>
						<Form.Control type="number" name="co2" value={element.co2} onChange={(event) => functions.edit(event.currentTarget)} />
						
							<InputGroup.Text id="basic-addon1">Kg &times; conf</InputGroup.Text>
						
					</InputGroup>
				</Col>
			</Form.Group>
			<DilutionList element={element} functions={functions} t={t} canWrite={canWrite} />
			{domain !== 'catalog' ?
				<Form.Group as={Row} controlId="formProductCategory" className="border-bottom">
					<Form.Label column sm="2">{t('products.labels.dilution_system')}</Form.Label>
					<Col sm="8">
						<Form.Control as="select" value={getValue(element.dilution_system, 'id')} name="dilution_system" className="mb-2" onChange={(event) => functions.edit({ name: 'dilution_system', type: 'select', value: getSelectedElement(event.target.value, ui_list.dilution_system_list, 'id') })}  >
							<option value="-1">{t('products.labels.select_dilution_system')}</option>
							{ui_list.dilution_system_list
								? [Object(ui_list.dilution_system_list).map((f, k) => (
									<option key={k} value={f.id} >{f.name}</option>
								))]
								: ''
							}
						</Form.Control>
					</Col>
				</Form.Group>
				: ''}
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('product.labels.concentrated')}</Form.Label>
				<Col sm="1">
					<Form.Check type="radio" name="concentrated" value={true} checked={element.concentrated} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="concentrated" value={false} checked={!element.concentrated} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom pb-1">
				<Form.Label column sm="2">{t('product.labels.for_disinfection')}</Form.Label>
				<Col sm="1">
					<Form.Check type="radio" name="for_disinfection" value={true} checked={element.for_disinfection} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="for_disinfection" value={false} checked={!element.for_disinfection} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
				{(element.for_disinfection && element.disinfection_type && element.disinfection_type.length > 0) || (element.has_ecolabel) || (element.has_camcertificate) ?
					<Col>
						<img
							src="/logo_cam21.jpg"
							height="40"
							className="d-inline-block align-top me-3"
							alt="cam21"
						/>
						<i>Conforme CAM DM 51 del 29.01.2021</i>
					</Col>
					:
					<Col>
						<FontAwesomeIcon icon="exclamation-triangle" /> Non conforme
					</Col>
				}


			</Form.Group>
			{element.for_disinfection ?
				<Form.Group as={Row} className="border-bottom pb-1">
					<Col>
						<Row className="border-bottom pb-1 mb-1">
							<Col sm="2">
								<Form.Check type="checkbox" className="ms-2" name="disinfection_type" value="pmc" checked={element.disinfection_type && element.disinfection_type.indexOf("pmc") !== -1} label={t('product.labels.pmc')} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
							{element.disinfection_type && element.disinfection_type.indexOf("pmc") !== -1 ?
								<Col>
									<Form.Control className="mb-2" type="number" name="registration_code_pmc" value={element.registration_code_pmc} onChange={(event) => functions.edit(event.currentTarget)} />
									<Form.Text className="text-muted">
										{t('products.labels.registration_code_pmc')}
									</Form.Text>
								</Col>
								: ''}
						</Row>
						<Row className="border-bottom pb-1 mb-1">
							<Col sm="2">
								<Form.Check type="checkbox" className="ms-2" name="disinfection_type" value="biocida" checked={element.disinfection_type && element.disinfection_type.indexOf("biocida") !== -1} label={t('product.labels.biocida')} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
							{element.disinfection_type && element.disinfection_type.indexOf("biocida") !== -1 ?
								<Col>
									<Row>
										<Col>
											<Form.Control className="mb-2" type="number" name="registration_code_biocida" value={element.registration_code_biocida} onChange={(event) => functions.edit(event.currentTarget)} />
											<Form.Text className="text-muted">
												{t('products.labels.registration_code_biocida')}
											</Form.Text>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Control className="mb-2" type="number" name="registration_code_min_authorization" value={element.registration_code_min_authorization} onChange={(event) => functions.edit(event.currentTarget)} />
											<Form.Text className="text-muted">
												{t('products.labels.registration_code_min_authorization')}
											</Form.Text>
										</Col>
									</Row>
									<Row>
										<Col sm="3">
											{t('product.labels.product_type_pt')}
										</Col>
										<Col>
											<Row>

												<Col className="bg-info me-1 text-light" sm="3">
													PT

												</Col>
												<Col className="bg-info text-light">
													Tipo di prodotto

												</Col>
											</Row>
											<Row>
												<Col>
													{biocida_pt_list.map((p, k) => (
														<Row key={k} className="border-bottom border-info">
															<Col sm="3">
																<Form.Check type="checkbox" className="ms-2" name="product_type" value={p.code} checked={element.product_type && element.product_type.indexOf(p.code) !== -1} label={p.code} onChange={(event) => functions.edit(event.currentTarget)} />
															</Col>
															<Col>
																{p.label}

															</Col>
														</Row>

													))}
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
								: ''}
						</Row>
						<Row className="pb-1 mb-1">
							<Col sm="2">
								<Form.Check type="checkbox" className="ms-2" name="disinfection_type" value="medical_ce" checked={element.disinfection_type && element.disinfection_type.indexOf("medical_ce") !== -1} label={t('product.labels.medical_ce')} onChange={(event) => functions.edit(event.currentTarget)} />
							</Col>
							{element.disinfection_type && element.disinfection_type.indexOf("medical_ce") !== -1 ?
								<Col>
									<Form.Control className="mb-2" type="number" name="registration_code_medical_ce" value={element.registration_code_medical_ce} onChange={(event) => functions.edit(event.currentTarget)} />
									<Form.Text className="text-muted">
										{t('products.labels.registration_code_medical_ce')}
									</Form.Text>
								</Col>
								: ''}
						</Row>
					</Col>
				</Form.Group>
				: ''}
			{element.for_disinfection ?
				<>
					<Form.Group as={Row} className="border-bottom pb-1">
						<Form.Label column sm="2">{t('product.labels.regulation_code')}</Form.Label>
						<Col>
							{domain === 'catalog' &&
								<Row>
									<Col>
									</Col>
									{(domain === 'catalog' || contract && contract.type === 'civil') &&
										<Col className="bg-info me-1 text-light">
											Ambito civile
										</Col>
										}
									{(domain === 'catalog' || contract && contract.type === 'hospital' )&&
										<Col className="bg-info me-1 text-light">
											Ambito sanitario
										</Col>
										}
								</Row>
								}
							<Row className="border-top border-info">
								<Col className="align-items-center" sm="3">
									Battericida / Funghicida
								</Col>
								{(domain === 'catalog' || (contract && contract.type === 'civil') )&&
									<Col>
										<Row>
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="1040" checked={element.regulation_code && element.regulation_code.indexOf("1040") !== -1} label='EN 1040' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
										<Row className="border-top border-light">
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="1276" checked={element.regulation_code && element.regulation_code.indexOf("1276") !== -1} label='EN 1276' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
										<Row className="border-top border-light">
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="1650" checked={element.regulation_code && element.regulation_code.indexOf("1650") !== -1} label='EN 1650' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
										<Row className="border-top border-light">
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="13697" checked={element.regulation_code && element.regulation_code.indexOf("13697") !== -1} label='EN 13697' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
									</Col>
									}
								{(domain === 'catalog' || (contract && contract.type === 'hospital')) &&
									<Col>
										<Row >
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="1040" checked={element.regulation_code && element.regulation_code.indexOf("1040") !== -1} label='EN 1040' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
										<Row className="border-top border-light">
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="13624" checked={element.regulation_code && element.regulation_code.indexOf("13624") !== -1} label='EN 13624' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
										<Row className="border-top border-light">
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="13727" checked={element.regulation_code && element.regulation_code.indexOf("13727") !== -1} label='EN 13727' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
									</Col>
									}
							</Row>
							{(domain === 'catalog' || (contract && contract.type === 'hospital')) &&
								<Row className="border-top border-info">
									<Col sm="3">
										Sporicida
									</Col>
									{domain === 'catalog' &&
										<Col className="text-center">
										</Col>
										}
									<Col>
										<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="14347" checked={element.regulation_code && element.regulation_code.indexOf("14347") !== -1} label='EN 14347' onChange={(event) => functions.edit(event.currentTarget)} />
									</Col>
								</Row>
								}
							{(domain === 'catalog' || (contract && contract.type === 'hospital')) &&
								<Row className="border-top border-info">
									<Col sm="3">
										Micobattericida
									</Col>
									{domain === 'catalog' &&
										<Col className="text-center">
										</Col>
										}
									<Col>
										<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="14348" checked={element.regulation_code && element.regulation_code.indexOf("14348") !== -1} label='EN 14348' onChange={(event) => functions.edit(event.currentTarget)} />
									</Col>
								</Row>
								}
							<Row className="border-top border-info">
								<Col sm="3">
									Virucida
								</Col>
								{(domain === 'catalog' || (contract && contract.type === 'civil' ))&&
									<Col className="text-center">
										<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="14476" checked={element.regulation_code && element.regulation_code.indexOf("14476") !== -1} label='EN 14476' onChange={(event) => functions.edit(event.currentTarget)} />
									</Col>
									}
								{(domain === 'catalog' || (contract && contract.type === 'hospital' ))&&
									<Col>
										<Row className="border-top border-light">
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="14476" checked={element.regulation_code && element.regulation_code.indexOf("14476") !== -1} label='EN 14476' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
										<Row >
											<Col>
												<Form.Check type="checkbox" className="ms-2" name="regulation_code" value="16777" checked={element.regulation_code && element.regulation_code.indexOf("16777") !== -1} label='EN 16777' onChange={(event) => functions.edit(event.currentTarget)} />
											</Col>
										</Row>
									</Col>
									}
							</Row>
						</Col>
					</Form.Group>
				</>
				:
				<>
					<Form.Group as={Row} className="border-bottom">
						<Form.Label column sm="2">{t('product.labels.has_ecolabel')}</Form.Label>
						<Col>
							<Form.Check type="radio" name="has_ecolabel" value={true} checked={element.has_ecolabel} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Check type="radio" name="has_ecolabel" value={false} checked={!element.has_ecolabel} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
						</Col>
					</Form.Group>
					{element.has_ecolabel ?
						<>
							<Form.Group as={Row} className="border-bottom">
								<Form.Label column sm="2">{t('products.labels.ecolabel_contract_code')}</Form.Label>
								<Col sm="8">
									<Form.Control className="mb-2" type="text" name="ecolabel_contract_code" value={element.ecolabel_contract_code} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							</Form.Group>
							<Form.Group as={Row} className="border-bottom">
								<Form.Label column sm="2">{t('products.labels.ecolabel_contract_expiration')}</Form.Label>
								<Col sm="8">
									<DateTime value={myTimestampToDate(element.ecolabel_contract_expirationdate)} dateFormat='DD/MM/YYYY' timeFormat={false} name="ecolabel_contract_expirationdate" locale="it" closeOnSelect={true} onChange={(e) => functions.edit({ name: 'ecolabel_contract_expirationdate', value: firebase.firestore.Timestamp.fromDate(e.toDate()) })} />
								</Col>
							</Form.Group>
						</>
						:
						<>
							<Form.Group as={Row} className="border-bottom">
								<Form.Label column sm="2">{t('product.labels.has_camcertificate')}</Form.Label>
								<Col>
									<Form.Check type="radio" name="has_camcertificate" value={true} checked={element.has_camcertificate} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
									<Form.Check type="radio" name="has_camcertificate" value={false} checked={!element.has_camcertificate} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							</Form.Group>
							{element.has_camcertificate ?
								<Row className="border-bottom">
									<Col>
										<i>Conforme CAM DM 51 del 29.01.2021</i>
									</Col>
								</Row>
								:
								''
							}
						</>}

				</>
			}
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('products.labels.certification')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="certification" value={element.certification} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}






export default translate()(ModalProduct)