import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, ButtonGroup, Badge, Nav, Tab } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import update from 'immutability-helper'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getSelectedElement, checkComponentPresence, compareName, getValue, getMediaIcon, getElementPreview } from "../util/ui_utils.js"
import styles from './constants/styles.js'
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"

import { addMediaType, editMediaType } from '../Firebase2/Storage2/dbGlobalSettings';

import ElementFormMedia from "../components/FormMedia.js"
import DomainContext from './contexts/domainContext'


const contractId = sessionStorage.getItem('contractId')
const companyId = '0'

const element_list = [];

function ModalMediaType({ item, setItem, t, is_new, small, item_list, index, canWrite, icon_list, section_list }) {
	const { domain } = useContext(DomainContext)

	const childRefAlert = useRef()
	const initValues = async () => {
		/* 		let newelement_list = await getMediaTypeList(contractId, companyId, 'mediatype', null)
				newelement_list.sort(compareName)
				setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
	}
	const newmediatype = {
		name: '',
		icon: 'file',
		section: 'contract',
		isThumb: false
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newmediatype)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newmediatype.media_list = []
		setCurrentElement(newmediatype)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addMediaType(curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editMediaType(curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			{is_new ?
				(small ?
					<Button onClick={() => handleShowNew()} variant="outline-primary"><FontAwesomeIcon icon='plus-square' /> </Button>
					:
					<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('mediatype.buttons.mediatype_add')}</Button>
				)
				: <Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit()}><FontAwesomeIcon icon="pencil-alt" /> </Button>
			}
			<AlertSection ref={childRefAlert} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					{is_new && <Modal.Title variant="primary">{t('mediatypes.modal.titlenew')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title variant="info">{t('mediatypes.modal.titleedit')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formMediaTypeCategory" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.section')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" value={curElement.section} name="section" className="mb-2" onChange={(event) => onChangeHandler({ name: 'section', type: 'select', value: event.target.value })}  >
									{section_list
										? [Object.keys(section_list).map((f, k) => (
											<option key={k} value={f} >{section_list[f].label}</option>
										))]
										: ''
									}
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="mediatypeName" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="name" value={curElement.name} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.icon')} </Form.Label>
							<Col sm="8">
								{icon_list.map((p, k) => (
									<Form inline className="mb-2">
										<Form.Label className="me-1">
											<FontAwesomeIcon size="lg" icon={p} />
										</Form.Label>
										<Form.Check key={k} type="radio" name="icon" value={p} checked={curElement.icon === p} onChange={(event) => onChangeHandler(event.currentTarget)} />
									</Form>
								))}
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formStructureCF" className="border-bottom">
							<Form.Label column sm="2">{t('settings.labels.is_preview')}</Form.Label>
							<Col sm="8">
								<Form.Check type="radio" name="isThumb" value={true} checked={curElement.isThumb} label={t('global.labels.yes')} onChange={(event) => onChangeHandler(event.currentTarget)} />
								<Form.Check type="radio" name="isThumb" value={false} checked={!curElement.isThumb} label={t('global.labels.no')} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



function ElementFormDetails({ element, functions, ui_list, t, canWrite }) {
	const { locale } = useContext(LocaleContext)
	const updateBrands = (b) => {
		functions.updateData('brand_list', b)
	}
	const updateCategories = (b) => {
		functions.updateData('mediatype_category', b)
	}

	return (
		<>
			<Form.Group as={Row} controlId="mediatypeName" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" value={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="mediatypeCode" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.code')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="code" value={element.code} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			{/* 			<Form.Group as={Row} controlId="formMediaTypeBrand" className="border-bottom">
				<Form.Label column sm="2">{t('mediatypes.labels.brand')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.brand.Id} name="brand" className="mb-2" onChange={(event) => functions.edit({ name: 'brand', type: 'select', value: getSelectedElement(event.target.value, ui_list.brand_list) })}  >
						<option value="-1">{t('mediatypes.labels.select_brand')}</option>
						{ui_list.brand_list
							? [Object(ui_list.brand_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					<ModalBaseObject is_new={true} small={true} cursection={{ name: 'brands', list: ui_list.brand_list }} t={t} functions={{ setCurSection: updateBrands }} />
				</Col>
			</Form.Group> */}
			<Form.Group as={Row} controlId="formMediaTypeCategory" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" value={element.category.Id} name="category" className="mb-2" onChange={(event) => functions.edit({ name: 'category', type: 'select', value: getSelectedElement(event.target.value, ui_list.category_list) })}  >
						<option value="-1">{t('global.labels.select_category')}</option>
						{ui_list.category_list
							? [Object(ui_list.category_list).map((f, k) => (
								<option key={k} value={f.Id} >{f.name[locale]}</option>
							))]
							: ''
						}
					</Form.Control>
				</Col>
				<Col sm="1">
					{canWrite ?
						<ModalBaseObject is_new={true} small={true} cursection={{ name: 'mediatype_category', list: ui_list.category_list }} t={t} functions={{ setCurSection: updateCategories }} />
						: ''}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="mediatypeNote" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="note" value={element.note} className="mb-2" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
export default translate()(ModalMediaType)