import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocaleContext from './contexts/locale.js'
import { Container, Row, Col, Button, Modal, Form, Nav, Tab, InputGroup } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"

import CurrencyInput from 'react-currency-input-field';
import DomainContext from './contexts/domainContext.js'


import ModalBaseObject from "./ModalBaseObject.js"
import AlertSection from "./AlertSection.js"
import ElementFormMedia from "./FormMedia.js"

import { getSelectedElement, checkComponentPresence } from "../util/ui_utils.js"
import { ModalInitButton } from './StructureGraphicElements.js'


import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject.js"
import { addSupplier as addSupplier2, editSupplier as editSupplier2 } from '../Firebase2/Storage2/dbSupplier.js';
import { editFileTracker as editFileTracker2 } from '../Firebase2/Storage2/dbFileStorage.js';


const referent_role_list = [
	{ Id: 1, name: {en:'titolare', it: 'titlare'} },
	{ Id: 2, name: {en:'direttore ', it: 'direttore commerciale'} }
]


const section = 'supplier'
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalSupplier({ item, setItem, t, is_new, small, item_list, canWrite }) {
	const { domain } = useContext(DomainContext)

	let { cid } = useParams();
	let contractId = cid

	const childRefAlert = useRef()
	const newsupplier = {
		name: '',
		code: '',
		vat: '',
		cf: '',
		address: {
			street: '',
			number: '',
			zip: '',
			city: '',
			state: ''
		},
		phone_list: [],
		phone: '',
		mobilephone: '',
		fax: '',
		email: '',
		pec: '',
		referent_list: [],
		bank: '',
		payment_mode: '',
		sdi: '',
		note: '',
		supply_list: []
	}
	const initValues = async () => {
		const brand_list = await getBaseObjectTypeList2(domain, 'brands')
		const category_list = await getBaseObjectTypeList2(domain, 'supplier_category')
		setUiList({ ...ui_list, brand_list, category_list })
		/* 		let newelement_list = await getSupplierList(contractId, companyId, 'supplier', null)
				newelement_list.sort(compareName)
				setElementList(newelement_list) */
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(newsupplier)
	const [ui_list, setUiList] = useState({})
	const [editedElement, setEditedElement] = useState(false)

	const handleShowNew = () => {
		newsupplier.media_list = []
		setCurrentElement(newsupplier)
		setShow(true)
		setEditedElement(false)
	}

	const handleShowEdit = () => {
		setCurrentElement(item)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}

	useEffect(() => {
		initValues()
	}, [])



	const handleClose = () => setShow(false);

	const handleSave = async () => {
		console.log("____________________________________SAVE_____________________________", is_new)
		if (is_new) {
			let return_data = await addSupplier2(domain, contractId, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let newlist = [...item_list]
				newlist.push(return_data)
				setItem(newlist)
				setShow(false);
			}
		} else {
			let return_data = await editSupplier2(domain, contractId, curElement.id, curElement)
			console.log("edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				for (let m = 0; m < curElement.media_list.length; m++) {
					let editTrackData = curElement.media_list[m].tracker
					editTrackData.item_reference = { category: curElement.category, name: curElement.name, id: curElement.id }
					console.log("-----edit media----", editTrackData)
					let return_data = await editFileTracker2(domain, contractId, editTrackData.id, editTrackData)
				}
				console.log(item_list)
				let edit_list = [...item_list]
				const c = checkComponentPresence(curElement, item_list, 'id')
				edit_list.splice(c, 1, curElement)
				setItem(edit_list)
				setShow(false);
			}
		}
	}

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={small} title_new={t('suppliers.buttons.add_new')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} />
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>
						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('suppliers.labels.new_supplier')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('suppliers.labels.edit_supplier')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info" /> <span className="text-info">{t('suppliers.labels.view_supplier')}</span>
								</>
							]
						}
						: {curElement.name}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item variant="secondary"global>
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('suppliers.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabReferent">{t('suppliers.labels.referent')}</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link variant="secondary" eventKey="tabSupplies">{t('suppliers.labels.supply')}</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabReferent">
											<ElementFormReferent element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabSupplies">
											<ElementFormSupplies element={curElement} t={t} functions={{ edit: onChangeHandler }} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSave}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


function ElementFormDetails({ element, functions, t }) {

	return (
		<>
			<Form.Group as={Row} controlId="formSupplierBusinessname" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.businessname')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="name" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierCode" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.code')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierVat" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.vat')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="vat" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierCf" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.cf')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="cf" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.address')} </Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" sub="address" id="supplierStreet" name="street"  onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							    </Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" id="supplierZip" name="zip"  onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							    </Form.Text>
						</Col>
					</Row>
					<Row>
						<Col >
							<Form.Control type="text" sub="address" id="supplierCity" name="city" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							    </Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" id="supplierState" name="state" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							    </Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierPhone" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.phone')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="phone" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierMobilephone" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.mobilephone')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="mobilephone" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierFax" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.fax')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="fax" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierEmail" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.email')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="email" name="email" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group><Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.bank')} </Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" id="supplierIban" name="iban" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('suppliers.labels.iban')}
							</Form.Text>
						</Col>
						<Col >
							<Form.Control type="text" id="supplierPayment" name="payment_mode" onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('suppliers.labels.payment_mode')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierNote" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.note')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="note" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}
function ElementFormReferent({ element, functions, t }) {

	const { locale } = useContext(LocaleContext)
	const newreferent = {
		Id:0,
		firstname: '',
		lastname: '',
		phone: '',
		mobilephone: '',
		fax: '',
		email: '',
		role: {}
	}
	const [showReferentEdit, setShowReferentEdit] = useState(false)
	const [newReferentEdit, setNewReferentEdit] = useState(true)
	const [curReferent, setCurReferent] = useState(newreferent)
	//----referent
	const handleCloseReferent = () => setShowReferentEdit(false);
	const handleSaveReferent = () => {
		if (newReferentEdit) {
			let newreferent_list = element.referent_list
			newreferent_list.push(curReferent)
			console.log(newreferent_list)
			functions.edit({name: 'referent_list', value: newreferent_list})
		} else {
			let editlist = element.referent_list
			const c = checkComponentPresence(curReferent, element.referent_list)
			editlist.splice(c, 1, curReferent)
			functions.edit({ name: 'referent_list', value: editlist })
		}
		setShowReferentEdit(false);
	}
	const handleDeleteReferent = (el) => {
		console.log(el)
		let editlist = element.referent_list
		const c = checkComponentPresence(el, element.referent_list)
		editlist.splice(c, 1)
		functions.edit({ name: 'referent_list', value: editlist })
	}
	const handleShowNewReferent = () => {
		setCurReferent(newreferent)
		setNewReferentEdit(true)
		setShowReferentEdit(true)
	}
	const handleShowEditReferent = (i) => {
		console.log('edit', i)
		setCurReferent(i)
		setNewReferentEdit(false)
		setShowReferentEdit(true)
	}
	const onChangeHandlerReferent = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurReferent({ ...curReferent, [name]: value })
	}
	return (
		<>
		<Row>

			<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowNewReferent()}><FontAwesomeIcon icon="plus-square" />{t('suppliers.labels.referent_add')}</Button></Col>
		</Row>
			<Row className="border-bottom  border-end mb-1">
				{element.referent_list && element.referent_list.length > 0 ?
					<Col>
						<Row >
							<Col className="bg-secondary text-light ms-1">{t('global.labels.name')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('suppliers.labels.role')} </Col>
							<Col className="bg-secondary text-light ms-1">&nbsp;</Col>
						</Row>
						{element.referent_list.map((i, key) => (
							<Row key={key}>
								<Col>{i.firstname}</Col>
								<Col>
									{i.role.name[locale]}
								</Col>
								<Col>
									<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditReferent(i)} /> </Button>
									<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteReferent(i)} /> </Button>
								</Col>
							</Row>))
						}
					</Col>
					: <Col> {t('suppliers.messages.no_referent')}</Col>
				}
			</Row>
			<Modal show={showReferentEdit} onHide={handleCloseReferent}>
				<Modal.Header closeButton>
					{newReferentEdit && <Modal.Title>{t('suppliers.labels.new_referent')}</Modal.Title>}
					{!newReferentEdit && <Modal.Title>{t('suppliers.labels.edit_referent')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">Name</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" placeholder="Enter first name" name="firstname" value={curReferent.firstname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
								<Form.Text className="text-muted">
									Insert valid name
							    </Form.Text>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
							<Form.Label column sm="2">Surname</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" placeholder="Enter last name" name="lastname" value={curReferent.lastname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
								<Form.Text className="text-muted" >
									Insert valid surname
							    </Form.Text>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">{t('supliers.labels.referent.role')} </Form.Label>
							<Col sm="8">
								<Form.Control as="select" className="mb-2" defaultValue={curReferent.role.Id} name="role" onChange={(event) => onChangeHandlerReferent({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, referent_role_list) })} >
									<option value="-1" >{t('equipments.labels.select_role')}</option>
									{referent_role_list.map((i, key) => (
										<option key={key} value={i.Id}  >{i.name[locale]}</option>
									))}
								</Form.Control>

							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">Phone</Form.Label>
							<Col sm="8">
								<Form.Control type="text" className="mb-2" name="phone" onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />

							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">Fax</Form.Label>
							<Col sm="8">
								<Form.Control type="text" className="mb-2" name="fax" onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />

							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="2">Email</Form.Label>
							<Col sm="8">
								<Form.Control type="email" className="mb-2" name="email" onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseReferent}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveReferent}>
						<FontAwesomeIcon icon="save" />{t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

		</>
	)
}

function ElementFormSupplies({ element, functions, t }) {
	const newsupply = {
		product_code: '',
		product_name: '',
		product_type: '',
		supplier_code: '',
		vat: 0,
		part_price: 0,
		package_price: 0,
		pack_price: 0,
		package_type: '',
		pack_type: '',
		package_count: 0,
		pack_count: 0,
		main_price_type: 'part',
		is_primary_supplier: true
	}
	const newsupplyB = {
		product_code: '',
		product_name: '',
		product_type: '',
		supplier_code: '',
		vat: 0,
		part_price: 12,
		package_price: 3,
		pack_price: 4,
		package_type: '',
		pack_type: '',
		package_count: 45,
		pack_count: 3,
		main_price_type: 'part',
		is_primary_supplier: true
	}
	const [showSupplyEdit, setShowSupplyEdit] = useState(false)
	const [showSupplyChoose, setShowSupplyChoose] = useState(false)
	const [newSupplyEdit, setNewQualificaionEdit] = useState(true)
	const [curSupply, setCurSupply] = useState(newsupply)
	const [isUpdating, setUpdating] = useState(false)
	const handleCloseSupply = () => setShowSupplyEdit(false);
	const handleCloseChooseSupply = () => setShowSupplyChoose(false);
	const handleSaveSupply = () => {
		setShowSupplyEdit(false);
		if (newSupplyEdit) {
			let newsupply_list = element.supply_list
			newsupply_list.push(curSupply)
			console.log(newsupply_list)
			functions.edit({ name: 'supply_list', value: newsupply_list })
		} else {

		}
	}
	const handleSaveChooseSupply = () => {
		setShowSupplyEdit(false);
	}
	const handleShowNewSupply = () => {
		setCurSupply(newsupply)
		setNewQualificaionEdit(true)
		setShowSupplyEdit(true)
	}
	const handleShowEditSupply = (i) => {
		setCurSupply(i)
		setNewQualificaionEdit(false)
		setShowSupplyEdit(true)
	}
	const handleShowChooseSupply = () => {
		setShowSupplyChoose(true)
	}
	const onChangeHandlerSupply = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurSupply({ ...curSupply, [name]: value })
	}
	const onUpdatePrice = () => {
		if (!isUpdating) {
			setUpdating(true)
			if (curSupply.main_price_type === 'part') {
				setCurSupply({
					...curSupply,
					package_price: isNaN(curSupply.package_count * curSupply.part_price) ? 0 : curSupply.package_count * curSupply.part_price,
					pack_price: isNaN(curSupply.pack_count * curSupply.package_count * curSupply.part_price) ? 0 : curSupply.pack_count * curSupply.package_count * curSupply.part_price
				})
			} else if (curSupply.main_price_type === 'package') {
				setCurSupply({
					...curSupply,
					part_price: curSupply.package_price / curSupply.package_count,
					pack_price: curSupply.pack_count * curSupply.package_price
				})
			} else if (curSupply.main_price_type === 'pack') {
				setCurSupply({
					...curSupply,
					part_price: curSupply.pack_price / curSupply.package_count / curSupply.pack_count,
					package_price: curSupply.pack_price / curSupply.pack_count,
				})

			}
			setUpdating(false)
		}

	}
	useEffect(() => {
		onUpdatePrice()
	},
		[curSupply.part_price, curSupply.pack_price, curSupply.package_price, curSupply.package_count, curSupply.pack_count],
	);
	return (
		<>
			<Form.Group as={Row} controlId="formProductSupply" className="border-bottom">
				<Col>
					<Row>
						<Col><Button variant="outline-primary" size="sm" onClick={() => handleShowNewSupply()}><FontAwesomeIcon icon="plus-square" />{t('products.labels.supply_add')}</Button></Col>
					</Row>
					{element.supply_list && element.supply_list.length > 0 ?
						<>
							<Row >
								<Col sm="2" small className="bg-secondary text-light ms-1"> </Col>
								<Col className="bg-secondary text-light ms-1">{t('products.labels.code')} </Col>
								<Col className="bg-secondary text-light ms-1">&nbsp;</Col>
							</Row>
							{element.supply_list.map((i, key) => (
								<Row>
									<Col>{i.product_code}</Col>
									<Col>{i.product_name}</Col>
									<Col>{i.product_category}</Col>
									<Col>{i.supplier_code}</Col>
									<Col>{i.part_price} </Col>
									<Col>
										<Button size="sm" variant="info" onClick={() => handleShowEditSupply(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" /> </Button>
									</Col>
								</Row>))
							}
						</>
						: <> {t('products.messages.no_supply')}</>
					}
				</Col>
			</Form.Group>

			<Modal show={showSupplyChoose} className="border-primary bg-secondary" size="xl">
				<Modal.Header closeButton>
					<Modal.Title>{t('products.modal.titlechoosesupply')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							{t('global.labels.filters')}
						</Col>
						<Col>
							<Button variant="outline-secondary">{t('suppliers.labels.equipments')} </Button>
							<Button variant="outline-secondary">{t('suppliers.labels.carts')} </Button>
							<Button variant="outline-secondary">{t('suppliers.labels.machines')} </Button>
							<Button variant="outline-secondary">{t('suppliers.labels.products')} </Button>
						</Col>
					</Row>
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1">{t('suppliers.labels.code')}</Col>
						<Col className="bg-secondary ms-1">{t('suppliers.labels.model')}</Col>
						<Col className="bg-secondary ms-1">{t('suppliers.labels.brand')}</Col>
						<Col className="bg-secondary ms-1">{t('suppliers.labels.type')}</Col>
						<Col className="bg-secondary ms-1"></Col>
					</Row>
					<Row>
						<Col>{t('suppliers.labels.code')}</Col>
						<Col>{t('suppliers.labels.model')}</Col>
						<Col>{t('suppliers.labels.brand')}</Col>
						<Col>{t('suppliers.labels.type')}</Col>
						<Col>
							<Button size="sm" variant="secondary" ><FontAwesomeIcon icon="info-circle" /> </Button>
							<Button size="sm" variant="outline-primary"><FontAwesomeIcon icon="check" /> </Button>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseChooseSupply}> {t('modal.close')}</Button>
					<Button className="btn-myprimary" form="supplyForm" onClick={handleSaveChooseSupply}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showSupplyEdit} className="border-primary bg-secondary" size="xl">
				<Modal.Header closeButton>
					{newSupplyEdit && <Modal.Title>{t('products.modal.titlenewsupply')} </Modal.Title>}
					{!newSupplyEdit && <Modal.Title>{t('products.modal.titleeditsupply')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Row className="border-bottom mb-2">
						<Form.Label column sm="2">{t('products.labels.equipment')}</Form.Label>
						<Col>
							<Button variant="outline-primary" className="mb-2" onClick={() => handleShowChooseSupply()}>{t('suppliers.labels.select_equipment')} </Button>
						</Col>
					</Row>
					<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
						<Form.Label column sm="2">{t('products.labels.supplier_code')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" className="mb-2" defaultValue={curSupply.supplier_code} name="supplier_code" onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formProductCF" className="border-bottom">
						<Form.Label column sm="2">{t('products.labels.vat')}</Form.Label>
						<Col>
							<Form.Control type="text" placeholder="" className="mb-2" defaultValue={curSupply.vat} name="vat" onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
						</Col>
					</Form.Group>
					<Row className="mb-2">
						<Col>
							{t('suppliers.labels.price')}
						</Col>
					</Row>
					<Row>
						<Col sm="4">
							<InputGroup className="mb-3">
								
									<InputGroup.Radio type="radio" name="main_price_type" value="part" checked={(curSupply && curSupply.main_price_type === 'part')} onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
								
								<CurrencyInput
									name="part_price"
									placeholder="€0,00"
									defaultValue={curSupply.part_price}
									value={curSupply.part_price}
									allowDecimals={true}
									className="form-control "
									decimalsLimit={2}
									prefix="€"
									onChange={(value, name) => onChangeHandlerSupply({ name, value })}
									precision={2}
									disabled={curSupply.main_price_type === 'part' ? false : true}
								/>
								
									<InputGroup.Text id="basic-addon1"> /{t('suppliers.labels.part')} </InputGroup.Text>
								
							</InputGroup>

						</Col>
					</Row>
					<Row>
						<Col sm="4">
							<InputGroup className="mb-3">
								
									<InputGroup.Radio type="radio" name="main_price_type" value="package" checked={(curSupply && curSupply.main_price_type === 'package')} onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
								
								<CurrencyInput
									name="package_price"
									placeholder="€0,00"
									value={curSupply.package_price}
									allowDecimals={true}
									className="form-control "
									decimalsLimit={2}
									prefix="€"
									onChange={(value, name) => onChangeHandlerSupply({ name, value })}
									precision={2}
									disabled={curSupply.main_price_type === 'package' ? false : true}
								/>
								
									<InputGroup.Text id="basic-addon1"> /{t('suppliers.labels.package')} </InputGroup.Text>
								
							</InputGroup>
						</Col>
						<Col>
							<Form.Control as="select" defaultValue="-1" name="package_type" onChange={(event) => onChangeHandlerSupply(event.currentTarget)} >
								<option value="1" checked={(element.level === "-1")}>{t('suppliers.labels.select_package')}</option>
							</Form.Control>
							<Form.Text className="text-muted">
								{t('suppliers.labels.package')}
							</Form.Text>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control type="text" placeholder="" value={curSupply.package_count} name="package_count" onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
								
									<InputGroup.Text id="basic-addon1" > /{t('suppliers.labels.pieces')} </InputGroup.Text>
								
							</InputGroup>
							<Form.Text className="text-muted">
								{t('suppliers.labels.piecesinpackages')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col sm="4">
							<InputGroup className="mb-3">
								
									<InputGroup.Radio type="radio" name="main_price_type" value="pack" checked={(curSupply && curSupply.main_price_type === 'pack')} onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
								
								<CurrencyInput
									name="pack_price"
									placeholder="€0,00"
									value={curSupply.pack_price}
									allowDecimals={true}
									className="form-control "
									decimalsLimit={2}
									prefix="€"
									onChange={(value, name) => onChangeHandlerSupply({ name, value })}
									precision={2}
									disabled={curSupply.main_price_type === 'pack' ? false : true}
								/>
								
									<InputGroup.Text id="basic-addon1"> /{t('suppliers.labels.pack')} </InputGroup.Text>
								
							</InputGroup>
						</Col>
						<Col>
							<Form.Control as="select" defaultValue="1" name="pack_type" onChange={(event) => onChangeHandlerSupply(event.currentTarget)} >
								<option value="1" checked={(element.level === "-1")}>{t('suppliers.labels.select_pack')}</option>
							</Form.Control>
							<Form.Text className="text-muted">
								{t('suppliers.labels.pack')}
							</Form.Text>
						</Col>
						<Col>
							<InputGroup>
								<Form.Control type="text" placeholder="" value={curSupply.pack_count} name="pack_count" onChange={(event) => onChangeHandlerSupply(event.currentTarget)} />
								
									<InputGroup.Text id="basic-addon1"> /{t('suppliers.labels.pieces')} </InputGroup.Text>
								
							</InputGroup>
							<Form.Text className="text-muted">
								{t('suppliers.labels.piecesinpacks')}
							</Form.Text>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseSupply}> {t('modal.close')}</Button>
					<Button className="btn-myprimary" form="supplyForm" onClick={handleSaveSupply}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)

}


export default translate()(ModalSupplier)