import React, { useState, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, ButtonGroup, Button, Nav, Tab} from 'react-bootstrap';

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';

import { getSelectedElement, checkComponentPresence, getBaseObjectValue, makeid } from "../util/ui_utils.js"
import { renderPhoneList, ModalInitButton } from '../components/StructureGraphicElements.js'

import ModalBaseObject from "../components/ModalBaseObject"
import AlertSection from "../components/AlertSection.js"
import PhoneList from "../components/PhoneList.js"
import ElementFormMedia from "../components/FormMedia.js"



import {

	addStructure as addStructure2,
	editStructure as editStructure2,
} from '../Firebase2/Storage2/dbBaseCompany';





const newstructure = {
	name: '',
	code: '',
	type: {},
	vat: '',
	cf: '',
	address: {
		street: '',
		number: '',
		zip: '',
		city: '',
		state: ''
	},
	referent_list: [],
	phone_list: [],
	email: '',
	pec: '',
	website: '',
	logo: '',
	sdi: '',
	note: '',
	parent_list: [],
	mq: 0,
	risk_area_summary: [],
	type_name: "structure",
	level: 0,
	childs: [],
	risk_area_summary_decommissioned: { total: { count: 0, size: 0 } },
	risk_area_summary_suspended: { total: { count: 0, size: 0 } },
	risk_area_summary_active: { total: { count: 0, size: 0 } },
	risk_area_summary: { total: { count: 0, size: 0 } },
}




//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalStructure = ({ is_new, structure, ui_list, t, canWrite, updateStructure, element_list }) => {

	const { domain } = useContext(DomainContext)
	const { contract } = useContext(GlobalDataContext)

	const childRefAlert = useRef()

	const [curStructure, setCurStructure] = useState(newstructure)
	const [editedElement, setEditedElement] = useState(false)
	const [showStructureEdit, setShowStructureEdit] = useState(false)

	//----structure edit
	const handleCloseStructure = () => setShowStructureEdit(false);

	const handleShowNewStructure = () => {
		setCurStructure(newstructure)
		setShowStructureEdit(true)
		setEditedElement(false)
	}

	const handleShowEditStructure = () => {
		console.log('edit structure', structure)
		setCurStructure(structure)
		setShowStructureEdit(true)
		setEditedElement(false)
	}



	const handleSaveStructure = async (close) => {
		const contractId = sessionStorage.getItem('contractId')
		console.log("SAVE STRUCTURE", curStructure)

		function postSave(tmp_structure_list) {
			updateStructure(tmp_structure_list)
			setEditedElement(false)
		}



		if (is_new) {
			console.log(domain, contractId)
			let return_data = await addStructure2(domain, contractId, curStructure)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let tmp_structure_list = [...element_list]
				tmp_structure_list.push(return_data)
				postSave(tmp_structure_list)
				if (close) {
					setShowStructureEdit(false);
				}
			}
		} else {
			let return_data = await editStructure2(domain, contractId, curStructure.id, curStructure)
			console.log("post edit", return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let tmp_structure_list = [...element_list]
				const c = checkComponentPresence(curStructure, element_list, 'id')
				tmp_structure_list.splice(c, 1, curStructure)
				postSave(tmp_structure_list)
				if (close) {
					setShowStructureEdit(false);
				}
			}
		}
	}





	const onChangeHandlerStructure = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub')
		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curStructure[name]
			if (el.checked) {
				oldcheck[value] = value;
			} else {
				oldcheck.splice(value, 1);
			}
			value = oldcheck
		}
		if (sub) {
			value = { ...curStructure[sub], [name]: value }
			name = sub;
		}
		console.log(name, value)
		setCurStructure({ ...curStructure, [name]: value })
		setEditedElement(true)
	}







	return (
		<>
			<ModalInitButton t={t} is_new={is_new} canWrite={canWrite} small={false} title_new={t('company.labels.structure_add')} action_new={handleShowNewStructure} action_edit={handleShowEditStructure} action_info={handleShowEditStructure} />
			<AlertSection ref={childRefAlert} />
			<Modal show={showStructureEdit} onHide={handleCloseStructure} size="xl" bsclass="full-modal" backdrop="static" className="bg-secondary"  scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{t('global.labels.new_structure')}</span>
							</>
							:
							[canWrite ?
								<>
									<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{t('company.labels.edit_structure')}</span>
								</>
								:
								<>
									<FontAwesomeIcon icon="info-circle" /> <span className="text-info">{t('company.labels.info_structure')}</span>
								</>
							]
						}
                        : {curStructure.name}&nbsp;
                        {editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid >
						<Tab.Container id="left-tabs-menu" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={3}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('products.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabMedia" disabled={is_new}>{t('structure.labels.imagesdocs')} </Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col sm={9}>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
 											<ElementFormStructureDetails element={curStructure} t={t} functions={{ edit: onChangeHandlerStructure, setedit: setEditedElement }} ui_list={ui_list} canWrite={canWrite} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabMedia">
											<ElementFormMedia element={curStructure} t={t} ui_list={ui_list} functions={{ edit: onChangeHandlerStructure, save: handleSaveStructure }} section='structure' canWrite={canWrite} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseStructure}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<ButtonGroup>
							<Button className={editedElement ? "btn-warning" : "btn-myprimary"} form="companyForm" onClick={handleSaveStructure}>
								<FontAwesomeIcon icon="save" /> {t('modal.save')}
							</Button>
							<Button variant={editedElement ? "outline-warning" : "outline-primary"} form="companyForm" onClick={() => handleSaveStructure(true)}>
								<FontAwesomeIcon icon="save" /> {t('modal.save_and_close')}
							</Button>
						</ButtonGroup>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)

}



//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormStructureDetails({ element, functions, t, ui_list, canWrite }) {


	return (
		<>
			<Form.Group as={Row} controlId="formStructureCode" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.code')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" defaultValue={element.code} name="code" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formStructureStructure" className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.name')} </Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="supplierAddress" className="border-bottom">
				<Form.Label column sm="2">{t('suppliers.labels.address')} </Form.Label>
				<Col>
					<Row>
						<Col>
							<Form.Control type="text" sub="address" name="street" value={element.address.street} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.street')}
							</Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" name="zip" value={element.address.zip} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.zip')}
							</Form.Text>
						</Col>
					</Row>
					<Row>
						<Col >
							<Form.Control type="text" sub="address" name="city" value={element.address.city} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.city')}
							</Form.Text>
						</Col>
						<Col sm="2">
							<Form.Control type="text" sub="address" name="state" value={element.address.state} onChange={(event) => functions.edit(event.currentTarget)} />
							<Form.Text className="text-muted">
								{t('global.labels.state')}
							</Form.Text>
						</Col>
					</Row>
				</Col>
			</Form.Group>
		{/* 	<PhoneList element={element} functions={functions} t={t} canWrite={canWrite} />
 */}

			<ElementFormReferent element={element} t={t} ui_list={ui_list} functions={functions} canWrite={canWrite} />
			<Form.Group as={Row} controlId="formStructurefailure_notification_email" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.failure_notification_email')}</Form.Label>
				<Col sm="6">
					<Form.Control className="mb-2" type="text" defaultValue={element.email} name="email" onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formSupplierNote" className="border-bottom">
				<Form.Label column sm="2">{t('structure.labels.note')}</Form.Label>
				<Col sm="8">
					<Form.Control className="mb-2" type="text" name="note" defaultValue={element.note} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}








//////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormReferent({ element, functions, t, ui_list, canWrite }) {

	const { locale } = useContext(LocaleContext)

	const newreferent = {
		id: makeid(5),
		firstname: '',
		lastname: '',
		phone_list: [],
		phone: '',
		area:'',
		mobilephone: '',
		fax: '',
		email: '',
		role: {}
	}
	const [showReferentEdit, setShowReferentEdit] = useState(false)
	const [newReferentEdit, setNewReferentEdit] = useState(true)
	const [curReferent, setCurReferent] = useState(newreferent)
	const [editIndex, setEditIndex] = useState(-1)

	//----referent
	const handleCloseReferent = () => setShowReferentEdit(false);

	const handleSaveReferent = () => {
		if (newReferentEdit) {
			let newreferent_list = element.referent_list
			newreferent_list.push(curReferent)
			console.log(newreferent_list)
			functions.edit({ name: 'referent_list', value: newreferent_list })
		} else {
			let editlist = element.referent_list
			console.log(curReferent, editlist)
			if (!curReferent.id){
				curReferent.id = makeid(5)
			}
			editlist.splice(editIndex, 1, curReferent)
			functions.edit({ name: 'referent_list', value: editlist })
		}
		setShowReferentEdit(false);
	}

	const handleDeleteReferent = (el, index) => {
		console.log(el)
		let editlist = element.referent_list
		editlist.splice(index, 1)
		functions.edit({ name: 'referent_list', value: editlist })
	}

	const handleShowNewReferent = () => {
		setCurReferent(newreferent)
		setEditIndex(-1)
		setNewReferentEdit(true)
		setShowReferentEdit(true)
	}

	const handleShowEditReferent = (i, index) => {
		console.log('edit', i)
		setEditIndex(index)
		setCurReferent(i)
		setNewReferentEdit(false)
		setShowReferentEdit(true)
	}

	const onChangeHandlerReferent = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurReferent({ ...curReferent, [name]: value })
	}

	const updateRoleList = (b) => {
		functions.updateData('referent_role', b)

	}


	return (
		<>
			<Row>
				<Col>{t('global.labels.referent_list')}</Col>
				<Col className="text-end">
					<Button variant="outline-primary" size="sm" onClick={() => handleShowNewReferent()}><FontAwesomeIcon icon="plus-square" /> {t('suppliers.labels.referent_add')}</Button>
				</Col>
			</Row>
			<Row className="border-bottom  border-end  border-start mb-2 mt-1 pb-1">
				{element.referent_list && element.referent_list.length > 0 ?
					<Col>
						<Row className="mb-1">
							<Col className="bg-secondary text-light">{t('global.labels.name')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('global.labels.lastname')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('suppliers.labels.role')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('global.labels.phone')}</Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element.referent_list.map((i, key) => (
							<Row key={key} className="border-bottom border-info mb-2 pb-1 element_row">
								<Col>{!i.id? <FontAwesomeIcon icon="exclamation-triangle" />:''} {i.firstname}</Col>
								<Col>{i.lastname}</Col>
								<Col>
									{getBaseObjectValue(i.role, 'name', locale)}
								</Col>
								<Col>
									{renderPhoneList(i.phone_list)}
								</Col>
								<Col sm="1" className="text-center">
									<ButtonGroup>
										<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditReferent(i, key)} /> </Button>
										{canWrite ?
											<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteReferent(i, key)} /> </Button>
											: ''}
									</ButtonGroup>
								</Col>
							</Row>))
						}
					</Col>
					: <Col className="font-italic">{t('suppliers.messages.no_referent')}</Col>
				}
			</Row>



			<Modal show={showReferentEdit} onHide={handleCloseReferent} size="xl" bsclass="full-modal" backdrop="static" >
				<Modal.Header closeButton>
					{newReferentEdit && <Modal.Title>{t('suppliers.labels.new_referent')}</Modal.Title>}
					{!newReferentEdit && <Modal.Title>{t('suppliers.labels.edit_referent')}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="1">{t('global.labels.name')}</Form.Label>
							<Col sm="7">
								<Form.Control size="sm" type="text" p name="firstname" value={curReferent.firstname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
							<Form.Label column sm="1">{t('global.labels.lastname')}</Form.Label>
							<Col sm="7">
								<Form.Control size="sm" type="text" name="lastname" value={curReferent.lastname} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierLastName" className="border-bottom">
							<Form.Label column sm="1">{t('global.labels.area')}</Form.Label>
							<Col sm="7">
								<Form.Control size="sm" type="text" name="area" value={curReferent.area} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="1">{t('supliers.labels.referent.role')} </Form.Label>
							<Col sm="7">
								<Form.Control as="select" className="mb-3" defaultValue={curReferent.role.Id} name="role" onChange={(event) => onChangeHandlerReferent({ name: 'role', type: 'select', value: getSelectedElement(event.target.value, ui_list.referent_role_list) })} >
									<option value="-2" >{t('equipments.labels.select_role')}</option>
									{ui_list.referent_role_list.map((i, key) => (
										<option key={key} value={i.Id}  >{i.name[locale]}</option>
									))}
								</Form.Control>
							</Col>
							<Col sm="1">
								<ModalBaseObject is_new={true} small={true} cursection={{ name: 'referent_role', list: ui_list.referent_role_list }} t={t} functions={{ setCurSection: updateRoleList }} />
							</Col>
						</Form.Group>
						<PhoneList element={curReferent} functions={{ edit: onChangeHandlerReferent }} t={t} canWrite={canWrite}/>
						<Form.Group as={Row} controlId="formSupplierCF" className="border-bottom">
							<Form.Label column sm="1">Email</Form.Label>
							<Col sm="7">
								<Form.Control type="email" className="mb-3" name="email" value={curReferent.email} onChange={(event) => onChangeHandlerReferent(event.currentTarget)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseReferent}>
						{t('modal.close')}
					</Button>
					{canWrite?
					<Button variant="primary" form="companyForm" onClick={handleSaveReferent}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
					:''}
				</Modal.Footer>
			</Modal>

		</>
	)
}
