import React  , {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Container , Button , Modal} from 'react-bootstrap';
//import {getValue} from "../util/ui_utils"
import { useTranslate } from 'react-polyglot'

import systemparams  from "./constants/systemparams";
import PrintFooter from "./generic/PrintFooter";



const Footer = ({ count_text, indication_text   }) => {
    const t = useTranslate();

    const [showInfo, setShowInfo] = useState(false)

    return (
			<Container className="footer" fluid>
				<Row >
					<Col className="text-white h5 align-self-center" sm="5">
					{count_text}
					</Col>
                    <Col style={{backgroundColor:'transparent', borderColor:'transparent', cursor:'pointer'}} className="text-center" onClick={() =>  setShowInfo(true)} >
                        <img
                            src="/claudit.Rwhite.png"
                            height="40"
                            className="d-inline-block align-middle mt-1 pt-1 pb-1"
                            alt="Claudit"
                        />
                    
                    </Col>
                    <Col className="align-self-center" sm="2">
					{indication_text}
                    </Col>
                    <Col className="pt-1 text-end" sm="3">
                        <img
                            src="/hcrm.png"
                            height="30"
                            className="d-inline-block align-middle mt-1 pt-1 pb-1"
                            alt="HCRM"
                        />
                    </Col>
				</Row>
            <Modal show={showInfo} onHide={() => setShowInfo(false)} >
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col className="text-center">
                        <img
                            src="/Logo.Claudit.registrato.png"
                            height="80"
                            className="d-inline-block align-middle"
                            alt="Claudit"
                        />

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            CLAUDIT versione {systemparams.sw_version}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="font-italic text-secondary">
                            Data ultima revisione: {systemparams.last_update}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                        </Row>
{/*                         <PrintFooter t={t} />
 */}                    </Container>
                </Modal.Body>
                <Modal.Footer>
					<Button alt={'ClAudit'} variant="outline-primary" href={'https://claudit.it'} target="_blank" ><FontAwesomeIcon icon="external-link-alt" /> claudit.it </Button>
                    <Button variant="secondary" onClick={() => setShowInfo(false)}>
                        {t('modal.close')}
                    </Button>

                </Modal.Footer>
            </Modal>
			</Container>
    )
}


export default Footer;
