import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Spinner, ButtonGroup, Modal, Image, Badge } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { differenceInDays, differenceInHours, format } from 'date-fns';

import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';

import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import ModalFailure from "../components/ModalFailure"
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"

import { InitContractHelper } from '../util/contract.js';
import { checkComponentPresence, getElementPreview, getElementCount, propComparator, myTimestampToDate, compareEditDateParam } from "../util/ui_utils.js"
import { RenderItemFileBadge, RoomInfoBadge } from '../components/StructureGraphicElements.js'


import { getFailureList, deleteFailure } from '../Firebase2/Storage2/dbFailure';
import { getTicketListForUser as getTicketListForUser2, getTicketListForGroup as getTicketListForGroup2 } from "../Firebase2/Storage2/dbTicket"
import { OneTicket } from './TicketView.js';
import { ModalDocument } from '../components/modal/ModalDocumentPreview.js';
import { ModalPrintTicket } from '../components/modal/ModalPrintTicket.js';
import { ticketType_dict } from '../components/constants/global_definitions.js';

const defaultfilter = { status: ['open', 'sent', 'sent_forward'], structure: [], pavillon: [], floor: [], department: [] }
//const contractId = sessionStorage.getItem('contractId')
const companyId = '0'

const section = 'failure'
const ticketcmount = 100;
////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const FailuresView = ({ t, uData }) => {
	let load_page = false
	const { locale } = useContext(LocaleContext)
	const { domain, domain_data } = useContext(DomainContext)
	const { contract, structureElements, roomtype_dict } = useContext(GlobalDataContext)
	const [viewSection, setViewMode] = useState('trace')

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	let { cid } = useParams();
	let contractId = cid

	const { initContract } = InitContractHelper()

	const [loading, setLoading] = useState(true)
	const [element_list, setElementList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [sentelement_list, setSentElementList] = useState([])
	const [receivedelement_list, setReceivedElementList] = useState([])
	const [canWrite, setWritePage] = useState(false)
	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)
	const [viewFilter, setViewFilter] = useState(false)

	const initValues = async () => {
		console.log(uData)
		const userData = uData
		if (domain_data.licence_status && uData && (uData.isGlobalAdmin || uData.write_page.indexOf('failure') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		console.log(domain, contractId, companyId)
		if (contract && domain.length > 0) {
			let newelement_list = await getFailureList(domain, contractId)
			console.log("ELEM", newelement_list)
			//let newelement_list = []
			newelement_list.sort(propComparator('createdAt')).reverse()
			setElementList(newelement_list)
			let _sent_message_list = await getTicketListForUser2(domain, contractId, uData.id)
			let _received_message_list = []
			console.log(userData.messagegroups)
			if (userData.messagegroups) {
				for (const group of userData.messagegroups.filter(g => g.contract_id === contractId)) {
					//for (const group of userData.messagegroups){
					console.log("GFROUP, ", group)
					let treceive_message_list = await getTicketListForGroup2(domain, contractId, group.message_group_id, ticketcmount)
					console.log("Received-MESSAGES", treceive_message_list)
					if (!treceive_message_list.error) {
						_received_message_list = [..._received_message_list, ...treceive_message_list.messageList]
					}
				}
			}
			console.log(_received_message_list)
			_received_message_list = _received_message_list.filter((value, index, self) => index === self.findIndex((t) => (t.id === value.id)))
			//			receive_message_list = await getMessageList(domain, contractId, mailcount)
			_received_message_list = _received_message_list.filter(t => t.type === 'failure')
			_received_message_list.sort(compareEditDateParam).reverse()
			_sent_message_list = _sent_message_list.filter(t => t.type === 'failure')
			_sent_message_list.sort(compareEditDateParam).reverse()
			console.log(_sent_message_list)
			//setElementList(_received_message_list)
			setSentElementList(_sent_message_list)
			setReceivedElementList(_received_message_list)
			if (newelement_list.length > 0) {
				setViewFilter(true)
			}
			setLoading(false)
			load_page = true
		} else {
			console.log("init contract return")

		}
	}



	const updateSentMessages = (el_list) => {
		setViewMode('sent')
		setSentElementList(el_list)
	}

	const updateReceivedMessages = (el_list) => {
		setViewMode('received')
		setReceivedElementList(el_list)
	}



	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("FAILURE reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("FAILURE init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])




	useEffect(() => {
		console.log('failure loadingcontract', load_page)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])





	useEffect(() => {
		if ((viewSection === 'received' && receivedelement_list && receivedelement_list.length > 0) ||
			(viewSection === 'sent' && sentelement_list && sentelement_list.length > 0) ||
			(viewSection === 'trace' && element_list && element_list.length > 0)) {
			setViewFilter(true)
		} else {
			setViewFilter(false)
		}


	}, [viewSection])



	return (
		<>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container className="titleStickyTop" fluid>
				<Row className="mt-1">
					<Col>
						<PageTitle title={t('global.pages.failure')} />
					</Col>
					<Col sm="3">
						{!loading && viewFilter &&
							<ModalFilter t={t} ui_list={ui_list} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} element_list={element_list} />
						}
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<ModalPrintTicket is_failure={true} viewSection={viewSection} element_list={viewSection === 'sent' ? sentelement_list : viewSection === 'received' ? receivedelement_list : element_list} uData={uData} t={t} />
						<ButtonGroup>
							{canWrite ?
								<Button onClick={() => setViewMode('sent')} variant={viewSection === 'sent' ? 'primary' : 'outline-secondary'}>{t('ticket.labels.view_created')}<Badge>{sentelement_list.length} </Badge></Button>
								: ''}
							<Button onClick={() => setViewMode('received')} variant={viewSection === 'received' ? 'info' : 'outline-secondary'}>{t('ticket.labels.view_received')}<Badge>{receivedelement_list.length}</Badge> </Button>
							<Button onClick={() => setViewMode('trace')} variant={viewSection === 'trace' ? 'info' : 'outline-secondary'}>ClauditTrace<Badge>{element_list.length}</Badge> </Button>

						</ButtonGroup>
					</Col>
				</Row>
				{!loading &&

					<>
						{viewSection === 'received' &&
							receivedelement_list && receivedelement_list.length > 0 &&
							<Row className="p-1 text-light">
								<Col className="bg-secondary ms-1" sm="1">Tipologia</Col>
								<Col className="bg-secondary ms-1" sm="1">Priorità</Col>
								<Col className="bg-secondary ms-1" sm="4">riferimenti</Col>
								<Col className="bg-secondary ms-1">destinatario</Col>
								<Col className="bg-secondary ms-1">creato da</Col>
								<Col className="bg-secondary ms-1" sm="1">data</Col>
								<Col className="bg-secondary ms-1 text-center" sm="1">Azioni</Col>
							</Row>
						}
						{viewSection === 'sent' &&
							sentelement_list && sentelement_list.length > 0 &&
							<Row className="p-1 text-light">
								<Col className="bg-secondary ms-1" sm="1">Tipologia</Col>
								<Col className="bg-secondary ms-1" sm="1">Priorità</Col>
								<Col className="bg-secondary ms-1" sm="4">riferimenti</Col>
								<Col className="bg-secondary ms-1">destinatario</Col>
								<Col className="bg-secondary ms-1">creato da</Col>
								<Col className="bg-secondary ms-1" sm="1">data</Col>
								<Col className="bg-secondary ms-1 text-center" sm="1">Azioni</Col>
							</Row>
						}

						{viewSection === 'trace' && element_list && element_list.length > 0 &&
							<Row className="p-1 text-light">
								<Col className="bg-secondary ms-1 p-0 text-center" sm="1"><FontAwesomeIcon icon='image' /></Col>
								<Col className="bg-secondary ms-1" sm="2">{t('global.labels.room')}</Col>
								<Col className="bg-secondary ms-1">{t('global.labels.failuretype')}</Col>
								<Col className="bg-secondary ms-1" sm="1">{t('failure.labels.employer_register')}</Col>
								<Col className="bg-secondary ms-1" sm="1">{t('global.labels.creation_date')}</Col>
								<Col className="bg-secondary ms-1" sm="1">{t('global.labels.status')}</Col>
								<Col className="bg-secondary ms-1" sm="1">{t('global.labels.closing_date')}</Col>
								<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
							</Row>
						}

					</>}
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{viewSection === 'trace' &&
							<>
								{element_list && element_list.length > 0 ?
									<>
										{element_list
											.filter(i => (
												(filterAppliedList.status.length === 0 ||
													filterAppliedList.status.indexOf(i.status) >= 0) &&
												checkFailurePositionPresence(filterAppliedList, i.room_list, i.structure, i.department)
												/* 										&& (filterAppliedList.structure.length === 0 ||
																							filterAppliedList.structure.indexOf(i.room.parent_list[0]) >= 0)
																						&& (filterAppliedList.pavillon.length === 0 ||
																							filterAppliedList.pavillon.indexOf(i.room.parent_list[1]) >= 0)
																						&& (filterAppliedList.department.length === 0 ||
																							filterAppliedList.department.indexOf(i.room.parent_list[3]) >= 0) */
											))
											.map((i, key) => (
												<OneFailure i={i} key={key} setElementList={setElementList} element_list={element_list} canWrite={canWrite} t={t} />
											))}
									</>
									: <>
										<Row>
											<Col className="font-italic">
												Nessun guasto inserito tramite app ClauditTrace
											</Col>
										</Row>
									</>}
							</>
						}
						{viewSection === 'received' &&
							<>
								{receivedelement_list && receivedelement_list.length > 0 ?
									<>
										{receivedelement_list
											.filter(i =>
										{
											return( (filterAppliedList.status.length === 0 ||
												filterAppliedList.status.indexOf(i.status_history ? i.status_history[i.status_history.length - 1].code_id: i.status) >= 0 )&&
											checkFailurePositionPresence(filterAppliedList, i.room_list, i.structure, i.department))
										}
										).map((m, k) => (
											<OneTicket key={k} t={t} ticket={m} setElementList={updateReceivedMessages} element_list={receivedelement_list} uData={uData} viewMode={viewSection} />
										))}
									</>
									:
									<Row>
										<Col className="font-italic">Nessun gusto segnalato</Col>
									</Row>}</>
						}
						{viewSection === 'sent' &&
							<>
								{sentelement_list && sentelement_list.length > 0 ?
									<>
										{sentelement_list.filter(i =>
										{
											return( (filterAppliedList.status.length === 0 ||
												filterAppliedList.status.indexOf(i.status_history ? i.status_history[i.status_history.length - 1].code_id: i.status) >= 0 )&&
											checkFailurePositionPresence(filterAppliedList, i.room_list, i.structure, i.department))
										}
										).map((m, k) => (
											<OneTicket key={k} t={t} ticket={m} setElementList={updateSentMessages} element_list={sentelement_list} uData={uData} viewMode={viewSection} />
										))}
									</>
									:
									<Row>
										<Col className="font-italic">Nessun guasto creato</Col>
									</Row>}
							</>
						}
					</>
				}



			</Container>
			<Footer count_text={loading ? <Spinner animation="border" role="status" /> : getElementCount({ t, element_list, name: t('global.labels.failure') })} />
		</>
	);
}

const checkFailurePositionPresence = (filterAppliedList, room_list, structure, department) => {
	let is_present = false
	if (room_list && room_list.length > 0) {
		for (const _r of room_list) {
			if ((filterAppliedList.structure.length === 0 ||
				filterAppliedList.structure.indexOf(_r.parent_list[0]) >= 0)
				&& (filterAppliedList.pavillon.length === 0 ||
					filterAppliedList.pavillon.indexOf(_r.parent_list[1]) >= 0)
				&& (filterAppliedList.department.length === 0 ||
					filterAppliedList.department.indexOf(_r.parent_list[3]) >= 0)) {
				is_present = true
			}
		}

	} else {
		if ((filterAppliedList.structure.length === 0 ||
			filterAppliedList.structure.indexOf(structure.id) >= 0)
			&& (filterAppliedList.department.length === 0 ||
				filterAppliedList.department.indexOf(department.id) >= 0)) {
			is_present = true
		}
	}
	return is_present

}


export const OneFailure = ({ i, t, setElementList, canWrite, element_list }) => {
	const { contract, structureElements, roomtype_dict } = useContext(GlobalDataContext)
	try{
	return (
		<Row className="element_row p-1 border-bottom " >
			<Col sm="1">
				{i.url &&
					<ModalDocument t={t} photo={i.url} />
				}
			</Col>
			<Col sm="2">
				<RoomInfoBadge parent_list={structureElements.all_room_dict[i.room.id]?.parent_list} />  {structureElements.all_room_dict[i.room.id]?.name} - {roomtype_dict[i.room.roomtype.id]?.name}
			</Col>
			<Col >
				<b>{i.failure.description}</b></Col>
			<Col sm="1">
				{i.employer?.register}
			</Col>
			<Col sm="1">
				{format(myTimestampToDate(i.createdAt), 'dd/MM/yyyy HH:mm')}
			</Col>
			<Col sm="1" >
				<FailureStatus s={i.status} t={t} />
				{i.closedAt &&
					<>&nbsp;(
						{getDifference(myTimestampToDate(i.closedAt), myTimestampToDate(i.createdAt), t)}
						)
					</>
				}
			</Col>
			<Col sm="1">
				{i.closedAt &&
					format(myTimestampToDate(i.closedAt), 'dd/MM/yyyy HH:mm')
				}
			</Col>
			<Col sm="1" className="text-center">
				{!i.closed &&
					<ButtonGroup>
						<ModalFailure is_new={false} t={t} item={i} setItem={setElementList} item_list={element_list} canWrite={canWrite} />
					</ButtonGroup>
				}
			</Col>
		</Row>
	)

	}catch(err){
	return (
		<Row className="element_row p-1 border-bottom " >
			<Col sm="1">
				---
			</Col>
			<Col sm="2">
			</Col>
			<Col sm="1">
			</Col>
			<Col sm="1">
			</Col>
			<Col sm="1" >
			</Col>
			<Col sm="1">
			</Col>
			<Col sm="1" className="text-center">
			</Col>
		</Row>
	)

	}
}

const getDifference = (end, start, t) => {
	if (differenceInDays(end, start) < 1) {
		return (<>{differenceInHours(end, start)} {t('global.labels.hours')}</>)
	} else {
		return (<>{differenceInDays(end, start)} {t('global.labels.days')}</>)
	}
}


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const FailureStatus = ({ s, t }) => {
	if (s === 'close') {
		return (
			<>
				<FontAwesomeIcon icon="clipboard-check" className="text-success" /> &nbsp; {t('failures.labels.solved')}
			</>
		)

	} else if (s === 'notvalid') {
		return (
			<>
				<FontAwesomeIcon icon="ban" className="text-secondary" /> &nbsp; {t('failures.labels.notvalid')}
			</>
		)
	} else {
		return (
			<>
				<FontAwesomeIcon icon="envelope-open" className="text-primary" /> &nbsp; {t('failures.labels.open')}
			</>
		)

	}
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalFilter = ({ t, filterAppliedList, setFilterAppliedList }) => {

	const { structureElements } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const ticket_type_dict = ticketType_dict({ t })
	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	const st_list = [
		{ label: t('failures.labels.solved'), id: 'close' },
		{ label: t('failures.labels.notvalid'), id: 'notvalid' },
		{ label: t('failures.labels.open'), id: 'open' },
	]


	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="primary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.structure.length > 0 ?
					<>
						{Object.keys(structureElements.all_structure_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.structure.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.structure.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('structure', d)}>
								{structureElements.all_structure_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.structure.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.pavillon.length > 0 ?
					<>
						{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.pavillon.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.pavillon.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('pavillon', d)}>
								{structureElements.all_pavillon_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.pavillon.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.department.length > 0 ?
					<>
						{Object.keys(structureElements.all_department_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.department.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.department.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('department', d)}>
								{structureElements.all_department_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.department.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{filterAppliedList.status.length > 0 ?
					<>
						{st_list.map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.status.indexOf(d.id) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.status.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('status', d)}>
								{d.label} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{Object.entries(ticket_type_dict.failure.status).map((g, k) => (
							<Button key={k} size="sm" className={filterAppliedList.status.indexOf(g[0]) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.status.indexOf(g[0]) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('status', g[0])}>
								{g[1]} <FontAwesomeIcon icon="times" />
							</Button>

						))}

						{filterAppliedList.status.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('status')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}

			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.structure')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_structure_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.structure.indexOf(structureElements.all_structure_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('structure', structureElements.all_structure_dict[d].id)}>
										{structureElements.all_structure_dict[d].name}
									</Button>
								))}
								{filterAppliedList.structure.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.pavillon')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_pavillon_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.pavillon.indexOf(structureElements.all_pavillon_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('pavillon', structureElements.all_pavillon_dict[d].id)}
										disabled={filterAppliedList.structure.indexOf(structureElements.all_pavillon_dict[d].parent_list[0]) === -1}
									>
										{structureElements.all_pavillon_dict[d].name}
									</Button>
								))}
								{filterAppliedList.pavillon.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('pavillon')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.department')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(structureElements.all_department_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.department.indexOf(structureElements.all_department_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('department', structureElements.all_department_dict[d].id)}
										disabled={filterAppliedList.structure.indexOf(structureElements.all_department_dict[d].parent_list[0]) === -1}
									>
										{structureElements.all_department_dict[d].name}
									</Button>
								))}
								{filterAppliedList.department.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('department')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.status')}
							</Col>
						</Row>
						<Row>
							<Col>
								{st_list.map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.status.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('status', d.id)}
									>
										{d.label}
									</Button>
								))}
								{Object.entries(ticket_type_dict.failure.status).map((g, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.status.indexOf(g[0]) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('status', g[0])}
									>
										{g[1]}
									</Button>
								))}
								{filterAppliedList.status.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('status')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}


export default translate()(FailuresView)



