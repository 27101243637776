import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Button, Card, Form, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { format, endOfMonth, differenceInMonths, addDays, isThisMonth, add, getDate } from 'date-fns'
import { it } from 'date-fns/locale'

import { useReactToPrint } from 'react-to-print';

import DomainContext from '../contexts/domainContext.js';
import GlobalDataContext from '../contexts/globaldataContext.js';
import { frequency_dict } from "../constants/global_definitions"


import { myTimestampToDate, compareName, compareStartDateParam, getValue, getElementPreview, myDateToTimestamp, checkComponentPresence, getPlanStartDate, filterWeekDay, getSelectedElementB, getMonthLength, isEditable } from '../../util/ui_utils.js';

import AlertSection from "../AlertSection.js"

import { IsDone } from '../StructureGraphicElements.js'

import { getEventRange } from "../../Firebase2/Storage2/dbAgenda"
import { getInterventionById as getInterventionById2 } from '../../Firebase2/Storage2/dbIntervention.js';
import { getInterventionLogList } from '../../Firebase2/Storage2/dbInterventionLog';
import { addInterventionReportValidation, editInterventionReportValidation } from '../../Firebase2/Storage2/dbInterventionReportValidation'
import firebase from 'firebase/compat/app'
import InterventionreportContext from '../contexts/interventionreportContext.js';
import PrintFooter from '../generic/PrintFooter.js';
import { firebaseAuth } from '../../provider/AuthProvider.js';


let load_page = false
const popoverTiming = { show: 250, hide: 100 }
let curdate = { start: new Date(), end: new Date() }


let department_dict = {}

let permission_department_list = []
let permission_structure_list = []


let filterAppliedList = { department: [], type: [], is_deleted: false }
const defaultFilter = { department: [], type: [] }


////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalStructureInterventionreport = ({ item, structure, workarea, t, year, month, uData, employer_dict }) => {
	const { contract, structureElements } = useContext(GlobalDataContext)
	const { intervention_dict, setInterventionDict } = useContext(InterventionreportContext)

	const { domain, domain_data } = useContext(DomainContext)

	const componentRef = useRef();
	const footerRef = useRef();
	const childRefAlert = useRef()
	const contractId = contract.id

	const [showPlanEdit, setShowInterventionreportEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curStructure, setCurStructure] = useState()
	const [event_list, setEventList] = useState([])
	const [loading, setLoading] = useState(false)
	const [full_event_list, setFullEventList] = useState([])
	const [selectedWA, setSelectedWA] = useState(workarea ? [workarea.id] : [])
	const [viewRoomList, setViewRoomList] = useState([])
	const [viewRoomSummary, setViewRoomSummary] = useState([])
	const [filterEvent, setFilterEvent] = useState(['ordinary', 'requested', 'periodic'])
	const [used_service_list, setUsedServiceList] = useState([])
	const [used_intervention_dict, setUsedInterventionDict] = useState({})
	const [is_new, setIsNew] = useState(false)
	const [curMonthValidate, setCurMonthValidate] = useState({})
	const [isEditable, setIsEditable] = useState(false)

	const [viewOptions, setViewOptions] = useState({
		showOperator: false, showTime: false, showOperatorCount: false, showMode: 'cols'
	})

	const frequency_elements = frequency_dict({ t })


	const updateViewOptions = (s, v) => {
		setViewOptions(vo => {
			return ({ ...vo, [s]: v })
		})
	}

	const handleShowEditMonthInterventionreport = async () => {
		setCurStructure(structure.interventionreport_summary_list)
		setEditedElement(false)
		setFilterEvent(['ordinary', 'requested', 'periodic'])
		setViewRoomList([])
		setViewRoomSummary([])
		setShowInterventionreportEdit(true)
		let temp_intervention_dict = { ...intervention_dict }
		let temp_used_intervention_dict = {}
		let temp_department_dict = { ...department_dict }
		let temp_used_service_list = [...used_service_list]
		//console.log(temp_department_dict)
		//    console.log(temp_intervention_dict)
		let _ev_list = []
		setLoading(true)
		const ds = new Date(year, month.id, 1)
		const start_month = new Date(year, month.id, 1)
		const _start_date = firebase.firestore.Timestamp.fromDate(ds)
		const _end_date = firebase.firestore.Timestamp.fromDate(addDays(new Date(year, month.id, getMonthLength(_start_date)), 1))
		let return_data = await getEventRange(domain, contract.id, _start_date, _end_date)
		console.log(return_data)
		const MAX_DAY = contract.lock_validation_nextdays
		if (isThisMonth(ds) || (isThisMonth(add(ds, { month: 1 })) && getDate(new Date()) < MAX_DAY)) {
			console.log("---editable")
			setIsEditable(true)
		} else {
			setIsEditable(false)
		}


		let ev_status_list = []

		return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention' || e.extendedProps.type === 'requested_intervention')
			&& (filterAppliedList.is_deleted || !e.extendedProps.is_deleted)
			&& e.extendedProps.structure_list.indexOf(structure.id) >= 0
			&& myTimestampToDate(e.start).getMonth() === month.id
			&& (!workarea || e.extendedProps.workarea_id === workarea.id))
		if (contract.execution_mode === 'claudittrace') {
			console.log("CLAUDITRACE")
			ev_status_list = await getInterventionLogList(domain, contract.id, null, null, _start_date, _end_date)
			for (const ev of ev_status_list) {
				console.log("EV_status", ev)
				const intervention = temp_intervention_dict[ev.intervention_id] || await getInterventionById2(domain, contractId, ev.intervention_id)
				console.log("intervention", intervention)
				if (intervention) {
					ev.intervention_data = intervention
					temp_intervention_dict[ev.intervention_id] = intervention
					temp_used_intervention_dict[ev.intervention_id] = intervention
				}
			}
			//console.log(ev_status_list)
		}
		for (const event of return_data) {
			let insert = false
			if (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(event.extendedProps.type) !== -1) {
				event.start = myTimestampToDate(event.start)
				event.end = myTimestampToDate(event.end)
				const ev_done_data = ev_status_list.filter(e => e.event_id === event.id)
				event.done_data = ev_done_data.length > 0 ? ev_done_data[0] : undefined
				//				console.log("DONE DATA:...", event.done_data)
				//          console.log("return_data[" + d + "].extendedProps: ", return_data[d].extendedProps)
				if (event.extendedProps.type === 'ordinary_intervention') {
					if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
						//          console.log(return_data[d].extendedProps.intervention_id, temp_intervention_dict[return_data[d].extendedProps.intervention_id])
						const response = temp_intervention_dict[event.extendedProps.intervention_id] ||
							(await getInterventionById2(domain, contractId, event.extendedProps.intervention_id))
						let department_list_id = []
						if (response) {
							for (const room of response.room_list) {
								//                const r = response.room_list[rr]

								if (!temp_department_dict[room.parent_list[3]]) {
									temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
								}
								if (department_list_id.indexOf(room.parent_list[3]) === -1) {
									department_list_id.push(room.parent_list[3])
								}

								if (filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) {
									insert = true
								} else {
									console.log(filterAppliedList)
								}
							}
							temp_intervention_dict[event.extendedProps.intervention_id] = response
							temp_used_intervention_dict[event.extendedProps.intervention_id] = response
							event.extendedProps.name = response.name + " " + response.service.name
							event.extendedProps.department_list_id = department_list_id
							if (temp_used_service_list.indexOf(response.service.id) !== -1) {
								temp_used_service_list.push(response.service.id)
							}
							event.extendedProps.intervention_data = response
							event.extendedProps.summary = "Totale Locali: " + response.room_count

						}
					} else {
						event.extendedProps.name = "Intervento ordinario"  // FIXME
						event.extendedProps.summary = "DB OLD DATA"
					}
					event.backgroundColor = 'Grey'

				} else if (event.extendedProps.type === 'periodic_intervention') {
					//if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || permission_department_list.indexOf(event.extendedProps.intervention_id) >= 0)) {
					if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
						const response = temp_intervention_dict[event.extendedProps.intervention_id] ||
							(await getInterventionById2(domain, contractId, event.extendedProps.intervention_id))
						temp_intervention_dict[event.extendedProps.intervention_id] = response
						temp_used_intervention_dict[event.extendedProps.intervention_id] = response
						event.extendedProps.name = response.name + " " + response.service.name
						event.extendedProps.intervention_data = response
						event.extendedProps.summary = "Totale Locali: " + response.room_count
						let department_list_id = []
						for (const room of response.room_list) {

							if (!temp_department_dict[room.parent_list[3]]) {
								temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
							}
							if (department_list_id.indexOf(room.parent_list[3]) === -1) {
								department_list_id.push(room.parent_list[3])
							}
							if (filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) {
								insert = true
							} else {
								//		console.log(filterAppliedList)
							}
						}
						if (temp_used_service_list.indexOf(response.service.id) !== -1) {
							temp_used_service_list.push(response.service.id)
						}
						event.extendedProps.department_list_id = department_list_id
					} else {
						event.extendedProps.name = "Intervento periodico"  // FIXME
						event.extendedProps.summary = "DB OLD DATA"
					}
					event.backgroundColor = 'LightGrey'
				} else if (event.extendedProps.type === 'requested_intervention') {
					if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
						const response = temp_intervention_dict[event.extendedProps.intervention_id] ||
							(await getInterventionById2(domain, contractId, event.extendedProps.intervention_id))
						temp_intervention_dict[event.extendedProps.intervention_id] = response
						temp_used_intervention_dict[event.extendedProps.intervention_id] = response
						event.extendedProps.name = response.name + " " + response.service.name
						event.extendedProps.intervention_data = response
						event.extendedProps.summary = "Totale Locali: " + response.room_count
						let department_list_id = []
						for (const room of response.room_list) {

							if (!temp_department_dict[room.parent_list[3]]) {
								temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
							}
							if (department_list_id.indexOf(room.parent_list[3]) === -1) {
								department_list_id.push(room.parent_list[3])
							}
							if (filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) {
								insert = true
							} else {
								//		console.log(filterAppliedList)
							}
						}
						if (temp_used_service_list.indexOf(response.service.id) !== -1) {
							temp_used_service_list.push(response.service.id)
						}
						event.extendedProps.department_list_id = department_list_id
					} else {
						event.extendedProps.name = "Intervento a richiesta"  // FIXME
						event.extendedProps.summary = "DB OLD DATA"
					}
					event.backgroundColor = 'LightBlue'
				}
				if (insert) {
					_ev_list.push(event)
				}
			}
		}
		//		_ev_list = _ev_list.filter(e => getMyDate(e.start).getMonth() === month.id && e.extendedProps.structure_list.indexOf(structure.id)>=0)
		_ev_list = _ev_list.sort(compareStartDateParam)

		//console.log(structure.interventionreport_summary_list, _ev_list, month, year)
		setInterventionDict(temp_intervention_dict)
		setUsedServiceList(temp_used_service_list)
		//console.log(temp_used_intervention_dict)
		setUsedInterventionDict(temp_used_intervention_dict)
		setEventList(_ev_list)
		//setSelectedWA(structure.workarea_list.map(w => w.id))
		setFullEventList(_ev_list)
		setLoading(false)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)

	}

	const changeSelectedWa = (w_id) => {
		let _selectedWA = [...selectedWA]
		if (selectedWA.indexOf(w_id) >= 0) {
			const c = selectedWA.indexOf(w_id)
			_selectedWA.splice(c, 1)
		} else {
			_selectedWA.push(w_id)
		}
		const filtered_ev_list = full_event_list.filter(e => _selectedWA.indexOf(e.extendedProps.workarea_id) >= 0)
		console.log("UPDATE WA", _selectedWA, full_event_list.length, filtered_ev_list.length)
		setEventList(filtered_ev_list)
		setSelectedWA(_selectedWA)
	}

	const changeSelectedParam = (el_id, param, setParam) => {
		let _param = [...param]
		if (param.indexOf(el_id) >= 0) {
			const c = param.indexOf(el_id)
			_param.splice(c, 1)
		} else {
			_param.push(el_id)
		}
		console.log("UPDATE WA", _param)
		setParam(_param)
	}

	const handleClosePlan = () => {
		setShowInterventionreportEdit(false)
	}

	const handleSaveValidate = async (is_validated) => {
		if (is_new) {
			const return_data = await addInterventionReportValidation(domain, contractId, is_validated)
			console.log(return_data)
			setCurMonthValidate(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				childRefAlert.current.addAlert('success', 'validazione eseguita correttamente')

			}
		} else {
			const return_data = await editInterventionReportValidation(domain, contractId, curMonthValidate.id, is_validated)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('success', 'validazione modificata correttamente')
			}
			setCurMonthValidate(return_data)
		}
		//setShowInterventionreportEdit(false)

	}

	const handlePrint = useReactToPrint({
		content: () => {
			const tableStat = componentRef.current.cloneNode(true);
			const PrintElem = document.createElement('div');
			PrintElem.appendChild(tableStat);
			return PrintElem;
		},
		pageStyle: pageStyle,
		documentTitle: `report interventi: ${structure.name} (${month.label} ${year})`,
	});

	const MonthButton = () => {
		const m_startdate = new Date(year, month.id, 1)
		const now = new Date()
		const m_enddate = endOfMonth(m_startdate)
		if (m_enddate >= myTimestampToDate(contract.startDate) && m_startdate <= myTimestampToDate(contract.endDate)) {
			if (now >= m_startdate) {
				return (
					<Button className="me-1" variant={workarea ? "info" : 'outline-info'} size="sm" onClick={() => handleShowEditMonthInterventionreport()}><FontAwesomeIcon icon="eye" />{workarea && !is_new ? (curMonthValidate.validate ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />) : ''} </Button>
				)
			} else {
				return (
					<Button disabled={true} className="me-1" variant={workarea ? "info" : 'outline-info'} size="sm" onClick={() => handleShowEditMonthInterventionreport()}><FontAwesomeIcon icon="eye" /> </Button>
				)
			}
		} else {
			return (
				<>
				</>
			)
		}
	}






	useEffect(() => {
		if (filterEvent.length === 0) {
			setEventList(full_event_list)
		} else {
			try {
				setEventList(full_event_list.filter(e => (

					(filterEvent.indexOf('ordinary')>=0 && e.extendedProps.type === 'ordinary_intervention') ||
					(filterEvent.indexOf('periodic')>=0 && e.extendedProps.type === 'periodic_intervention') ||
					(filterEvent.indexOf('requested')>=0 && e.extendedProps.type === 'requested_intervention') 
				)
				))

			} catch (err) {
			}
		}

	}, [filterEvent])

	useEffect(() => {
		const start_month = new Date(year, month.id, 1)
		if (workarea) {

			let prev_data = workarea.validation_list.filter(a => a.year === year && a.month === month.id)
			if (prev_data.length > 0) {
				setCurMonthValidate(prev_data[0])
				setIsNew(false)
			} else {
				setIsNew(true)
				const newmonth_validate = {
					month: month.id,
					year: year,
					validate: false,
					date: getPlanStartDate(start_month),
					workarea_id: workarea.id,
					user_validate_id: uData.id || -1,
					user_validate_name: uData.displayName || 'admin',
					note: '',
					lastEdit: firebase.firestore.Timestamp.now()
				}
				setCurMonthValidate(newmonth_validate)
			}
		}
	}, [])



	const testAlert = () => {
		childRefAlert.current.addAlert('success', 'validazione modificata correttamente')

	}



	return (
		<>
			<MonthButton />
			{curStructure ?
				<Modal show={showPlanEdit} onHide={handleClosePlan} fullscreen bsclass="full-modal" backdrop="static" scrollable={true}>
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						<AlertSection ref={childRefAlert} />
						<Container fluid>
							<Row className="border-bottom mb-1">
								<Col>
									<Modal.Title>
										<FontAwesomeIcon icon="info" /> <span className="text-info">{t('contract.labels.view_month_interventionreport')}</span> {month.label} {year}: {structure.name}&nbsp;
										{workarea && curMonthValidate.validate ? <>({t('workarea.labels.report_validate')})</> : ''
										}
										{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
									</Modal.Title>
								</Col>
							</Row>
							<Row>
								<Col sm="1"><FontAwesomeIcon icon="cogs" /></Col>
								<Col>

									{!workarea ?
										<Row>
											<Col sm="2">
												Seleziona le aree di lavoro da visualizzare
											</Col>
											<Col>
												{structure.workarea_list.map((w, wk) => (
													<Button size="sm" key={wk} variant={selectedWA.length === 0 || selectedWA.indexOf(w.id) >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedWa(w.id)}>{w.name}</Button>
												))}
											</Col>
										</Row>
										:
										<></>}
									<Row >
										<Col>
											<Row>
												<Col className='border-bottom'>
													CALENDARIO
												</Col>
											</Row>
											<Row>
												<Col sm="4">
													Visualizza interventi:
												</Col>
												<Col>
													<Button size="sm" variant={filterEvent.indexOf('ordinary') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('ordinary', filterEvent, setFilterEvent)}>{t('global.pages.ordinaryintervention')}</Button>
													<Button size="sm" variant={filterEvent.indexOf('periodic') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('periodic', filterEvent, setFilterEvent)}>{t('global.pages.periodicintervention')}</Button>
													<Button size="sm" variant={filterEvent.indexOf('requested') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('requested', filterEvent, setFilterEvent)}>{t('global.pages.requestedintervention')}</Button>
												</Col>

											</Row>
											<Row>
												<Col sm="4" >
													Visualizza elenco locali:
												</Col>
												<Col>
													<Button size="sm" disabled={filterEvent.indexOf('ordinary') < 0} variant={viewRoomList.indexOf('ordinary') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('ordinary', viewRoomList, setViewRoomList)}>{t('global.pages.ordinaryintervention')}</Button>
													<Button size="sm" disabled={filterEvent.indexOf('periodic') < 0} variant={viewRoomList.indexOf('periodic') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('periodic', viewRoomList, setViewRoomList)}>{t('global.pages.periodicintervention')}</Button>
													<Button size="sm" disabled={filterEvent.indexOf('requested') < 0} variant={viewRoomList.indexOf('requested') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('requested', viewRoomList, setViewRoomList)}>{t('global.pages.requestedintervention')}</Button>
												</Col>

											</Row>
											<Row>
												<Col sm="4"  >
													Modalità calendario:
												</Col>
												<Col>
													<Button size="sm" variant={viewOptions.showMode === 'grid' ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showMode', 'grid')}><FontAwesomeIcon icon="grip-vertical" /> </Button>
													<Button size="sm" variant={viewOptions.showMode === 'cols' ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showMode', 'cols')}><FontAwesomeIcon icon="grip-lines-vertical" /></Button>
													<Button size="sm" variant={viewOptions.showMode === 'rows' ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showMode', 'rows')}><FontAwesomeIcon icon="grip-lines" /></Button>
												</Col>
											</Row>
											{contract.execution_mode === 'claudittrace' &&
												<Row>
													<Col sm="4"  >
														matricola operatore (su clauditTrace)
													</Col>
													<Col>
														<Button size="sm" variant={viewOptions.showOperator ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showOperator', true)}>visualizza </Button>
														<Button size="sm" variant={!viewOptions.showOperator ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showOperator', false)}>nascondi</Button>
													</Col>
												</Row>
											}
											{/* 									{contract.execution_mode === 'claudittrace' &&
												<Row>
													<Col sm="4"  >
														Orario esecuzione (su clauditTrace)
													</Col>
													<Col>
														<Button size="sm" variant={viewOptions.showTime ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showTime', true)}>visualizza </Button>
														<Button size="sm" variant={!viewOptions.showTime ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showTime', false)}>nascondi</Button>
													</Col>
												</Row>
											}*/}
											{contract.execution_mode === 'claudittrace' &&
												<Row>
													<Col sm="4"  >
														Conteggio operatori giornalieri (su clauditTrace)
													</Col>
													<Col>
														<Button size="sm" variant={viewOptions.showOperatorCount ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showOperatorCount', true)}>visualizza </Button>
														<Button size="sm" variant={!viewOptions.showOperatorCount ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => updateViewOptions('showOperatorCount', false)}>nascondi</Button>
													</Col>
												</Row>
											}
										</Col>
										<Col className="border-start">
											<Row>
												<Col className='border-bottom'>
													RIEPILOGO</Col>
											</Row>
											<Row>
												<Col sm="3">
													Visualizza riepilogo locali:
												</Col>
												<Col>
													<Button size="sm" disabled variant={viewRoomSummary.indexOf('ordinary') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('ordinary', viewRoomSummary, setViewRoomSummary)}>{t('global.pages.ordinaryintervention')}</Button>
													<Button size="sm" variant={viewRoomSummary.indexOf('periodic') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('periodic', viewRoomSummary, setViewRoomSummary)}>{t('global.pages.periodicintervention')}</Button>
													<Button size="sm" variant={viewRoomSummary.indexOf('requested') >= 0 ? 'secondary' : 'outline-secondary'} className="me-1 mb-1" onClick={() => changeSelectedParam('requested', viewRoomSummary, setViewRoomSummary)}>{t('global.pages.requestedintervention')}</Button>
												</Col>

											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</Modal.Header>
					<Modal.Body>

						<Container fluid ref={componentRef} className="print_page">
							<style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>

							<Row>
								<Col className='ms-2 me-2'>
									<Row className="mt-2 border-bottom">
										{domain_data ?
											<>
												<Col className="text-center">
													<img
														src={!domain_data.logo ? "/Logo.Claudit.registrato.png" : domain_data.logo}
														height="50"
														alt=""
													/>
												</Col>
												<Col className="text-dark">
													{domain_data.name}
												</Col>
											</>
											: <>
											</>}
										<Col className="text-center">
											{contract ?
												<>
													{getElementPreview(contract, '40px')}
												</>
												: ''}
										</Col>
										<Col className="text-dark">
											{contract && contract.company.name ? contract.company.name : ''}
										</Col>
									</Row>
									<Row className="">
										<Col xs="2" className="text-dark text-end">
											Struttura
										</Col>
										<Col className="font-weight-bold border-end">
											{structure.name}
										</Col>
										<Col xs="2" className="text-dark text-end">
											Periodo
										</Col>
										<Col className="font-weight-bold">
											{month.label} {year}
										</Col>
									</Row>

									<Row>
										<Col className="bg-primary text-light mb-1">
											{t("global.labels.workarea")}
										</Col>
									</Row>
									<Row>
										<Col className="bg-secondary text-light text-start" >
											{t('global.labels.name')}
										</Col>
										<Col className="bg-secondary text-light ms-1 text-start" >
											<Row className="border-bottom border-light">
												<Col>
													{t("global.labels.referents")}
												</Col>
											</Row>
											<Row>
												<Col>
													Cognome
												</Col>
												<Col>
													Nome
												</Col>
												<Col>
													Area
												</Col>
											</Row>
										</Col>
									</Row>
									{structure.workarea_list.filter(w => selectedWA.length === 0 || selectedWA.indexOf(w.id) !== -1).map((w, wk) => (
										<Row key={wk} className="border-bottom pb-1 mb-1">
											<Col>
												{w.name}
											</Col>
											<Col>
												{w.referent_list.map((i, key) => (
													<Row key={key} className="pb-0 mb-0">
														<Col>{i.lastname}</Col>
														<Col>{i.firstname}</Col>
														<Col>{i.area}</Col>
													</Row>))
												}
											</Col>
										</Row>

									))}
									<Row>
										<Col className="bg-primary text-light mb-1">
											Calendario
										</Col>
									</Row>
									{loading ?
										<Row>
											<Col className="text-center">
												<Spinner animation="border" role="status">
													<span className="sr-only">Loading...</span>
												</Spinner>

											</Col>
										</Row>
										: <>
											{event_list.length > 0 ?
												<>
													{viewOptions.showMode === 'rows' ?
														<Row className="mb-1">
															<Col className="bg-secondary text-light text-start" xs="1">
																{t('global.labels.day')}
															</Col>
															<Col className="bg-secondary text-light ms-1 text-start">{t('global.labels.intervention')}
															</Col>
															<Col className="bg-secondary text-light ms-1 " xs={(viewOptions.showOperator || viewOptions.showTime) ? "3" : "2"}>
																<Row>
																	<Col>
																		{t('reports.labels.done')}
																	</Col>
																	{viewOptions.showOperator &&
																		<Col>
																			Operatore
																		</Col>}
																	{viewOptions.showTime &&
																		<Col>
																			Orario
																		</Col>}
																</Row>
															</Col>
														</Row>
														:
														<Row className="mb-1">
															<Col className="bg-secondary text-light text-start" xs="1">
																{t('global.labels.day')}
															</Col>
															<Col className="bg-secondary text-light ms-1 text-start">{t('global.labels.intervention')}
															</Col>
															<Col className="bg-secondary text-light ms-1 text-end" xs="1">{t('reports.labels.done')}
															</Col>
															<Col className="bg-secondary text-light ms-1 text-start border-start border-info" xs="1">{t('global.labels.day')}
															</Col>
															<Col className="bg-secondary text-light ms-1 text-start">{t('global.labels.intervention')}
															</Col>
															<Col className="bg-secondary text-light ms-1 text-end" xs="1">{t('reports.labels.done')}
															</Col>
														</Row>
													}
													<Row >
														<Col>
															<DaysList
																event_list={event_list}
																t={t}
																month_length={getMonthLength(event_list[0].start)}
																viewRoomList={viewRoomList}
																show_workarea={!workarea}
																employer_dict={employer_dict}
																viewOptions={viewOptions}
															/>
														</Col>
													</Row>
												</>
												: <Row>
													<Col>
														{workarea ?
															<>
																Nessun evento per questa area di lavoro in questo mese
															</>
															:
															<>
																Nessun evento per questa struttura in questo mese
															</>
														}
													</Col>
												</Row>}
										</>}
									{viewRoomSummary.indexOf('periodic') >= 0 ?
										<>
											<Row >
												<Col className="bg-primary text-light mb-1 mt-1">
													Riepilogo interventi periodici
												</Col>
											</Row>
											<Row>
												{/* 													<Col className="bg-secondary text-light text-start" xs="2">
														{t('service.labels.frequency')}
													</Col> */}
												<Col className="bg-secondary text-light ms-1 text-start" xs="3">
													{t("intervention.labels.intervention")}
												</Col>
												<Col className="bg-secondary text-light ms-1 text-start" xs="3">
													{t("global.labels.service")}
												</Col>
												<Col className="bg-secondary text-light ms-1 text-start" >
													<Row className="border-bottom border-light">
														<Col>
															{t("global.labels.room_list")}
														</Col>
													</Row>
													<Row className="small">
														<Col className=" me-1 ps-1 pe-1" xs="1">
															tot
														</Col>
														<Col className=" me-1" xs="3">
															Tipologia
														</Col>
														<Col className=" me-1">
															Locali
														</Col>
													</Row>
												</Col>
											</Row>
											{Object.keys(used_intervention_dict).filter(i_key => used_intervention_dict[i_key].type === 'periodic' && used_intervention_dict[i_key].room_list.length > 0).map((i_key, key) => (

												<Row key={key} className={used_intervention_dict[i_key].type === 'ordinary' ? 'd-none' : 'border-bottom mb-1 pb-1'}>
													{/* 														<Col xs="2">
															{frequency_elements[used_intervention_dict[i_key].frequency_mode] ? frequency_elements[used_intervention_dict[i_key].frequency_mode].label : ''}
														</Col> */}
													<Col xs="3">
														{used_intervention_dict[i_key].name}
													</Col>
													<Col xs="3">
														{console.log(used_intervention_dict[i_key])}
														{used_intervention_dict[i_key].service.name}
													</Col>
													<Col>
														<RoomList t={t} room_list={used_intervention_dict[i_key].room_list} intervention_type="periodic_intervention" />
													</Col>
												</Row>

											))}
										</>
										: <></>}
									<Row>
										<Col><b>Validazione interventi:</b>&nbsp;
											{(contract.execution_mode === undefined || contract.execution_mode === 'auto') &&
												<>Automatica (in base all'orario)</>
											}
											{(contract.execution_mode === 'manual') &&
												<>Manuale</>
											}
											{(contract.execution_mode === 'claudittrace') &&
												<>Tramite ClauditTrace</>
											}
										</Col>
									</Row>
									{workarea && !validateDisabled(year, month) ?
										<Row className="border-bottom mb-1 pb-1 mt-1 border-bottom border-top">
											<Col>
												{curMonthValidate.validate ?
													<Badge>
														{t('workarea.labels.validated')}
													</Badge>
													:
													<Badge>
														{t('workarea.labels.unvalidated')
														}
													</Badge>
												}
											</Col>
											<Col>
												{curMonthValidate.note}
											</Col>
										</Row>
										:
										<>
										</>
									}

									<Row className="text-center">
										{curMonthValidate.validate ?
											<Col>
												<Row>
													<Col className="font-italic">
														Data validazione
													</Col>
												</Row>
												<Row>
													<Col className="text-secondary">
														{format(myTimestampToDate(curMonthValidate.lastEdit), 'dd/MM/yyyy HH:mm')}
													</Col>
												</Row>
											</Col>
											:
											<Col>
												<Row>
													<Col className="font-italic">
														Data report
													</Col>
												</Row>
												<Row>
													<Col className="text-secondary">
														{format(myTimestampToDate(curMonthValidate.lastEdit), 'dd/MM/yyyy HH:mm')}
													</Col>
												</Row>
											</Col>
										}
										<ContractReferent />
										<Col>
											<Row>
												<Col className="font-italic">
													Responsabile
												</Col>
											</Row>
											<ReferentList structure={structure} selectedWA={selectedWA} />
										</Col>
									</Row>
								</Col>
							</Row>

							<PrintFooter />
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Container fluid>
							<Row className="h4">
								<Col className="text-end">
									<Button variant="secondary" onClick={handleClosePlan} className="me-1">
										{t('modal.close')}
									</Button>
									{workarea ?
										(uData.isGlobalAdmin || workarea.referent_list.filter(r => r.user_id === uData.id).length > 0)
											&& (!contract.lock_validation_active || isEditable) ?
											<ModalValidation functions={{ save: handleSaveValidate }} t={t} curMonthValidate={curMonthValidate} validateDisabled={validateDisabled(year, month)} />
											: <>
											</>
										: <></>}
									<Button variant="info" onClick={handlePrint} className="ms-1">
										<FontAwesomeIcon icon="print" />  {t('modal.print')}
									</Button>
								</Col>
							</Row>
						</Container>
					</Modal.Footer>
				</Modal>
				: ''}
		</>
	)
}



const validateDisabled = (year, month) => {
	const m_startdate = new Date(year, month.id, 1)
	const now = new Date()
	const m_enddate = endOfMonth(m_startdate)
	if (now > m_enddate) {
		return false
	} else {
		return true
	}

}



const ReferentList = ({ structure, selectedWA }) => {
	const [ref_list, setRefList] = useState([])
	useEffect(() => {
		const wa_list = structure.workarea_list.filter(w => selectedWA.length === 0 || selectedWA.indexOf(w.id) !== -1)
		let r_list = []
		for (const w in wa_list) {
			for (const i in wa_list[w].referent_list) {

				const r = wa_list[w].referent_list[i]
				if (checkComponentPresence(r, r_list, 'id') < 0) {
					r_list.push(r)
				}
			}
		}
		setRefList(r_list)

	}, [selectedWA])
	return (

		ref_list.map((i, key) => (
			<Row key={key} className="font-weight-bold mb-0 pb-0">
				<Col className="text-end mb-0 pb-0 pe-1">{i.lastname}</Col>
				<Col className="text-start mb-0 pb-0 ps-1 ">{i.firstname}</Col>
			</Row>))
	)
}

///////////////////////////////////////////////////////////////////////////////////////
const DaysList = ({ t, event_list, col_id, month_length, viewRoomList, showMode, show_workarea, employer_dict, viewOptions }) => {

	//console.log(event_list)
	const days_list = Array.from(Array(month_length + 1).keys())
	days_list.shift()
	if (col_id === 0) {
		let rows = []
		for (let d = 1; d <= 16; d++) {
			rows.push(<OneReportDay t={t} d={d} event_list={event_list.filter(e => myTimestampToDate(e.start).getDate() === d)} show_workarea={show_workarea} employer_dict={employer_dict} viewOptions={viewOptions} />)
		}
		return (<>{rows}</>)
	} else if (col_id === 1) {
		let rows = []
		for (let d = 17; d <= month_length; d++) {
			rows.push(<OneReportDay t={t} d={d} event_list={event_list.filter(e => myTimestampToDate(e.start).getDate() === d)} show_workarea={show_workarea} employer_dict={employer_dict} viewOptions={viewOptions} />)
		}
		return (<>{rows}</>)
	} else {
		if (viewOptions.showMode === 'grid') {
			const rows = days_list.reduce(function (rows, key, index) {
				return (index % 2 == 0 ? rows.push([key])
					: rows[rows.length - 1].push(key)) && rows;
			}, []);
			return (
				rows.map((row) =>
				(
					<div key={row}>
						<Row key={row} >
							{row.map(col => (
								<OneReportDay
									size={6}
									key={col}
									t={t}
									d={col}
									event_list={event_list.filter(e => myTimestampToDate(e.start).getDate() === col)}
									lastDay={col > month_length - 2}
									viewRoomList={viewRoomList}
									show_workarea={show_workarea}
									employer_dict={employer_dict}
									viewOptions={viewOptions}
								/>
							))}
						</Row>
					</div>
				)
				)
			)
		} else if (viewOptions.showMode === 'rows') {
			return (
				days_list.map(((col, k) =>
				(
					<OneReportDay
						size={12}
						key={k}
						t={t}
						d={col}
						fullWidth={true}
						event_list={event_list.filter(e => myTimestampToDate(e.start).getDate() === col)}
						lastDay={col > month_length - 1}
						viewRoomList={viewRoomList}
						show_workarea={show_workarea}
						employer_dict={employer_dict}
						viewOptions={viewOptions}
					/>
				))
				)
			)

		} else {
			const half = Math.ceil(days_list.length / 2);

			const firstHalf = days_list.slice(0, half)
			let secondHalf = []
			if (days_list.length % 2 === 0) {
				secondHalf = days_list.slice(-half)
			} else {
				secondHalf = days_list.slice(-half + 1)
			}

			return (
				<Row>
					<Col >
						{firstHalf.map(col => (
							<Row key={col}>
								<OneReportDay
									size={12}
									key={col}
									t={t}
									d={col}
									event_list={event_list.filter(e => myTimestampToDate(e.start).getDate() === col)}
									lastDay={col === half}
									viewRoomList={viewRoomList}
									show_workarea={show_workarea}
									employer_dict={employer_dict}
									viewOptions={viewOptions}
								/>
							</Row>
						))}
					</Col>
					<Col>
						{secondHalf.map(col => (
							<Row key={col}>
								<OneReportDay
									size={12}
									key={col}
									t={t}
									d={col}
									event_list={event_list.filter(e => myTimestampToDate(e.start).getDate() === col)}
									lastDay={col > month_length - 1}
									viewRoomList={viewRoomList}
									show_workarea={show_workarea}
									employer_dict={employer_dict}
									viewOptions={viewOptions}
								/>
							</Row>
						))}
					</Col>

				</Row>
			)
		}
	}

}

const getInterventionColor = (type) => {
	if (type === 'ordinary_intervention') {
		return 'info'
	} else if (type === 'periodic_intervention') {
		return 'warning'
	} else {
		return 'danger'
	}

}

const OneReportDay = ({ t, d, event_list, lastDay, viewRoomList, size, show_workarea, employer_dict, viewOptions, fullWidth }) => {
	const { contract } = useContext(GlobalDataContext)
	const getOperatorCount = () => {
		if (contract.execution_mode === 'claudittrace') {
			const op_count = [... new Set(event_list.map(e => e.done_data?.operator_id).filter((e, k) => e !== undefined))].length
			if (op_count > 0) {
				return (
					<Badge><FontAwesomeIcon icon="user" /><FontAwesomeIcon icon="times" />
						{[... new Set(event_list.map(e => e.done_data?.operator_id).filter((e, k) => e !== undefined))].length}
					</Badge>
				)

			} else {
				return ''
			}
		} else {
			return ''
		}
	}

	const lastColWidth = () => {
		if (fullWidth) {
			if (viewOptions.showOperator || viewOptions.showTime) {
				return "2"
			} else {
				return "1"
			}

		} else {
			if (viewOptions.showOperator || viewOptions.showTime) {
				return "3"
			} else {
				return "2"
			}

		}
	}

	return (
		<>
			{fullWidth && d === 16 &&

				<div className="page-break" />
			}
			<Col xs={size} className={lastDay ? "print_data border-bottom border-info" : "print_data"}>
				<Row className={size === 12 || d % 2 === 0 ? "border-top border-info border-start" : "border-top  border-info"}>
					<Col xs="1" className="p-1 text-center">
						{d} {viewOptions.showOperatorCount && getOperatorCount()}
					</Col>
					<Col>
						{event_list.map((e, ke) =>
							<Row key={ke} className={ke < event_list.length - 1 && !fullWidth ? "element_row pb-1 mb-1 border-bottom" : "element_row pb-1 mb-1"}>
								<Col>

									<Row>
										<Col>

											<Badge bg={getInterventionColor(e.extendedProps.type)}>
												{format(myTimestampToDate(e.start), 'HH:mm')} - {format(myTimestampToDate(e.end), 'HH:mm')}
											</Badge>&nbsp;
											{show_workarea &&
												<span style={{ fontSize: '0.7em' }} className="text-info">{e.extendedProps.workarea_name}&nbsp;</span>
											}
											<span style={{ fontSize: '0.7em' }}>
												{e.extendedProps.name}
											</span>
										</Col>
										<Col xs={lastColWidth()} className='p-0'>
											{console.log("a", e)}
											<IsDone
												contract_execution_mode={contract.execution_mode}
												end={myTimestampToDate(e.end)}
												done={e.done_data || e.extendedProps.execution_done}
												report={true}
												operator={viewOptions.showOperator}
												employer_dict={employer_dict}
												showTime={viewOptions.showTime}
											/>
										</Col>
									</Row>
									{((viewRoomList.indexOf('ordinary') >= 0 && e.extendedProps.type === 'ordinary_intervention')
										|| (viewRoomList.indexOf('periodic') >= 0 && e.extendedProps.type === 'periodic_intervention')
										|| (viewRoomList.indexOf('requested') >= 0 && e.extendedProps.type === 'requested_intervention')
									)
										?
										<Row>
											<Col>
												<RoomList
													t={t}
													room_list={e.extendedProps.intervention_data.room_list}
													title={true}
													intervention_type={e.extendedProps.type}
													service_id={e.extendedProps.intervention_data.service.id}
													cw={e.extendedProps.intervention_data.custom_weekdays}
													start={e.start}
												/>
											</Col>
										</Row>

										: <></>}
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Col >
		</>
	)
}


// --------------------------------------------------------
const RoomList = ({ t, room_list, title, intervention_type, cw, service_id, start }) => {
	const { roomtype_dict, structureElements } = useContext(GlobalDataContext)
	console.log(intervention_type, room_list)
	const filtered_room_list = (intervention_type === 'periodic_intervention' || intervention_type === 'requested_intervention') ?
		room_list.filter(_room => {
			//console.log(structureElements.all_room_dict[_room.id])
			if (intervention_type === 'requested_intervention') {
				return true
			} else {

				return structureElements.all_room_dict[_room.id].status === 'active'
			}
		}
		)
		:
		//	event_intervention.room_list.filter(_room => filterCurrentDayRoom(e, _room, structureElements.all_room_dict, roomtype_dict))
		room_list.filter(room =>
			filterWeekDay(getSelectedElementB(
				service_id,
				roomtype_dict[structureElements.all_room_dict[room.id].roomtype.id] ? roomtype_dict[structureElements.all_room_dict[room.id].roomtype.id].service_list : [],
				'service',
				'id'
			).weekdays, start, cw, room.id))

	console.log("filtered room list", filtered_room_list)
	const aresult = filtered_room_list.reduce(function (r, a) {
		(r[a.roomtype.id.id ? a.roomtype.id.id : a.roomtype.id] = r[a] || []).push(a);
		return r;
	}, {})
	let result = {}
	for (const r of filtered_room_list) {
		const rt_id = r.roomtype.id.id ? r.roomtype.id.id : r.roomtype.id
		if (result[rt_id]) {
			result[rt_id].push(structureElements.all_room_dict[r.id])
		} else {
			result[rt_id] = [structureElements.all_room_dict[r.id]]
		}

	}
	console.log("roomtype symmary", result)
	return (
		<>
			{title ?
				<Row className="small">
					<Col className="bg-light me-1 p-1 text-center" xs="1">
						tot
					</Col>
					<Col className="bg-light me-1 p-1" xs="3">
						Tipologia
					</Col>
					<Col className="bg-light me-1 p-1">
						Locali ({filtered_room_list.length} attivi / {room_list.length} programmati)
					</Col>
				</Row>
				: <></>}
			{Object.keys(result).map((rt_id, key) => (

				<Row key={key} className="small">
					<Col xs="1" className="font-weight-bold p-1 text-center">
						{result[rt_id].length}
					</Col>
					<Col xs="3" className="font-weight-bold">
						{getValue(roomtype_dict[rt_id], 'name')}:
					</Col>
					<Col>
						{result[rt_id].map((r, r_id) => (
							<span key={r.id} className="me-1">
								{r.name}
							</span>
						)).reduce((prev, curr) => [prev, ', ', curr])}
					</Col>
				</Row>

			))}
		</>
	)
}





// --------------------------------------------------------
const ContractReferent = () => {
	const { contract } = useContext(GlobalDataContext)
	if (contract.managementgroup_list && contract.managementgroup_list.length > 0) {

		const r = contract.managementgroup_list.filter(g => g.report_reference)
		return (
			<Col>
				<Row>
					<Col className="font-style-italic text-center">
						Referente ditta
					</Col>
				</Row>
				{r.map((g, k) => (
					<Row key={k} className="font-weight-bold">
						<Col>
							{g.lastname} {g.firstname}
						</Col>
					</Row>
				)
				)}
			</Col>
		)

	} else {
		return (<></>)
	}

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
function ModalValidation({ curMonthValidate, setItem, t, functions, canWrite, validateDisabled }) {


	const [showEdit, setShow] = useState(false)
	const [curElement, setCurrentElement] = useState(curMonthValidate)
	const [editedElement, setEditedElement] = useState(false)

	const { userData } = useContext(firebaseAuth)

	const handleShowEdit = () => {
		setCurrentElement(curMonthValidate)
		setShow(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 500)
	}


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}


	const validate = (v) => {
		functions.save({ ...curElement, validate: v })
		setEditedElement(false)
		setShow(false)
	}

	const handleClose = () => setShow(false);


	return (
		<>
			{true ?
				<Button onClick={handleShowEdit} disabled={validateDisabled}>
					{t('modal.validate_mode')}&nbsp;
					({curElement.validate && userData && userData.isGlobalAdmin ?
						t('workarea.labels.unvalidate')
						:
						t('workarea.labels.validate')
					})
				</Button>
				:
				<></>}
			<Modal show={showEdit} onHide={handleClose} className="bg-secondary" size="xl">
				<Modal.Header closeButton  >
					<Modal.Title>
						{t('modal.validate_mode')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} className="border-bottom mb-1 pb-1 mt-1">
							<Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="note" value={curElement.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Row>
							<Col>
								{curElement.validate ?
									<Badge bg="success">
										{t('workarea.labels.validated')}
									</Badge>
									:
									<Badge bg="warning" text="dark">
										{t('workarea.labels.unvalidated')
										}
									</Badge>
								}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('modal.close')}
					</Button>
					{console.log("USERDATA", userData)}
					{userData && userData.isGlobalAdmin &&
						<Button variant="warning" onClick={() => validate(false)}>
							<FontAwesomeIcon icon="times" /> {t('modal.unvalidate')}
						</Button>
					}
					<Button variant="success" onClick={() => validate(true)}>
						<FontAwesomeIcon icon="check" /> {t('modal.validate')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}





const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
		display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		background-color: #fff !important,
		page-break-after: always;
	  }
  }

  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


