import React, { Component, useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, FormControl, Row, Col, Form, Button, ButtonGroup, InputGroup, Tab, Nav } from 'react-bootstrap';
import { useParams } from "react-router-dom"
import DateTime from 'react-datetime';
import { translate } from 'react-polyglot'
//import DateTime from '@nateradebaugh/react-datetime';





import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from '../components/contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"



import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import styles from '../components/constants/styles.js'
import ModalClothing from "../components/ModalClothing"

import { getSelectedElement, getValue, myTimestampToDate, checkComponentPresence, propComparator, compareName, makeid, getBaseObjectValue } from "../util/ui_utils.js"




import { getBaseObjectTypeList as getBaseObjectTypeList2 } from "../Firebase2/Storage2/dbBaseObject"
import { getClothingList as getClothingList2, addClothing as addClothing2, editClothing as editClothing2, deleteClothing as deleteClothing2 } from "../Firebase2/Storage2/dbClothing"
import { getTrainingList as getTrainingList2, addTraining as addTraining2, editTraining as editTraining2, deleteTraining as deleteTraining2 } from "../Firebase2/Storage2/dbTraining"
import { getWorkContractList as getWorkContractList2, addWorkContract as addWorkContract2, editWorkContract as editWorkContract2, deleteWorkContract as deleteWorkContract2 } from "../Firebase2/Storage2/dbWorkContract"
import { getEmployerList } from '../Firebase2/Storage2/dbEmployers';
import firebase from 'firebase/compat/app'
import { ServiceBadge } from '../components/StructureGraphicElements.js';


const newworkcontract = {
	name: '',
	working_hours_per_week: 0,
	daily_working_hours: 0,
	weekly_overtime: 0,
	daysoff: 0,
	hourly_distance_between_shifts: 0,
	hours_of_leave: 0,
	note: '',
	qualification_list: []
}

const companyId = "0"
const two_row_limit = 5
let load_page = false








const WorkContractView = ({ t, uData }) => {
	const { domain } = useContext(DomainContext)
	const { contract, risk_area_list, risk_area_dict, } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid


	const [showEditWorkcontract, setShowWorkcontract] = useState(false);
	const [newEditWorkcontract, setNewWorkcontract] = useState(true);
	const [curElement, setCurrentElement] = useState(newworkcontract)
	const [element_list, setElementList] = useState([])
	const [edited_qualification_id_list, setEdited_qualification_id_list] = useState({})
	const [ui_list, setUiList] = useState({})
	const [canWrite, setWritePage] = useState(false)
	const [employer_list, setEmployers_list]= useState([])
	const [editedElement, setEditedElement] = useState(false)
	const [loading, setLoading] = useState(true)

	const handleClose = () => setShowWorkcontract(false);

	const handleSave = async () => {
		const newlist = [...element_list]
		if (newEditWorkcontract) {

			let return_data = await addWorkContract2(domain, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				newlist.push(return_data)
				setElementList(newlist)
				setShowWorkcontract(false);
				setEditedElement(false)
			}
		} else {
			let return_data = await editWorkContract2(domain, curElement.id, curElement)
			console.log(return_data)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const c = checkComponentPresence(curElement, element_list, 'id')
				newlist.splice(c, 1, curElement)
				setElementList(newlist)
				setShowWorkcontract(false);
				setEditedElement(false)
				for (const ql_id in edited_qualification_id_list){
					const el_list = employer_list.filter(e => e.qualification_id && e.qualification_id === ql_id)
					for (const e of el_list){
						console.log(e.need_training_list, e.clothing_list)

					}
				}
			}
		}
	}


	const setEditQL = (ql_id, ql) => {
		setEdited_qualification_id_list({...edited_qualification_id_list, [ql_id]:ql})
	}
	const handleShowNew = () => {
		setCurrentElement(newworkcontract)
		setNewWorkcontract(true)
		setShowWorkcontract(true)
		setEditedElement(false)
	}

	const handleShowEdit = (el) => {
		setCurrentElement(el)
		setNewWorkcontract(true)
		setNewWorkcontract(false)
		setShowWorkcontract(true)
		setEditedElement(false)
	}

	const handleDelete = (el) => {
		console.log(domain, contractId, el)
		const cb = async () => {
			let return_data = await deleteWorkContract2(domain, el.id)
			if (return_data && return_data.error) {
				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				const editlist = [...element_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				setElementList(editlist)
			}
		}
		childRefDelete.current.confirmDelete(t('workcontract.messages.delete_workcontract_title'), t('workcontract.messages.delete_workcontract'), cb)
	}

	const onChangeHandler = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curElement[name]
			if (event.checked) {
				oldcheck[value] = value;
			} else {
				delete oldcheck[value];
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurrentElement({ ...curElement, [name]: value })
		setEditedElement(true)
	}


	const initValues = async () => {
		if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('workcontract') !== -1)) {
			setWritePage(true)
		}
		setLoading(true)
		if (contract && domain.length > 0) {
			contractId = contract.id
			const qualification_title = await getBaseObjectTypeList2(domain, 'qualification_title')
			const study_titles = await getBaseObjectTypeList2(domain, 'study_titles')
			setUiList({ ...ui_list, study_titles, qualification_title })
			let new_list = await getWorkContractList2(domain, contractId)
			let _employers_list =  await getEmployerList(domain, contractId, {})
			setElementList(new_list.sort(compareName))
			setEmployers_list(_employers_list)
			setLoading(false)
		} else {
			console.log('problem')
		}
	}

	const onUpdateData = (data, b) => {
		//DEBUG: console.log("onUpdateData. DATA:", data, "   B: ", b)
		setUiList({ ...ui_list, [data]: b.list })
	}

	useEffect(() => {
		async function loadData() {
			contractId = cid
			console.log("OPERATIONS reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("OPERATIONS init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}

	}, [])


	useEffect(() => {
		console.log('OPERATIONS loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])




	return (
		<div>

			<DeleteConfirmationSection ref={childRefDelete} />
			<AlertSection ref={childRefAlert} />
			<Container fluid>

				<Row className="mt-1">
					<Col sm="6">
						<PageTitle title={t('navs.settings.workcontract')} />
					</Col>
					<Col sm={2}>
						<Form inline>
							<FormControl type="text" disabled placeholder={t('search')} className="" />
							<Button variant="outline-secondary"><FontAwesomeIcon icon="search" /></Button>
						</Form>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<Button onClick={() => handleShowNew()}><FontAwesomeIcon icon='plus-square' /> {t('workcontracts.buttons.add_new')}</Button>
					</Col>
				</Row>
				{element_list && element_list.length > 0 ?
					<>
						<Row className="p-1 text-light">
							<Col className="bg-secondary ms-1">{t('workcontracts.labels.name')}</Col>
							<Col className="bg-secondary ms-1">{t('workcontracts.labels.qualification_list')}</Col>
							<Col className="bg-secondary ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>

						{element_list.map((i, key) => (
							<Row className="oneCompany p-1 border-bottom" key={key}>
								<Col>{i.name}</Col>
								<Col>{i.qualification_list.map((q, k) => (
									<Row key={k}>
										<Col>{q.name}</Col>
										<Col>{getValue(q.level, 'name')}</Col>
									</Row>
								))}</Col>
								<Col className="text-center" sm="1" >
									<ButtonGroup>
										<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEdit(i)}><FontAwesomeIcon icon="pencil-alt" /> </Button>
										<Button variant="danger" size="sm" onClick={() => handleDelete(i)}><FontAwesomeIcon icon='trash' /> </Button>
									</ButtonGroup>
								</Col>

							</Row>
						))}
					</>
					: <> <Row><Col>
						{t('workcontracts.messages.no_workcontract')}
					</Col></Row>
					</>
				}




				<Modal show={showEditWorkcontract} onHide={handleClose} className="bg-secondary" size="xl">
					<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
						{newEditWorkcontract && <Modal.Title variant="primary">{t('workcontracts.modal.titlenew')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
						{!newEditWorkcontract && <Modal.Title variant="info">{t('workcontracts.modal.titleedit')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler, setEditQL:setEditQL }} ui_list={ui_list} />
						</Container>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t('modal.close')}
						</Button>
						<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSave}>
							<FontAwesomeIcon icon="save" />{t('modal.save')}
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>

		</div >
	);
}


function ElementFormDetails({ element, functions, t, ui_list }) {

	const newqualification = {
		id: 0,
		name: '',
		level: '',
		clothing_list: [],
	}
	const qualification_level_list = {
		1: { id: '1', label: '1°' },
		2: { id: '2', label: '2°' },
		3: { id: '3', label: '3°' },
		4: { id: '4', label: '4°' },
		5: { id: '5', label: '5°' },
		6: { id: '6', label: '6°' },
		7: { id: '7', label: '7°' },
		8: { id: '8', label: '8°' },
	}
	const [showQualificationEdit, setShowQualificationEdit] = useState(false)
	const [newQualificationEdit, setNewQualificationEdit] = useState(true)
	const [curQualification, setCurQualification] = useState(newqualification)

/* 	//----qualification
	const handleCloseQualification = () => setShowQualificationEdit(false);
	const handleSaveQualification = () => {
		if (newQualificationEdit) {
			let newqualification_list = element.qualification_list || []
			newqualification_list.push(curQualification)
			console.log(newqualification_list)
			functions.edit({ name: 'qualification_list', value: newqualification_list })
		} else {
			let editlist = element.qualification_list
			const c = checkComponentPresence(curQualification, element.qualification_list)
			editlist.splice(c, 1, curQualification)
			functions.edit({ name: 'qualification_list', value: editlist })
		}
		setShowQualificationEdit(false);
	} */
	const handleDeleteQualification = (el) => {
		console.log(el)
		let editlist = element.qualification_list
		const c = checkComponentPresence(el, element.qualification_list, 'id')
		editlist.splice(c, 1)
		functions.edit({ name: 'qualification_list', value: editlist })
	}
/* 	const handleShowNewQualification = () => {
		setCurQualification(newqualification)
		setNewQualificationEdit(true)
		setShowQualificationEdit(true)
	}
	const handleShowEditQualification = (i) => {
		console.log('edit', i)
		setCurQualification(i)
		setNewQualificationEdit(false)
		setShowQualificationEdit(true)
	}
	const onChangeHandlerQualification = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurQualification({ ...curQualification, [name]: value })
	} */

	return (
		<>
			<Form.Group as={Row} controlId="workcontractFirstName" className="border-bottom">
				<Form.Label column sm="4">{t('global.labels.name')}</Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Row>
				<Col className="text-end">
					<ModalQualification is_new={true} t={t} elem_parent={element} functions={functions} ui_list={ui_list} />
				</Col>
			</Row>
			<Row className="border-bottom  border-end mb-1 mt-1">
				{element.qualification_list && element.qualification_list.length > 0 ?
					<Col>
						<Row>
							<Col className="bg-secondary text-light ms-1">{t('global.labels.level')} </Col>
							<Col className="bg-secondary text-light ms-1">{t('global.labels.qualification')} </Col>
							<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
						</Row>
						{element.qualification_list.map((i, key) => (
							<Row key={key}>
								<Col>
									{getValue(i.level, 'name')}
								</Col>
								<Col>
									{i.name}
								</Col>
								<Col sm="1">
									<ButtonGroup>
										<ModalQualification is_new={false} t={t} element={i} elem_parent={element} functions={functions} ui_list={ui_list} />
										<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteQualification(i)} /> </Button>
									</ButtonGroup>
								</Col>
							</Row>))
						}
					</Col>
					: <Col> {t('workcontract.messages.no_qualification')}</Col>
				}
			</Row>


		</>
	)
}

function ModalQualification({ element, functions, t, is_new, ui_list, elem_parent }) {
	const { locale } = useContext(LocaleContext)

	const updateQualifications = (b) => {
		functions.updateData('qualification_title', b)
	}

	const newqualification = {
		name: '',
		id: makeid(7),
		level: {},
		title: '',
		service_list: [],
		depend_on: [],
		report_from: [],
		relates_to: [],
		clothing_list: [],
		active: true
	}
	const [showQualificationEdit, setShowQualificationEdit] = useState(false)
	const [newQualificationEdit, setNewQualificaionEdit] = useState(true)
	const [curQualification, setCurQualification] = useState(newqualification)
	const [editedElement, setEditedElement] = useState(false)


	const handleCloseQualification = () => setShowQualificationEdit(false);

	const handleSaveQualification = () => {
		let newqualification_list = elem_parent.qualification_list
		if (is_new) {
			newqualification_list.push(curQualification)
			console.log(newqualification_list)
			functions.edit({ name: 'qualification_list', value: newqualification_list })
		} else {
			const c = checkComponentPresence(curQualification, elem_parent.qualification_list, 'id')
			newqualification_list.splice(c, 1, curQualification)
			functions.edit({ name: 'qualification_list', value: newqualification_list })
			functions.setEditQL(curQualification.id, curQualification)
		}
		setShowQualificationEdit(false);
		setEditedElement(false)
	}


	const handleShowNewQualification = () => {
		setCurQualification(newqualification)
		setNewQualificaionEdit(true)
		setShowQualificationEdit(true)
		setEditedElement(false)
	}

	const handleShowEditQualification = () => {
		setCurQualification(element)
		setNewQualificaionEdit(false)
		setShowQualificationEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerQualification = (el) => {
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curQualification[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurQualification({ ...curQualification, [name]: value })
		setEditedElement(true)
	}


	return (
		<>
			{is_new
				? <Button variant="outline-primary" size="sm" onClick={() => handleShowNewQualification()}><FontAwesomeIcon icon="plus-square" />{t('workcontract.labels.qualification_add')}</Button>
				:
				<Button variant="outline-info" size="sm" onClick={() => handleShowEditQualification()}><FontAwesomeIcon icon="pencil-alt" /></Button>
			}

			<Modal show={showQualificationEdit} className="bg-secondary" fullscreen backdrop="static">
				<Modal.Header className={editedElement ? "bg-warning" : ""}>
					{newQualificationEdit && <Modal.Title><span className="text-primary"><FontAwesomeIcon icon="plus-square" />{t('employes.modal.titlenewqualification')}</span> {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!newQualificationEdit && <Modal.Title><span className="text-info"><FontAwesomeIcon icon="pencil-alt" />{t('employes.modal.titleeditqualification')} </span> {editedElement ? <FontAwesomeIcon icon="save" /> : ''} </Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Tab.Container id="modal-tab-navigator" defaultActiveKey="tabDetails">
							<Row>
								<Col sm={2}>
									<Nav variant="pills" className="flex-column">
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabDetails">{t('global.labels.details')} </Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabClothing">{t('workcontract.labels.clothing_list')}</Nav.Link>
										</Nav.Item>
										<Nav.Item className="mb-1">
											<Nav.Link variant="secondary" eventKey="tabTraining">{t('workcontract.labels.training')}</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
								<Col>
									<Tab.Content>
										<Tab.Pane eventKey="tabDetails">
											<ElementFormQualificationDetails element={curQualification} t={t} functions={{ edit: onChangeHandlerQualification }} ui_list={ui_list} element_list={elem_parent.qualification_list} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabClothing">
											<ElementFormOneQualificationClothings element={curQualification} functions={{ edit: onChangeHandlerQualification }} t={t} />
										</Tab.Pane>
										<Tab.Pane eventKey="tabTraining">
											<ElementFormOneQualificationTrainings element={curQualification} functions={{ edit: onChangeHandlerQualification }} t={t} />
										</Tab.Pane>
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>

					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseQualification}> {t('modal.close')}</Button>
					<Button className={editedElement ? "btn-warning" : "btn-myprimary"} form="companyForm" onClick={handleSaveQualification}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)

}





/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormQualificationDetails({ element, functions, t, ui_list, element_list }) {
	const { domain } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { service_dict } = useContext(GlobalDataContext)

	const [service_list, setServiceList] = useState([])

	const qualification_level_list = [
		{ Id: '1', name: '1°' },
		{ Id: '2', name: '2°' },
		{ Id: '3', name: '3°' },
		{ Id: '4', name: '4°' },
		{ Id: '5', name: '5°' },
		{ Id: '6', name: '6°' },
		{ Id: '7', name: '7°' },
		{ Id: '8', name: '8°' },
	]

	const initValues = async () => {
		let servicelist = Object.values(service_dict)
		servicelist.sort(compareName)
		setServiceList(servicelist)
	}

	useEffect(() => {
		initValues()
	}, [])
	return (
		<>
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.title')} </Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="name" defaultValue={element.name} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group> 
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.description')} </Form.Label>
				<Col sm="8">
					<Form.Control type="text" name="description" defaultValue={element.description} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontract.labels.knowledge')} </Form.Label>
				<Col sm="8">
					<Form.Control type="textarea" rows="3" name="knowledge" value={element.knowledge} onChange={(event) => functions.edit(event.target)} className="mb-2" />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.qualification_level')}</Form.Label>
				<Col sm="8">
					<Form.Control as="select" defaultValue={element.level.Id} name="level" onChange={(event) => functions.edit({ name: 'level', type: 'select', value: getSelectedElement(event.target.value, qualification_level_list) })} >
						<option value="-1" >{t('employee.labels.select_qualification_level')}</option>
						{qualification_level_list.map((i, k) => (
							<option value={i.Id} key={k}>{i.name}</option>
						))}
					</Form.Control>
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formEmployeeLevel" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('global.labels.service')}</Form.Label>
				<Col>
					<Form.Group as={Row} className="pb-1">
						{service_list.map((a, k) => (
							<Col key={k} sm="3" className={element.service_list && element.service_list.indexOf(a.id) >= 0? "m-1 bg-info text-light":' m-1 bg-light'}>

								<Form.Check key={k} type="checkbox" inline name="service_list" checked={element.service_list && element.service_list.indexOf(a.id) >= 0} value={a.id} label="" alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								<ServiceBadge service={a} freq={true}/>
							</Col>
						))}
					</Form.Group>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontracts.labels.depend_on')}</Form.Label>
				<Col>
					{element_list.length > 0 ?
						<Form.Group as={Row} className="pb-1">
							{element_list.filter(i => i.id !== element.id).map((a, k) => (
								<Col key={k} sm="3">
									<Form.Check key={k} type="checkbox" name="depend_on" checked={element.depend_on && element.depend_on.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						: <i>{t('workcontracts.messages.no_qualification')}</i>}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontracts.labels.report_from')}</Form.Label>
				<Col>
					{element_list.length > 0 ?
						<Form.Group as={Row} className="pb-1">
							{element_list.filter(i => i.id !== element.id).map((a, k) => (
								<Col key={k} sm="3">
									<Form.Check key={k} type="checkbox" name="report_from" checked={element.report_from && element.report_from.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						: <i>{t('workcontracts.messages.no_qualification')}</i>}
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('workcontracts.labels.relates_to')}</Form.Label>
				<Col>
					{element_list.length > 0 ?
						<Form.Group as={Row} className="pb-1">
							{element_list.filter(i => i.id !== element.id).map((a, k) => (
								<Col key={k} sm="3">
									<Form.Check key={k} type="checkbox" name="relates_to" checked={element.relates_to && element.relates_to.indexOf(a.id) >= 0} value={a.id} label={a.name} alt={a.name} onChange={(event) => functions.edit(event.currentTarget)} />
								</Col>
							))}
						</Form.Group>
						: <i>{t('workcontracts.messages.no_qualification')}</i>}
				</Col>
			</Form.Group>
			<Form.Group as={Row} controlId="formQ" className="border-bottom mb-1 pb-1">
				<Form.Label column sm="2">{t('employes.labels.qualification_active')}</Form.Label>
				<Col sm="8">
					<Form.Check type="radio" name="active" value={true} checked={(element.active === true)} label={t('global.labels.yes')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="radio" name="active" value={false} checked={(element.active === false)} label={t('global.labels.no')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
		</>
	)
}






function ElementFormOneQualificationClothings({ element, functions, t, ui_list }) {
	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)
	const { locale } = useContext(LocaleContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()
	const canWrite = true

	const newcomponent = {
		name: '',
		type: '',
		control_list: [],
		quantity: 1
	}
	const newgood = {
		name: '',
		control_list: [],
		category: '',
	}
	const newclothing = {
		name: '',
		category: '',
	}



	const economic_material_status = [
		'ottimo', 'buono', 'da sostituire'
	]
	const [clothing_list, setClothingList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showGoodEdit, setShowGoodEdit] = useState(false)
	const [curGood, setCurGood] = useState(newgood)
	const [newEdit, setNewEdit] = useState(true)



	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element[section]
		if (editlist) {
			const c = checkComponentPresence(el, element[section], 'id')
			if (c >= 0) {
				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				if (el.name.indexOf('cestino') >= 0) {
					el.is_trash = true
				}
				editlist.push({ ...el, quantity: 1 })
			}
		} else {
			editlist = [{ ...el, quantity: 1 }]
		}
		functions.edit({ name: section, value: editlist })
	}

	const handleSubtractEl = (el, section) => {
		console.log("SUB EL", section, el)
		let editlist = element[section]
		const c = checkComponentPresence(el, element[section], 'id')
		if (editlist[c].quantity > 1) {
			editlist[c].quantity = parseInt(editlist[c].quantity) - 1
		} else {

		}
		functions.edit({ name: section, value: editlist })
	}



	const handleDeleteEl = (el, list) => {
		console.log(el, list)
		const cb = () => {
			let editlist = element[list]
			const c = checkComponentPresence(el, element[list], 'id')
			editlist.splice(c, 1)
			functions.edit({ name: list, value: editlist })
		}

		if (list === 'clothing_list') {
			childRefDelete.current.confirmDelete(good_dict[list].delete_title, good_dict[list].delete, cb)
		}
	}


	//----good

	const handleShowNewGood = (list) => {
		newgood.good_type = list
		setCurGoodSection(good_dict[list])
		setCurGood(newgood)
		setNewEdit(true)
		setShowGoodEdit(true)
	}

	const handleShowEditGood = (i, list) => {
		i.good_type = list
		setCurGoodSection(good_dict[list])
		setCurGood(i)
		setNewEdit(false)
		setShowGoodEdit(true)
	}

	const handleCloseGood = () => setShowGoodEdit(false);

	const onChangeHandlerGood = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurGood({ ...curGood, [name]: value })
	}

	const handleSaveGood = async () => {
		console.log(curGoodSection, newEdit, curGood)
		if (curGoodSection.id === 'clothing_list') {
			if (newEdit) {
				let return_data = await addClothing2(domain, contractId, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...clothing_list]
					newlist.push(return_data)
					setClothingList(newlist)
					setShowGoodEdit(false);
				}
			} else {
				let return_data = await editClothing2(domain, contractId, curGood.id, curGood)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...clothing_list]
					const c = checkComponentPresence(curGood, clothing_list, 'id')
					newlist.splice(c, 1, curGood)
					setClothingList(newlist)
					setShowGoodEdit(false);
				}
			}



		}

	}

	const handleDeleteGood = (el, category_type) => {
		console.log('delete')
		const cb = async () => {
			let return_data
			if (category_type === 'clothing_list') {
				const editlist = [...clothing_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				return_data = await deleteClothing2(domain, contractId, el.id)
				setClothingList(editlist)
			}
			console.log(return_data)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(good_dict[category_type].delete_title, good_dict[category_type].delete, cb)
	}


	const initValues = async () => {
		let newclothing_list = await getClothingList2(domain, contractId)
		setClothingList(newclothing_list.sort(compareName))
	}


	const good_dict = {
		clothing_list: {
			id: 'clothing_list',
			title: t('global.labels.clothing'),
			available: t('workcontract.labels.avaiable_clothing'),
			empty: t('workcontract.messages.no_clothing'),
			add: t('workcontract.labels.clothing_add'),
			new: t('workcontracts.labels.new_clothing'),
			edit: t('workcontracts.labels.edit_clothing'),
			delete_title: t('workcontract.labels.delete_clothing_title'),
			delete: t('workcontract.labels.delete_clothing')

		},
	}

	const [curGoodSection, setCurGoodSection] = useState(good_dict['clothing_list'])




	const OneQualificationClothing = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="border-bottom mb-1 pb-1">
				<Col className="font-weight-bold">{i.name}</Col>
				<Col sm="3">{getBaseObjectValue(i.category, 'name', locale)}	</Col>
				<Col className="p-0 text-center" sm="5" md="4" xl="3" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i, section)} /> </Button>
				</Col>
			</Row>
		)

	}



	const OneGoodElement = ({ i, k, list }) => {
		return (
			<Row key={i.id + k} className={styles.source_row}>
				<Col sm="1" className="p-0 text-center">
					<Col className="p-0 text-center" sm="1">
						{checkComponentPresence(i, list, 'id') !== -1 ?
							<Button size="sm" variant="secondary" disabled><FontAwesomeIcon icon="check" /><FontAwesomeIcon icon="plus-square" /> </Button>
							:
							<Button size="sm" variant="light" onClick={() => handleAddEl(i, list)}><FontAwesomeIcon icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /> </Button>
						}
					</Col>
				</Col>
				<Col sm="3">{getBaseObjectValue(i.category, 'name', locale)}	</Col>
				<Col>{i.name}</Col>
			</Row>
		)
	}


	const OneSection = ({ i, k, list }) => {
		let good_list = clothing_list

		const [searchTerm, setSearchTerm] = useState('')
		const handleChangeSearch = (section, s) => {
			console.log(section, s)
			if (s && s.value.length > 0) {
				setSearchTerm(s.value)
			} else {
				setSearchTerm('')
			}
		}
		return (
			<>
				<Row className="border-bottom h5 mt-1">
					<Col sm="7" className="text-primary">
						{i.title}
					</Col>
					{showComponentEdit ?
						<>
							<Col className="text-info">{i.available}</Col>
							<Col className="text-end">
								<ModalClothing is_new={true} t={t} item={clothing_list} setItem={setClothingList} item_list={clothing_list} canWrite={canWrite} />
							</Col>
						</>
						: ''}
				</Row>
				<Row className="border-bottom border-dark mb-4 pb-2">


					{element[list] && element[list].length > 0 ?
						<Col className="border-end">
							<Row >
								<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
								{list === 'clothing_list' ?
									<>
										<Col className="bg-secondary text-light ms-1" sm="4" md="3" xl="3" >{t('global.labels.actions')} </Col>
									</>
									: ''}
							</Row>
							{element[list].map((i, key) => (

								<OneQualificationClothing i={i} key={key} k={key} section={list} showComponentEdit={showComponentEdit} element_list={element[list]} />

							))}

						</Col>
						: <Col> {i.empty}</Col>
					}
					{showComponentEdit
						?
						<>
							<Col sm="1"></Col>
							<Col className="border-start editSection">
								{good_list && good_list.length > 0 ?
									<>
										<Row >
											<Col className="bg-secondary text-light ms-1" sm="1">&nbsp;</Col>
											<Col className="bg-secondary text-light ms-1" sm="3">{t('structure.labels.component_category')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
										</Row>
										{good_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm[list].toLowerCase()) >= 0))).map((i, key) => (
											<OneGoodElement i={i} key={k} k={k} list={list} />
										))}
									</>
									: <> {i.empty}</>
								}
							</Col>
						</>
						: ''
					}
				</Row>
			</>
		)

	}




	useEffect(() => {
		initValues()
	}, [])

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Row className="mb-1">
				<Col className="text-end">
					{showComponentEdit ?
						<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
						:
						<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('structure.labels.component_mod')}</Button>
					}
				</Col>
			</Row>

			{Object.keys(good_dict).map((section, k) => (
				<OneSection i={good_dict[section]} list={section} key={k} />
			))}






			<Modal show={showGoodEdit} onHide={handleCloseGood} >
				<Modal.Header closeButton>
					{newEdit && <Modal.Title>{curGoodSection.new}</Modal.Title>}
					{!newEdit && <Modal.Title>{curGoodSection.edit}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.title')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.name} type="text" name="name" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.category')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curGood.category} type="text" name="category" onChange={(event) => onChangeHandlerGood(event.target)} />
							</Col>
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseGood}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveGood}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}







function ElementFormOneQualificationTrainings({ element, functions, t, ui_list }) {
	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)

	const childRefAlert = useRef()
	const childRefDelete = useRef()

	const { locale } = useContext(LocaleContext)
	const newcomponent = {
		name: '',
		type: '',
		control_list: [],
		quantity: 1
	}
	const newgood = {
		name: '',
		control_list: [],
		category: '',
	}
	const newtraining = {
		name: '',
		description: '',
		hour_length: 4,
		expiration: true,
		expiration_months: 24,
	}


	const [training_list, setTrainingList] = useState([])
	const [showComponentEdit, setShowComponentEdit] = useState(false)
	const [newComponentEdit, setNewComponentEdit] = useState(true)
	const [curComponent, setCurComponent] = useState(newcomponent)
	const [showTrainingEdit, setShowTrainingEdit] = useState(false)
	const [curTraining, setCurTraining] = useState(newtraining)
	const [newEdit, setNewEdit] = useState(true)



	const handleCloseComposition = () => setShowComponentEdit(false);

	const handleOpenComposition = () => {
		setCurComponent(newcomponent)
		setNewComponentEdit(true)
		setShowComponentEdit(true)
	}

	//---------------generic function
	const handleAddEl = (el, section) => {
		console.log("ADD EL", section, el)
		let editlist = element[section]
		if (editlist) {
			const c = checkComponentPresence(el, element[section], 'id')
			if (c >= 0) {
				editlist[c].quantity = parseInt(editlist[c].quantity) + 1
			} else {
				editlist.push({ ...el })
			}
		} else {
			editlist = [{ ...el}]
		}
		functions.edit({ name: section, value: editlist })
	}




	const handleDeleteEl = (el, list) => {
		console.log(el, list)
		const cb = () => {
			let editlist = element[list]
			const c = checkComponentPresence(el, element[list], 'id')
			editlist.splice(c, 1)
			functions.edit({ name: list, value: editlist })
		}

		if (list === 'training_list') {
			childRefDelete.current.confirmDelete(good_dict[list].delete_title, good_dict[list].delete, cb)
		}
	}

	//----good

	const handleShowNewGood = (list) => {
		newgood.good_type = list
		setCurGoodSection(good_dict[list])
		setCurTraining(newgood)
		setNewEdit(true)
		setShowTrainingEdit(true)
	}

	const handleShowEditGood = (i, list) => {
		i.good_type = list
		setCurGoodSection(good_dict[list])
		setCurTraining(i)
		setNewEdit(false)
		setShowTrainingEdit(true)
	}

	const handleCloseGood = () => setShowTrainingEdit(false);

	const onChangeHandlerTraining = (event) => {
		console.log(event)
		let { name, value, type } = event
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		}
		console.log(name, value)
		setCurTraining({ ...curTraining, [name]: value })
	}

	const handleSaveGood = async () => {
		console.log(curGoodSection, newEdit, curTraining)
		if (curGoodSection.id === 'training_list') {
			if (newEdit) {
				let return_data = await addTraining2(domain, contractId, curTraining)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...training_list]
					newlist.push(return_data)
					setTrainingList(newlist)
					setShowTrainingEdit(false);
				}
			} else {
				let return_data = await editTraining2(domain, contractId, curTraining.id, curTraining)
				console.log(return_data)
				if (return_data && return_data.error) {
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					const newlist = [...training_list]
					const c = checkComponentPresence(curTraining, training_list, 'id')
					newlist.splice(c, 1, curTraining)
					setTrainingList(newlist)
					setShowTrainingEdit(false);
				}
			}



		}

	}

	const handleDeleteTraining = (el, category_type) => {
		console.log('delete')
		const cb = async () => {
			let return_data
			if (category_type === 'training_list') {
				const editlist = [...training_list]
				const c = checkComponentPresence(el, editlist, 'id')
				editlist.splice(c, 1)
				return_data = await deleteTraining2(domain, contractId, el.id)
				setTrainingList(editlist)
			}
			console.log(return_data)
		}
		console.log(el)
		childRefDelete.current.confirmDelete(good_dict[category_type].delete_title, good_dict[category_type].delete, cb)
	}


	const initValues = async () => {
		let newtraining_list = await getTrainingList2(domain, contractId)
		setTrainingList(newtraining_list.sort(compareName))
	}


	const good_dict = {
		training_list: {
			id: 'training_list',
			title: t('global.labels.training'),
			available: t('workcontract.labels.avaiable_training'),
			empty: t('workcontract.messages.no_training'),
			add: t('workcontract.labels.training_add'),
			new: t('workcontracts.labels.new_training'),
			edit: t('workcontracts.labels.edit_training'),
			delete_title: t('workcontract.labels.delete_training_title'),
			delete: t('workcontract.labels.delete_training')

		},
	}

	const [curGoodSection, setCurGoodSection] = useState(good_dict['training_list'])




	const OneQualificationTraining = ({ i, k, element_list, section, showComponentEdit }) => {
		return (

			<Row key={k} className="p-1">
				<Col sm="3">{i.code}</Col>
				<Col>{i.name}</Col>
				<Col>{i.description}</Col>
				<Col className="p-0" sm="5" md="4" xl="3" >
					<Button size="sm" variant="outline-danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteEl(i, section)} /> </Button>
				</Col>
			</Row>
		)

	}


	const OneTraining = ({ i, k, list }) => {
		return (
			<Row key={k} className="border-bottom p-1">
				<Col sm="1" className="p-0 text-center">
					<Button size="sm" variant="light" onClick={() => handleAddEl(i, list)}><FontAwesomeIcon className="text-warning" icon="arrow-left" />&nbsp;<FontAwesomeIcon icon="plus-square" /> </Button>
				</Col>
				<Col sm="3">{i.code}</Col>
				<Col>{i.name}</Col>
				<Col sm="1" className="p-0 text-center">
					<Button size="sm" variant="outline-info" onClick={() => handleShowEditGood(i, list)}><FontAwesomeIcon icon="pencil-alt" /></Button>
{/* 					<Button size="sm" variant="outline-danger" onClick={() => handleDeleteTraining(i, list)}><FontAwesomeIcon icon="trash" /> </Button> */}
				</Col>
			</Row>
		)
	}


	const OneSection = ({ i, k, list }) => {
		let good_list = training_list

		const [searchTerm, setSearchTerm] = useState('')
		const handleChangeSearch = (section, s) => {
			console.log(section, s)
			if (s && s.value.length > 0) {
				setSearchTerm(s.value)
			} else {
				setSearchTerm('')
			}
		}
		return (
			<>
				<Row className="border-bottom h5 mt-1">
					<Col sm="7" className="text-primary">
						{i.title}
					</Col>
					{showComponentEdit ?
						<>
							<Col className="text-info">{i.available}</Col>
							<Col className="text-end"><Button variant="outline-primary" size="sm" onClick={() => handleShowNewGood(list)}><FontAwesomeIcon icon="plus-square" /> {i.add}</Button></Col>
						</>
						: ''}
				</Row>
				<Row className="border-bottom border-dark mb-4 pb-2">


					{element[list] && element[list].length > 0 ?
						<Col className="border-end">
							<Row >
								<Col className="bg-secondary text-light ms-1" sm="3">{t('global.labels.code')} </Col>
								<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
							</Row>
							{element[list].sort(propComparator('code')).map((i, key) => (

								<OneQualificationTraining i={i} key={key} k={key} section={list} showComponentEdit={showComponentEdit} element_list={element[list]} />

							))}

						</Col>
						: <Col className="font-italic"> {i.empty}</Col>
					}
					{showComponentEdit
						?
						<>
							<Col className="border-start editSection">
								{good_list && good_list.length > 0 ?
									<>
										<Row >
										
											<Col className="bg-secondary text-light ms-1" sm="3">{t('global.labels.code')} </Col>
											<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
											<Col className="bg-secondary text-light ms-1" sm="2">{t('global.labels.actions')} </Col>
										</Row>
										{good_list.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm[list].toLowerCase()) >= 0))).map((i, key) => (
											<OneTraining i={i} key={key} k={key} list={list} />
										))}
									</>
									: <i> {i.empty}</i>
								}
							</Col>
						</>
						: ''
					}
				</Row>
			</>
		)

	}




	useEffect(() => {
		initValues()
	}, [])

	return (
		<>
			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Row className="mb-1">
				<Col className="text-end">
					{showComponentEdit ?
						<Button variant="outline-secondary" size="sm" onClick={() => handleCloseComposition()}><FontAwesomeIcon icon="check" /> {t('structure.labels.component_modend')}</Button>
						:
						<Button variant="outline-primary" size="sm" onClick={() => handleOpenComposition()}><FontAwesomeIcon icon="pencil-alt" /> {t('workcontracts.labels.training_mod')}</Button>
					}
				</Col>
			</Row>

			{Object.keys(good_dict).map((section, k) => (
				<OneSection i={good_dict[section]} list={section} key={k} />
			))}






			<Modal show={showTrainingEdit} onHide={handleCloseGood} >
				<Modal.Header closeButton>
					{newEdit && <Modal.Title>{curGoodSection.new}</Modal.Title>}
					{!newEdit && <Modal.Title>{curGoodSection.edit}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.name')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curTraining.name} type="text" name="name" onChange={(event) => onChangeHandlerTraining(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" value={curTraining.description} type="text" name="description" onChange={(event) => onChangeHandlerTraining(event.target)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('global.labels.hour_length')}</Form.Label>
							<Col sm="4">
								<InputGroup className="mb-2">
									<Form.Control size="sm" value={curTraining.hour_length} type="number" min="1" max="24" name="hour_length" onChange={(event) => onChangeHandlerTraining(event.target)} />
									
										<InputGroup.Text size="sm"> {t('global.labels.hours')} </InputGroup.Text>
									
								</InputGroup>
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="roomtypeName">
							<Form.Label column sm="2">{t('workcontracts.labels.expiration')}</Form.Label>
							<Col sm="4">
								<Form.Check type="radio" name="expiration" value={true} checked={curTraining.expiration} label={t('global.labels.yes')} onChange={(event) => onChangeHandlerTraining(event.currentTarget)} />
								<Form.Check type="radio" name="expiration" value={false} checked={!curTraining.expiration} label={t('global.labels.no')} onChange={(event) => onChangeHandlerTraining(event.currentTarget)} />
							</Col>
							{curTraining.expiration ?
								<Col sm="4">
									<InputGroup className="mb-2">
										<Form.Control size="sm" value={curTraining.expiration_months} type="number" min="1" max="72" name="expiration_months" onChange={(event) => onChangeHandlerTraining(event.target)} />
										
											<InputGroup.Text size="sm"> {t('global.labels.months')} </InputGroup.Text>
										
									</InputGroup>
								</Col>
								: ''
							}
						</Form.Group>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseGood}>
						{t('modal.close')}
					</Button>
					<Button className="btn-myprimary" form="companyForm" onClick={handleSaveGood}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>


		</>
	)
}



export default translate()(WorkContractView)