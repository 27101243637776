
import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, Spinner, ButtonGroup, Badge, Card } from 'react-bootstrap';
import { ModalInitButton } from '../../components/StructureGraphicElements.js'

import { getSelectedElement, getValue } from "../../util/ui_utils.js"


import { getAllDepartmentInStructure as getAllDepartmentInStructure2, getAllFloorInStructure, getFloorById, getPavillonById, getStructureList as getStructureList2 } from "../../Firebase2/Storage2/dbBaseCompany"



import { getWorkareaList } from '../../Firebase2/Storage2/dbWorkarea';
import ModalAdvanceUserPermission from './ModalAdvanceUserPermission';

let preload = false




export const ModalUserContractPermission = ({ is_new, element, t, functions, ui_list, user_displayname, index, domain, contract_list, element_list, not_contract }) => {

	const newcontractgroup = {
		contract_id: -1,
		structure: {},
		department: {},
		workarea: {},
		mode: 'structure',
		permissiongroup: [],
		dashboardsection: []
	}

	const [structure_list, setStructureList] = useState([])
	const [department_list, setDepartmentList] = useState([])
	const [workarea_list, setWorkareaList] = useState([])

	const [showContractgroupEdit, setShowContractgroupEdit] = useState(false)
	const [curContractgroup, setCurContractgroup] = useState(newcontractgroup)
	const [editedElement, setEditedElement] = useState(false)
	const [loading, setLoading] = useState(false)


	const handleCloseContractgroup = () => setShowContractgroupEdit(false);

	const handleSaveContractgroup = async () => {
		console.log(domain, curContractgroup)
		if (is_new) {
			let newcontractgroups = element.contractgroups || []
			newcontractgroups.push(curContractgroup)
			console.log(newcontractgroups)
			functions.edit({ name: 'contractgroups', value: newcontractgroups })
		} else {
			let editlist = element_list
			//            const c = checkComponentPresence(curContractgroup, element.contractgroups)
			editlist.splice(index, 1, curContractgroup)
			functions.edit({ name: 'contractgroups', value: editlist })
		}
		setShowContractgroupEdit(false);
	}

	const handleShowNew = () => {
		preload = false
		console.log("new contractGroup (preload)", preload)
		setCurContractgroup(newcontractgroup)
		setShowContractgroupEdit(true)
		setEditedElement(false)
	}


	const handleShowEdit = async () => {
		preload = true
		console.log('edit contractGroup (element, index, preload)', element, index, preload)
		setShowContractgroupEdit(true)
		setCurContractgroup(element)
		setEditedElement(false)
	}


	useEffect(() => {
		console.log("UPDATE contract contract group: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeContract({ value: element.contract_id })
			if (!element.structure.id) {
				console.log("END LOAD INITIAL DATA contract")
				preload = false
			}
		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [curContractgroup.contract_id])


	useEffect(() => {
		async function preloadNext() {
			const ret_data = await onChangeContract({ value: curContractgroup.contract_id })
		}
		preloadNext()

	}, [curContractgroup.mode])


	useEffect(() => {
		console.log("UPDATE contract group structure: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeStructure({ value: getValue(element.structure, 'id') })
			if (!element.department.id) {
				console.log("END LOAD INITIAL DATA structrue")
				preload = false
			}
		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [structure_list])




	useEffect(() => {
		console.log("UPDATE contract group, department: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeDepartment({ value: getValue(element.department, 'id') })
			console.log("END LOAD INITIAL DATA department")
			preload = false
		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [department_list])


	useEffect(() => {
		console.log("UPDATE contract group workarea: preload", preload)
		async function preloadNext() {
			const ret_data = await onChangeWorkarea({ value: getValue(element.workarea, 'id') })
			if (!element.department.id) {
				console.log("END LOAD INITIAL DATA workarea")
				preload = false
			}

		}
		if (preload && showContractgroupEdit) {
			preloadNext()
		}
	}, [workarea_list])



	const onChangeContract = async (el) => {
		console.log("Change contractid", domain, el)
		if (preload) {
			setCurContractgroup({ ...curContractgroup, contract_id: el.value })
		} else {
			setCurContractgroup({ ...curContractgroup, contract_id: el.value, structure: {}, department: {}, workarea: {} })
			setEditedElement(true)

		}

		if (!not_contract) {

			if (curContractgroup.mode === 'structure') {

				const return_data = await getStructureList2(domain, el.value) || []
				if (return_data && return_data.error) {
					console.log(return_data)
					return false
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					setStructureList(return_data)
					console.log('structure_list', return_data)
					return return_data
				}
			} else {
				const return_data = await getWorkareaList(domain, el.value) || []
				if (return_data && return_data.error) {
					console.log(return_data)
					return false
					//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
				} else {
					setWorkareaList(return_data)
					console.log('workarea_list', return_data)
					return return_data
				}
			}
		}

	}

	const onChangeWorkarea = async (el) => {
		if (el) {
			const workarea_id = el.value

			console.log(" workareaid", el, el.value, workarea_list)
			const workarea = getSelectedElement(workarea_id, workarea_list, 'id')
			console.log("SEL workarea", workarea_id, el.value, workarea.id, workarea)
			if (preload) {
				setCurContractgroup({ ...curContractgroup, workarea: { id: workarea.id, name: workarea.name } })
			} else {
				setCurContractgroup({ ...curContractgroup, workarea: { id: workarea.id, name: workarea.name }, department: {}, structure: {} })
				setEditedElement(true)

			}
		}

	}

	const onChangeStructure = async (el) => {
		//	setLoading(true)
		console.log("structure", el)
		if (el) {
			setLoading(true)
			const structure_id = el.value

			console.log(" structureid", el, el.value, structure_list)
			const structure = getSelectedElement(structure_id, structure_list, 'id')
			console.log("SEL STRUCTURE", structure_id, el.value, structure.id, structure)
			if (preload) {
				setCurContractgroup({ ...curContractgroup, structure: { id: structure.id, name: structure.name } })

			} else {
				setCurContractgroup({ ...curContractgroup, structure: { id: structure.id, name: structure.name }, department: {}, workarea: {} })
				setEditedElement(true)

			}
			//		functions.edit({ name: el.name, value: structure })
			let return_data = await getAllDepartmentInStructure2(structure_id)
			if (return_data && return_data.error) {
				console.error(return_data)
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				let f_dict = {}
				let p_dict = {}
				for (let d of return_data) {
					if (f_dict[d.parent_list[2]]) {
						d.floor_name = f_dict[d.parent_list[2]].name
					} else {
						const f = await getFloorById(domain, curContractgroup.contract_id, structure_id, d.parent_list[1], d.parent_list[2])
						if (!f.error) {
							f_dict[d.parent_list[2]] = f
							d.floor_name = f.name
						}
					}
					if (p_dict[d.parent_list[1]]) {
						d.pavillon_name = p_dict[d.parent_list[1]].name
					} else {
						const _p = await getPavillonById(domain, curContractgroup.contract_id, structure_id, d.parent_list[1])
						console.log("PAV", _p)
						if (!_p.error) {
							p_dict[d.parent_list[1]] = _p
							d.pavillon_name = _p.name
						}
					}

				}
				setDepartmentList(return_data)
				//			setRoomList([])
				setLoading(false)
				console.log('department_list', el.value, return_data)
			}
		}
	}


	const onChangeDepartment = async (el) => {
		console.log("deparmtn id", el, department_list)
		if (!preload) {

			setEditedElement(true)
		}
		const department = getSelectedElement(el.value, department_list, 'id')
		setCurContractgroup({ ...curContractgroup, department: { id: department.id, name: department.name } })
	}





	const onChangeHandlerContractgroup = (el) => {
		let { name, value, type } = el
		let sub = undefined;
		try {
			sub = el.getAttribute('sub') || el.sub
		} catch (err) { }
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curContractgroup[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		setCurContractgroup({ ...curContractgroup, [name]: value })
	}





	return (
		<>

			<ModalInitButton t={t} is_new={is_new} canWrite={true} title_new={not_contract ? t('user.labels.add_catalog_permission') : t('user.labels.add_contract_permission')} action_new={handleShowNew} action_edit={handleShowEdit} action_info={handleShowEdit} is_secondary={true} />
			<Modal show={showContractgroupEdit} onHide={handleCloseContractgroup} scrollable={true} fullscreen backdrop="static" className="bg-secondary">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""} >
					<Modal.Title>

						{is_new ?
							<>
								<FontAwesomeIcon icon="plus-square" /> <span className="text-primary">{not_contract ? t('users.modal.title_newcataloggroupassing') : t('users.modal.title_newcontractgroupassing')}</span>
							</>
							:
							<>
								<FontAwesomeIcon icon="pencil-alt" /> <span className="text-info">{not_contract ? t('users.modal.editcataloggrouppassing') : t('users.modal.editcontractgrouppassing')} </span>
							</>
						}&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="formEquipmentBrand" className="border-bottom">
							<Form.Label column sm="2">{not_contract ? t('global.labels.catalog') : t('global.labels.contract')}</Form.Label>
							<Col sm="8">
								<Form.Control as="select" value={curContractgroup.contract_id} name="contract_id" className="mb-2" onChange={(event) => onChangeContract({ name: 'contract_id', type: 'select', value: event.target.value })}  >
									<option value="-1" >{not_contract ? t('catalog.labels.select_catalog') : t('contract.labels.select_contract')}</option>
									{contract_list.map((f, k) => (
										<option key={k} value={f.id} >{f.name}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						{curContractgroup.contract_id !== -1 ?
							<>
								<Form.Group as={Row} controlId="userGroups" className="pb-1 border-bottom mb-1">
									<Form.Label column sm="2">{t('global.labels.roles')}</Form.Label>
									<Col>
										<Row>
											{Object.keys(ui_list.group_list).map((g, k) => (
												<Col key={k} sm="3">
													<Form.Check type="checkbox" name="permissiongroup" checked={curContractgroup.permissiongroup.indexOf(ui_list.group_list[g].id) >= 0} value={ui_list.group_list[g].id} label={ui_list.group_list[g].name} onChange={(event) => onChangeHandlerContractgroup(event.currentTarget)} />
												</Col>
											))}
											<Col sm="4">
												<ModalAdvanceUserPermission item={curContractgroup.custompermission} setItem={(p) => functions.edit({ name: 'custompermission', value: p })} />
											</Col>
										</Row>
									</Col>
								</Form.Group>
								{!not_contract ?
									<>
										<Form.Group as={Row} controlId="userGroups" className="pb-1 border-bottom mb-1">
											<Form.Label column sm="2">{t('users.labels.access_mode')}</Form.Label>
											<Col sm="3">
												<Form.Check type="radio" name="mode" value={'structure'} checked={curContractgroup.mode === 'structure'} label={t('global.labels.structure')} onChange={(event) => onChangeHandlerContractgroup(event.currentTarget)} />
												<Form.Check type="radio" name="mode" value={'workarea'} checked={curContractgroup.mode === 'workarea'} label={t('global.labels.workarea')} onChange={(event) => onChangeHandlerContractgroup(event.currentTarget)} />
											</Col>
										</Form.Group>
										{curContractgroup.mode === 'structure' || curContractgroup.mode === undefined ?
											<Form.Group as={Row} controlId="userGroups" className="pb-1">
												<Form.Label column sm="2">{t('users.labels.filter_structure')}
												</Form.Label>
												<Col sm="3">
													<Form.Control as="select" className="mb-2" name="structure" value={getValue(curContractgroup.structure, 'id')} onChange={(event) => onChangeStructure(event.currentTarget)} >
														<option value="-1" >{t('structure.labels.all_structure')}</option>
														{structure_list
															? [Object(structure_list).map((f, k) => (
																<option key={k} value={f.id} >{f.name}</option>
															))]
															: ''
														}
													</Form.Control>
												</Col>
												<Col sm="5">
													{loading ? <Spinner animation="border" role="status">
														<span className="sr-only">Loading...</span>
													</Spinner> : <>
														<Form.Control as="select" className="mb-2" name="department" value={getValue(curContractgroup.department, 'id')} onChange={(event) => onChangeDepartment(event.currentTarget)} >
															<option value="-1" >{t('structure.labels.all_department')}</option>
															{department_list
																? [Object(department_list).map((f, k) => (
																	<option key={k} value={f.id} >{f.pavillon_name} | {f.floor_name} | {f.name}</option>
																))]
																: ''}
														</Form.Control>
													</>}
												</Col>
											</Form.Group>
											:
											<Form.Group as={Row} controlId="userGroups" className="pb-1">
												<Form.Label column sm="2">{t('users.labels.filter_workarea')}
													{loading ? <Spinner animation="border" role="status">
														<span className="sr-only">Loading...</span>
													</Spinner> : ''}</Form.Label>
												<Col sm="3">
													<Form.Control as="select" className="mb-2" name="workarea" value={getValue(curContractgroup.workarea, 'id')} onChange={(event) => onChangeWorkarea(event.currentTarget)} >
														<option value="-1" >{t('workarea.labels.all_workarea')}</option>
														{workarea_list
															? [Object(workarea_list).map((f, k) => (
																<option key={k} value={f.id} >{f.name}</option>
															))]
															: ''
														}
													</Form.Control>
												</Col>
											</Form.Group>
										}
									</>
									: ''}
							</> : ''}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseContractgroup}>
						{t('modal.close')}
					</Button>
					<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveContractgroup}>
						<FontAwesomeIcon icon="save" /> {t('modal.save')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
