import React, { useState, useEffect, useRef, createRef, useContext } from 'react';
import { Modal, Container, Row, Col, Form, Button, ButtonGroup, Spinner, Popover, OverlayTrigger, Badge, Tooltip } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import { addHours, format, getDay, set, setHours, setMinutes } from 'date-fns'
// pick a date util library
import DateTime from 'react-datetime';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import 'react-day-picker/lib/style.css';
import { it } from 'date-fns/locale'

import { serviceType_list } from "../components/constants/global_definitions"
import { useReactToPrint } from 'react-to-print';

import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"
import PrintFooter from './generic/PrintFooter';


import { createEventId } from '../event-utils'
import PageTitle from "../components/PageTitle"
import Footer from "../components/Footer"

import { myTimestampToDate, getValue, getSelectedElementB, getSelectedElement, getElementCount } from "../util/ui_utils.js"
import { ItemProgress, renderFrequency, ServiceBadge, RiskareaBadge, RoomInfoBadge, MyBadge } from '../components/StructureGraphicElements.js'


import firebase from 'firebase/compat/app'


import { getEventRange as getEventRange2, addEvent as addEvent2 } from '../Firebase2/Storage2/dbAgenda';

import { getInterventionById as getInterventionById2 } from '../Firebase2/Storage2/dbIntervention'








let curdate = { start: new Date(), end: new Date() }
const newEvent = {
    start: new Date(),
    end: new Date(),
    id: createEventId(),
    extendedProps: {
        subject: '',
        location: '',
        shared: -1,
        reminder: false,
        reminder_time: 15,
        note: '',
        periodic_data: {
            date_list: [],
            start: '6:00',
            end: '8:00'
        }
    }
};
let filterAppliedList = { structure: [], department: [], type: [] }



let department_dict = {}
let used_service_list = []
let intervention_dict = {}

let permission_department_list = []
let permission_structure_list = []

let load_page = false

const defaultFilter = { structure: [], department: [], type: [] }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const PlanningCheck = ({ t, ui_list }) => {
    //console.log("plannint", uData)

    const { domain } = useContext(DomainContext)
    const { locale } = useContext(LocaleContext)
    const { contract, structureElements, risk_area_dict, service_dict, operation_dict, } = useContext(GlobalDataContext)

    const { initContract } = InitContractHelper()
    const calendarRef = createRef()
    const componentRef = useRef();

    let { cid } = useParams();
    let contractId = cid
    const service_type = serviceType_list({ t })


    const [title, setTitle] = useState('')
    const [showEdit, setShow] = useState(false);
    const [showViewEvent, setShowView] = useState(false);
    const [newEdit, setNew] = useState(true);
    const [curEvent, setCurEvent] = useState(newEvent);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [loading, setLoading] = useState(true)
    //  const [intervention_dict, setInterventionDict] = useState({})
    //  const [filterAppliedList, setFilterAppliedList] = useState({ department: [], type: [] })
    //  const [department_dict, setDepartmentDict] = useState({})
    //  const [used_service_list, setUsedServiceList] = useState([])


    const updateAppliedFilter = (section, elem) => {
        const c = filterAppliedList[section].indexOf(elem)
        console.log('index', c)
        let f_data = [...filterAppliedList[section]]
        if (c === -1) {
            f_data.push(elem)
        } else {
            f_data.splice(c, 1)
        }
        //   setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
        filterAppliedList = { ...filterAppliedList, [section]: f_data }
        let calendarApi = calendarRef.current.getApi()
        //    setShowFilter(false)
        calendarApi.refetchEvents()
    }

    const resetAppliedFilter = (section) => {
        filterAppliedList = { ...filterAppliedList, [section]: [] }
        setShowFilter(false)
        let calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents()

    }

    useEffect(() => {

    }, [filterAppliedList])



    const onChangeHandler = (event) => {
        let { name, value } = event;
        if (name !== 'start' && name !== 'end') {
            console.log(name, value)
            let newprops = { ...curEvent.extendedProps, [name]: value }
            setCurEvent({ ...curEvent, extendedProps: newprops })
        } else {
            try {
                const d = myTimestampToDate(value)
                value = set(name === 'end'? curEvent.start: curEvent.end, { hours: d.getHours(), minutes: d.getMinutes() })
            } catch (err) {

            }
            setCurEvent({ ...curEvent, [name]: value })
            console.log("newEventDate", curEvent)
        }
    }


    const handleClose = () => {
        setShow(false)
    }


    const handleCloseViewEvent = () => {
        setShowView(false)
    }


    const [showFilterEvent, setShowFilter] = useState(false)

    const handleCloseFilterEvent = () => {
        setShowFilter(false)
    }


    const handleSave = async () => {
        setShow(false)
        handleEventAdd(curEvent)

        const return_data = await addEvent2(domain, contractId, curEvent)
        console.log(return_data)
    }




    const handleDateSelect = (selectInfo) => {
        console.log("handleDateSelect - selectInfo:", selectInfo)
        //    setCurEvent({ ...newEvent, start: selectInfo.start, end: selectInfo.end })
        //  setNew(true)
        //    setShow(true)
    }




    const getEventList = async (el) => {
        console.log("start data", el)
        if (el.start >= curdate.start && el.end <= curdate.end) {
            console.log('*************keep data')
        } else {
            curdate = el
        }
        setLoading(true)

        let temp_intervention_dict = { ...intervention_dict }
        let temp_department_dict = { ...department_dict }
        let temp_service_list = [...used_service_list]
        //console.log(temp_department_dict)
        //    console.log(temp_intervention_dict)
        let event_list = []

        if (contract && domain.length > 0) {

            let return_data = await getEventRange2(domain, contractId, firebase.firestore.Timestamp.fromDate(el.start), firebase.firestore.Timestamp.fromDate(el.end))

            //    return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention'))
            return_data = return_data.filter(e => (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention'))
            console.log("EVENT return data", return_data)
            //      console.log(uData)
            //      for (let d = 0; d < return_data.length; d++) {
            for (const event of return_data) {
                let insert = false
                if (filterAppliedList.type.length === 0 || filterAppliedList.type.indexOf(event.extendedProps.type) !== -1) {
                    event.start = myTimestampToDate(event.start)
                    event.end = myTimestampToDate(event.end)
                    //          console.log("return_data[" + d + "].extendedProps: ", return_data[d].extendedProps)
                    if (event.extendedProps.type === 'ordinary_intervention') {
                        //console.log("----", event.extendedProps.intervention_id, permission_department_list, event.extendedProps.department_list)
                        //db_workarea_list = db_workarea_list.filter(w => w.structure_list.some(d => permission_structure_list.indexOf(d) !== -1))
                        //            if (return_data[d].extendedProps.intervention_id && (permission_department_list.length === 0 || permission_department_list.indexOf(return_data[d].extendedProps.intervention_id) >= 0)) {
                        if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
                            //          console.log(return_data[d].extendedProps.intervention_id, temp_intervention_dict[return_data[d].extendedProps.intervention_id])
                            const response = temp_intervention_dict[event.extendedProps.intervention_id] ||
                                (await getInterventionById2(domain, contractId, event.extendedProps.intervention_id))
                            let department_list_id = []
                            console.log(response)
                            if (response) {
                                for (const room of response.room_list) {
                                    //                const r = response.room_list[rr]

                                    if (!temp_department_dict[room.parent_list[3]]) {
                                        temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
                                    }
                                    if (department_list_id.indexOf(room.parent_list[3]) === -1) {
                                        department_list_id.push(room.parent_list[3])
                                    }

                                    if (filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) {
                                        insert = true
                                    } else {
                                        console.log(filterAppliedList)
                                    }
                                }
                                temp_intervention_dict[event.extendedProps.intervention_id] = response
                                event.extendedProps.name = response.name + " " + response.service.name
                                event.extendedProps.department_list_id = department_list_id
                                if (temp_service_list.indexOf(response.service.id) !== -1) {
                                    temp_service_list.push(response.service.id)
                                }
                                event.extendedProps.intervention_data = response
                                event.extendedProps.summary = "Totale Locali: " + response.room_count

                            }
                        } else {
                            event.extendedProps.name = "Intervento ordinario"  // FIXME
                            event.extendedProps.summary = "DB OLD DATA"

                        }
                        event.backgroundColor = 'Grey'

                    } else if (event.extendedProps.type === 'periodic_intervention') {
                        //if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || permission_department_list.indexOf(event.extendedProps.intervention_id) >= 0)) {
                        if (event.extendedProps.intervention_id && (permission_department_list.length === 0 || event.extendedProps.department_list.some(d => permission_department_list.indexOf(d) !== -1))) {
                            const response = temp_intervention_dict[event.extendedProps.intervention_id] ||
                                (await getInterventionById2(domain, contractId, event.extendedProps.intervention_id))
                            temp_intervention_dict[event.extendedProps.intervention_id] = response
                            event.extendedProps.name = response.name + " " + response.service.name
                            event.extendedProps.intervention_data = response
                            event.extendedProps.summary = "Totale Locali: " + response.room_count
                            let department_list_id = []
                            for (const room of response.room_list) {

                                if (!temp_department_dict[room.parent_list[3]]) {
                                    temp_department_dict[room.parent_list[3]] = structureElements.all_department_dict[room.parent_list[3]]
                                }
                                if (department_list_id.indexOf(room.parent_list[3]) === -1) {
                                    department_list_id.push(room.parent_list[3])
                                }
                                if (filterAppliedList.department.length === 0 || filterAppliedList.department.indexOf(room.parent_list[3]) !== -1) {
                                    insert = true
                                } else {
                                    console.log(filterAppliedList)
                                }
                            }
                            if (temp_service_list.indexOf(response.service.id) !== -1) {
                                temp_service_list.push(response.service.id)
                            }
                            event.extendedProps.department_list_id = department_list_id
                        } else {
                            event.extendedProps.name = "Intervento periodico"  // FIXME
                            event.extendedProps.summary = "DB OLD DATA"
                        }
                        event.backgroundColor = 'LightGrey'
                    }
                    if (insert) {
                        event_list.push(event)
                    }
                }
            }

            console.log('RETURN DATA Afiltered', event_list)
            used_service_list = temp_service_list
            department_dict = temp_department_dict
            intervention_dict = temp_intervention_dict

        }
        console.log("EVENT COUNT")
        setCurrentEvents(event_list)
        setLoading(false)
        return event_list

    }




    const handleEventClick = (clickInfo) => {
        console.log("myevent", clickInfo.event.extendedProps)
        if (clickInfo.event.extendedProps.type === 'ordinary_intervention') {
            setCurEvent({
                ...newEvent,
                id: clickInfo.event.id,
                start: clickInfo.event.start,
                end: clickInfo.event.end,
                extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
            })
            setShowView(true)
        } else if (clickInfo.event.extendedProps.type === 'periodic_intervention') {
            setCurEvent({
                ...newEvent,
                id: clickInfo.event.id,
                start: clickInfo.event.start,
                end: clickInfo.event.end,
                extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
            })
            setShowView(true)
        } else if (clickInfo.event.extendedProps.type === 'request_ticket') {
            setCurEvent({
                ...newEvent,
                id: clickInfo.event.id,
                start: clickInfo.event.start,
                end: clickInfo.event.end,
                extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
            })
            setShowView(true)
        } else if (clickInfo.event.extendedProps.type === 'reporting_ticket') {
            setCurEvent({
                ...newEvent,
                id: clickInfo.event.id,
                start: clickInfo.event.start,
                end: clickInfo.event.end,
                extendedProps: { ...clickInfo.event.extendedProps, name: clickInfo.event.extendedProps.name }
            })
            setShowView(true)
        } else {
            setCurEvent({ ...newEvent, id: clickInfo.event.id, start: clickInfo.event.start, end: clickInfo.event.end, extendedProps: clickInfo.event.extendedProps })
            console.log(curEvent)
            setNew(false)
            setShow(true)
        }
    }



    const handleEventAdd = (addInfo) => {
        console.log(addInfo)

    }


    const handleEvents = (events) => {
        setCurrentEvents(events)
    }

    const handlePrint = useReactToPrint({
        content: () => {
            const tableStat = componentRef.current.cloneNode(true);
            const PrintElem = document.createElement('div');
            PrintElem.appendChild(tableStat);
            return PrintElem;
        },
        pageStyle: pageStyle,
        documentTitle: `Planning controlli: `,
    });


    const initValues = async () => {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents()

    }

    useEffect(() => {
        async function loadData() {
            let contractId = cid
            console.log("PLANNING reinit contract from param", contractId)
            if (contractId && contractId !== null) {
                const ret_data = await initContract(contractId)
                console.log("PLANNING init contract return", ret_data)
            }

        }
        if (!contract) {
            loadData()

        }
    }, [])




    useEffect(() => {
        console.log('PLANNING loadingcontract', contract)
        if (contract && !load_page) {
            initValues()
        }
    }, [contract])


    useEffect(() => {
        console.log('update ref')
        if (calendarRef && calendarRef.current) {
            let calendarApi = calendarRef.current.getApi()
            console.log(calendarApi.view)
            setTitle(calendarApi.view.title)

        }

    }, [calendarRef])

    const serviceColor = (s) => {

        try {
            const service = service_dict[s.id] || s

            return (
                <MyBadge
                    color=
                        {`rgba(${getValue(service.color, 'r')}, ${getValue(service.color, 'g')}, ${getValue(service.color, 'b')}, ${getValue(service.color, 'a')})`}>
                &nbsp;</MyBadge>
            )
        } catch (err) {
            console.log(err)
            return (<Badge></Badge>)
        }

    }

    // --------------------------------------------------------
    const renderEventContent = (info) => {
        if (info.timeText.length === 2) {
            info.timeText = info.timeText + ":00"
        }
        let evtId = "event-" + info.event.id;
        let tt = ''
        let room_count = ''
        try {
            tt = format(info.event.start, 'HH:mm', { locale: it }) + " - " + format(info.event.end, 'HH:mm', { locale: it })
        } catch (err) {
            //console.log(info.event.start, info.event.end)
        }
        if (info.event.extendedProps.type === 'ordinary_intervention') {
            try {
                room_count = info.event.extendedProps.intervention_data.room_list.filter(room => (
                    getSelectedElementB(info.event.extendedProps.intervention_data.service.id, ui_list.roomtype_dict[room.roomtype.id].service_list, 'service', 'id').weekdays.indexOf(getDay(info.event.start).toString()) !== -1
                )).length


            } catch (err) {
                console.log(err)
            }
        } else {
            room_count = info.event.extendedProps.intervention_data.room_list.length
        }

        const popover = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">{info.event.extendedProps.type === 'ordinary_intervention' ? t('workarea.labels.ordinary_intervention') : t('workarea.labels.periodic_intervention')}:&nbsp;{info.event.extendedProps.intervention_data.name}</Popover.Header>
                <Popover.Body>
                    <Container>
                        <Row>
                            <Col>
                                <ServiceBadge service={ui_list.service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {tt}
                                &nbsp;|&nbsp;
                                <FontAwesomeIcon icon="user" /> &times; {info.event.extendedProps.intervention_data.operating_units}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {info.event.extendedProps.department_list_id ?
                                    <>
                                        {info.event.extendedProps.department_list_id.map((d, k) => (
                                            <Badge bg="light" text="dark" key={k} className="me-1">
                                                {department_dict[d].name}
                                            </Badge>
                                        ))}
                                    </>
                                    : ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {room_count}
                                &nbsp;
                                locali

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {info.event.extendedProps.intervention_data.risk_area_summary ? <ItemProgress risk_list={info.event.extendedProps.intervention_data.risk_area_summary} item={info.event.extendedProps.intervention_data} ui_list={ui_list} /> : ''}
                            </Col>
                        </Row>
                    </Container>
                </Popover.Body>
            </Popover>
        );

        if (info.view.type === 'dayGridMonth') { //------mensile

            if (info.event.extendedProps.type === 'ordinary_intervention') {

                return (
                    <OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
                        <div className="fc-content" id={evtId} >
                            <span className="fc-title"><MyBadge colorRgb ={info.backgroundColor } className="text-light">{info.timeText}</MyBadge>{serviceColor(info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}</span>
                        </div>
                    </OverlayTrigger>
                )
            } else if (info.event.extendedProps.type === 'periodic_intervention') {
                return (
                    <OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
                        <div className="fc-content" id={evtId} >
                            <span className="fc-title"><MyBadge colorRgb={info.backgroundColor} className="text-dark">{info.timeText}</MyBadge>{serviceColor(info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}</span>
                        </div>
                    </OverlayTrigger>
                )

            } else {
                return (
                    <>
                        <b>{info.timeText}</b>
                        <i>{info.event.title}</i>
                    </>
                )

            }
        } else if (info.view.type === 'timeGridWeek') {  //-------settimanale

            if (info.event.extendedProps.type === 'ordinary_intervention') {
                return (
                    <OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
                        <div className="fc-content" id={evtId}>
                            <span className="fc-title">

                                {serviceColor(info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.name}</span>
                        </div>
                    </OverlayTrigger>
                )
            } else {
                return (
                    <OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
                        <div id={evtId} className="fc-content text-dark">
                            <span className="fc-title">{serviceColor(info.event.extendedProps.intervention_data.service)} {info.event.extendedProps.intervention_data.service.name}</span>
                        </div>
                    </OverlayTrigger>
                )

            }



        } else { //-----------------------giornaliero

            if (info.event.extendedProps.type === 'ordinary_intervention') {
                try {
                    return (
                        <OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
                            <div className="fc-content" id={evtId}>
                                <span className="fc-title"><b>{info.timeText}</b>: <ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} /> {getValue(info.event.extendedProps, 'name')}</span>
                            </div>
                        </OverlayTrigger>
                    )
                } catch (err) {
                    return (
                        <>
                            <b>{info.timeText}</b>
                            <br />
                        </>
                    )

                }
            } else if (info.event.extendedProps.type === 'periodic_intervention') {

                try {
                    return (
                        <OverlayTrigger trigger={['focus', 'hover']} placement="bottom" overlay={popover}>
                            <div className="fc-content" id={evtId}>
                                <span className="fc-title"><b>{info.timeText}</b>: <ServiceBadge service={service_dict[info.event.extendedProps.intervention_data.service.id]} freq={true} /> {getValue(info.event.extendedProps, 'name')}</span>
                            </div>
                        </OverlayTrigger>
                    )
                } catch (err) {
                    return (
                        <>
                            <b>{info.timeText}</b>
                            <br />
                        </>
                    )

                }
            } else if (info.event.extendedProps.type === 'request_ticket') {

            } else if (info.event.extendedProps.type === 'reporting_ticket') {

            }
            else {
                return (
                    <>
                        <b>{info.timeText}</b>
                        <i>{info.event.title}</i>
                    </>
                )

            }

        }
    }



    // --------------------------------------------------------
    const OneRoomRow = ({ room, index, intervention_type }) => {
        const i = getSelectedElementB(curEvent.extendedProps.intervention_data.service.id, ui_list.roomtype_dict[room.roomtype.id].service_list, 'service', 'id')
        return (
            <Row key={room.id + index} className={intervention_type === 'periodic_intervention' || i.weekdays.indexOf(getDay(curEvent.start).toString()) !== -1 ? 'border-bottom border-start pb-1 mt-1 bg-light border-success' : 'border-bottom pb-1 mt-1 text-secondary'} >
                <Col>
                    {i.weekdays.indexOf(getDay(curEvent.start).toString()) !== -1 ?
                        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="right-start" overlay={<Tooltip>locale presente da programmazione nel giorno selezionato</Tooltip>}>
                            <FontAwesomeIcon icon="check" />
                        </OverlayTrigger>
                        : ''}
                    <RoomInfoBadge parent_list={room.parent_list} />
                    {room.name}
                </Col>
                <Col className="text-muted">
                    {room.roomtype.name}
                </Col>
                <Col sm="2">
                    {renderFrequency(room.roomtype.id, curEvent.extendedProps.intervention_data.service.id, ui_list, getDay(curEvent.start).toString())}
                </Col>
                <Col sm="1">{room.size}</Col>
                <Col sm="2 text-center">
                    <RiskareaBadge riskarea={risk_area_dict[room.risk_area.Id]} />
                </Col>
                <Col sm="1">
                    {getValue(room, 'cdc')}
                </Col>
            </Row>
        )

    }



    // --------------------------------------------------------
    const EventInfo = () => {
        try {
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {format(curEvent.start, 'cccc dd/MM (HH:mm', { locale: it })} - {format(curEvent.end, 'HH:mm)', { locale: it })} | {getValue(curEvent.extendedProps.intervention_data, 'name')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    {curEvent.extendedProps.type === 'ordinary_intervention' ?
                                        (t('workarea.labels.ordinary_intervention'))
                                        : (t('workarea.labels.periodic_intervention'))
                                    }
                                </Col>
                                <Col>
                                    <ServiceBadge service={service_dict[curEvent.extendedProps.intervention_data.service.id]} freq={true} />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {curEvent.extendedProps.intervention_data && curEvent.extendedProps.intervention_data.room_list && curEvent.extendedProps.intervention_data.room_list.length > 0 ?
                                        <>
                                            <Row className="p-1 text-light mt-4">
                                                <Col className="ms-1 text-info" >{t('workarea.labels.roomlist')}</Col>
                                            </Row>
                                            <Row className="p-1 mb-1">
                                                {/* 			<Col className="ms-1 bg-light text-secondary" >{t('global.labels.position')}</Col> */}
                                                <Col className="ms-1 bg-secondary text-light" >{t('global.labels.name')}</Col>
                                                <Col className="ms-1 bg-secondary text-light" >{t('structures.labels.room_type')}</Col>
                                                <Col className="ms-1 bg-secondary text-light" sm="2" ></Col>
                                                <Col className="ms-1 bg-secondary text-light text-center" sm="1" >m<sup>2</sup></Col>
                                                <Col className="ms-1 bg-secondary text-light text-center" sm="2" >
                                                    {contract.type === 'civil' ?
                                                        t('global.labels.standard_cleaning_short')
                                                        :
                                                        t('global.labels.risk_area_short')
                                                    }
                                                </Col>
                                                <Col className="ms-1 bg-secondary text-light" sm="1" >Cdc</Col>
                                            </Row>
                                            {curEvent.extendedProps.intervention_data.room_list.map((room, index) => (
                                                <OneRoomRow key={index} room={room} intervention_type={curEvent.extendedProps.type} />

                                            ))}
                                        </>
                                        : ''
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </>
            )
        } catch (err) {
            console.log(err, curEvent)
            return (
                <Modal.Body>

                </Modal.Body>
            )

        }

    }


    //######/////####////calenda API external call
    function handleCalendarNext() {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.next()
    }

    function handleCalendarPrev() {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.prev()
    }

    function handleCalendarHome() {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.today()
    }

    function handleCalendarChangeView(view) {
        let calendarApi = calendarRef.current.getApi()
        calendarApi.changeView(view)
    }


    function getCalendarTitle(myref) {
        try {
            let calendarApi = myref.current.getApi()
            console.log(calendarApi.view)
            return (calendarApi.view.title)
        } catch (err) {
            return undefined

        }

    }

    const CalendarTitle = ({ myref }) => {
        const [title, setTitle] = useState('')
        useEffect(() => {
            console.log('update ref')
            if (myref && myref.current) {
                let calendarApi = myref.current.getApi()
                console.log(calendarApi.view)
                setTitle(calendarApi.view.title)

            }

        }, [myref])
        try {
            let calendarApi = myref.current.getApi()
            console.log(calendarApi.view)
            return title

        } catch (err) {
            return (<></>)

        }

    }





    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="6">
                        <b>{title}</b>
                        {loading ?
                            <>
                                <Spinner animation="border" role="status" />
                                &nbsp;
                                Caricamento in corso
                            </>
                            : ''}
                    </Col>
                    <Col className="text-end" >

                        <ModalFilter t={t} defaultfilter={defaultFilter} calendarRef={calendarRef} className="me-2" />

                        <ButtonGroup className="ms-2 me-2">
                            <Button variant="secondary" size="sm" onClick={handleCalendarPrev} ><FontAwesomeIcon icon="angle-left" /></Button>
                            <Button variant="secondary" size="sm" onClick={handleCalendarHome} ><FontAwesomeIcon icon="home" /></Button>
                            <Button variant="secondary" size="sm" onClick={handleCalendarNext} ><FontAwesomeIcon icon="angle-right" /></Button>
                        </ButtonGroup>
                    </Col>
                </Row>

            </Container>



            <Container fluid id="domContainer">
                <Row>
                    {/*
          <Col sm="2">
            <HelpButton title='Istruzioni' text='<ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
          ' mycontainer={myref} />
            {renderSidebar()}

          </Col>
          */}
                    <Col ref={componentRef}>
                        <style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>
                        <FullCalendar

                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={false}
                            buttonText={
                                {
                                    today: 'oggi',
                                    month: 'mese',
                                    week: 'settimana',
                                    day: 'giorno',
                                    list: 'lista'
                                }

                            }
                            allDaySlot={false}
                            allDayText={'tutto il giorno'}
                            firstDay="1"
                            locale='it'
                            initialView='dayGridMonth'
                            eventResize={false}
                            editable={false}
                            selectable={false}
                            height='auto'
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={true}
                            slotLabelFormat={{ hour: "numeric", minute: "2-digit", omitZeroMinute: false }}
                            slotDuration="00:15:00"
                            initialEvents={getEventList}
                            select={handleDateSelect}
                            eventContent={renderEventContent}
                            eventClick={handleEventClick}
                            eventsSet={handleEvents}
                            eventAdd={handleEventAdd}
                        /* 
                        eventAdd={function(){}}
                        eventChange={function(){}}
                        eventRemove={function(){}}
                        */
                        />
                    </Col>
                </Row>

                <Modal show={showEdit} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        {newEdit && <Modal.Title>{t('agenda.labels.new_event')}</Modal.Title>}
                        {!newEdit && <Modal.Title>{t('agenda.labels.edit_event')}</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <Form >
                            <Form.Group as={Row} controlId="eventSubject">
                                <Form.Label column sm="2">{t('agenda.labels.subject')}</Form.Label>
                                <Col sm="8">
                                    <Form.Control size="sm" type="text" name="subject" value={curEvent.extendedProps.subject} onChange={(event) => onChangeHandler(event.currentTarget)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="eventLocation">
                                <Form.Label column sm="2">{t('agenda.labels.location')}</Form.Label>
                                <Col sm="8">
                                    <Form.Control size="sm" type="text" name="location" defaultValue={curEvent.extendedProps.location} onChange={(event) => onChangeHandler(event.currentTarget)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="">
                                <Form.Label column sm="2">{t('global.labels.note')}</Form.Label>
                                <Col sm="8">
                                    <Form.Control size="sm" type="text" name="note" defaultValue={curEvent.extendedProps.note} onChange={(event) => onChangeHandler(event.currentTarget)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="eventShare">
                                <Form.Label column sm="2">Visibilità</Form.Label>
                                <Col sm="8">
                                    <Form.Check type="radio" name="shaded" value="-1" checked={parseInt(curEvent.extendedProps.shared) === -1} label="no" onChange={(event) => onChangeHandler(event.currentTarget)} />
                                    <Form.Check type="radio" name="shared" value="0" checked={parseInt(curEvent.extendedProps.shared) === 0} label="Si (condiviso)" onChange={(event) => onChangeHandler(event.currentTarget)} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="eventStart">
                                <Form.Label column sm="2">{t('agenda.labels.start')}</Form.Label>
                                <Col sm="8">
                                    <DateTime initialValue={myTimestampToDate(curEvent.start)} dateFormat='DD/MM/YYYY' timeFormat="HH:mm" name="start" onChange={(e) => onChangeHandler({ name: 'start', value: e })} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="eventStart">
                                <Form.Label column sm="2">{t('agenda.labels.end')}</Form.Label>
                                <Col sm="8">
                                    <DateTime initialValue={myTimestampToDate(curEvent.end)} dateFormat='DD/MM/YYYY' timeFormat="HH:mm" name="end" locale="it" onChange={(e) => onChangeHandler({ name: 'end', value: e })} />
                                </Col>
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t('modal.close')}
                        </Button>
                        <Button className="btn-myprimary" form="companyForm" onClick={handleSave}>
                            <FontAwesomeIcon icon="save" />{t('modal.save')}
                        </Button>
                    </Modal.Footer>
                </Modal>






                <Modal show={showViewEvent} onHide={handleCloseViewEvent} size="xl">
                    <EventInfo />
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseViewEvent}>
                            {t('modal.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={showFilterEvent} onHide={handleCloseFilterEvent} size="xl">
                    <Modal.Header>
                        <Modal.Title>

                            <FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                {t('global.labels.structure')}
                            </Row>
                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        {structureElements.structure_list.map((g, k) => (
                                            <Button key={k} size="sm" variant={filterAppliedList.structure.indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('structure', g.id)}>
                                                {g.name}
                                            </Button>
                                        ))}
                                        {filterAppliedList.structure.length > 0 ?
                                            <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
                                            : ''}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseFilterEvent}>
                            {t('modal.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        </>
    )
}



const FilterButton = ({ d, filterAppliedList, department_dict, updateAppliedFilter, structureElements, show_inactive }) => {
    try {
        if (show_inactive) {

            return (

                <Button className="m-1" size="sm" variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"} onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>
                    {getValue(getSelectedElement(department_dict[d].parent_list[0], structureElements.structure_list, 'id'), 'name')}:
                    {department_dict[d].name}
                </Button>
            )
        } else {
            return (

                <Button size="sm" className={filterAppliedList.department.indexOf(department_dict[d].id) !== -1 ? '' : 'd-none'}
                    variant={filterAppliedList.department.indexOf(department_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
                    onClick={(e) => updateAppliedFilter('department', department_dict[d].id)}>{
                        department_dict[d].name} <FontAwesomeIcon icon="times" />
                </Button>
            )

        }
    } catch (err) {
        return (

            <Button className="m-1" size="sm" variant="outline-secondary" disabled >
                {d}
            </Button>
        )

    }

}





////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
const ModalFilter = ({ t, defaultfilter, event_list, calendarRef }) => {
    const { contract, service_dict, structureElements } = useContext(GlobalDataContext)

    const [showFilter, setShowFilter] = useState(false)


    const service_type = serviceType_list({ t })


    const handleCloseFilter = () => {
        setShowFilter(false)
    }

    const resetAppliedFilter = (section) => {
        if (section) {
            filterAppliedList = { ...filterAppliedList, [section]: [] }
        } else {
            filterAppliedList = (defaultfilter)
        }
        let calendarApi = calendarRef.current.getApi()
        calendarApi.refetchEvents()
        setShowFilter(false)
    }

    const updateAppliedFilter = (section, elem) => {
        const c = filterAppliedList[section].indexOf(elem)
        let f_data = [...filterAppliedList[section]]
        if (c === -1) {
            f_data.push(elem)
        } else {
            f_data.splice(c, 1)
        }
        let calendarApi = calendarRef.current.getApi()
        filterAppliedList = { ...filterAppliedList, [section]: f_data }
        calendarApi.refetchEvents()
    }


    function checkInterventionPresence(d) {
        for (let i in intervention_dict) {
            if (intervention_dict[i].service && intervention_dict[i].service.id === d) {
                return true
            }
        }
        return false
    }

    return (
        <>

            <ButtonGroup>
                <Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
                {filterAppliedList.structure.length > 0 ?
                    <>
                        {filterAppliedList.structure.map((s, sk) => (
                            <Button size="sm" variant="outline" onClick={() => updateAppliedFilter('structure', s)}>{structureElements.all_structure_dict[s].name}</Button>
                        ))}
                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
                    </>
                    : ''}
                {filterAppliedList.check_mode.length > 0 ?
                    <>
                        {filterAppliedList.check_mode.indexOf('autocheck') >= 0 ?
                            <Button size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('check_mode', 'autocheck')}>
                                {t('contract.labels.autocheck')}
                            </Button>
                            : ''}
                        {filterAppliedList.check_mode.indexOf('contradictory') >= 0 ?
                            <Button size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('check_mode', 'contradictory')}>
                                {t('contract.labels.contradictory')}
                            </Button>
                            : ''}
                        {filterAppliedList.check_mode.indexOf('client_checks') >= 0 ?
                            <Button size="sm" variant="secondary" onClick={(e) => updateAppliedFilter('check_mode', 'client_checks')}>
                                {t('contract.labels.client_checks')}
                            </Button>
                            : ''}
                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('check_mode')}><FontAwesomeIcon icon="times" /></Button>
                    </>

                    : ''}
            </ButtonGroup>




            <Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
                <Modal.Header>
                    <Modal.Title>

                        <FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            {t('global.labels.mode')}
                        </Row>
                        <Row>
                            <Col>
                                <ButtonGroup>
                                    {contract && contract.autocheck && contract.autocheck.length > 0 ?
                                        <Button size="sm" variant={filterAppliedList.check_mode.indexOf('autocheck') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('check_mode', 'autocheck')}>
                                            {t('contract.labels.autocheck')}
                                        </Button>
                                        : ''}
                                    {contract && contract.contradictory && contract.contradictory.length > 0 ?
                                        <Button size="sm" variant={filterAppliedList.check_mode.indexOf('contradictory') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('check_mode', 'contradictory')}>
                                            {t('contract.labels.contradictory')}
                                        </Button>
                                        : ''}
                                    {contract && contract.client_checks && contract.client_checks.length > 0 ?
                                        <Button size="sm" variant={filterAppliedList.check_mode.indexOf('client_checks') === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('check_mode', 'client_checks')}>
                                            {t('contract.labels.client_checks')}
                                        </Button>
                                        : ''}
                                    {filterAppliedList.check_mode.length > 0 ?
                                        <Button variant="outline-secondary" onClick={() => resetAppliedFilter('check_mode')}><FontAwesomeIcon icon="times" /></Button>
                                        : ''}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            {t('global.labels.structure')}
                        </Row>
                        <Row>
                            <Col>
                                <ButtonGroup>
                                    {structureElements.structure_list.map((g, k) => (
                                        <Button key={k} size="sm" variant={filterAppliedList.structure.indexOf(g.id) === -1 ? 'outline-secondary' : "secondary"} onClick={(e) => updateAppliedFilter('structure', g.id)}>
                                            {g.name}
                                        </Button>
                                    ))}
                                    {filterAppliedList.structure.length > 0 ?
                                        <Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('structure')}><FontAwesomeIcon icon="times" /></Button>
                                        : ''}
                                </ButtonGroup>
                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilter}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}









































export default translate()(PlanningCheck)






const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  div.page-footer{
	display:none;
  }
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
	div.page-footer {
	display:block;
		position: fixed;
		z-index: 9;
		bottom: 0;
		width: 100%;
		height: 50px;
		font-size: 9px;
		color: #000;
		page-break-after: always;
	  }
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;


