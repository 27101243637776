import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
const DeleteconfirmationSection = forwardRef((props, ref) => {
    const def_dialog = {
        message: '',
        title: 'conferma',
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(def_dialog)
    useImperativeHandle(ref, () => ({
        confirmDelete(title, message, confirmCallback, undoCallback, icon, btn_class) {
            setConfirmDialog({
                message: message,
                title: title,
                saveCallback: confirmCallback,
                undoCallback: undoCallback,
                icon: icon || 'trash', 
                btn_class: btn_class || 'danger'
            })
            setShowConfirmModal(true)
        }
    }));
    const handleClose = () => {
        if (confirmDialog.undoCallback) {
            try {
                confirmDialog.undoCallback()
            } catch (err) {
                console.log( "(DeleteconfirmationSection) ", err)
            }
        }
        setShowConfirmModal(false)
    }
    const handleSave = () => {
        if (confirmDialog.saveCallback) {
            try {
                confirmDialog.saveCallback()
            } catch (err) {
                console.log("|||||error|||||", err)
            }
        }
        setShowConfirmModal(false)
    }

    return <div className="deleteconfirmation_container">
        <Modal show={showConfirmModal} onHide={handleClose} centered   className="bg-secondary">
            <Modal.Header closeButton>
                <Modal.Title>{confirmDialog.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html:  confirmDialog.message}}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    <FontAwesomeIcon icon="times" />Annulla
                </Button>
                <Button variant={confirmDialog.btn_class} onClick={handleSave}>
                    <FontAwesomeIcon icon={confirmDialog.icon} />Conferma 
						</Button>
            </Modal.Footer>
        </Modal>
    </div>
})
export default DeleteconfirmationSection